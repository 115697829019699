import axios from "axios";
import { IParamsOrders } from "../../models/params";
import { paramsConfig } from "../../utils/ParamsUtils";

// const API_PATH_CIS = process.env.REACT_APP_API_URL + "/concept-answers";

const BASE_URL = process.env.REACT_APP_EMR_API_URL;
const API_PATH_CIS = process.env.REACT_APP_CIS_API_URL;
const API_PATH_PMS = process.env.REACT_APP_PMS_API_URL;
const API_PATH_ADMIN = process.env.REACT_APP_ADMIN_API_URL;
const API_PATH_DICH_VU = BASE_URL + "/medical-exam/request-service";
const API_PATH_ORDER_GROUP = BASE_URL + "/order-groups";
const API_PATH_ORDER = BASE_URL + "/orders";
const MOCK_API = "https://647f3a78c246f166da905f3b.mockapi.io";

export const searchByPage = (searchObject: any) => {
  let url = API_PATH_CIS + "/concept-answers/search";
  let config = {
    params: {
      // pageIndex: searchObject?.pageIndex,
      // pageSize: searchObject?.pageSize,
      // parentCode: searchObject?.parentCode,
      // conceptId: searchObject?.conceptId,
      parentId: searchObject?.parentId,
      conceptId: searchObject?.conceptId
    }
  }
  return axios.get(url, config);
};

export const getDSLoaiDichVu = (searchObject: any) => {
  let url = API_PATH_CIS + "/concepts/services/types/DSDV";
  return axios.get(url);
};

export const getDSNhomDichVu = (searchObject: any) => {
  let url = API_PATH_CIS + "/concept-answers/groups/" + searchObject?.parentCode;
  return axios.get(url);
};

export const getPhongThucHien = (searchObject: any) => {
  // let url = API_PATH_ADMIN + "/department/page";
  let url = API_PATH_ADMIN + "/room/page";
  return axios.get(url);
};

export const createDSChiDinhDV = (data: any) => {
  let url = API_PATH_DICH_VU;
  return axios.post(url, data);
};

export const updateDSChiDinhDV = (data: any) => {
  let url = API_PATH_DICH_VU;
  return axios.put(url, data);
};

export const deleteDSChiDinhDV = (id: string ) => {
  let url = API_PATH_ORDER + `/order-group/${id}`
  return axios.delete(url);
};

export const getDSNhomDVDaChiDinh = (params: IParamsOrders ) => {
  let url = API_PATH_ORDER_GROUP;
  return axios.get(url, paramsConfig(params));
};

export const getDSCongKhamDaChiDinh = (params: IParamsOrders) => {
  let url = API_PATH_ORDER;
  return axios.get(url, paramsConfig(params));
};

export const getDSDVDaChiDinh = (params: IParamsOrders) => {
  let url = API_PATH_ORDER + "/details";
  return axios.get(url, paramsConfig(params));
};

export const getThongTinPhieuChiDinh = (params: IParamsOrders) => {
  let url = API_PATH_DICH_VU;
  return axios.get(url, paramsConfig(params));
}