const columnThongTinVienPhi = [
  { title: "Tên dịch vụ", field: "termSvcNameClone", className: "spaces w-16" },
  { title: "Đơn vị", field: "svcUnit", className: "spaces width-20px" },
  {
    title: "Đối tượng",
    field: "visitObjectTypeName",
    className: "spaces width-30px text-center",
  },
  {
    title: "SL",
    field: "svcQuantity",
    className: "spaces text-center width-10px",
  },
  {
    title: "Đơn giá",
    field: "svcPriceClone",
    className: "spaces width-25px text-end",
  },
  { title: "% TT", field: "phanTramThanhToan", className: "spaces width-18px" },
  {
    title: "Thành tiền",
    field: "svcTotalClone",
    className: "spaces width-32px text-end",
  },
  { title: "BHYT Trả", field: "BHYTTra", className: "spaces width-28px" },
  { title: "% bh", field: "phanTramBaoHiem", className: "spaces width-20px" },
  { title: "BN trả", field: "benhNhanTra", className: "spaces width-25px" },
  { title: "#bncct", field: "bncct", className: "spaces width-35px" },
  { title: "#bntt", field: "bntt", className: "spaces width-30px" },
  { title: "Đã thu", field: "svcCollected", className: "spaces width-35px text-end" },
  { title: "#Đã thu-CCT", field: "daThuCCT", className: "spaces width-65px" },
  { title: "#Đã thu-bntt", field: "daThubntt", className: "spaces width-65px" },
  { title: "Yêu cầu thu", field: "yeuCauThu", className: "spaces width-65px" },
];

export { columnThongTinVienPhi };
