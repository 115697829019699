import { Column } from 'react-table';

import { TableCustomCell } from '../../component/table/components/TableCustomCell';
import { TableCustomHeader } from '../../component/table/components/TableCustomHeader';
import {
    HINH_THUC, LOAI_DOI_TUONG_CONST, UU_TIEN
} from '../../phan-he-kham-benh/constants/BenhNhanConst';
import { IBenhNhanModel } from '../../phan-he-xet-nghiem/models/DanhSachBenhNhanModels';
import { IDanhSachDVCdhaTdcn } from '../models/ModelsPhanHeCDHAVaTDCN';
import { formatTrangThaiBenhNhan } from '../../utils/FormatUtils';

export const columnsDSChiDinh = [
    { title: "Mã dịch vụ", field: "maDichVu", className: "spaces fs-8 w-10" },
    { title: "Tên dịch vụ", field: "tenDichVu", className: "spaces fs-8 w-20" },
    { title: "Phương pháp", field: "phuongPhap", className: "spaces fs-8 w-20" },
    { title: "Số lượng", field: "soLuong", className: "spaces fs-8 w-10" },
    { title: "Trạng thái", field: "trangThai", className: "spaces fs-8 w-20" },
    { title: "Ghi chú", field: "ghiChu", className: "spaces fs-8 w-20" },
]

export const ColumnsDsBenhNhanCDHA: ReadonlyArray<Column<IBenhNhanModel>> = [
    {
        Header: (props) => (
            <TableCustomHeader<IBenhNhanModel>
                tableProps={props}
                title={"TT"}
                className="text-light min-w-30px"
            />
        ),
        id: "TT",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-center"
                data={formatTrangThaiBenhNhan(props?.data[props?.row?.index]?.color)}
            />
        ),
    },
    // {
    //     Header: (props) => (
    //         <TableCustomHeader<IBenhNhanModel>
    //             tableProps={props}
    //             title={"STT"}
    //             className="text-center text-light min-w-30px"
    //         />
    //     ),
    //     id: "STT",
    //     Cell: ({ ...props }) => (
    //         <TableCustomCell
    //             className="text-center"
    //             data={+props.row.id + 1}
    //         />
    //     ),
    // },
    {
        Header: (props) => (
            <TableCustomHeader<IBenhNhanModel>
                tableProps={props}
                title={"STT"}
                className="text-center text-light min-w-30px"
            />
        ),
        id: "STT",
        Cell: ({ ...props }) => (
            <TableCustomCell
                className="text-center"
                data={+props.row.id + 1}
            />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<IBenhNhanModel>
                tableProps={props}
                title={"Tên bệnh nhân"}
                className="text-center text-light min-w-300px"
            />
        ),
        id: "tenKH",
        Cell: ({ ...props }) => (
            <div className="text-system">
                <div className="flex m-0">
                    <span className="text-uppercase fs-14px">{props?.data[props?.row?.index]?.patientName}</span>
                    {props?.data[props?.row?.index]?.uuTien === UU_TIEN.uuTien.code ? (
                        <span>
                            <i className="bi bi-check-circle-fill text-cyan ms-2"></i>
                        </span>
                    ) : (
                        ""
                    )}
                </div>
                <div className="flex m-0">
                    <span className="text-uppercase fw-semibold fs-14px">{props?.data[props?.row?.index]?.patientCode}</span>
                    <span className="px-1 fs-14px"> - </span>
                    <span className='fs-14px'>
                        {props?.data[props?.row?.index]?.visitObjectTypeName}
                    </span>
                    <span className="px-1 fs-14px"> - </span>
                    <span className='fs-14px'>
                        {props?.data[props?.row?.index]?.gender}
                    </span>
                </div>
            </div>
        ),
    },
    // {
    //     Header: (props) => (
    //         <TableCustomHeader<IBenhNhanModel>
    //             tableProps={props}
    //             title={"Mã phiếu"}
    //             className="text-center text-light min-w-60px"
    //         />
    //     ),
    //     id: "Mã phiếu",
    //     Cell: ({ ...props }) => (
    //         <TableCustomCell
    //             className="text-center fs-14"
    //             data={props?.data[props?.row?.index]?.code}
    //         />
    //     ),
    // },
];



export const columnMauDSKetQuaThucHien: ReadonlyArray<Column<any>> = [
    {
        Header: (props: any) => (
            <div className="text-center text-dark min-w-30px p-2 border-x">
                <span>Tên mẫu</span>
            </div>
        ),
        id: 'Tên mẫu',
        Cell: ({ ...props }: any) => {
            return <TableCustomCell
                tableProps={props}
                className="px-3 spaces line-height-30 border"
                data={props.row.original.tenMau}
            />
        },
    },
];