import axios from 'axios';
import { OctResponse } from '../../models/OctResponse';
import { IDichVuChuaCoKetQua, IInPhieuChiDinhThuocParams, IKhoThuoc, ISearchThuocParams, IThuoc } from '../models/ThuocModels';

const BASE_URL = process.env.REACT_APP_WMS_API_URL;
const CIS_BASE_URL = process.env.REACT_APP_EMR_API_URL;

interface IThuocResponse<T> {
	content: T;
	empty: boolean;
	size: number;
	totalElements: number;
	totalPages: number;
}

export const getDanhSachKhoThuoc = async (): Promise<IKhoThuoc[]> => {
	const { data } = await axios.get<OctResponse<IKhoThuoc[]>>(
		`${BASE_URL}/item/get-lst-ware-house`
	);
	return data.data;
};

export const searchThuoc = async (params: ISearchThuocParams): Promise<IThuoc[]> => {
	const { data } = await axios.post<OctResponse<IThuocResponse<IThuoc[]>>>(
		`${BASE_URL}/item/search`,
		params
	);
	return data.data.content;
};

export const saveDonThuoc = async (params: any): Promise<OctResponse<any>> => {
	const { data } = await axios.post<OctResponse<any>>(
		`${CIS_BASE_URL}/medical-item-group/save`,
		params
	);
	return data;
};

export const deleteDonThuoc = async (id: string): Promise<OctResponse<any>> => {
	const { data } = await axios.delete<OctResponse<any>>(
		`${CIS_BASE_URL}/medical-item-group/delete-by-id/${id}`
	);
	return data;
};

export const getDrugListById = async (params: object): Promise<OctResponse<any>> => {
	const { data } = await axios.post<OctResponse<any>>(
		`${CIS_BASE_URL}/medical-item-order/search`,
		params
	);
	return data;
};

export const getDetailDonThuoc = async (drugId: string): Promise<OctResponse<any>> => {
	const { data } = await axios.get<OctResponse<any>>(
		`${CIS_BASE_URL}/medical-item-group/get-by-id/${drugId}`
	);
	return data;
};

export const getDrugGroupListByVisitId = async ({
	visitId,
	type,
}: {
	visitId: string;
	type: string;
}): Promise<OctResponse<any>> => {
	const { data } = await axios.get<OctResponse<any>>(
		`${CIS_BASE_URL}/medical-item-group/get-list-by-visit-id/${visitId}/and-type/${type}`
	);
	return data;
};

export const getDetailThuoc = async (thuocId: string): Promise<OctResponse<any>> => {
	const { data } = await axios.get<OctResponse<any>>(`${BASE_URL}/item/${thuocId}`);
	return data;
};

export const printPhieuChiDinhThuoc = async (params: IInPhieuChiDinhThuocParams) => {
	return await axios.post<Blob>(`${CIS_BASE_URL}/medical-item-group/export`, params, {
		responseType: 'blob',
	});
};

export const getDichVuChuaCoKetQua = async ({
	patientId,
	visitId,
}: {
	patientId: string;
	visitId: string;
}): Promise<OctResponse<IDichVuChuaCoKetQua[]>> => {
	const { data } = await axios.get<OctResponse<IDichVuChuaCoKetQua[]>>(
		`${CIS_BASE_URL}/medical-item-group/get-no-result-order-by-patient-id` +
		`/${patientId}/and-visit-id/${visitId}`
	);
	return data;
};

export const getDanhSachDonThuocCu = async (): Promise<OctResponse<any>> => {
	const { data } = await axios.get<OctResponse<any>>(
		`${CIS_BASE_URL}/`
	);
	return data;
};