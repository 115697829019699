import { useEffect, useState } from "react";
import { IListColor } from "../utils/models";
import { generateSecureRandomId } from "../utils/AppFunction";
import { formatDateToDDMMYYYY } from "../utils/FormatUtils";

type Props = {
  dsPhieu: any[];
  listColorPhieu?: IListColor[];
  handleSelectRowData?: (rowData: any) => void;
  handleContextMenu?: (e: any, item: any) => void;
  className?: string;
  title?: string;
  isGetFirstData?: boolean;
  selectedIndex?: number;
};

const DanhSachPhieu = ({
  dsPhieu,
  listColorPhieu,
  handleSelectRowData,
  handleContextMenu,
  className,
  title,
  isGetFirstData,
  selectedIndex,
}: Props) => {
  const [currentIndex, setIndex] = useState<any>(null);
  const [danhSachPhieu, setDanhSachPhieu] = useState<any>([]);
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    if (isGetFirstData) {
      setIndex(0);
      if (dsPhieu?.length) handleSelectRowData?.(dsPhieu[0]);
    } else {
      setIndex(selectedIndex ?? null);
    }
  }, [dsPhieu]);

  useEffect(() => {
    setDanhSachPhieu(!showAll ? dsPhieu?.slice(0, 5) : dsPhieu);
  }, [showAll, dsPhieu]);

  const handleClickPhieu = (currentIndex: number, item: any) => {
    setIndex(currentIndex);
    handleSelectRowData?.(item);
  };

  const renderColorTrangThaiPhieu = (trangThai: number) => {
    return (
      listColorPhieu?.find((item) => item?.code === trangThai) ?? { name: "" }
    );
  };

  const handleShowAllPhieu = () => {
    setShowAll(!showAll);
  };

  const handleCustomContextMenu = (e: any, item: any) => {
    e.preventDefault();
    handleContextMenu && handleContextMenu(e, item);
  };

  const renderPhieu = () => {
    return danhSachPhieu?.length > 0 ? (
      danhSachPhieu?.map((item: any, index: number) => {
        return (
          <div
            key={generateSecureRandomId()}
            className={`cursor-pointer`}
            onClick={() => handleClickPhieu(index, item)}
            onContextMenu={(e) => handleCustomContextMenu(e, item)}
          >
            <div
              className={`border spaces  min-h-29 rounded-1 d-flex flex-column justify-content-center align-items-center phieu py-4 px-8 ${
                index === currentIndex ? "is-active" : ""
              }`}
            >
              <div className="text fw-semibold">{item?.date || formatDateToDDMMYYYY(item?.invDate) || ""}</div>
              {title && <div className="text">{item?.[title] || ""}</div>}
            </div>
            <div className="d-flex spaces mt-1 gap-1">
              {item?.nhomDichVu?.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    className={`spaces width-33 h-3 status-column ${
                      renderColorTrangThaiPhieu(item?.trangThai)?.name
                    } ${className}`}
                  ></div>
                );
              })}
            </div>
          </div>
        );
      })
    ) : (
      <div className="spaces min-h-29"></div>
    );
  };

  return (
    <>
      <div className={`d-flex gap-2 ps-1 ds-phieu pe-2 ${className}`}>
        {renderPhieu()}
        {danhSachPhieu?.length >= 5 && (
          <span
            className="d-flex align-items-center cursor-pointer mx-1"
            onClick={handleShowAllPhieu}
          >
            <i
              className={`fa-solid fa-circle-${
                showAll ? "left" : "right"
              } fs-2`}
            ></i>
          </span>
        )}
      </div>
    </>
  );
};

export default DanhSachPhieu;
