import Fuse from 'fuse.js';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { CODE } from '../../../../../utils/Constant';
import { vatTuColumn } from '../../../../constants/Columns';
import { PhanHeTiepDonContext } from '../../../../contexts/PhanHeTiepDonContext';
import { getDrugListById } from '../../../../services/ThuocService';
import DanhSachPhieu from '../../../tab-thuoc/components/table-don-thuoc/DanhSachPhieu';
import SearchField from '../../../tab-thuoc/components/table-don-thuoc/SearchField';
import VatTuList from '../../../tab-thuoc/components/table-don-thuoc/TableDonThuoc';
import { useTabVatTuContext } from '../../context';

const TableVatTu = () => {
	const {
		donVatTuList,
		donVatTuDetail,
		donVatTuSelectedIndex,
		setDonVatTuSelectedIndex,
		setDonVatTuDetail,
	} = useTabVatTuContext();

	const { benhNhanInfo } = useContext(PhanHeTiepDonContext);

	const initialTableDataRef = useRef<any[]>([]);

	const [tableData, setTableData] = useState<any[]>([]);
	const [danhSachPhieu, setDanhSachPhieu] = useState<any[]>([]);

	useEffect(() => {
		if (donVatTuDetail?.id) {
			getTableData(donVatTuDetail.id);
		}
	}, [donVatTuDetail?.id, benhNhanInfo?.shouldUpdate]);

	useEffect(() => {
		if (!donVatTuList.length) {
			setDanhSachPhieu([]);
			setTableData([]);
			return;
		}

		setDanhSachPhieu(
			donVatTuList.map((vatTu, index) => ({
				...vatTu,
				index,
			}))
		);
	}, [donVatTuList]);

	const getTableData = async (groupId: string) => {
		try {
			const response = await getDrugListById({
				medicalItemGroupId: groupId,
			});
			if (response.code === CODE.SUCCESS && response.data) {
				const result = response.data.content;
				const listTemp = result.map((row: any) => ({
					...row,
					maVatTu: row.medicalItemCode,
					tenVatTu: row.name,
					donVi: row.unit,
					soLuong: row.totalQuantity,
					donGia: row.unitPrice,
					thanhTien: row.intoMoney,
				}));
				setTableData(listTemp);
				initialTableDataRef.current = listTemp;
			}
		} catch (error) {
			console.error(error);
		}
	};

	const onPhieuSelect = (data: any) => {
		setDonVatTuDetail(data);
		setDonVatTuSelectedIndex(data.index);
	};

	const onSearch = (keySearch: string) => {
		if (!initialTableDataRef.current) {
			return;
		}

		if (!keySearch) {
			setTableData(initialTableDataRef.current);
			return;
		}

		const fuse = new Fuse(initialTableDataRef.current, {
			keys: ['name', 'medicalItemCode', 'route', 'unitPrice', 'intoMoney'],
			threshold: 0.8,
		});
		const result = fuse.search(keySearch).map(record => record.item);
		setTableData(result);
	};

	return (
		<div className="bg-white mb-2">
			<SearchField onSearch={onSearch} />
			<DanhSachPhieu
				data={danhSachPhieu}
				onSelect={onPhieuSelect}
				selectedIndex={donVatTuSelectedIndex}
			/>
			<VatTuList data={tableData} columns={vatTuColumn} />
		</div>
	);
};

export default TableVatTu;
