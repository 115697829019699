import { Dispatch, FC, SetStateAction, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import InputSearch from "../../../component/InputSearch";
import { TableCustom } from "../../../component/table/table-custom/TableCustom";
import { SELECTION_MODE } from "../../../utils/Constant";
import { ColunmDanhSachLanKham } from "../../constants/Columns";
import { LanKham, MauKhamBenh } from "../../models/ThongTinKhamBenhModel";
interface Props {
    handleClose: () => void;
    setMauSelected: Dispatch<SetStateAction<MauKhamBenh | null>>;
}
const ModalDanhSachLanKham: FC<Props> = ({ handleClose, setMauSelected }) => {
    const [selectedRow, setSelectedRow] = useState<any>();
    const [dataDanhSachMau, setDataDanhSachMau] = useState<LanKham[]>([]);

    return (
        <>
            <Modal
                show
                centered
                size="xl"
                className="dialog-background"
                onHide={() => {
                    handleClose();
                }}
            >
                <Modal.Header closeButton className="modal-header">
                    <Modal.Title className="text-transform-none">Danh sách lần khám</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="spaces pb-5">
                        <InputSearch handleChange={() => { }} handleKeyDown={() => { }} handleSearch={() => { }} />
                    </div>
                    <TableCustom
                        selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
                        data={dataDanhSachMau}
                        columns={ColunmDanhSachLanKham}
                        getSelectedRowsData={setSelectedRow}
                        minHeight={450}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        className="btn-secondary spaces W-80"
                        onClick={() => {
                            setMauSelected(selectedRow[0] || selectedRow);
                            handleClose();
                        }}
                    >
                        Đóng
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
export default ModalDanhSachLanKham;
