import { useContext, useEffect, useState } from "react";
import { Button, Row } from "react-bootstrap";

import { Formik } from "formik";
import moment from "moment";
import { toast } from "react-toastify";
import { AppContext } from "../appContext/AppContext";
import FilterSidebar from "../component/filter-sidebar/FilterSidebar";
import TextField from "../component/TextField";
import {
  CODE,
  DEFAULT_PAGE_INDEX,
  KEY_DS_DANH_MUC_TIEP_DON,
  RESPONSE_MESSAGE,
} from "../utils/Constant";
import BangDsDichVuChuyenKhoa from "./components/BangDsDichVuChuyenKhoa";
import BangDsPhieuChuyenKhoa from "./components/BangDsPhieuChuyenKhoa";
import {
  LIST_ORDER,
  WORK_FLOW_STATUS_CHUYEN_KHOA,
} from "./constants/PhanHeChuyenKhoaConstants";
import ModalThucHienChuyenKhoa from "./components/modal-thuc-hien/ModalThucHienChuyenKhoa";
import "./PhanHeChuyenKhoa.scss";
import {
  calculateAge,
  formatDateAdvanceToString,
  formatDateDTO,
  formatDateToYYYYMMDD,
} from "../utils/FormatUtils";
import {
  createObsValue,
  getInforBnChuyenKhoa,
  getInforPhieuChuyenKhoa,
  surgeryGroups,
  getStatusSummary,
} from "./services/PhanHeChuyenKhoaService";
import WfButton from "../workflows/components/WfButton";
import { WfConstTracker } from "../workflows/Constant";
import { handleConvertAddress } from "../utils/AppFunction";
import { getWfWorkflows } from "../workflows/services/WfWorkflowsService";
import { getIndexedDBItem } from "../utils/IndexedDB";
import { IBenhNhanV3ChuyenKhoa } from "./models/PhanHeChuyenKhoaModel";

const LIST_FORMAL = [
  {
    id: 1,
    value: "1",
    name: "Ngày y lệnh",
    code: "indicationTime",
  },
  {
    id: 3,
    value: "3",
    name: "Ngày thực hiện",
    code: "executionStartTime",
  },
  {
    id: 4,
    value: "4",
    name: "Ngày trả kết quả",
    code: "resultReportingTime",
  },
];
const PhanHeChuyenKhoa = () => {
  const { setIsLoading } = useContext(AppContext);
  const [shouldOpenModalHuyThucHien, setShouldOpenModalHuyThucHien] =
    useState<boolean>(false);
  const [shouldOpenModalHuyKetQua, setShouldOpenModalHuyKetQua] =
    useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>("");
  const [dataSearched, setDataSearched] = useState<IBenhNhanV3ChuyenKhoa[]>([]);
  const [openModalInPhieu, setOpenModalInPhieu] = useState(false);
  const [templatePrint, setTemplatePrint] = useState<any>();
  const [openModalThucHien, setOpenModalThucHien] = useState(false);
  const [filter, setFilter] = useState<any>();
  const [dsDichVu, setDsDichVu] = useState<any[]>([]);
  const [isViewModal, setIsViewModal] = useState(true);
  const [statusSummary, setStatusSummary] = useState<any>([]);
  const [workFlowStatus, setWorkFlowStatus] = useState("");
  const [listICD10, setListICD10] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<any>(null);

  const handleDoubleClick = (row: any) => {
    setSelectedRow({...row?.original});
  };

  useEffect(() => {
    selectedRow && setOpenModalThucHien(true);
  }, [selectedRow])

  const getPatientChuyenKhoa = async (filter: any) => {
    setIsLoading(true);
    let searchObject = {
      pageIndex: DEFAULT_PAGE_INDEX,
      pageSize: 9999999,
      keyword: filter?.keyword,
      sortBy: filter?.formal?.code,
      descending: filter?.order?.id === LIST_ORDER.DERCEASE.id ? true : false,
      fromDate: formatDateToYYYYMMDD(filter.period?.fromDate),
      toDate: formatDateToYYYYMMDD(filter.period?.toDate),
    };
    try {
      let { data } = await surgeryGroups(searchObject);
      if (data?.code === CODE.SUCCESS) {
        setDataSearched(data?.data?.content);
        setIsLoading(false);
      }
    } catch (e) {
      toast.error(RESPONSE_MESSAGE.ERROR);
      setIsLoading(false);
    }
  };

  const [infoBnSelectedChuyenKhoa, setInfoBnSelectedChuyenKhoa] =
    useState<IBenhNhanV3ChuyenKhoa>();
  const handleThucHien = (currStatusId: any) => {
    if (infoBnSelectedChuyenKhoa) {
      setInfoBnSelectedChuyenKhoa({
        ...infoBnSelectedChuyenKhoa,
        chanDoanHinhAnh: [
          {
            ...infoBnSelectedChuyenKhoa?.chanDoanHinhAnh?.[0],
            nguoiThucHien: "Quản trị hệ thống",
            ngayThucHien: moment().format("HH:mm DD/MM/YYYY"),
          },
        ],
      } as IBenhNhanV3ChuyenKhoa);
      handleGetStatusSummary();
      setOpenModalThucHien(
        currStatusId === WORK_FLOW_STATUS_CHUYEN_KHOA.DANG_THUC_HIEN.code
      );
      if (currStatusId === WORK_FLOW_STATUS_CHUYEN_KHOA.CHUA_THUC_HIEN.code) {
        handleSubmitForm({}, currStatusId);
      } else {
        handleGetInfoBn(infoBnSelectedChuyenKhoa);
      }
      setWorkFlowStatus(currStatusId);
      setTimeout(() => {
        doSearch(filter);
      }, 100);
    } else {
      toast.warning("Vui lòng chọn bệnh nhân thực hiện!");
    }
  };

  const getWorkFlowStatus = async () => {
    try {
      let tracker = WfConstTracker.RISPACS;
      let objectId = infoBnSelectedChuyenKhoa?.id || "";
      let { data } = await getWfWorkflows(tracker, objectId);
      if (data?.code === CODE.SUCCESS) {
        setWorkFlowStatus(data?.data?.currStatusCode);
      }
    } catch (e) {
      toast.error(RESPONSE_MESSAGE.ERROR);
    }
  };

  useEffect(() => {
    getWorkFlowStatus();
  }, [infoBnSelectedChuyenKhoa]);

  useEffect(() => {
    setIsViewModal(
      workFlowStatus !== WORK_FLOW_STATUS_CHUYEN_KHOA.DANG_THUC_HIEN.code
    );
  }, [workFlowStatus]);
  const handleSubmitForm = async (
    values: any,
    currentId?: any,
    currentTab?: any
  ) => {
    let valueSubmit = !currentTab
      ? {
          surgeryInformationEntryDate: formatDateDTO(
            values?.surgeryInformationEntryDate
          ),
          surgeryInformationEntryPerson:
            values?.surgeryInformationEntryPerson || null,
          surgeryScheduledTime: values?.surgeryScheduledTime
            ? formatDateDTO(values?.surgeryScheduledTime)
            : null,
          surgeryAnesthesiaInductionTime: values?.surgeryAnesthesiaInductionTime
            ? formatDateDTO(values?.surgeryAnesthesiaInductionTime)
            : null,
          surgeryStartTime: values?.surgeryStartTime
            ? formatDateDTO(values?.surgeryStartTime)
            : null,
          surgeryEndTime: values?.surgeryEndTime
            ? formatDateDTO(values?.surgeryEndTime)
            : null,
          surgeryInformationEntryDepartment:
            values?.surgeryInformationEntryDepartment?.id || null,
          surgeryInformationEntryRoom:
            values?.surgeryInformationEntryRoom?.code ||
            values?.surgeryInformationEntryRoom ||
            null,
          surgeryInitialDiagnosis:
            values?.surgeryInitialDiagnosis?.code || null,
          surgeryComorbidity: values?.surgeryComorbidity?.code || null,
          surgeryPreoperativeDiagnosis:
            values?.surgeryPreoperativeDiagnosis?.code || null,
          surgeryPostoperativeDiagnosis:
            values?.surgeryPostoperativeDiagnosis?.code || null,
          surgerySurgicalProcedure: values?.surgerySurgicalProcedure || null,
          surgerySurgicalTechnique: values?.surgerySurgicalTechnique || null,
          surgerySurgicalMethod: values?.surgerySurgicalMethod || null,
          surgerySurgicalType:
            values?.surgerySurgicalType?.id ||
            values?.surgerySurgicalType ||
            null,
          surgerySurgicalStatus:
            values?.surgerySurgicalStatus?.id ||
            values?.surgerySurgicalStatus ||
            null,
          surgeryAnesthesiaMethod:
            values?.surgeryAnesthesiaMethod?.id ||
            values?.surgeryAnesthesiaMethod ||
            null,
          surgeryBloodType:
            values?.surgeryBloodType?.id || values?.surgeryBloodType || null,
          surgeryRhFactor:
            values?.surgeryRhFactor?.id || values?.surgeryRhFactor || null,
          surgerySurgicalComplication:
            values?.surgerySurgicalComplication?.id ||
            values?.surgerySurgicalComplication ||
            null,
          surgeryMortality:
            values?.surgeryMortality?.id || values?.surgeryMortality || null,
          surgeryDrain: values?.surgeryDrain || null,
          surgeryMeche: values?.surgeryMeche || null,
          surgeryDrainRemovalTime: values?.surgeryDrainRemovalTime
            ? formatDateDTO(values?.surgeryDrainRemovalTime)
            : null,
          surgerySutureRemovalTime: values?.surgerySutureRemovalTime
            ? formatDateDTO(values?.surgerySutureRemovalTime)
            : null,
          surgeryOther: values?.surgeryOther || null,
          surgerySurgicalDescription:
            values?.surgerySurgicalDescription || null,
          surgerySurgicalProcedureDiagram:
            values?.surgerySurgicalProcedureDiagram || null,
          surgeryPrimarySurgeon1st: values?.surgeryPrimarySurgeon1st || null,
          surgeryPrimarySurgeon2nd: values?.surgeryPrimarySurgeon2nd || null,
          surgerySurgicalAssistant1st:
            values?.surgerySurgicalAssistant1st || null,
          surgerySurgicalAssistant2nd:
            values?.surgerySurgicalAssistant2nd || null,
          surgerySurgicalAssistant3rd:
            values?.surgerySurgicalAssistant3rd || null,
          surgeryAnesthesiologist: values?.surgeryAnesthesiologist || null,
          surgeryAnesthesiaAssistant1st:
            values?.surgeryAnesthesiaAssistant1st || null,
          surgeryAnesthesiaAssistant2nd:
            values?.surgeryAnesthesiaAssistant2nd || null,
          surgeryAssistant: values?.surgeryAssistant || null,
          surgeryInstrumentTechnician:
            values?.surgeryInstrumentTechnician || null,
          surgeryTechnician: values?.surgeryTechnician || null,
          surgeryMedicalHistorySummary:
            values?.surgeryMedicalHistorySummary || null,
        }
      : {
          result: values?.noiDungMauKetQua || "",
          startTime: values?.startTime || null,
          endTime: values?.endTime || null,
          executionTime: values?.executionTime || "",
          minimumTime: values?.minimumTime || "",
          conclusion: values?.conclusion || "",
        };
    let dataSubmit = {
      personId: infoBnSelectedChuyenKhoa?.patient?.personId,
      orderId: selectedRow?.orderId,
      values: valueSubmit,
    };

    try {
      setIsLoading(true);
      let { data } = await createObsValue(dataSubmit);
      toast.success(
        currentId === WORK_FLOW_STATUS_CHUYEN_KHOA.CHUA_THUC_HIEN.code
          ? "Hủy thực hiện thành công"
          : "Lưu thực hiện thành công"
      );
      handleGetInfoBn(infoBnSelectedChuyenKhoa);
    } catch (error) {
      toast.error("Xảy ra lỗi, vui lòng thử lại!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleHuyThucHien = () => {
    setInfoBnSelectedChuyenKhoa({
      ...infoBnSelectedChuyenKhoa,
      chanDoanHinhAnh: [
        {
          ...infoBnSelectedChuyenKhoa?.chanDoanHinhAnh?.[0],
          nguoiThucHien: "",
          ngayThucHien: "",
        },
      ],
    } as IBenhNhanV3ChuyenKhoa);
    toast.warning(
      "Bệnh nhân có số phiếu " +
        infoBnSelectedChuyenKhoa?.chanDoanHinhAnh?.[0]?.soPhieu +
        " đã huỷ thực hiện"
    );
    handleCloseHuyThucHien();
  };

  const handleHuyKetQua = () => {
    setInfoBnSelectedChuyenKhoa({
      ...infoBnSelectedChuyenKhoa,
      chanDoanHinhAnh: [
        {
          ...infoBnSelectedChuyenKhoa?.chanDoanHinhAnh?.[0],
          nguoiTraKetQua: "",
          ngayTraKetQua: "",
          noiTraKetQua: "",
        },
      ],
    } as IBenhNhanV3ChuyenKhoa);
    toast.warning(
      "Bệnh nhân có số phiếu " +
        infoBnSelectedChuyenKhoa?.chanDoanHinhAnh?.[0]?.soPhieu +
        " đã huỷ kết quả "
    );
    handleCloseHuyKetQua();
  };

  const handleCloseHuyThucHien = () => {
    setShouldOpenModalHuyThucHien(false);
  };

  const handleCloseHuyKetQua = () => {
    setShouldOpenModalHuyKetQua(false);
  };

  const handlePrint = (key: string) => {
    setOpenModalInPhieu(true);
  };

  const handleGetInfoBn = async (item: any) => {
    setIsLoading(true);
    try {
      let { data } = await getInforBnChuyenKhoa(item?.id);
      let { data: dataPhieu } = await getInforPhieuChuyenKhoa(item?.id);
      if (CODE.SUCCESS === data?.code && dataPhieu?.code === CODE.SUCCESS) {
        let icdXPrimary = listICD10?.find(
          (item: any) => item?.code === dataPhieu?.data?.icdXPrimary
        );
        let dsDichVu = dataPhieu?.data?.orders?.map((item: any) => {
          return {
            ...item?.term,
            orderId: item?.orderId,
            obs: item?.obs,
          };
        });
        setInfoBnSelectedChuyenKhoa({
          ...item,
          ...data?.data,
          ...dataPhieu?.data,
          icdXPrimaryName: icdXPrimary?.name,
        });
        setDsDichVu(dsDichVu);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const dataToPrint = (data: any) => {
    return {
      hoTen: data?.hoTen,
      gioiTinh: data?.gioiTinh,
      tuoi: data?.age,
      namSinh: data?.age ? moment().year() - data?.age : "",
      thoiGianLapPhieu: moment().format("HH:mm DD/MM/YYYY"),
    };
  };
  const doSearch = (data: any) => {
    setFilter(data);
    getPatientChuyenKhoa(data);
  };

  const handleGetStatusSummary = async () => {
    try {
      let { data } = await getStatusSummary();
      if (data?.code === CODE.SUCCESS) {
        setStatusSummary(data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGetStatusSummary();
  }, []);

  const handleGetListICD10 = async () => {
    try {
      let res = await getIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON);
      setListICD10(res?.listICD10 || []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetListICD10();
  }, []);

  return (
    <>
      <div className="reception-list bg-gray">
        <Row className="phanHeVienPhi h-100">
          <div className="pe-0 dsBenhNhan spaces width-27_4 h-calc-vh-35 bg-white pt-6">
            <FilterSidebar
              title="Danh sách phiếu"
              formalList={LIST_FORMAL}
              onSearch={doSearch}
              listMenuItem={[]}
              handleSelectOption={() => {}}
            />
            <div className="flex-1 spaces pt-10">
              <BangDsPhieuChuyenKhoa
                setInfoPhieu={handleGetInfoBn}
                dataSearched={dataSearched}
                statusSummary={statusSummary}
              />
            </div>
          </div>
          <div className="pl-0 dsBenhNhan ml-pt-0_6 spaces border width-72 ">
            <div className="grid-6-6">
              <div className="bg-white">
                <h4 className="text-title fw-bold fs-4 spaces mb-0 py-5 px-10">
                  Thông tin bệnh nhân
                </h4>
              </div>
              <div className="bg-white">
                <h4 className="text-title fw-bold fs-4 spaces mb-0 py-5 px-10">
                  Thông tin phiếu
                </h4>
              </div>
            </div>
            <div className="grid-6-6 spaces mb-0">
              <div className="bg-white spaces p-10 ">
                <div className="bg-white">
                  <div className="d-flex spaces mb-5">
                    <div className="spaces w-20 fw-bold">Họ và tên:</div>
                    <div className="spaces w-80 color-disable">
                      {infoBnSelectedChuyenKhoa?.patient?.personName || "..."}
                    </div>
                  </div>
                  <div className="d-flex spaces mb-5">
                    <div className="spaces w-20 fw-bold">Ngày sinh:</div>
                    <div className="spaces w-80 color-disable">
                      {infoBnSelectedChuyenKhoa?.patient?.dobYear
                        ? `${
                            infoBnSelectedChuyenKhoa?.patient?.dobYear
                          } - ${calculateAge(
                            infoBnSelectedChuyenKhoa?.patient?.dobDay,
                            infoBnSelectedChuyenKhoa?.patient?.dobMonth,
                            infoBnSelectedChuyenKhoa?.patient?.dobYear
                          )}`
                        : "..."}
                    </div>
                  </div>
                  <div className="d-flex spaces mb-5">
                    <div className="spaces w-20 fw-bold">Giới tính:</div>
                    <div className="spaces w-80 color-disable">
                      {infoBnSelectedChuyenKhoa?.patient?.gender || "..."}
                    </div>
                  </div>
                  <div className="d-flex spaces mb-5">
                    <div className="spaces w-20 fw-bold">Địa chỉ:</div>
                    <div className="spaces w-80 color-disable text-truncate">
                      {infoBnSelectedChuyenKhoa?.patient?.provinceName
                        ? handleConvertAddress(
                            infoBnSelectedChuyenKhoa?.patient
                          )
                        : "..."}
                    </div>
                  </div>
                  <div className="d-flex spaces mb-5">
                    <div className="spaces w-20 fw-bold text-truncate">
                      Chẩn đoán:
                    </div>
                    <div className="spaces w-80 color-disable">
                      {infoBnSelectedChuyenKhoa?.icdXPrimaryName || "..."}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white spaces p-10">
                <div className="d-flex spaces mb-5">
                  <div className="spaces w-35 fw-bold">Mã phiếu:</div>
                  <div className="spaces w-70 color-disable">
                    {infoBnSelectedChuyenKhoa?.code || "..."}
                  </div>
                </div>
                <div className="d-flex spaces mb-5">
                  <div className="spaces w-35 fw-bold">Người chỉ định:</div>
                  <div className="spaces w-70 color-disable">
                    {infoBnSelectedChuyenKhoa?.indicationPerson || "..."} -{" "}
                    {infoBnSelectedChuyenKhoa?.deptIndicationDeptName || "..."}
                  </div>
                </div>
                <div className="d-flex spaces mb-5">
                  <div className="spaces w-35 fw-bold">Thời gian chỉ định:</div>
                  <div className="spaces w-70 color-disable">
                    {infoBnSelectedChuyenKhoa?.indicationTime
                      ? formatDateAdvanceToString(
                          infoBnSelectedChuyenKhoa?.indicationTime
                        )
                      : "..."}
                  </div>
                </div>
                <div className="d-flex spaces mb-5">
                  <div className="spaces w-35 fw-bold">Thực hiện:</div>
                  <div className="spaces w-70 color-disable text-truncate">
                    {infoBnSelectedChuyenKhoa?.executor || "..."} -{" "}
                    {infoBnSelectedChuyenKhoa?.executionStartTime
                      ? formatDateAdvanceToString(
                          infoBnSelectedChuyenKhoa?.executionStartTime
                        )
                      : "..."}{" "}
                    - {infoBnSelectedChuyenKhoa?.deptFulfillDeptName || "..."}
                  </div>
                </div>
                <div className="d-flex spaces mb-5">
                  <div className="spaces w-35 text-danger fw-bold">
                    Kết quả:
                  </div>
                  <div className="spaces w-70 text-danger fw-bold text-truncate">
                    {infoBnSelectedChuyenKhoa?.resultReporter || "..."} -{" "}
                    {infoBnSelectedChuyenKhoa?.resultReportingTime
                      ? formatDateAdvanceToString(
                          infoBnSelectedChuyenKhoa?.resultReportingTime
                        )
                      : "..."}{" "}
                    -{" "}
                    {infoBnSelectedChuyenKhoa?.deptResultReportingDept || "..."}
                  </div>
                </div>
              </div>
            </div>
            <div className="spaces bg-white">
              <BangDsDichVuChuyenKhoa
                dataDanhSachDVChuyenKhoa={dsDichVu}
                infoBnSelectedChuyenKhoa={infoBnSelectedChuyenKhoa}
                setDsDichVu={setDsDichVu}
                handleDoubleClick={handleDoubleClick}
                isViewModal={isViewModal}
                handleGetInfoBn={handleGetInfoBn}
              />
            </div>
            <div className="d-flex flex-end spaces px-12 py-16 gap-10 bg-white">
              {/* <Button
                className="btn-fill spaces min-w-100px"
                onClick={() => {
                  handleThucHien();
                }}
              >
                Thực hiện
              </Button> */}
              <WfButton
                tracker={WfConstTracker.RISPACS}
                objectId={infoBnSelectedChuyenKhoa?.id || ""}
                additionalFunc={handleThucHien}
              ></WfButton>
              <Button className="btn-fill spaces min-w-100px">
                Phiếu chỉ định
              </Button>
            </div>
          </div>
        </Row>
      </div>
      {openModalThucHien && (
        <ModalThucHienChuyenKhoa
          handleClose={() => {
            setOpenModalThucHien(false);
          }}
          handleTraKetQua={() => {}}
          infoBenhNhan={infoBnSelectedChuyenKhoa}
          selectedRow={selectedRow}
          isViewModal={false}
          handleSubmitForm={handleSubmitForm}
          listICD10={listICD10}
        />
      )}
    </>
  );
};

export { PhanHeChuyenKhoa };
