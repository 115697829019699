import React from "react";

type Props = {
    status: any;
};

const ShowStatus = (props: Props) => {
  let { status } = props;
  let arr: any[] = Object.values(status);

  return (
    <div className="d-flex spaces px-24 py-10 gap-16">
      {arr.map((obj: any) => {
        return (
          <div key={obj?.code} className="min-w-140px text-start spaces gap-8">
            <i className={obj.className}></i>
            &nbsp;
            <span>{obj.name}</span>
          </div>
        );
      })}
    </div>
  );
};

export default ShowStatus;
