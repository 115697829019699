import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ContextMenu from '../../../component/ContextMenuV3';
import InputSearch from '../../../component/InputSearch';
import LabelRequired from '../../../component/LabelRequired';
import { TableCustom } from '../../../component/table/table-custom/TableCustom';
import { IContextMenu } from '../../../phan-he-tiep-nhan-thanh-toan/models/ModelTabDSDangKy';
import { CODE, ORDER_TYPE_ID, RESPONSE_MESSAGE, SELECTION_MODE } from '../../../utils/Constant';
import {
	columnsPhieuChiDinhCu,
	columnsPhieuChiDinhCuThuoc,
} from '../../columns/tab-thuoc/ColumnTabThuoc';
import { CONTEXT_MENU_KE_THUOC_KEY } from '../../constants/Thuoc';
import { IThongTinDon } from '../../modals/modal-ke-thuoc/model';
import { IKeThuocRequest } from '../../models/ThuocModels';
import { getDrugGroupListByVisitId, getDrugListById } from '../../services/ThuocService';
import SoNgayDungThuocDialog from './SoNgayDungThuocDialog';

type Props = {
	open: boolean;
	onClose: () => void;
	patientInfo?: {
		visitId: string;
	};
	onSave?: (data: any) => void;
};

type IDonThuocCu = Pick<
	IThongTinDon,
	| 'id'
	| 'doctorOrderDay'
	| 'designatedPerson'
	| 'designatedPlace'
	| 'provisionalDiagnosis'
	| 'lstMedicalItemOrder'
>;

type IDanhSachThuocCu = Pick<
	IKeThuocRequest,
	'id' | 'name' | 'unit' | 'totalQuantity' | 'numberOfDays' | 'route' | 'visitObjectTypeName'
>;

const contextMenuThuocOptions: IContextMenu[] = [
	{
		icon: <i className="bi bi-x-lg text-danger"></i>,
		code: CONTEXT_MENU_KE_THUOC_KEY.XOA_THUOC,
		name: 'Xóa thuốc',
	},
];

const ModalDonThuocCu = ({ open, onClose, patientInfo, onSave }: Props) => {
	const [openSoNgayDungThuocDialog, setOpenSoNgayDungThuocDialog] = useState<boolean>(false);
	const [danhSachPhieuChiDinhCu, setDanhSachPhieuChiDinhCu] = useState<IDonThuocCu[]>([]);
	const [danhSachThuocCu, setDanhSachThuocCu] = useState<IDanhSachThuocCu[]>([]);
	const [selectedRow, setSelectedRow] = useState<IDonThuocCu[]>([]);
	const [contextMenu, setContextMenu] = useState<null | {
		x: number;
		y: number;
	}>(null);

	const danhSachThuocCacheRef = useRef<Map<string, IDanhSachThuocCu[]>>(new Map());
	let contextMenuRowRef = useRef<IDanhSachThuocCu | null>();

	useEffect(() => {
		if (patientInfo?.visitId) {
			getDanhSachPhieuChiDinh(patientInfo.visitId);
		}
	}, [patientInfo?.visitId]);

	useEffect(() => {
		if (selectedRow?.[0]?.id) {
			const cacheValue = danhSachThuocCacheRef.current.get(selectedRow[0]?.id);
			if (cacheValue) {
				setDanhSachThuocCu(cacheValue);
				return;
			}
			getDanhSachThuocTheoPhieuChiDinh(selectedRow[0]?.id);
		}
	}, [selectedRow?.[0]?.id]);

	const getDanhSachPhieuChiDinh = async (visitId: string) => {
		try {
			const { code, data } = await getDrugGroupListByVisitId({
				visitId,
				type: ORDER_TYPE_ID.THUOC,
			});

			if (CODE.SUCCESS === code) {
				const result = data
					.sort(
						(a: any, b: any) =>
							new Date(b.designatedDate).getTime() -
							new Date(a.designatedDate).getTime()
					)
					.map((row: any) => {
						return {
							...row,
							doctorOrderDay: moment(new Date(`${row?.doctorOrderDay}Z`)).format(
								'DD/MM/YYYY'
							),
						};
					});
				setDanhSachPhieuChiDinhCu(result);
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	const onRowThuocClickContextMenu = (value: any) => {
		const actionCode = value.code;

		switch (actionCode) {
			case CONTEXT_MENU_KE_THUOC_KEY.XOA_THUOC:
				setDanhSachThuocCu(row =>
					row.filter(item => item.id !== contextMenuRowRef.current?.id)
				);
				contextMenuRowRef.current = null;
				break;
			default:
				break;
		}
		setContextMenu(null);
	};

	const getDanhSachThuocTheoPhieuChiDinh = async (drugGroupId: string) => {
		try {
			const { code, data } = await getDrugListById({
				medicalItemGroupId: drugGroupId,
			});

			if (CODE.SUCCESS === code) {
				const result = data.content;
				setDanhSachThuocCu(result);
				danhSachThuocCacheRef.current.set(drugGroupId, result);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleContextMenu = (e: any, row: any) => {
		e.preventDefault();
		setContextMenu({ x: e.clientX, y: e.clientY });
		contextMenuRowRef.current = row?.original;
	};

	const onSubmit = () => {
		setOpenSoNgayDungThuocDialog(true);
	};

	const onSaveNgayDungThuoc = (value: number) => {
		setOpenSoNgayDungThuocDialog(false);
		const listTemp = danhSachThuocCu.map((row: any) => {
			return {
				...row,
				id: null,
				soNgayDung: value,
				numberOfDays: value,
			};
		});
		onSave &&
			onSave({
				...selectedRow[0],
				danhSachThuoc: listTemp,
			});
		onClose();
	};

	return (
		<Modal
			className="dialog-background"
			size="xl"
			show={open}
			animation
			centered
			backdropClassName="spaces top-50"
		>
			<Modal.Header className="py-3 header-modal">
				<Modal.Title className="text-pri">Danh sách đơn thuốc cũ</Modal.Title>
				<Button className="btn-close bg-white" onClick={onClose}></Button>
			</Modal.Header>

			<Modal.Body className="pt-16 pb-10 px-24 spaces">
				<InputSearch handleChange={() => {}} placeholder="Tìm kiếm" />
				<div className="mt-3 spaces">
					<TableCustom<IDonThuocCu>
						verticalScroll
						columns={columnsPhieuChiDinhCu}
						data={danhSachPhieuChiDinhCu}
						selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
						getSelectedRowsData={setSelectedRow}
						className="mt-3"
						minHeight={250}
					/>
				</div>
				<div className="mt-3 spaces">
					<LabelRequired label="Danh sách thuốc" />
					<TableCustom<IDanhSachThuocCu>
						verticalScroll
						columns={columnsPhieuChiDinhCuThuoc}
						data={danhSachThuocCu}
						selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
						className="mt-3"
						minHeight={250}
						handleContextMenu={handleContextMenu}
					/>
				</div>
			</Modal.Body>

			<Modal.Footer className="d-flex justify-content-end py-1">
				<div className="d-flex gap-3">
					<Button className="btn-fill" onClick={onSubmit}>
						<i className="bi bi-check-lg"></i>
						Sử dụng đơn thuốc
					</Button>
					<Button className="btn-fill" onClick={onSubmit}>
						<i className="bi bi-check-lg"></i>
						Sử dụng đơn thuốc + Chẩn đoán
					</Button>
					<Button className="btn-outline" onClick={onClose}>
						Đóng
					</Button>
				</div>
			</Modal.Footer>

			{contextMenu && (
				<ContextMenu
					handleClickOptionContextMenu={onRowThuocClickContextMenu}
					handleCloseMenu={() => setContextMenu(null)}
					data={contextMenuThuocOptions}
					target={contextMenu}
				/>
			)}

			{openSoNgayDungThuocDialog && (
				<SoNgayDungThuocDialog
					open={openSoNgayDungThuocDialog}
					onClose={() => setOpenSoNgayDungThuocDialog(false)}
					onSave={onSaveNgayDungThuoc}
				/>
			)}
		</Modal>
	);
};

export default ModalDonThuocCu;
