export const dataVatTuTrongKho = [
  {
    maThuoc: "M001",
    tenThuoc: "Paracetamol",
    hoatChat: "Paracetamol",
    hamLuong: "500mg",
    donVi: "Viên",
    soLo: "L001",
    soHopDong: "HD001",
    ngayHopDong: "01/02/2024",
    tonKho: "1000",
    choXuat: "500",
    dongBang: "5",
    hanDung: "01/02/2025",
    soDangKy: "D001",
    dauKy: "200",
    giaMua: "5000",
    giaBan: "10000",
    giaDichVu: "8000",
    giaBaoHiem: "9000",
    giaVienPhi: "9500",
    ngayNhap: "01/02/2024",
    phongLuu: "PL001",
    quyetDinhTrungThau: "QD001",
    soGoiThau: "SGT001",
    nhomThau: "NT001",
    namThau: "2024",
    medicineStore: "MS001",
    medicineId: "MI001",
  }
]

export const lichSuNhapXuat = [
  {
    id: "LS001",
    soPhieu: "PN001",
    thoiGian: "01/02/2024",
    soLuong: "50",
    donGia: "5000",
    soLo: "L001",
    hanSuDung: "01/02/2025",
    soDK: "DK001",
    nguoiThucHien: "Người 1",
    lanSuDung: "1",
    maBN: "BN001",
    tenBN: "Người Bệnh 1",
    khoaPhong: "Khoa 1",
    ngayTra: "10/02/2024",
    dienGiai: "Nhập kho",
  },
  {
    id: "LS002",
    soPhieu: "PN001",
    thoiGian: "01/02/2024",
    soLuong: "50",
    donGia: "5000",
    soLo: "L001",
    hanSuDung: "01/02/2025",
    soDK: "DK001",
    nguoiThucHien: "Người 1",
    lanSuDung: "1",
    maBN: "BN001",
    tenBN: "Người Bệnh 1",
    khoaPhong: "Khoa 1",
    ngayTra: "10/02/2024",
    dienGiai: "Nhập kho",
  },
  {
    id: "LS003",
    soPhieu: "PN001",
    thoiGian: "01/02/2024",
    soLuong: "50",
    donGia: "5000",
    soLo: "L001",
    hanSuDung: "01/02/2025",
    soDK: "DK001",
    nguoiThucHien: "Người 1",
    lanSuDung: "1",
    maBN: "BN001",
    tenBN: "Người Bệnh 1",
    khoaPhong: "Khoa 1",
    ngayTra: "10/02/2024",
    dienGiai: "Nhập kho",
  },
];

export const lichSuThayDoiDauKy = [
  {
    id: "LSDK001",
    ngayThayDoi: "01/02/2024",
    nguoiThayDoi: "Người Thay Đổi 1",
    dauKy: "500",
    tonKho: "600",
    thayDoi: "100",
    tonKho1: "700",
    duyet: "Đã duyệt",
    thayDoi1: "50",
    duyet1: "Đã duyệt",
    noiDung: "Thay đổi số lượng tồn kho",
    medicineBillId: "MB001",
    medicineBillCode: "MBC001",
    medicineDataId: "MD001",
  },
  {
    id: "LSDK002",
    ngayThayDoi: "01/02/2024",
    nguoiThayDoi: "Người Thay Đổi 1",
    dauKy: "500",
    tonKho: "600",
    thayDoi: "100",
    tonKho1: "700",
    duyet: "Đã duyệt",
    thayDoi1: "50",
    duyet1: "Đã duyệt",
    noiDung: "Thay đổi số lượng tồn kho",
    medicineBillId: "MB001",
    medicineBillCode: "MBC001",
    medicineDataId: "MD001",
  },
  {
    id: "LSDK003",
    ngayThayDoi: "01/02/2024",
    nguoiThayDoi: "Người Thay Đổi 1",
    dauKy: "500",
    tonKho: "600",
    thayDoi: "100",
    tonKho1: "700",
    duyet: "Đã duyệt",
    thayDoi1: "50",
    duyet1: "Đã duyệt",
    noiDung: "Thay đổi số lượng tồn kho",
    medicineBillId: "MB001",
    medicineBillCode: "MBC001",
    medicineDataId: "MD001",
  },
];

export const dsPhieuChoXuat = [
  {
    idPhieu: "PCX001",
    soPhieu: "SP001",
    phieuTongHop: "PTH001",
    maBN: "MBN001",
    tenBenhNhan: "Người Bệnh 1",
    khoaDuyet: "Khoa 1",
    ngayDuyet: "01/02/2024",
    nguoiDuyet: "Người Duyệt 1",
    slYeuCau: "100",
    slDuyet: "80",
    dienGiai: "Duyệt phiếu xuất",
  },
  {
    idPhieu: "PCX002",
    soPhieu: "SP001",
    phieuTongHop: "PTH001",
    maBN: "MBN001",
    tenBenhNhan: "Người Bệnh 1",
    khoaDuyet: "Khoa 1",
    ngayDuyet: "01/02/2024",
    nguoiDuyet: "Người Duyệt 1",
    slYeuCau: "100",
    slDuyet: "80",
    dienGiai: "Duyệt phiếu xuất",
  },
  {
    idPhieu: "PCX003",
    soPhieu: "SP001",
    phieuTongHop: "PTH001",
    maBN: "MBN001",
    tenBenhNhan: "Người Bệnh 1",
    khoaDuyet: "Khoa 1",
    ngayDuyet: "01/02/2024",
    nguoiDuyet: "Người Duyệt 1",
    slYeuCau: "100",
    slDuyet: "80",
    dienGiai: "Duyệt phiếu xuất",
  },
];

export const dataVatTuTrongTatCaCacKho = [
  {
    "maThuoc": "ACE1",
    "items": [
      {
        maThuoc: "M001",
        tenThuoc: "Paracetamol",
        hoatChat: "Paracetamol",
        hamLuong: "500mg",
        donVi: "Viên",
        soLo: "L001",
        soHopDong: "HD001",
        ngayHopDong: "01/02/2024",
        tonKho: "1000",
        choXuat: "500",
        dongBang: "5",
        hanDung: "01/02/2025",
        soDangKy: "D001",
        dauKy: "200",
        giaMua: "5000",
        giaBan: "10000",
        giaDichVu: "8000",
        giaBaoHiem: "9000",
        giaVienPhi: "9500",
        ngayNhap: "01/02/2024",
        phongLuu: "PL001",
        quyetDinhTrungThau: "QD001",
        soGoiThau: "SGT001",
        nhomThau: "NT001",
        namThau: "2024",
        medicineStore: "MS001",
        medicineId: "MI001",
      },
      {
        maThuoc: "M002",
        tenThuoc: "Paracetamol",
        hoatChat: "Paracetamol",
        hamLuong: "500mg",
        donVi: "Viên",
        soLo: "L001",
        soHopDong: "HD001",
        ngayHopDong: "01/02/2024",
        tonKho: "1000",
        choXuat: "500",
        dongBang: "5",
        hanDung: "01/02/2025",
        soDangKy: "D001",
        dauKy: "200",
        giaMua: "5000",
        giaBan: "10000",
        giaDichVu: "8000",
        giaBaoHiem: "9000",
        giaVienPhi: "9500",
        ngayNhap: "01/02/2024",
        phongLuu: "PL001",
        quyetDinhTrungThau: "QD001",
        soGoiThau: "SGT001",
        nhomThau: "NT001",
        namThau: "2024",
        medicineStore: "MS001",
        medicineId: "MI001",
      },
    ]
  },
  {
    "maThuoc": "ACE2",
    "items": [
      {
        maThuoc: "M003",
        tenThuoc: "Paracetamol",
        hoatChat: "Paracetamol",
        hamLuong: "500mg",
        donVi: "Viên",
        soLo: "L001",
        soHopDong: "HD001",
        ngayHopDong: "01/02/2024",
        tonKho: "1000",
        choXuat: "500",
        dongBang: "5",
        hanDung: "01/02/2025",
        soDangKy: "D001",
        dauKy: "200",
        giaMua: "5000",
        giaBan: "10000",
        giaDichVu: "8000",
        giaBaoHiem: "9000",
        giaVienPhi: "9500",
        ngayNhap: "01/02/2024",
        phongLuu: "PL001",
        quyetDinhTrungThau: "QD001",
        soGoiThau: "SGT001",
        nhomThau: "NT001",
        namThau: "2024",
        medicineStore: "MS001",
        medicineId: "MI001",
      },
      {
        maThuoc: "M004",
        tenThuoc: "Paracetamol",
        hoatChat: "Paracetamol",
        hamLuong: "500mg",
        donVi: "Viên",
        soLo: "L001",
        soHopDong: "HD001",
        ngayHopDong: "01/02/2024",
        tonKho: "1000",
        choXuat: "500",
        dongBang: "5",
        hanDung: "01/02/2025",
        soDangKy: "D001",
        dauKy: "200",
        giaMua: "5000",
        giaBan: "10000",
        giaDichVu: "8000",
        giaBaoHiem: "9000",
        giaVienPhi: "9500",
        ngayNhap: "01/02/2024",
        phongLuu: "PL001",
        quyetDinhTrungThau: "QD001",
        soGoiThau: "SGT001",
        nhomThau: "NT001",
        namThau: "2024",
        medicineStore: "MS001",
        medicineId: "MI001",
      },
    ]
  },
]

export const dataDsVatTu = [
  {
    maThuoc: "M001",
    tenThuoc: "Paracetamol",
    goiThau: "G001",
    donVi: "Viên",
    soLuong: "100",
    giaMua: "5000",
    vat: "10%",
    chietKhau: "5%",
    giaMuaThuc: "4750",
    thanhTien: "475000",
    sdk: "SDK001",
    soLo: "L001",
    hanSuDung: "01/01/2023",
    giaBan: "10000",
    donViNhap: "Viên",
    quyCachDongGoi: "Hộp",
    nhaCungCap: "NCC001",
    soLuongNhap: "200",
  }
];

export const theKho = [
  {
    ngayThang: "01/02/2024",
    phieuNhap: "PN001",
    phieuXuat: "PX001",
    soLo: "L001",
    hanDung: "01/02/2025",
    dienGiai: "Thẻ kho nhập xuất",
    slDauKy: "500",
    slNhap: "100",
    slXuat: "50",
    slTon: "550",
    donGia: "5000",
    thanhTien: "250000",
    ghiChu: "Ghi chú thẻ kho",
    idPhieu: "ID001",
    idData: "Data001",
    idMedicine: "Medicine001",
  },
  {
    ngayThang: "01/02/2024",
    phieuNhap: "PN001",
    phieuXuat: "PX001",
    soLo: "L001",
    hanDung: "01/02/2025",
    dienGiai: "Thẻ kho nhập xuất",
    slDauKy: "500",
    slNhap: "100",
    slXuat: "50",
    slTon: "550",
    donGia: "5000",
    thanhTien: "250000",
    ghiChu: "Ghi chú thẻ kho",
    idPhieu: "ID001",
    idData: "Data001",
    idMedicine: "Medicine001",
  },
  {
    ngayThang: "01/02/2024",
    phieuNhap: "PN001",
    phieuXuat: "PX001",
    soLo: "L001",
    hanDung: "01/02/2025",
    dienGiai: "Thẻ kho nhập xuất",
    slDauKy: "500",
    slNhap: "100",
    slXuat: "50",
    slTon: "550",
    donGia: "5000",
    thanhTien: "250000",
    ghiChu: "Ghi chú thẻ kho",
    idPhieu: "ID001",
    idData: "Data001",
    idMedicine: "Medicine001",
  },
];

export const dataDsPhieu = [
  {
    maPhieu: "MB12345",
    ngayThu: "02/02/2024",
    soTien: "5.000"
  }
]

export const mangDsVatTu = [
  {
    maVatTu: "VT001",
    maTuongDuong: "VTTD001",
    maChayPhacDo: "CPD001",
    tenVatTu: "Paracetamol",
    tenVatTuBenhVien: "Paracetamol BV",
    donVi: "Viên",
    soDangKy: "D001",
    soLo: "L001",
    quyetDinhTrungThau: "QDTT001",
    soGoiThau: "SGT001",
    nhomThau: "NT001",
    namThau: "2024",
    giaMua: "5000",
    VAT: "10%",
    giaDichVu: "8000",
    giaBaoHiem: "9000",
    giaVienPhi: "9500",
    ngayNhap: "01/02/2024",
    id: "ID001",
    maImport: "MI001",
  },
  {
    maVatTu: "VT002",
    maTuongDuong: "VTTD002",
    maChayPhacDo: "CPD002",
    tenVatTu: "Ibuprofen",
    tenVatTuBenhVien: "Ibuprofen BV",
    donVi: "Viên",
    soDangKy: "D002",
    soLo: "L002",
    quyetDinhTrungThau: "QDTT002",
    soGoiThau: "SGT002",
    nhomThau: "NT002",
    namThau: "2024",
    giaMua: "6000",
    VAT: "10%",
    giaDichVu: "8500",
    giaBaoHiem: "9500",
    giaVienPhi: "10000",
    ngayNhap: "02/02/2024",
    id: "ID002",
    maImport: "MI002",
  },
  {
    maVatTu: "VT003",
    maTuongDuong: "VTTD003",
    maChayPhacDo: "CPD003",
    tenVatTu: "Amoxicillin",
    tenVatTuBenhVien: "Amoxicillin BV",
    donVi: "Viên",
    soDangKy: "D003",
    soLo: "L003",
    quyetDinhTrungThau: "QDTT003",
    soGoiThau: "SGT003",
    nhomThau: "NT003",
    namThau: "2024",
    giaMua: "7000",
    VAT: "10%",
    giaDichVu: "9000",
    giaBaoHiem: "10000",
    giaVienPhi: "11000",
    ngayNhap: "03/02/2024",
    id: "ID003",
    maImport: "MI003",
  },
];

export const dataDsKho = [
  {
    maPhong: "Kho máu",
    items: [
      {
        maPhong: "KMau",
        tenKhoaPhong: "Kho Máu"
      }
    ]
  },
  {
    maPhong: "Kho nhà thuốc",
    items: [
      {
        maPhong: "01",
        tenKhoaPhong: "Nhà thuốc 01"
      },
      {
        maPhong: "NT",
        tenKhoaPhong: "Nhà thuốc"
      },
    ]
  },
]