import clsx from 'clsx';
import { useField } from 'formik';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Form, FormControlProps, ListGroup, Overlay, Popover } from 'react-bootstrap';
import './style.scss';

type Props = FormControlProps & {
	label: string;
	name: string;
	options: string[];
	className: string;
	inputClassName?: string;
	labelClassName?: string;
	[x: string]: any;
};

const AutoComplete = ({
	label,
	name,
	options,
	className,
	inputClassName,
	labelClassName,
	...props
}: Props) => {
	const [isShow, setIsShow] = useState<boolean>(false);
	const [suggestOptions, setSuggestOptions] = useState<string[]>([]);

	const [field, , { setValue }] = useField(name);
	const { value: query } = field;

	const containerRef = useRef<HTMLDivElement>(null);
	const initialOptionsRef = useRef<string[]>([]);
	const inputRef = useRef<HTMLInputElement>(null);
	const groupRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (options.length) {
			setSuggestOptions(options);
			initialOptionsRef.current = options;
		}
	}, [options]);

	useEffect(() => {
		window.addEventListener('click', checkMouseClickOutSide, true);
		return () => {
			window.removeEventListener('click', checkMouseClickOutSide, true);
		};
	}, []);

	const checkMouseClickOutSide = (e: MouseEvent) => {
		if (containerRef.current && !containerRef.current.contains(e.target as Node)) {
			setIsShow(false);
		}
	};

	const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		setValue(inputValue);

		if (!inputValue) {
			setSuggestOptions(initialOptionsRef.current);
		} else {
			const searchResult = initialOptionsRef.current.filter(option =>
				compareValue(option, inputValue.trim())
			);
			setSuggestOptions(searchResult);
		}
	};

	const compareValue = (sourceString: string, compareString: string) => {
		return sourceString.toLowerCase().indexOf(compareString.toLowerCase()) > -1;
	};

	const highlightText = (value: string) => {
		const regex = new RegExp('(' + query + ')', 'gi');

		if (!query) return value;

		return value.replace(regex, `<strong>${query}</strong>`);
	};

	const renderList = (options: string[]) => {
		return (
			<Popover
				className="input-autocomplete-popover"
				style={{
					minWidth: inputRef.current?.offsetWidth,
				}}
			>
				<Popover.Body className="p-2">
					{Boolean(options.length) && (
						<ListGroup className="mt-2 spaces max-h-200 overflow-auto" ref={groupRef}>
							{options.map((option, index) => (
								<ListGroup.Item
									className="border border-0"
									action
									key={index}
									onClick={(e: React.MouseEvent<Element>) => {
										e.preventDefault();
										setValue(option);
										setIsShow(false);
									}}
									tabIndex={0}
								>
									<div
										dangerouslySetInnerHTML={{
											__html: highlightText(option),
										}}
									></div>
								</ListGroup.Item>
							))}
						</ListGroup>
					)}
				</Popover.Body>
			</Popover>
		);
	};

	return (
		<div className={className} ref={containerRef}>
			<div className="d-flex align-items-center">
				<Form.Label
					className={clsx('spaces text-lable-input max-content-width mb-0', labelClassName)}
				>
					{label}
				</Form.Label>
				<Form.Control
					className={clsx('spaces px-4 customs-input', inputClassName)}
					ref={inputRef}
					{...field}
					value={query}
					onFocus={() => {
						setIsShow(true);
					}}
					onChange={onSearch}
					{...props}
				/>
			</div>

			<Overlay
				placement="bottom-start"
				show={isShow && !!suggestOptions.length}
				target={inputRef.current}
				container={containerRef.current}
				rootClose
				offset={[0, 5]}
			>
				{renderList(suggestOptions)}
			</Overlay>
		</div>
	);
};

export default AutoComplete;
