import TextField from "../../../../component/TextField";
import { FC, useContext, useState } from "react";
import { KhamLamSangContext } from "../KhamLamSang";
import { Button } from "react-bootstrap";
import { OctTable } from "@oceantech/oceantech-ui";

type TableTienSuBanThanProps = {
  independentForm?: boolean,
  height?: number,
  data?: any,
  onSubmit: (values: any) => void,
  onHide: () => void,
}

const TableTienSuBanThan: FC<TableTienSuBanThanProps> = ({
  independentForm = false,
  height = 200,
  data = [],
  onSubmit,
  onHide,
}) => {
  const context = useContext(KhamLamSangContext);
  const [localData, setLocalData] = useState<any[]>(data);
  const dataTienSuBanThan = independentForm ? localData : context.dataTienSuBanThan;
  const setDataTienSuBanThan = independentForm ? setLocalData : context.setDataTienSuBanThan;
  const isView = independentForm ? false : context.isView;

  const handleAddRow = () => {
    const listData = [...dataTienSuBanThan];
    const newListData = listData.concat([{}]);
    setDataTienSuBanThan(newListData);
  };

  const hanldeDeleteRow = (index: number) => {
    const updatedData = dataTienSuBanThan.filter(
      (_: any, i: number) => i !== index
    );
    setDataTienSuBanThan(updatedData);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    const updatedData = [...dataTienSuBanThan];
    if (updatedData[index]) {
      updatedData[index][name] = value
      setDataTienSuBanThan(updatedData);
    }
  };

  const columns = [
    ...(!isView
      ? [
          {
            name: "",
            field: "",
            headerStyle: {
              width: "5%",
              minWidth: 50,
            },
            cellStyle: {
              textAlign: "center",
            },
            render: (row: any, index: number, stt: number) => (
              <i
                onClick={(e) => hanldeDeleteRow(index)}
                className="bi bi-trash3-fill text-danger spaces ml-10  "
              ></i>
            ),
          },
        ]
      : []),
    {
      name: "Tên bệnh",
      field: "",
      headerStyle: {
        width: "30%",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => (
        <TextField
          name="diseaseName"
          type="text"
          className="spaces pl-8"
          value={row.diseaseName || ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(e, index)
          }
          disabled={isView}
        />
      ),
    },
    {
      name: "Năm phát hiện",
      field: "",
      headerStyle: {
        width: "15%",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => (
        <TextField
          name="discoveryYear"
          type="number"
          value={row.discoveryYear || ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(e, index)
          }
          disabled={isView}
        />
      ),
    },
    {
      name: "Tên bệnh nghề nghiệp",
      field: "",
      headerStyle: {
        width: "30%",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => (
        <TextField
          name="occDiseaseName"
          type="text"
          value={row.occDiseaseName || ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(e, index)
          }
          disabled={isView}
        />
      ),
    },
    {
      name: "Năm phát hiện",
      field: "",
      headerStyle: {
        width: "15%",
      },
      cellStyle: {
        textAlign: "center",
      },
      render: (row: any, index: number, stt: number) => (
        <TextField
          name="occDiscoveryYear"
          type="number"
          value={row.occDiscoveryYear || ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(e, index)
          }
          disabled={isView}
        />
      ),
    },
  ];

  return (
    <>
      <div className={`d-flex justify-content-between spaces ${independentForm ? '' : 'px-10'}`}>
        {!independentForm && (
          <h4 className="text-title-kls fw-bold fs-5 mt-6 mb-1">
            Tiền sử bản thân
          </h4>
        )}
        <div>
          {!isView && (
            <Button className={`btn-fill ${independentForm ? 'mt-2' : 'mt-6'}`} onClick={handleAddRow}>
              Thêm
            </Button>
          )}
        </div>
      </div>
      <OctTable
        id="table-tien-su-ban-than"
        className="mt-2"
        data={dataTienSuBanThan || []}
        columns={columns}
        notEdit={false}
        noToolbar={true}
        clearToolbar={true}
        noPagination={true}
        height={height}
      />
      {independentForm && (
        <div className="d-flex gap-4 justify-content-end spaces py-10 border-top">
          <Button className="btn-fill" onClick={e => onSubmit(dataTienSuBanThan)}>Lưu</Button>
          <Button className="btn-outline" onClick={onHide}>Đóng</Button>
        </div>
      )}
    </>
  );
};

export default TableTienSuBanThan;
