import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

type Props = {
  data: any;
  show: boolean;
  onHide: () => void;
};

const ShowErrorPopup = (props: Props) => {
  let { data, show, onHide } = props;
  const [lstErrors, setListErrors] = useState([]);

  useEffect(() => {
	if(data) {
		const arr = data?.message.split("\n").map((item: any) => {
			return {
				nameError: item
			}
		});
		setListErrors(arr);
	}
  }, [data])

  return (
    <Modal centered show={show} onHide={onHide} size="sm">
		<Modal.Header className="p-3">
			<h4 className="text-title fw-bold fs-4">
				Danh sách lỗi import nhân viên
			</h4>
		</Modal.Header>
		<Modal.Body className="spaces p-0 bg-white">
			<div className="p-5">
				{lstErrors.map((item: any, index: number) => {
					return <div key={index}>{`- ${item?.nameError}`}</div>;
				})}
			</div>
		</Modal.Body>
		<Modal.Footer>
			<Button
				className=" min-w-80px btn-secondary btn mr-5"
				onClick={onHide}
			>
				Đóng
			</Button>
		</Modal.Footer>
    </Modal>
  );
};

export default ShowErrorPopup;
