import { FunctionComponent, useEffect, useState } from "react";
import { IBenhNhan } from "../models/DSBenhNhanKhamBenhModels";

interface InfoPatientProps {
  infoBenhNhan: IBenhNhan | undefined;
}

const InfoPatient: FunctionComponent<InfoPatientProps> = ({ infoBenhNhan }) => {
  const [infoBN, setInfoBN] = useState<any>({
    personName: "",
    code: "",
    age: "",
    gender: "",
    object: "",
    administrativeUnit: "",
  });

  useEffect(() => {
    setInfoBN({
      personName: infoBenhNhan?.thongTinKhamBenh?.patient?.personName,
      code: infoBenhNhan?.thongTinKhamBenh?.patient?.code,
      age: calculateAge(infoBenhNhan?.thongTinKhamBenh?.patient?.dobDay, infoBenhNhan?.thongTinKhamBenh?.patient?.dobMonth, infoBenhNhan?.thongTinKhamBenh?.patient?.dobYear),
      gender: infoBenhNhan?.thongTinKhamBenh?.patient?.gender,
      object: infoBenhNhan?.thongTinKhamBenh?.visit?.visitObjectTypeName,
      administrativeUnit: infoBenhNhan?.thongTinKhamBenh?.patient?.administrativeUnit,
    })
    return () => {};
  }, [infoBenhNhan]);

  const calculateAge = (day?: any, month?: any, year?: any) => {
    const parsedDay = Number(day);
    const parsedMonth = Number(month);
    const parsedYear = Number(year);
    let agePatient;

    // Kiểm tra nếu bất kỳ giá trị nào không phải là số hoặc không hợp lệ
    if (isNaN(parsedDay) || isNaN(parsedMonth) || isNaN(parsedYear)) {
      agePatient = 0;
      return agePatient;
    }

    // Kiểm tra phạm vi hợp lệ của ngày, tháng, năm
    if (parsedDay < 1 || parsedDay > 31 || parsedMonth < 1 || parsedMonth > 12 || parsedYear.toString().length !== 4) {
      agePatient = 0;
      return agePatient;
    }

    // Kiểm tra ngày hợp lệ của tháng
    const daysInMonth = new Date(parsedYear, parsedMonth, 0).getDate();
    if (parsedDay > daysInMonth) {
      agePatient = 0;
      return agePatient;
    }

    const today = new Date();
    const birthDate = new Date(parsedYear, parsedMonth - 1, parsedDay);

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
      agePatient = age--;
      return agePatient;
    }

    return agePatient;
  };

  return (
    <>
      {infoBN ? (
        <div className="border-bt-dashed mx-negative-23 spaces px-10 py-6">
          <div className="d-flex flex-end w-100 flex-column">
            <div className="text-uppercase fw-600 fs-14px line-height-22 spaces">
              {infoBN?.personName} | {infoBN?.code} | Tuổi: {infoBN?.age} | {infoBN?.gender} | {infoBN?.object}
            </div>
            <div className="fs-14px line-height-22 spaces">
              {infoBN?.administrativeUnit}
            </div>
          </div>
        </div>
      ) : (
        <div className="info-patient">&nbsp;</div>
      )}
    </>
  );
};

export default InfoPatient;
