import { Form as FormFormik, Formik, FormikErrors } from "formik";
import { Dispatch, FC, SetStateAction, useContext, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { KEY_LOCALSTORAGE } from "../../../../auth/core/_consts";
import AutocompleteV2 from "../../../../component/AutocompleteObjectV2";
import LabelRequired from "../../../../component/LabelRequired";
import TextField from "../../../../component/TextField";
import { createSoThuTien } from "../../../../phan-he-vien-phi/services/PhanHeVienPhiServices";
import { CODE, RESPONSE_MESSAGE, SIMPLE_CATEGORY_TYPE } from "../../../../utils/Constant";
import { localStorageItem } from "../../../../utils/LocalStorage";
import { getSimpleCategory } from "../../../../utils/ServicesUtils";
import { IDsNhanVien } from "../../../models/SoThuModel";
import ModalChonNhanVien from "./ModalChonNhanVien";
import { AppContext } from "../../../../appContext/AppContext";

type Props = {
  show: boolean;
  onHide: Dispatch<
    SetStateAction<{
      openChonSoThu: boolean;
      openDanhSachSoThu: boolean;
      openTaoMoiSoThu: boolean;
    }>
  >;
  openDanhSachSoThu: boolean;
  setOpenDanhSachSoThu: Dispatch<SetStateAction<boolean>>
};

const ModalTaoSoThu: FC<Props> = (props) => {
  const { show, onHide, openDanhSachSoThu, setOpenDanhSachSoThu } = props;
  const { setIsLoading } = useContext(AppContext);
  const [shouldOpenChonNhanVienDialog, setShouldOpenChonNhanVienDialog] = useState(false);
  const [listNhanVien, setListNhanVien] = useState<IDsNhanVien[]>([]);
  let currentUser = localStorageItem.get(KEY_LOCALSTORAGE.ACCESS_TOKEN_DECODE);

  const handleChangeSelect = () => { };
  let validationSchema = Yup.object({});
  const handleFormSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      let userNames = [`${currentUser?.name}`]
      let userUsernames = [`${currentUser?.sub}`];
      let submitData = {
        ...values,
        ledgerTypeId: values?.ledgerType?.id,
        ledgerTypeCode: values?.ledgerType?.code,
        ledgerTypeName: values?.ledgerType?.name,
        createdPerson: currentUser?.name,
        vat: values?.vat,
        code: values?.code,
        name: values?.name,
        invStartNumber: Number(values?.invStartNumber),
        invEndNumber: Number(values?.invEndNumber),
        invTotalNumber: Number(values?.invTotalNumber),
        isLocked: values?.isLocked ? true : false,
        userNames,
        userUsernames,
      };
      let { data } = await createSoThuTien(submitData);
      if (CODE.SUCCESS === data?.code) {
        toast.success(RESPONSE_MESSAGE.ADD.SUCCESS);
        onHide({
          openChonSoThu: !openDanhSachSoThu,
          openDanhSachSoThu: openDanhSachSoThu,
          openTaoMoiSoThu: false,
        });
        setOpenDanhSachSoThu(false);
      } else {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      }
    } catch (error) {
      toast.warning(RESPONSE_MESSAGE.ERROR);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleShouldOpenChonNhanVienDialog = () => {
    setShouldOpenChonNhanVienDialog(true);
  }

  const isValidValue = (num: any) => num !== null && num !== undefined && !isNaN(num) && num !== '';
  const handleChangeInvNumber = (name: string, value: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void, values: any) => { 
    setFieldValue(name, value);
    let valueConverted = Number(value);
    let invTotalNumber = Number(values?.invTotalNumber);
    let invStartNumber = Number(values?.invStartNumber);
    switch (name) {
      case 'invTotalNumber':
        if (isValidValue(value) && isValidValue(invStartNumber)) {
          setFieldValue('invEndNumber', invStartNumber + valueConverted - 1);
        } else {
          setFieldValue('invEndNumber', null);
        }
        break;
      case 'invStartNumber':
        if (isValidValue(invTotalNumber) && isValidValue(value)) {
          setFieldValue('invEndNumber', valueConverted + invTotalNumber - 1);
        } else {
          setFieldValue('invEndNumber', null);
        }
        break;
      default:
        break;
    }
  }

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={() =>
          onHide({
            openChonSoThu: !openDanhSachSoThu,
            openDanhSachSoThu: openDanhSachSoThu,
            openTaoMoiSoThu: false,
          })
        }
        size="xl"
      >
        <Formik<any>
          initialValues={{}}
          validationSchema={validationSchema}
          validateOnChange={true}
          validate={(values) => {
            const errors: FormikErrors<any> = {};
            return errors;
          }}
          onSubmit={handleFormSubmit}
        >
          {({values, handleChange, setFieldValue}) => (
            <FormFormik>
              <Modal.Header closeButton className="py-5 header-modal">
                <Modal.Title>Tạo mới sổ thu tiền</Modal.Title>
              </Modal.Header>
              <Modal.Body className="spaces pl-12 pr-12">
                <Row>
                  <Col xl={6}>
                    <Row className="d-flex mb-2 align-items-center">
                      <Col xs={6} className="spaces">
                        <LabelRequired label="Ngày tạo" />
                        <input
                          className="form-control customs-input"
                          name="tuNngay"
                          type="date"
                        />
                      </Col>
                      <Col xs={6} className="spaces">
                        <LabelRequired
                          label="Người tạo"
                        />
                        <TextField
                          labelClassName="ps-2 min-w-90px fw-normal"
                          value={currentUser?.name || ""}
                          disabled
                        />
                      </Col>
                    </Row>
                    <Row className="d-flex mb-2 align-items-end">
                      <Col xs={4} className="spaces">
                        <LabelRequired label="Loại sổ" />
                        <AutocompleteV2
                          options={[]}
                          searchFunction={getSimpleCategory}
                          searchObject={{ type: SIMPLE_CATEGORY_TYPE.LOAI_SO }}
                          name="ledgerType"
                          className="autocomplete-custom radius spaces width-100 "
                          onChange={(data) => setFieldValue("ledgerType", data)}
                        />
                      </Col>
                      <Col xs={4} className="d-flex align-items-center">
                        <div className="spaces">
                          <LabelRequired label="VAT(%)" />
                          <TextField
                            name="vat"
                            labelClassName="ps-2 min-w-90px fw-normal"
                            value={values.vat || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>

                      <Col xs={4} className="d-flex align-items-center">
                        <Form.Check
                          type={"checkbox"}
                          label={"Sổ tủ trực"}
                        />
                      </Col>
                    </Row>
                    <Row className="d-flex mb-2 align-items-center">
                      <Col xs={4} className="d-flex align-items-center">
                        <div className="spaces">
                          <LabelRequired label="Mã sổ" />
                          <TextField
                            name="code"
                            labelClassName="min-w-90px fw-normal"
                            value={values.code || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                      <Col xs={4} className="d-flex align-items-center">
                        <div className="spaces">
                          <LabelRequired label="Tên sổ" />
                          <TextField
                            name="name"
                            labelClassName="ps-2 min-w-90px fw-normal"
                            value={values.name || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>

                      <Col xs={4} className="d-flex align-items-center">
                        <div className="spaces">
                          <LabelRequired label="Ký hiệu" />
                          <TextField
                            name="symbol"
                            labelClassName="ps-2 min-w-90px fw-normal"
                            value={values.symbol || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="d-flex mb-2 align-items-center">
                      <Col xs={4} className="d-flex align-items-center">
                        <div className="spaces">
                          <LabelRequired label="Tổng số phiếu" />
                          <TextField
                            name="invTotalNumber"
                            labelClassName="min-w-90px fw-normal"
                            value={values.invTotalNumber || ""}
                            onChange={(e: any) => handleChangeInvNumber(e.target.name, e.target.value, setFieldValue, values)}
                          />
                        </div>
                      </Col>
                      <Col xs={4} className="d-flex align-items-center">
                        <div className="spaces">
                          <LabelRequired label="Số bắt đầu" />
                          <TextField
                            name="invStartNumber"
                            labelClassName="min-w-90px fw-normal"
                            value={values.invStartNumber || ""}
                            onChange={(e: any) => handleChangeInvNumber(e.target.name, e.target.value, setFieldValue, values)}
                          />
                        </div>
                      </Col>
                      <Col xs={4} className="d-flex align-items-center">
                        <div className="spaces">
                          <LabelRequired label="Số kết thúc" />
                          <TextField
                            name="invEndNumber"
                            labelClassName="ps-2 min-w-90px fw-normal"
                            value={values.invEndNumber || ""}
                            disabled
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="d-flex mb-2 align-items-center">
                        <div className="spaces w-100">
                          <Button className="btn-fill mb-1" onClick={handleShouldOpenChonNhanVienDialog}>Phân quyền thêm người sử dụng</Button>
                          <TextField
                            disabled
                            name="phanQuyen"
                            as="textarea"
                            value={listNhanVien?.map(item => item?.tenNV)?.join("; ") || ""}
                            labelClassName="min-w-90px fw-normal"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="d-flex mb-2 align-items-center">
                        <div className="spaces w-100">
                          <LabelRequired label="Ghi chú" />
                          <TextField
                            name="notes"
                            as="textarea"
                            labelClassName="min-w-90px fw-normal"
                            value={values.notes || ""}
                            onChange={handleChange}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} className="d-flex align-items-center">
                        <div className="spaces w-100">
                          <Form.Check // prettier-ignore
                            type={"checkbox"}
                            label={"Khóa"}
                            name="isLocked"
                            checked={values?.isLocked}
                            onChange={(e) => {
                              setFieldValue("isLocked", e.target.checked);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  <Col xl={6}>
                    <Row>
                      <Col className="text-center fs-4 fw-bold mb-2">Hoá đơn điện tử</Col>
                    </Row>

                    <Row>
                      <Col>
                        <LabelRequired label="Loại hóa đơn phát hành" />
                        <TextField
                          name="loaiHoaDon"
                          label
                        />
                      </Col>

                      <Col>
                        <LabelRequired label="Id của thông báo phát hành" />
                        <TextField
                          name="idCuaThongBao"
                          label
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <LabelRequired label="Ký hiệu của thông báo phát hành" />
                        <TextField
                          name="kyHieuThongBao"
                          label
                        />
                      </Col>

                      <Col>
                        <LabelRequired label="Mẫu hóa đơn của thông báo phát hành" />
                        <TextField
                          name="mauHoaDon"
                          label
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <LabelRequired label="VATRateName" />
                        <TextField
                          name="vatRateName"
                          label
                        />
                      </Col>
                    </Row>

                    <Row className="mt-2 align-items-end">
                      <Col>
                        <Button className="btn-fill">
                          <i className="fa-solid fa-check"></i>
                          Chọn thông báo phát hành
                        </Button>
                      </Col>

                      <Col>
                        <Form.Check
                          type={"checkbox"}
                          label={"Không tự động tạo hóa đơn điện tử"}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer className="flex flex-middle flex-center py-1">
                <Button
                  className="btn-fill min-w-60px"
                  type="submit"
                >
                  Lưu
                </Button>
                <Button
                  className="btn-outline"
                  onClick={() => {
                    onHide({
                      openChonSoThu: !openDanhSachSoThu,
                      openDanhSachSoThu: openDanhSachSoThu,
                      openTaoMoiSoThu: false,
                    });
                    setOpenDanhSachSoThu(false);
                  }}
                >
                  Đóng
                </Button>
              </Modal.Footer>
            </FormFormik>
          )}
        </Formik>
      </Modal>

      <ModalChonNhanVien setListNhanVien={setListNhanVien} show={shouldOpenChonNhanVienDialog} handleCloseDialog={() => setShouldOpenChonNhanVienDialog(false)} />
    </>
  );
};
export { ModalTaoSoThu };

