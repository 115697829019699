import { KEY_AUTHORITIES } from "../../modules/utils/Permission";

export type TMenu = {
  title: string,
  to: string,
  defaultTo?: string,
  name: string,
  permission: string,
  ability?: string,
  icon?: string,
  classNameIcon?: string,
}

export const listModule = [
  // {
  //     permission: KEY_AUTHORITIES.ACCESS.DAT_LICH_HEN,
  //     name: "Đặt lịch hẹn",
  //     to: "/dat-lich-hen",
  //     imgSrc: "media/svg/dashboard-icons/appointment.svg"
  // },
  {
    permission: KEY_AUTHORITIES.ACCESS.TIEP_DON,
    name: "Tiếp đón",
    to: "/phan-he-tiep-nhan",
    imgSrc: "media/svg/dashboard-icons/bills.svg"
  },
  {
    permission: KEY_AUTHORITIES.ACCESS.VIEN_PHI,
    name: "Viện phí",
    to: "/vien-phi",
    imgSrc: "media/svg/dashboard-icons/bills.svg"
  },
  {
    permission: KEY_AUTHORITIES.ACCESS.KHAM_BENH,
    name: "Khám bệnh",
    to: "/kham-benh",
    imgSrc: "media/svg/dashboard-icons/diagnose.svg"
  },
  {
    permission: KEY_AUTHORITIES.ACCESS.XET_NGHIEM,
    name: "Xét nghiệm",
    to: "/test",
    imgSrc: "media/svg/dashboard-icons/microscope.svg"
  },
  {
    permission: KEY_AUTHORITIES.ACCESS.CDHA_TDCN,
    name: "CĐHA",
    to: "/cdha-tdcn",
    imgSrc: "media/svg/dashboard-icons/cdha.svg"
  },
  {
    permission: KEY_AUTHORITIES.ACCESS.CHUYEN_KHOA,
    name: "Chuyên khoa",
    to: "/chuyen-khoa",
    imgSrc: "media/svg/dashboard-icons/cdha.svg"
  },
  // {
  //   permission: KEY_AUTHORITIES.ACCESS.NHA_THUOC,
  //   name: "Nhà thuốc",
  //   to: "/kho-duoc",
  //   imgSrc: "media/svg/dashboard-icons/pharmacy.svg"
  // },
  // {
  //   permission: KEY_AUTHORITIES.ACCESS.KHO_THUOC,
  //   name: "Kho dược",
  //   to: "/kho-vat-tu",
  //   imgSrc: "media/svg/dashboard-icons/medicine.svg"
  // },
  // {
  //   permission: KEY_AUTHORITIES.ACCESS.KHO_VAT_TU,
  //   name: "Kho vật tư",
  //   to: "/kho-vat-tu",
  //   imgSrc: "media/svg/dashboard-icons/appointment.svg"
  // },
  {
    permission: KEY_AUTHORITIES.ACCESS.KHAM_DOAN,
    name: "Khám đoàn",
    to: "/phan-he-kham-doan",
    imgSrc: "media/svg/dashboard-icons/appointment.svg"
  },
  {
    permission: KEY_AUTHORITIES.ACCESS.QUAN_TRI_HE_THONG,
    name: "Quản trị hệ thống",
    to: "/quan-tri-he-thong",
    imgSrc: "media/svg/dashboard-icons/appointment.svg"
  },
  // {
  //   permission: KEY_AUTHORITIES.ACCESS.BAO_CAO,
  //   name: "Báo cáo",
  //   to: "/nha-thuoc-thu-ngan",
  //   imgSrc: "media/svg/dashboard-icons/report.svg"
  // }
];
