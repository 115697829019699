import {
    createContext,
    Dispatch,
    ReactNode,
    SetStateAction,
    useContext,
    useEffect,
    useState,
} from 'react';

type ContextProps = {
	donVatTuList: any[];
	donVatTuDetail: any;
	setDonVatTuDetail: Dispatch<SetStateAction<any>>;
	donVatTuSelectedIndex: number;
	setDonVatTuSelectedIndex: Dispatch<SetStateAction<number>>;
};

type ContextProviderProps = {
	children: ReactNode;
	initialValues: {
		initialDonVatTuList: any[];
	};
};

const initialContextValues: ContextProps = {
	donVatTuDetail: null,
	donVatTuList: [],
	donVatTuSelectedIndex: 0,
	setDonVatTuDetail: () => {},
	setDonVatTuSelectedIndex: () => {},
};

const TabVatTuContext = createContext<ContextProps>(initialContextValues);

export const TabVatTuContextProvider = ({
	children,
	initialValues: { 
		initialDonVatTuList 
	},
}: ContextProviderProps) => {
	const [donVatTuDetail, setDonVatTuDetail] = useState<any>();
	const [donVatTuSelectedIndex, setDonVatTuSelectedIndex] = useState<number>(0);

	useEffect(() => {
		if (!initialDonVatTuList.length) {
			setDonVatTuDetail({});
			return;
		}

		setDonVatTuDetail(initialDonVatTuList[donVatTuSelectedIndex]);
	}, [initialDonVatTuList, donVatTuSelectedIndex]);

	return (
		<TabVatTuContext.Provider
			value={{
				donVatTuList: initialDonVatTuList,
				donVatTuDetail,
				setDonVatTuDetail,
				donVatTuSelectedIndex,
				setDonVatTuSelectedIndex,
			}}
		>
			{children}
		</TabVatTuContext.Provider>
	);
};

export const useTabVatTuContext = () => useContext(TabVatTuContext);
