import { Formik, FormikHelpers } from "formik";
import { Dispatch, FC, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AppContext } from "../appContext/AppContext";
import './PhanHeKhamDoan.scss';
import { CODE, KEY_DS_TAB_TIEP_NHAN, REGEX } from "../utils/Constant";
import { initialValuesKhamDoan } from "./constants/PhanHeKhamDoan";
import { IKhamDoan } from "./models/KhamDoanModel";
import DSHopDong from "./components/DSHopDong";
import { localStorageItem } from "../utils/LocalStorage";
import { Button, InputGroup } from "react-bootstrap";
import TextField from "../component/TextField";
import TextValidator from "../component/TextValidator";
import { Col, Form } from "react-bootstrap";
// import ModelThemMoiHopDong from "./ModelThemMoiHopDong"
import ThongTinHopDong from "./components/ThongTinHopDong/ThongTinHopDong"
import { SearchObject } from "./models/KhamDoanModel"
import { formatDateDTO } from "../utils/FormatUtils"

export interface PhanHeKhamDoanContextProps {
    thongTinBenhNhan: IKhamDoan;
    setThongTinBenhNhan: (value: IKhamDoan) => void;
    handleAddTab: (eventKey: string) => void;
    workFlowStatus: any;
    setWorkFlowStatus: any;
}

 const initialContext = {
    thongTinBenhNhan: {} as IKhamDoan,
    setThongTinBenhNhan: () => { },
    handleAddTab: () => { },
    workFlowStatus: {},
    setWorkFlowStatus: () => { },
}

export const PhanHeKhamDoanContext = createContext<PhanHeKhamDoanContextProps>(initialContext)

export const PhanHeKhamDoan: FC = () => {
    const { setBreakCrumb, setDSMenu, setEventKey, setIsLoading, setCurrentTab } = useContext(AppContext);
    let [isPrint, setIsPrint] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState("")
    let [objectSearch, setObjectSearch] = useState<any>();
    let [isPay, setIsPay] = useState<{ boolean: boolean, page: number, pageSize: number }>({ boolean: false, page: 0, pageSize: 0 });
    const [thongTinBenhNhan, setThongTinBenhNhan] = useState<IKhamDoan>(initialValuesKhamDoan);
    const [workFlowStatus, setWorkFlowStatus] = useState<any>("");
    const [orderMain, setOrderMain] = useState<object | null>(null);
    const intl = useIntl();
    let breakCrumb = [
        { text: intl.formatMessage({ id: 'MENU.RECEIVE' }), url: '' },
        { text: intl.formatMessage({ id: 'MENU.RECEIVE' }), url: '/receive' },
    ];
    useEffect(() => {
    }, []);

    const handleAddTab = (eventKey: string) => {
    }

    let validationSchema = Yup.object({
        contractName: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        companyName: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        contractValue: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        paymentMethod: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        // contractValue: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),

        // district: Yup.object().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        // ward: Yup.object().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        // insurance: Yup.object().when('loaiDoiTuong.code', {
        //     is: CODE_DOI_TUONG?.BAO_HIEM,
        //     then: Yup.object().shape({
        //         insCode: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        //         soThe2: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        //         soThe3: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        //         soThe4: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        //         KCBBD: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        //         hanThe: Yup.object().shape({
        //             ngayStart: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        //             thangStart: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        //             namStart: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        //             ngayEnd: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        //             thangEnd: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        //             namEnd: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        //         }),
        //     }),
        // }),
    })

    const handleFormSubmit = async (values: IKhamDoan, { setValues }: FormikHelpers<IKhamDoan>) => {
     
    }
    const handleChangeValueInput = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setObjectSearch({
            ...objectSearch,
            [event.target.name]: event.target.value,
        });
    };
    return (
        <Formik<IKhamDoan>
            initialValues={{ ...thongTinBenhNhan }}
            // validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                setValues,
                touched,
                isValid,
                errors,
                setFieldValue,
            }) => (
                <Form onSubmit={handleSubmit}>
                    <PhanHeKhamDoanContext.Provider
                        value={{
                            thongTinBenhNhan, setThongTinBenhNhan,
                            handleAddTab,
                            setWorkFlowStatus,
                            workFlowStatus
                        }}
                    >
                        <div className="reception-list bg-white">

                            <div className="flex-1 flex flex-column">
                                <div className="pt-3 h-100 px-3">
                                    <ThongTinHopDong
                                        setActiveTab={setActiveTab}
                                    />
                                    {/* )} */}
                                </div>
                            </div>


                        </div>

                    </PhanHeKhamDoanContext.Provider>
                </Form>
            )}
        </Formik >
    )
}

export default PhanHeKhamDoan;
