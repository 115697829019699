
const CheckIcon = ({ data, className }: any) => {
    let isResultReleased = data?.isResultReleased;
    return (
        <div className='d-flex'>
            <div
                className={`${isResultReleased ? "background-color-blue" : "background-color-gray"} icon-circle-check spaces min-w-20 mr-10 ${className || ""}`}
            >
                <i className="bi-check icon-check me-2"></i>
            </div>
            <div>{data?.name}</div>
        </div>
    )
};

export default CheckIcon;