import React, { useContext, useEffect, useState } from "react";
import "./KhamLamSang.scss";
import GenerateFormHTML from "../../../../component/generate-form/GenerateFormHTML"
import { generateForm } from "../../../../utils/AppFunction";
import { AppContext } from "../../../../appContext/AppContext";
import { CODE } from "../../../../utils/Constant";
import { saveKhamBenh } from "../services/KhamLamSangSevice"
import { KhamLamSangContext, KhamLamSangContextProps } from "../KhamLamSang";
import { PhanHeKhamDoanContext, PhanHeKhamDoanContextProps } from "../../../PhanHeKhamDoan";
import { WF_STATUS } from "../../../constants/PhanHeKhamDoan";

type Props = {
  // selectedRow: any;
};

const TabKhamDaLieu = ({ }: Props) => {
  const { TTNhanVien ,TTDetailKhamBenh} = useContext<KhamLamSangContextProps>(KhamLamSangContext);
  const { workFlowStatus } = useContext<PhanHeKhamDoanContextProps>(PhanHeKhamDoanContext)
  const { setIsLoading } = useContext(AppContext);
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const [isView, setIsView] = useState<any>(false);

  useEffect(() => {
    getListform()
  }, [])

  useEffect(() => {
    handlerCheckDisable()
  }, [TTNhanVien?.encounter?.id,workFlowStatus?.currStatusCode])

  const handlerCheckDisable = () => {
    if (TTNhanVien?.encounter?.id && workFlowStatus?.currStatusCode != WF_STATUS.KET_THUC_KHAM.CODE ) { setIsView(false) } else {
      setIsView(true)
    }
  }

  const handlerSaveForm = async (value: any) => {
    let values = {
      ...value?.dataSend,
    }
    try {
      setIsLoading(true);
      let { data } = await saveKhamBenh(values, "khamDaLieu", TTNhanVien?.encounter?.id);
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
      } else {
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }
  const getListform = async () => {
    let data = await generateForm("khamDaLieu", setIsLoading)
    setListJsonGenerate(data)
  }
  return (
    <div className="bg-white  modelKhamBenh spaces h-calc-vh-340  mb-6 fl-1">
      <GenerateFormHTML
        isView={isView}
        listFieldAuto={listJsonGenerate}
        handleSave={handlerSaveForm}
        itemEdit={TTDetailKhamBenh}
        isUpdate={true}
      />
    </div>
  );
};

export default TabKhamDaLieu;
