import { Form, Formik, FormikErrors, useFormikContext } from "formik";
import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import * as Yup from "yup";
import AutocompleteV2 from "../../component/AutocompleteObjectV2";
import LabelRequired from "../../component/LabelRequired";
import { IMenuVienPhi } from "../models/VienPhiInfoModels";
import { getDsChonSoThuTien, saveChonSoThuTien } from "../services/PhanHeVienPhiServices";
import { CODE, RESPONSE_MESSAGE } from "../../utils/Constant";
import { toast } from "react-toastify";
import { AppContext } from "../../appContext/AppContext";

type Props = {
  show: IMenuVienPhi;
  onHide: Dispatch<SetStateAction<IMenuVienPhi>>;
  setDataSoThu: Dispatch<SetStateAction<any>>;
};

const ModalChonSoThu: FC<Props> = (props) => {
  const { show, onHide, setDataSoThu } = props;
  const { setIsLoading } = useContext(AppContext);
  let validationSchema = Yup.object({
    soTamUng: Yup.object().nullable().required("Không được để trống"),
    soHoanUng: Yup.object().nullable().required("Không được để trống"),
    soThuTien: Yup.object().nullable().required("Không được để trống"),
  });
  const handleFormSubmit = async (values: any) => {
    try {
      setIsLoading(true);
      let submitData: { [key: string]: string } = {};
      Object.entries({ ...values }).forEach(([key, value] : any) => {
        submitData[key] = value?.id;
      });
      let {data} = await saveChonSoThuTien(submitData);
      if (CODE.SUCCESS === data?.code) {
        toast.success(RESPONSE_MESSAGE.ADD.SUCCESS);
        onHide({
          openChonSoThu: false,
          openDanhSachSoThu: false,
          openTaoMoiSoThu: false,
          openDanhSachPhieu: false,
        })
      } else {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      }
    } catch (error) {
      toast.warning(RESPONSE_MESSAGE.ERROR);
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <Modal
        centered
        show={show.openChonSoThu}
        onHide={() =>
          onHide({
            openChonSoThu: false,
            openDanhSachSoThu: false,
            openTaoMoiSoThu: false,
            openDanhSachPhieu: false,
          })
        }
        size="lg"
        contentClassName="w-75 m-auto"
      >
        <Formik<any>
          initialValues={{}}
          validationSchema={validationSchema}
          validateOnChange={true}
          validate={(values) => {
            const errors: FormikErrors<any> = {};
            return errors;
          }}
          onSubmit={handleFormSubmit}
        >
          {({ touched, errors, setFieldValue, values }) => (
            <Form>
              <Modal.Header closeButton className="py-5 bg-white">
                <Modal.Title>Chọn sổ thu tiền</Modal.Title>
              </Modal.Header>
              <Modal.Body className="p-8">
               <FormChonSoThu />
              </Modal.Body>
              <Modal.Footer className="flex flex-middle flex-center spaces gap-3 py-3">
                <Button
                  className="btn-fill spaces min-w-80"
                  onClick={() => {
                    setDataSoThu(null);
                    onHide({
                      openChonSoThu: false,
                      openDanhSachSoThu: false,
                      openTaoMoiSoThu: true,
                      openDanhSachPhieu: false,
                    })
                  }}
                >
                  Thêm
                </Button>
                <Button className="btn-fill spaces min-w-80" type="submit">
                  Lưu
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
};
export { ModalChonSoThu };

const FormChonSoThu: FC<any> = (props) => {
  const {values, setFieldValue, setValues, errors, touched} = useFormikContext<any>();

  const [options, setOptions] = useState<any>([]);
  const updatePageData = async () => {
    try {
      let { data } = await getDsChonSoThuTien();
      let selectedValues: { [key: string]: string } = {};
      Object.entries(data?.data).forEach(([key, value]: any) => {
        selectedValues[key] = value?.find((item: any) => item?.isSelected);
      });
      setValues(selectedValues);
      setOptions(data?.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    updatePageData();
  }, []);

  return <>
    <div className=" w-100 mb-4 d-flex">
      <LabelRequired
        label="Sổ tạm ứng"
        className="ps-2 min-w-150px"
      />
      <AutocompleteV2
        options={options?.soTamUng || []}
        name="soTamUng"
        value={values?.soTamUng}
        onChange={(selectedOption) => setFieldValue("soTamUng", selectedOption)}
        className="autocomplete-custom radius width-100 spaces "
        menuPlacement="bottom"
        errors={errors?.soTamUng}
        touched={touched?.soTamUng}
      />
    </div>
    <div className=" w-100 mb-4 d-flex">
      <LabelRequired
        label="Sổ hoàn ứng"
        className="ps-2 min-w-150px"
      />
      <AutocompleteV2
        options={options?.soHoanUng || []}
        name="soHoanUng"
        value={values?.soHoanUng}
        onChange={(selectedOption) => setFieldValue("soHoanUng", selectedOption)}
        className="autocomplete-custom radius width-100 spaces "
        menuPlacement="bottom"
        errors={errors?.soHoanUng}
        touched={touched?.soHoanUng}
      />
    </div>
    <div className="w-100 mb-4 d-flex">
      <LabelRequired
        label="Sổ thu tiền, hoàn tiền"
        className="ps-2 min-w-150px"
      />
      <AutocompleteV2
        options={options?.soThuTien || []}
        name="soThuTien"
        value={values?.soThuTien}
        onChange={(selectedOption) => setFieldValue("soThuTien", selectedOption)}
        className="autocomplete-custom radius width-100 spaces "
        menuPlacement="bottom"
        errors={errors?.soThuTien}
        touched={touched?.soThuTien}
      />
    </div>
    <div className="w-100 mb-4 d-flex">
      <LabelRequired
        label="Sổ thu tiền (có VAT)"
        className="ps-2 min-w-150px"
      />
      <AutocompleteV2
        options={options?.soThuTienVat || []}
        name="soThuTienVat"
        value={values?.soThuTienVat}
        onChange={(selectedOption) => setFieldValue("soThuTienVat", selectedOption)}
        className="autocomplete-custom radius width-100 spaces "
      />
    </div>
    <div className="w-100 mb-4 d-flex">
      <LabelRequired
        label="Sổ thu tiền (nhà thuốc)"
        className="ps-2 min-w-150px"
      />
      <AutocompleteV2
        options={options?.soThuTienNhaThuoc || []}
        name="soThuTienNhaThuoc"
        value={values?.soThuTienNhaThuoc}
        onChange={(selectedOption) => setFieldValue("soThuTienNhaThuoc", selectedOption)}
        className="autocomplete-custom radius width-100 spaces "
      />
    </div>
    <div className=" w-100 mb-4 d-flex">
      <LabelRequired
        label="Sổ miễn giảm"
        className="ps-2 min-w-150px"
      />
      <AutocompleteV2
        options={options?.soMienGiam || []}
        name="soMienGiam"
        value={values?.soMienGiam}
        onChange={(selectedOption) => setFieldValue("soMienGiam", selectedOption)}
        className="autocomplete-custom radius width-100 spaces "
      />
    </div>
  </>
}
