import React, { createContext, useContext, useEffect, useState } from "react";
import TextField from "../../../component/TextField";
import TextValidator from "../../../component/TextValidator";
import DSHopDong from "../DSHopDong";
import { Button, InputGroup } from "react-bootstrap";
import { Row } from "react-bootstrap";
import DanhSachBenhNhan from "./DanhSachBenhNhan";
import KhamLamSangTab from "./tab-kham-benh/KhamLamSangTab";
import { PhanHeKhamDoanContext } from "../../PhanHeKhamDoan";
import { WF_STATUS } from "../../constants/PhanHeKhamDoan";

type Props = {};
export interface KhamLamSangContextProps {
  TTHopDong: any;
  setTTHopDong: any;
  TTNhanVien: any;
  setTTNhanVien: any;
  TTDetailKhamBenh: any;
  setTTDetailKhamBenh: any;
  dataTienSuBanThan: any;
  setDataTienSuBanThan: any;
  isView: boolean;
  setIsView: any;
  bmiData: any;
  setBMIData: any;
  isSearchNV: boolean;
  setIsSearchNV: any;
}
const initialContext = {
  TTHopDong: {},
  setTTHopDong: () => { },
  TTNhanVien: {},
  setTTNhanVien: () => { },
  TTDetailKhamBenh: {},
  setTTDetailKhamBenh: () => { },
  dataTienSuBanThan: [],
  setDataTienSuBanThan: () => {},
  isView: false,
  setIsView: () => {},
  bmiData: {},
  setBMIData: () => {},
  isSearchNV: false,
  setIsSearchNV: () => {},
}
export const KhamLamSangContext = createContext<KhamLamSangContextProps>(initialContext)

const QuanLyHopDong = (props: Props) => {
  const { workFlowStatus } = useContext(PhanHeKhamDoanContext);
  const [filter, setFilter] = useState<any>(null);
  const [objectSearch, setObjectSearch] = useState<any>();
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [openDSHopDong, setOpenDSHopDong] = useState(true);
  const [openQuanLyHopDong, setOpenQuanLyHopDong] = useState(false);
  const [TTHopDong, setTTHopDong] = useState<any>({});
  const [TTNhanVien, setTTNhanVien] = useState<any>({});
  const [TTDetailKhamBenh, setTTDetailKhamBenh] = useState<any>({});
  const [dataTienSuBanThan, setDataTienSuBanThan] = useState([]);
  const [isView, setIsView] = useState<boolean>(false);
  const [bmiData, setBMIData] = useState<any>({});
  const [isSearchNV, setIsSearchNV] = useState<any>({});

  const handleDisable = () => {
    if (
      TTNhanVien?.encounter?.id &&
      workFlowStatus?.currStatusCode !== WF_STATUS.KET_THUC_KHAM
    ) {
      setIsView(false);
    } else {
      setIsView(true);
    }
  };

  useEffect(() => {
    handleDisable();
  }, [TTNhanVien, workFlowStatus?.currStatusCode]);

  const handleSubmitSearch = () => {
    setFilter({ ...objectSearch })
  }
  const handlerCloseHD = () => {
    setTTHopDong({});
    setTTNhanVien({});
    setTTDetailKhamBenh({});
    setOpenDSHopDong(true);
    setOpenQuanLyHopDong(false);
    setIsSearchNV(false);
  }
  const handlerOpenHD = (data: any) => {
    setTTHopDong(data);
    setTTNhanVien({});
    setOpenDSHopDong(false);
    setOpenQuanLyHopDong(true)
  }
  const handleChangeValueInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setObjectSearch({
      ...objectSearch,
      [event.target.name]: event.target.value,
    });
  };
  return (
    <KhamLamSangContext.Provider
      value={{
        TTHopDong,
        TTNhanVien,
        setTTNhanVien,
        setTTHopDong,
        setTTDetailKhamBenh,
        TTDetailKhamBenh,
        dataTienSuBanThan,
        setDataTienSuBanThan,
        isView,
        setIsView,
        bmiData,
        setBMIData,
        isSearchNV,
        setIsSearchNV
      }}
    >
      <div className="reception-list bg-white">
        {openDSHopDong && (
          <div className="bg-white ">
            <div className=" bg-white">
              <h4 className="text-title border-bottom fw-bold fs-4 spaces mb-0  px-8 py-3">
                Danh sách hợp đồng khám sức khoẻ
              </h4>
            </div>

            <div className="d-flex spaces px-8 mt-6 bg-white justify-content-between align-items-center mb-6">
              <div className="d-flex spaces mr-10">
                <TextField
                  label="Ngày bắt đầu"
                  name="startDate"
                  type="date"
                  value={objectSearch?.startDate || ""}
                  onChange={handleChangeValueInput}
                />
              </div>
              <div className="d-flex spaces mr-10">
                <TextField
                  label="Ngày kết thúc"
                  name="endDate"
                  type="date"
                  value={objectSearch?.endDate || ""}
                  onChange={handleChangeValueInput}
                />
              </div>
              <div className="d-flex flex-grow-1">
                <InputGroup className="spaces h-29 px-0">
                  <TextValidator
                    className="spaces h-29 min-w-180 border-radius-left-right-none"
                    name="name"
                    placeholder="Tìm kiếm"
                    value={objectSearch?.name || ""}
                    onChange={handleChangeValueInput}
                  />
                  <Button
                    className="btn-fill spaces min-w-105 h-29 "
                    onClick={handleSubmitSearch}
                  >
                    <span>Tìm kiếm</span>
                    <i className="bi bi-caret-down-fill ms-2 fs-7" />
                  </Button>
                </InputGroup>
              </div>
            </div>
          </div>
        )}
        
        {openDSHopDong && (
          <DSHopDong
            show={openDSHopDong}
            setOpenAddEditHopDong={() => {}}
            isResearch={!openQuanLyHopDong}
            checked="multi"
            filter={filter}
            onHandlerOpenThongTinHĐ={handlerOpenHD}
          />
        )}

        {openQuanLyHopDong && (
          <Row className="h-100 bg-gray">
            <div className="pe-0 bg-white spaces width-27 h-calc-vh-35">
              <DanhSachBenhNhan
                handlerCloseHD={handlerCloseHD}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                setFilter={setFilter}
              />
            </div>
            <div className="pl-0  ml-pt-0_6 spaces width-72  bg-white">
              <KhamLamSangTab />
            </div>
          </Row>
        )}
      </div>
    </KhamLamSangContext.Provider>
  );
};

export default QuanLyHopDong;
