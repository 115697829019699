import { Column } from "react-table";
import { ThanhToanInfo } from "../../models/DSBenhNhanKhamBenhModels";
import { TableCustomHeader } from "../../../component/table/components/TableCustomHeader";
import { TableCustomCell } from "../../../component/table/components/TableCustomCell";

export const ThanhToanColumns: ReadonlyArray<Column<ThanhToanInfo>> = [
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Ngày y lệnh"}
                className="text-center text-light min-w-100px"
            />
        ),
        id: "ngayYLenh",
        Cell: ({ ...props }) => <TableCustomCell className="text-start" data={props?.data[props.row.index]?.ngayYLenh} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Mã tương đương"}
                className="text-center text-light min-w-200px"
            />
        ),
        id: "Mã tương đương",
        Cell: ({ ...props }) => <TableCustomCell className="text-start" data={props?.data[props.row.index]?.maTuongDuong} />,
    },{
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Tên dịch vụ, thuốc, vật tư"}
                className="text-center text-light min-w-250px"
            />
        ),
        id: "Tên dịch vụ, thuốc, vật tư",
        Cell: ({ ...props }) => <TableCustomCell className="text-start" data={props?.data[props.row.index]?.tenDVTVT} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Đối tượng"}
                className="text-center text-light min-w-100px"
            />
        ),
        id: "Đối tượng",
        Cell: ({ ...props }) => <TableCustomCell className="text-center" data={props?.data[props.row.index]?.doiTuong} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Đơn vị"}
                className="text-center text-light min-w-100px"
            />
        ),
        id: "Đơn vị",
        Cell: ({ ...props }) => <TableCustomCell className="text-center" data={props?.data[props.row.index]?.donVi} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Số lượng"}
                className="text-center text-light min-w-125px"
            />
        ),
        id: "Số lượng",
        Cell: ({ ...props }) => <TableCustomCell className="text-center" data={props?.data[props.row.index]?.soLuong} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Đơn giá"}
                className="text-center text-light min-w-125px"
            />
        ),
        id: "Đơn giá",
        Cell: ({ ...props }) => <TableCustomCell className="text-center" data={props?.data[props.row.index]?.donGia} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Loại dịch vụ"}
                className="text-center text-light min-w-250px"
            />
        ),
        id: "Loại dịch vụ",
        Cell: ({ ...props }) => <TableCustomCell className="text-start" data={props?.data[props.row.index]?.loaiDichVu} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Nguồn chi trả"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "Nguồn chi trả",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.nguonChiTra} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Giá BHYT"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "Giá BHYT",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.giaBHYT} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Ngày kết thúc"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "Ngày kết thúc",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.ngayKetThuc} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Tiền hao phí"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "Tiền hao phí",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.tienHaoPhi} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Tỷ lệ TT"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "Tỷ lệ TT",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.tyLeTT} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Thành tiền"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "Thành tiền",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.thanhTien} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"BH thanh toán"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "BH thanh toán",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.bhThanhToan} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Tỷ lệ BHTT"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "Tỷ lệ BHTT",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.tyLeBHTT} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Trần thanh toán"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "Trần thanh toán",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.tranThanhToan} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Mức thưởng"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "Mức thưởng",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.mucThuong} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Miễn giảm"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "Miễn giảm",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.mienGiam} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"BN thanh toán"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "BN thanh toán",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.bnThanhToan} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"#Cùng chi trả"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "Cùng chi trả",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.cungChiTra} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"#Tự chi trả"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "Tự chi trả",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.tuChiTra} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Đã thu"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "Đã thu",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.daThu} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Còn nợ"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "Còn nợ",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.conNo} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"YC nộp"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "YC nộp",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.ycNop} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Khoa chỉ định"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "Khoa chỉ định",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.khoaChiDinh} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Phòng chỉ định"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "Phòng chỉ định",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.phongChiDinh} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Người chỉ định"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "Người chỉ định",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.nguoiChiDinh} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Phòng thực hiện"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "Phòng thực hiện",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.phongThucHien} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Người thực hiện"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "Người thực hiện",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.nguoiThucHien} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Loại chi phí"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "Loại chi phí",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.loaiChiPhi} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Ghi chú đối tượng dịch vụ"}
                className="text-center text-light min-w-250px"
            />
        ),
        id: "Ghi chú đối tượng dịch vụ",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.ghiChuDoiTuongDichVu} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Ghi chú đơn giá"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "Ghi chú đơn giá",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.ghiChuDonGia} />,
    },
    {
        Header: (props) => (
            <TableCustomHeader<ThanhToanInfo>
                tableProps={props}
                title={"Ghi chú TLTT"}
                className="text-center text-light min-w-150px"
            />
        ),
        id: "Ghi chú TLTT",
        Cell: ({ ...props }) => <TableCustomCell className="text-end" data={props?.data[props.row.index]?.ghiChuTLTT} />,
    },
]

export const columnThuocThayThe = [
    { title: "Mã thuốc", field: "maThuoc", className: "spaces min-w-150 text-center" },
    { title: "Tên thuốc", field: "tenThuoc", className: "spaces min-w-300" },
    { title: "Đơn vị", field: "donVi", className: "spaces min-w-70 text-center" },
    { title: "Tồn kho", field: "tonKho", className: "spaces min-w-70 text-center" },
    { title: "Kho", field: "kho", className: "spaces min-w-150" },
    { title: "Giá BHYT", field: "giaBhyt", className: "spaces min-w-100 text-end" },
    { title: "Giá viện phí", field: "giaVienPhi", className: "spaces min-w-100 text-end" },
    { title: "Giá dịch vụ", field: "giaDichVu", className: "spaces min-w-100 text-end" },
    { title: "Hoạt chất", field: "hoatChat", className: "spaces min-w-150 text-center" },
    { title: "Hàm lượng", field: "hamLuong", className: "spaces min-w-100 text-center" },
    { title: "Nồng độ", field: "nongDo", className: "spaces min-w-100" },
    { title: "Hãng sản xuất", field: "hangSanXuat", className: "spaces min-w-200 text-center" },
  ]