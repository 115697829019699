import { FormikErrors, FormikTouched, useFormikContext } from "formik";
import moment from "moment";
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../../appContext/AppContext";
import { ConfirmDialog } from "../../../component/ConfirmDialog";
import ContextMenu from "../../../component/ContextMenuV3";
import { IconBack, IconButtonSave, IconMenu } from "../../../component/IconSvg";
import InputSearch from "../../../component/InputSearch";
import LabelRequired from "../../../component/LabelRequired";
import SelectTree from "../../../component/SelectTree";
import TextField from "../../../component/TextField";
import SimpleTable from "../../../component/table/simple-table/SimpleTable";
import { TableCustom } from "../../../component/table/table-custom/TableCustom";
import { IContextMenu } from "../../../phan-he-tiep-nhan-thanh-toan/models/ModelTabDSDangKy";
import { IDanhSachDanhMuc } from "../../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel";
import {
  KEY_DS_DANH_MUC_TIEP_DON,
  SELECTION_MODE,
  TERM_CLASS_IDS
} from "../../../utils/Constant";
import { formatMoney } from "../../../utils/FormatUtils";
import { getIndexedDBItem } from "../../../utils/IndexedDB";
import InfoPatientRight from "../../components/InfoPatientRight";
import ModalLuuMau from "../../components/modal-chi-dinh-dich-vu/ModalLuuMau";
import ModalDsMauDaTao from "../../components/modal-chi-dinh-dich-vu/modal-ds-mau-da-tao/ModalDsMauDaTao";
import ModalDsPhieuChiDinhCu from "../../components/modal-chi-dinh-dich-vu/modal-ds-mau-da-tao/ModalDsPhieuChiDinhCu";
import ModalThemPhieuDieuTri from "../../components/modal-chi-dinh-dich-vu/modal-them-phieu-dieu-tri/ModalThemPhieuDieuTri";
import ModalICD10 from "../../components/modal-icd10/ModalICD10";
import ModalEditOneColumn from "../../components/modal-ke-thuoc/ModalEditOneColumn";
import { TreeChiDinhDichVu } from "../../constants/KhamBenh";
import { PhanHeTiepDonContext } from "../../contexts/PhanHeTiepDonContext";
import {
  DsDichVuChiDinhColumn,
  dsDichVuColumnsSimple,
} from "./ChiDinhDichVuColumn";
import { localStorageItem } from "../../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../../auth/core/_consts";
import { getThongTinPhieuChiDinh } from "../../services/ChiDinhDVService";
import { CODE_DOI_TUONG } from "../../../phan-he-tiep-nhan-thanh-toan/constants/PhanHeTiepNhan";
import { handleSearchByFuse } from "../../../utils/AppFunction";

interface Props {
  handleClose: Dispatch<SetStateAction<boolean>>;
  dieuTri?: boolean;
  isSuaPhieu?: boolean;
}

const FormChiDinhDichVu: FC<Props> = (props) => {
  let { handleClose, dieuTri, isSuaPhieu } = props;
  let { values, setFieldValue, setValues, errors, touched } = useFormikContext<any>();
  let currentUser = localStorageItem.get(KEY_LOCALSTORAGE.ACCESS_TOKEN_DECODE);
  const { benhNhanInfo } = useContext(PhanHeTiepDonContext);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [codeCollapses, setCodeCollapses] = useState<string[]>([]);
  const [idSelected, setIdSelected] = useState<string>("");
  const [dsDichVuChiDinh, setDSDichVuChiDinh] = useState<any[]>([]);
  const [shouldOpenLuuMauModal, setShouldOpenLuuMauModal] =
    useState<boolean>(false);
  const [shouldOpenDsMauModal, setShouldOpenDsMauModal] =
    useState<boolean>(false);
  const [shouldOpenDsPhieuChiDinhModal, setShouldOpenDsPhieuChiDinhModal] =
    useState<boolean>(false);
  const [shouldOpenPhieuDieuTriModal, setShouldOpenPhieuDieuTriModal] =
    useState<boolean>(false);
  const [shouldOpenGhiChuPopup, setShouldOpenGhiChuPopup] =
    useState<boolean>(false);
  const [shouldOpenSoLuongPopup, setShouldOpenSoLuongPopup] =
    useState<boolean>(false);
  const [shouldOpenConfirmDialog, setShouldOpenConfirmDialog] =
    useState<boolean>(false);
  const [openModalThemPhieuDieuTri, setOpenModalThemPhieuDieuTri] =
    useState<boolean>(false);
  const [shouldOpenModalICD10, setShouldOpenModalICD10] =
    useState<boolean>(false);
  const [treeData, setTreeData] = useState<any>(TreeChiDinhDichVu);
  const [contextMenu, setContextMenu] = useState<null | {
    x: number;
    y: number;
  }>(null);
  const [indexRowContext, setIndexRowContext] = useState<number>();
  const [listDichVu, setListDichVu] = useState<any[]>([]);
  const [listDichVuSearch, setListDichVuSearch] = useState<any[]>([]);
  const [icd10Field, setICD10Field] = useState<string>("");
  const [selectionModeICD10, setSelectionModeICD10] = useState<
    "checkbox" | "radio"
  >();
  const [listICD10, setListICD10] = useState<any>([]);
  const [expenseObject, setExpenseObject] = useState({
    tong: '0',
    benhNhan: '0',
    conNo: '0',
  }) 
  const [textSearch, setTextSearch] = useState<string>("");
  const typeObjectCode = benhNhanInfo?.thongTinKhamBenh?.visit?.visitObjectTypeCode;
  const { BAO_HIEM, VIEN_PHI, YEU_CAU} = CODE_DOI_TUONG;

  const handleContextMenu = (e: any, row: any) => {
    e.preventDefault();
    setSelectedRow(row?.original);
    setContextMenu({ x: e.clientX, y: e.clientY });
    setIndexRowContext(row?.index);
  };

  const CODE_MENU = {
    SUA_SO_LUONG: {
      MOT: "1",
      HAI: "2",
      BA: "3",
      BON: "4",
      NHAP_SO_LUONG: "nhapSoLuong",
    },
    SUA_GIA: "suaGia",
    GHI_CHU: "ghiChu",
    XOA_DICH_VU: "xoaDichVu",
  };

  const updateColumnDSDichVuChiDinh = (column: string, newValue: any) => {
    setDSDichVuChiDinh(
      dsDichVuChiDinh?.map((item) => {
        if (item?.maDichVu === selectedRow?.maDichVu) {
          item[column] = newValue;
        }
        return item;
      })
    );
  };

  const handleClickOptionContextMenu = (value: any) => {
    const menuActions = {
      [CODE_MENU.SUA_SO_LUONG.NHAP_SO_LUONG]: () =>
        setShouldOpenSoLuongPopup(true),
      [CODE_MENU.SUA_SO_LUONG.MOT]: () =>
        updateColumnDSDichVuChiDinh("soLuong", CODE_MENU.SUA_SO_LUONG.MOT),
      [CODE_MENU.SUA_SO_LUONG.HAI]: () =>
        updateColumnDSDichVuChiDinh("soLuong", CODE_MENU.SUA_SO_LUONG.HAI),
      [CODE_MENU.SUA_SO_LUONG.BA]: () =>
        updateColumnDSDichVuChiDinh("soLuong", CODE_MENU.SUA_SO_LUONG.BA),
      [CODE_MENU.SUA_SO_LUONG.BON]: () =>
        updateColumnDSDichVuChiDinh("soLuong", CODE_MENU.SUA_SO_LUONG.BON),
      [CODE_MENU.SUA_GIA]: () =>
        toast.warning(
          `Dịch vụ [${selectedRow?.tenDichVu}] không được sửa đơn giá`
        ),
      [CODE_MENU.GHI_CHU]: () => setShouldOpenGhiChuPopup(true),
      [CODE_MENU.XOA_DICH_VU]: () => setShouldOpenConfirmDialog(true),
    };
    menuActions[value?.code]?.();
    setContextMenu(null);
  };

  const dropListChiDinhDichVu: IContextMenu[] = [
    {
      title: "Dịch vụ",
    },
    {
      icon: <i className="bi bi-pencil-square text-pri"></i>,
      // code: CODE_MENU.SUA_SO_LUONG.,
      name: "Sửa số lượng",
      children: [
        { code: CODE_MENU.SUA_SO_LUONG.MOT, name: "1" },
        { code: CODE_MENU.SUA_SO_LUONG.HAI, name: "2" },
        { code: CODE_MENU.SUA_SO_LUONG.BA, name: "3" },
        { code: CODE_MENU.SUA_SO_LUONG.BON, name: "4" },
        { code: CODE_MENU.SUA_SO_LUONG.NHAP_SO_LUONG, name: "Nhập số lượng" },
      ],
    },
    {
      icon: <i className="bi bi-currency-exchange text-pri"></i>,
      code: CODE_MENU.SUA_GIA,
      name: "Sửa giá",
    },
    {
      icon: <i className="fa-solid fa-message text-pri"></i>,
      code: CODE_MENU.GHI_CHU,
      name: "Ghi chú",
    },
    {
      icon: <i className="bi bi-x-lg text-danger"></i>,
      code: CODE_MENU.XOA_DICH_VU,
      name: "Xóa dịch vụ",
    },
  ];

  const handleDelete = () => {
    let newArr = [...(values?.orders || [])];
    if (indexRowContext || indexRowContext === 0) {
      newArr.splice(indexRowContext, 1);
    }
    setFieldValue("orders", newArr);
    const objFee = handleCaculateFee(newArr);
    setExpenseObject({
      tong: formatMoney(objFee?.tong).toString(),
      benhNhan: formatMoney(objFee?.benhNhan).toString(),
      conNo: formatMoney(objFee?.conNo).toString()
    });
    setShouldOpenConfirmDialog(false);
  };

  const handleSaveGhiChu = (values: { ghiChu: string }) => {
    updateColumnDSDichVuChiDinh("ghiChu", values.ghiChu);
  };

  const handleOpenThemPhieuDieuTri = () => {
    setOpenModalThemPhieuDieuTri(true);
  };

  const handleConvertTreeData = (data: any[]) => {
    let convertedData: any[] = data.map((item, index) => {
      return {
        ...item,
        code: item?.code || item?.name,
        name: item?.name,
        ...(item?.terms &&
          item?.terms?.length && {
            filter: handleConvertTreeData(item?.terms || []),
          }),
      };
    });
    return convertedData;
  };

  const handleGetSelectedItem = (data: any) => {
    setListDichVu(data?.subs || []);
    setListDichVuSearch(data?.subs || []);
    setTextSearch("");
  };

  const handleChangeEncounterDiagnosis = (name: string, value: any) => {
    setFieldValue("encounterDiagnosis", {
      ...values.encounterDiagnosis,
      [name]: value,
    });
  };

  const handleChangeICD10 = (data: any) => {
    switch (icd10Field) {
      case "provisionalDiagnosis":
        handleChangeEncounterDiagnosis(`${icd10Field}`, data?.code || "");
        break;
      case "comorbidity":
        let comorbidity = data?.map((item: any) => item?.name).join("; ");
        handleChangeEncounterDiagnosis(`${icd10Field}`, comorbidity || "");
        break;
      default:
        break;
    }
  };

  const updatePageData = async () => {
    try {
      let { orderId, orderGroupId } = benhNhanInfo?.thongTinKhamBenh || {};
      let termClassId = 0;
      if (isSuaPhieu) {
        let responsePhieu = await getThongTinPhieuChiDinh({
          orderId, orderGroupId
        });
        termClassId = responsePhieu?.data?.data?.termClassId || 0;
        let orders = responsePhieu?.data?.data?.terms || [];
        setValues({
          ...values,
          encounterDiagnosis: {
            ...values.encounterDiagnosis,
            ...responsePhieu?.data?.data?.encounterDiagnosis
          },
          orders,
        });
        handleSelectService(orders);
      };
      let responseDanhMuc: IDanhSachDanhMuc = await getIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON);
      let listLoaiDichVu = [];
      switch (termClassId) {
        case TERM_CLASS_IDS.XET_NGHIEM:
          listLoaiDichVu.push(responseDanhMuc?.listDichVu?.[0]);
          break;
        case TERM_CLASS_IDS.CDHA:
          listLoaiDichVu.push(responseDanhMuc?.listDichVu?.[1]);
          break;
        default:
          listLoaiDichVu = responseDanhMuc?.listDichVu || [];
          break;
      };
      let treeDataConverted = {
        code: "all",
        name: "Tất cả dịch vụ",
        icon: <IconMenu />,
        filter: handleConvertTreeData(listLoaiDichVu),
      };
      setTreeData(treeDataConverted);
      setListICD10(responseDanhMuc?.listICD10 || []);
    } catch (e) {
      console.error(e);
    }
  };
  
  useEffect(() => {
    updatePageData();
  }, []);

  useEffect(() => {
    if (benhNhanInfo?.thongTinKhamBenh?.encounterDiagnosis) {
      setValues({
        ...values,
        encounterDiagnosis: {
          ...benhNhanInfo?.thongTinKhamBenh?.encounterDiagnosis,
        },
      });
    }
  }, [benhNhanInfo?.thongTinKhamBenh?.encounterDiagnosis]);

  const handleRenderCell = (columnData: any, dataKey: string, rowData: any) => {
    rowData = {
      ...rowData,
      svcFee: formatMoney(rowData?.attributes?.svcFee) || 0,
      svcFeeHospital: formatMoney(rowData?.attributes?.svcFeeHospital) || 0,
      svcFeeIns: formatMoney(rowData?.attributes?.svcFeeIns) || 0,
      svcQuantity: rowData?.attributes?.svcQuantity || 1,
      svcPrice: formatMoney(handleRenderPrice(rowData)) || 0,
      deptLaboratoryName: rowData?.attributes?.deptLaboratoryName,
    };
    if (typeof rowData.get === "function") {
      return rowData.get(dataKey);
    } else {
      return rowData[dataKey];
    }
  };

  const handleRenderPrice= (rowData: any) => {
    switch (typeObjectCode) {
      case YEU_CAU: {
        return rowData?.attributes?.svcFee || 0;
      }
      case VIEN_PHI: {
        return rowData?.attributes?.svcFeeHospital || 0;
      }
      case BAO_HIEM: {
        return rowData?.attributes?.svcFeeIns || 0;
      }
      default: {
        return 0;
      }
    }
  };

  const handleCaculateFee = (data: any) => {
    let objResult = {
      tong: 0,
      benhNhan: 0,
      conNo: 0
    };

    data?.forEach((item: any) => {
      switch (typeObjectCode) {
        case YEU_CAU: {
          objResult = {
            tong: objResult.tong + Number(item?.attributes?.svcFee),
            benhNhan: objResult.benhNhan + Number(item?.attributes?.svcFee),
            conNo: objResult.conNo + Number(item?.attributes?.svcFee)
          };
          break;
        }
        case VIEN_PHI: {
          objResult = {
            tong: objResult.tong + Number(item?.attributes?.svcFeeHospital),
            benhNhan: objResult.benhNhan + Number(item?.attributes?.svcFeeHospital),
            conNo: objResult.conNo + Number(item?.attributes?.svcFeeHospital)
          };
          break;
        }
        case BAO_HIEM: {
          objResult = {
            tong: objResult.tong + Number(item?.attributes?.svcFeeIns),
            benhNhan: objResult.benhNhan + Number(item?.attributes?.svcFeeIns),
            conNo: objResult.conNo + Number(item?.attributes?.svcFeeIns)
          };
          break;
        }
        default: {
          objResult = {
            tong: 0,
            benhNhan: 0,
            conNo: 0
          };
          break;
        }
      }
    });
    return objResult;
  };

  const handleSelectService = (data: any) => {
    const objFee = handleCaculateFee(data);
    setExpenseObject({
      tong: formatMoney(objFee?.tong).toString(),
      benhNhan: formatMoney(objFee?.benhNhan).toString(),
      conNo: formatMoney(objFee?.conNo).toString()
    });
    setFieldValue("orders", data);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): any => {
    const value = (event.target as HTMLInputElement).value;
    setTextSearch(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    handleSearchByFuse(event, listDichVuSearch, textSearch, setListDichVu);
  };
  
  return (
    <div className="h-100">
      <Modal.Header className="py-3 header-modal">
        <div className="d-flex gap-6">
          <div
            className="d-flex align-items-center gap-3 cursor-pointer"
            onClick={() => handleClose(false)}
          >
            <IconBack />
            <span className="fw-500">Quay lại</span>
          </div>
          <div className="d-flex gap-4">
            <Dropdown className="dropdown-btn menu-icon">
              <Dropdown.Toggle className="btn-navy-outlined spaces W-200">
                Mẫu chỉ định
                <i className="bi bi-caret-down-fill text-pri ms-2"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="min-w-200px p-0 top-100 mt-2">
                <Dropdown.Item className="p-0 border-bottom">
                  <div className="d-flex flex-column spaces px-20 py-12">
                    <p className="text-pri mb-0">Tên mẫu</p>
                    <p className="mb-0 fw-light">Chẩn đoán</p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item className="p-0 border-bottom">
                  <div className="d-flex flex-column spaces px-20 py-12">
                    <p className="text-pri mb-0">Tên mẫu</p>
                    <p className="mb-0 fw-light">Chẩn đoán</p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item className="p-0 border-bottom">
                  <div className="d-flex flex-column spaces px-20 py-12">
                    <p className="text-pri mb-0">Tên mẫu</p>
                    <p className="mb-0 fw-light">Chẩn đoán</p>
                  </div>
                </Dropdown.Item>
                {/* <Dropdown.Item>
                          <div className="" onClick={() => setShouldOpenLuuMauModal(true)}>Lưu vào mẫu mới</div>
                        </Dropdown.Item> */}
                <Dropdown.Item
                  className="p-0"
                  onClick={() => setShouldOpenDsMauModal(true)}
                >
                  <div className="spaces px-20 py-12 line-height-30">
                    <span className="fw-bold text-pri text-center">
                      Danh sách mẫu chỉ định
                    </span>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/* <Dropdown className="dropdown-btn menu-icon">
              <Dropdown.Toggle className="btn-navy-outlined spaces W-200">
                Phiếu chỉ định cũ
                <i className="bi bi-caret-down-fill text-pri ms-2"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="min-w-200px p-0 top-100 mt-2">
                <Dropdown.Item className="p-0 border-bottom">
                  <div className="d-flex flex-column spaces px-20 py-12">
                    <p className="text-pri mb-0">Tên mẫu</p>
                    <p className="mb-0 fw-light">Chẩn đoán</p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item className="p-0 border-bottom">
                  <div className="d-flex flex-column spaces px-20 py-12">
                    <p className="text-pri mb-0">Tên mẫu</p>
                    <p className="mb-0 fw-light">Chẩn đoán</p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item className="p-0 border-bottom">
                  <div className="d-flex flex-column spaces px-20 py-12">
                    <p className="text-pri mb-0">Tên mẫu</p>
                    <p className="mb-0 fw-light">Chẩn đoán</p>
                  </div>
                </Dropdown.Item>
                <Dropdown.Item
                  className="p-0"
                  onClick={() => setShouldOpenDsPhieuChiDinhModal(true)}
                >
                  <div className="d-flex align-items-center spaces px-20 py-12 line-height-30">
                    <span className="fw-bold text-pri text-center">
                      Danh sách phiếu CĐ cũ
                    </span>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
          </div>
        </div>
        {benhNhanInfo?.thongTinKhamBenh ? (
          <InfoPatientRight benhNhanInfo={benhNhanInfo} />
        ) : (
          <div className="spaces h-65"></div>
        )}
      </Modal.Header>
      <Modal.Body className="py-6 px-0 spaces overflow-hidden spaces h-calc-vh-90">
        <div className="d-flex h-100">
          <div className="spaces width-20 bg-white radius-2 d-flex flex-column">
            <div className="spaces px-12 py-5 h-42">
              <InputSearch
                placeholder="Tìm kiếm"
                handleChange={() => {}}
                className="spaces h-32 pr-4 radius-3"
              />
            </div>
            <SelectTree
              className="w-100 py-2 ps-4 flex-grow-1 border border-top-0 overflow-auto"
              codeCollapses={codeCollapses}
              handleChangeCollapsesCode={setCodeCollapses}
              idSelected={idSelected}
              handleChangeSelectId={setIdSelected}
              getSelectedItem={handleGetSelectedItem}
              selectTree={treeData}
            />
          </div>
          <div className="d-flex flex-column spaces width-80 bg-light radius-2">
            <div className="bg-white radius-2 spaces py-10 px-10 d-flex justify-content-between">
              <div className="text-pri fw-bold">Chỉ định dịch vụ</div>
              {dieuTri && (
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    handleOpenThemPhieuDieuTri();
                  }}
                  className="text-black"
                >
                  <u>Thêm phiếu điều trị</u>
                </a>
              )}
            </div>
            <div className="d-flex flex-wrap spaces radius-2 p-10 border-top bg-white">
              <div className="spaces W-235 mb-4">
                <TextField
                  label="Ngày y lệnh"
                  name="ngayYLenh"
                  labelClassName="ms-0 min-w-100px"
                  value={moment(
                    values?.ngayYLenh ? values?.ngayYLenh : new Date()
                  ).format("DD/MM/YYYY - HH:mm")}
                  disabled
                />
              </div>
              <div className="spaces W-285 ml-10 position-relative">
                <TextField
                  label="Chẩn đoán"
                  className="spaces w-100"
                  inputClassName="w-100"
                  name="provisionalDiagnosis"
                  value={values?.encounterDiagnosis?.provisionalDiagnosis || ""}
                  labelClassName="ms-0 min-w-85px"
                  errors={(errors?.encounterDiagnosis as FormikErrors<any>)?.provisionalDiagnosis }
                  touched={(touched?.encounterDiagnosis as FormikTouched<any>)?.provisionalDiagnosis }
                />
                <div className="spaces width-4">
                  <h6
                    className="position-absolute muc-huong cursor-pointer"
                    onClick={() => {
                      if (benhNhanInfo?.thongTinKhamBenh.isExamined) {
                        setICD10Field("provisionalDiagnosis");
                        setSelectionModeICD10("radio");
                        setShouldOpenModalICD10(true);
                      }
                    }}
                  >
                    <i className="bi bi-three-dots fs-4 text-pri d-block spaces mt-5"></i>
                  </h6>
                </div>
              </div>
              <div className="flex-grow-1">
                <TextField
                  inputClassName="w-100"
                  name="provisionalDiagnosis"
                  value={
                    listICD10.find(
                      (item: any) =>
                        item.code ===
                        values?.encounterDiagnosis?.provisionalDiagnosis
                    )?.name || ""
                  }
                  labelClassName="ms-0 min-w-95px"
                  errors={(errors?.encounterDiagnosis as FormikErrors<any>)?.provisionalDiagnosis}
                  touched={(touched?.encounterDiagnosis as FormikTouched<any>)?.provisionalDiagnosis}
                />
              </div>

              <div className="w-100 d-flex">
                <div className="flex-grow-1 position-relative">
                  <TextField
                    label="Bệnh kèm theo"
                    className="spaces w-100"
                    inputClassName="w-100 spaces pr-40"
                    name="comorbidity"
                    value={values?.encounterDiagnosis?.comorbidity || ""}
                    labelClassName="ms-0 min-w-100px"
                  />
                  <div className="spaces width-4">
                    <h6
                      className="position-absolute muc-huong cursor-pointer"
                      onClick={() => {
                        if (benhNhanInfo?.thongTinKhamBenh.isExamined) {
                          setICD10Field("comorbidity");
                          setSelectionModeICD10("checkbox");
                          setShouldOpenModalICD10(true);
                        }
                      }}
                    >
                      <i className="bi bi-three-dots fs-4 text-pri d-block spaces mt-5"></i>
                    </h6>
                  </div>
                </div>
                <div className="spaces W-310 mb-4 ml-10">
                  <TextField
                    label="BS điều trị"
                    name="bacSiDieuTri"
                    type="text"
                    value={currentUser?.name || ""}
                    labelClassName="ms-0 min-w-85px"
                    disabled
                  />
                </div>
              </div>

              <div className="w-100">
                <TextField
                  className="spaces"
                  inputClassName="w-100"
                  label="Ghi chú"
                  name="notes"
                  value={values?.encounterDiagnosis?.notes || ""}
                  onChange={(e: any) =>
                    handleChangeEncounterDiagnosis(
                      e.target.name,
                      e.target.value
                    )
                  }
                  labelClassName="ms-0 min-w-100 spaces"
                />
              </div>
            </div>
            <div className="spaces flex-grow-1 bg-white px-0 radius-2 mt-6 table-ke-thuoc">
              <div className="d-flex flex-column spaces width-100 bg-light h-100">
                <div className="bg-white">
                  <div className="px-10 py-5 h-35 spaces">
                    <InputSearch
                      value={textSearch || ""}
                      placeholder="Tìm kiếm"
                      handleChange={handleChange}
                      handleKeyDown={handleKeyDown}
                      className="spaces h-25 pr-4 radius-3"
                    />
                  </div>
                  <div className="min-h-180 spaces bg-white mb-6">
                    <SimpleTable
                      data={listDichVu || []}
                      columns={dsDichVuColumnsSimple}
                      height={300}
                      width={1000}
                      scrollable={true}
                      type={SELECTION_MODE.MULTI_TABLE}
                      setDataChecked={handleSelectService}
                      dataChecked={values?.orders || []}
                      handleRenderCell={handleRenderCell}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column flex-grow-1 mt-2 bg-white">
                  <div className="d-flex spaces pt-10 pb-6 px-10 text-pri fw-bold">
                    Phiếu chỉ định
                  </div>
                  <div className="flex-grow-1 spaces h-calc-vh-710">
                    <TableCustom
                      columns={DsDichVuChiDinhColumn(handleRenderPrice)}
                      data={values?.orders || []}
                      handleContextMenu={handleContextMenu}
                      height={220}
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between spaces px-10 mt-6 bg-white">
                  <div className="d-flex justify-content-between spaces gap-10">
                    <div className="spaces width-50">
                      <div className="d-flex justify-content-between spaces pb-4 gap-4">
                        <LabelRequired
                          label="Tổng chi phí"
                          className="text-danger ms-0 spaces pr-16 min-w-135"
                        />
                        <div className="text-danger fw-500 spaces px-8 py-2">
                          {expenseObject?.tong || 0}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between spaces pb-4 gap-4">
                        <LabelRequired
                          label="Tạm ứng"
                          className="ms-0 spaces pr-16 min-w-135"
                        />
                        <div className="text-pri fw-500 spaces px-8 py-2">
                          0
                        </div>
                      </div>
                    </div>
                    <div className="spaces width-50">
                      <div className="d-flex justify-content-between spaces pb-4 gap-4">
                        <LabelRequired
                          label="BHYT"
                          className="ms-0 spaces pr-16 min-w-135"
                        />
                        <div className="text-pri fw-500 spaces px-8 py-2">
                          0
                        </div>
                      </div>
                      <div className="d-flex justify-content-between spaces pb-4 gap-4">
                        <LabelRequired
                          label="Đã thu"
                          className="ms-0 spaces pr-16 min-w-135"
                        />
                        <div className="text-pri fw-500 spaces px-8 py-2">
                          0
                        </div>
                      </div>
                    </div>
                    <div className="spaces width-50">
                      <div className="d-flex justify-content-between spaces pb-4 gap-4">
                        <LabelRequired
                          label="Nguồn khác"
                          className="ms-0 spaces pr-16 min-w-135"
                        />
                        <div className="text-pri fw-500 spaces px-8 py-2">
                          0
                        </div>
                      </div>
                      <div className="d-flex justify-content-between spaces pb-4 gap-4">
                        <LabelRequired
                          label="Miễn giảm"
                          className="ms-0 spaces pr-16 min-w-135"
                        />
                        <div className="text-pri fw-500 spaces px-8 py-2">
                          0
                        </div>
                      </div>
                    </div>
                    <div className="spaces width-50">
                      <div className="d-flex justify-content-between spaces pb-4 gap-4">
                        <LabelRequired
                          label="Bệnh nhân"
                          className="ms-0 spaces pr-16 min-w-135"
                        />
                        <div className="text-pri fw-500 spaces px-8 py-2">
                          {expenseObject?.benhNhan || 0}
                        </div>
                      </div>
                      <div className="d-flex justify-content-between spaces pb-4 gap-4">
                        <LabelRequired
                          label="Còn nợ"
                          className="ms-0 spaces pr-16 min-w-135"
                        />
                        <div className="text-pri fw-500 spaces px-8 py-2">
                          {expenseObject?.conNo || 0}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center spaces gap-10">
                    <Button className="btn-outline min-w-100px">
                      <i className="bi bi-printer" /> In
                    </Button>
                    <Button className="btn-fill min-w-100px" type="submit">
                      <IconButtonSave /> Lưu
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      {shouldOpenLuuMauModal && (
        <ModalLuuMau handleClose={() => setShouldOpenLuuMauModal(false)} />
      )}
      {shouldOpenDsMauModal && (
        <ModalDsMauDaTao
          dsDichVuChiDinh={dsDichVuChiDinh}
          setDsDichVuChiDinh={(data: any) => setDSDichVuChiDinh(data)}
          handleClose={() => setShouldOpenDsMauModal(false)}
        />
      )}
      {shouldOpenDsPhieuChiDinhModal && (
        <ModalDsPhieuChiDinhCu
          handleClose={() => setShouldOpenDsPhieuChiDinhModal(false)}
        />
      )}
      {shouldOpenPhieuDieuTriModal && (
        <ModalThemPhieuDieuTri
          handleClose={() => setShouldOpenPhieuDieuTriModal(false)}
        />
      )}
      {contextMenu && (
        <ContextMenu
          handleClickOptionContextMenu={handleClickOptionContextMenu}
          handleCloseMenu={() => setContextMenu(null)}
          data={dropListChiDinhDichVu}
          target={contextMenu}
        />
      )}
      <ConfirmDialog
        className="z-index-1060"
        show={shouldOpenConfirmDialog}
        title="Thông báo"
        message="Bạn có chắc chắn muốn bỏ dịch vụ này không ?"
        yes="Xác nhận"
        close="Đóng"
        onCloseClick={() => setShouldOpenConfirmDialog(false)}
        onYesClick={() => handleDelete()}
      />
      {shouldOpenGhiChuPopup && (
        <ModalEditOneColumn
          handleSave={handleSaveGhiChu}
          code="ghiChu"
          name="Ghi chú"
          handleClose={() => setShouldOpenGhiChuPopup(false)}
        />
      )}
      {shouldOpenSoLuongPopup && (
        <ModalEditOneColumn
          handleSave={(values) =>
            updateColumnDSDichVuChiDinh("soLuong", values?.soLuong)
          }
          code="soLuong"
          name="Chỉnh sửa số lượng"
          handleClose={() => setShouldOpenSoLuongPopup(false)}
        />
      )}

      {openModalThemPhieuDieuTri && (
        <ModalThemPhieuDieuTri
          handleClose={() => setOpenModalThemPhieuDieuTri(false)}
        />
      )}

      {shouldOpenModalICD10 && (
        <ModalICD10
          data={listICD10}
          handleClose={() => setShouldOpenModalICD10(false)}
          setMauSelected={handleChangeICD10}
          selectionMode={selectionModeICD10}
        />
      )}
    </div>
  );
};

export default FormChiDinhDichVu;
