import { FC } from "react";
import TableTienSuBanThan from "../../phan-he-kham-doan/components/tab-kham-lam-sang/tab-kham-benh/TableTienSuBanThan";
import BMIComponent from "../../phan-he-kham-doan/components/tab-kham-lam-sang/tab-kham-benh/BMIComponent";

interface DynamicProps {
  data: any;
}

const GenerateDynamicComponent: FC<DynamicProps> = (props) => {
  const { data } = props;

  const Default = () => {
    return <></>;
  };

  const components: { [key: string]: FC<any> } = {
    tableTienSuBanThan: TableTienSuBanThan,
    BMIComponent: BMIComponent
  };

  const DynamicContent = components[data?.componentName] || Default;

  return <DynamicContent {...data} />;
};

export default GenerateDynamicComponent;
