import { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ButtonInPhieu from '../../../../../component/button-in-phieu';
import { ConfirmDialog } from '../../../../../component/ConfirmDialog';
import { CODE, ORDER_TYPE_ID, RESPONSE_MESSAGE } from '../../../../../utils/Constant';
import { PhanHeTiepDonContext } from '../../../../contexts/PhanHeTiepDonContext';
import ModalKeVatTu from '../../../../modals/modal-ke-vat-tu/ModalKeVatTu';
import { IInPhieuChiDinhThuocParams } from '../../../../models/ThuocModels';
import { deleteDonThuoc, printPhieuChiDinhThuoc } from '../../../../services/ThuocService';
import { useTabVatTuContext } from '../../context';

type ActionType = 'edit' | 'delete' | 'cancel';

type DialogActionProps = {
	open: boolean;
	action: ActionType;
};

const ThaoTacPhieuVatTu = () => {
	const { donVatTuDetail, setDonVatTuSelectedIndex } = useTabVatTuContext();
	const { benhNhanInfo, setBenhNhanInfo } = useContext(PhanHeTiepDonContext);

	const { patient, visit } = benhNhanInfo?.thongTinKhamBenh || {};

	const [guiPhieu, setGuiPhieu] = useState(false);
	const [dialogAction, setDialogAction] = useState<DialogActionProps>({
		open: false,
		action: 'edit',
	});

	const onDeleteDonVatTu = async () => {
		try {
			const response = await deleteDonThuoc(donVatTuDetail.id);
			if (CODE.SUCCESS === response.code) {
				toast.success(RESPONSE_MESSAGE.DELETE.SUCCESS);
				setDialogAction({
					open: false,
					action: 'edit',
				});
				updatePatientInfo();
				setDonVatTuSelectedIndex(0);
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	const onHuyGuiDonVatTu = async () => {};

	const updatePatientInfo = () => {
		setBenhNhanInfo({
			...benhNhanInfo,
			shouldUpdate: !benhNhanInfo.shouldUpdate,
		});
	};

	return (
		<div className="d-flex gap-3 justify-content-end py-2 me-2 bg-white">
			{guiPhieu ? (
				<div className="d-flex gap-3 justify-content-center">
					<ButtonInPhieu<IInPhieuChiDinhThuocParams>
						fetchExport={printPhieuChiDinhThuoc}
						params={{
							fileType: 'pdf',
							patientId: patient?.id,
							medicalItemGroupId: donVatTuDetail?.id,
							visitId: visit?.id,
							type: parseInt(ORDER_TYPE_ID.VAT_TU),
						}}
						label="In phiếu"
					/>
					<Button className="btn-secondary mr-5" onClick={() => setGuiPhieu(!guiPhieu)}>
						Hủy phiếu
					</Button>
				</div>
			) : (
				<div className="d-flex gap-3 justify-content-center">
					<Button
						className="btn-navy-outlined"
						onClick={() => {
							setDialogAction({
								open: true,
								action: 'edit',
							});
						}}
					>
						Sửa phiếu
					</Button>
					<Button className="btn-fill" onClick={() => setGuiPhieu(!guiPhieu)}>
						Gửi phiếu
					</Button>
					<Button
						className="btn-secondary"
						onClick={() => {
							setDialogAction({
								open: true,
								action: 'delete',
							});
						}}
					>
						Xóa phiếu
					</Button>
				</div>
			)}

			{dialogAction.open && dialogAction.action === 'edit' && (
				<ModalKeVatTu
					open={dialogAction.open}
					handleClose={() => {
						setDialogAction({
							open: false,
							action: 'edit',
						});
					}}
					onSave={updatePatientInfo}
					itemEdit={donVatTuDetail}
				/>
			)}

			{dialogAction.open && ['delete', 'cancel'].includes(dialogAction.action) && (
				<ConfirmDialog
					className="z-index-1060"
					show={dialogAction.open}
					title="Thông báo"
					message={`Bạn có chắc chắn muốn ${
						dialogAction.action === 'delete' ? 'xóa' : 'hủy'
					} đơn vật tư này không ?`}
					yes="Xác nhận"
					close="Đóng"
					onCloseClick={() => {
						setDialogAction({
							open: false,
							action: 'edit',
						});
					}}
					onYesClick={
						dialogAction.action === 'delete' ? onDeleteDonVatTu : onHuyGuiDonVatTu
					}
				/>
			)}
		</div>
	);
};

export default ThaoTacPhieuVatTu;
