import { Button, Col, Dropdown, Form, Modal, Row } from "react-bootstrap";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Autocomplete } from "../../../component/Autocomplete";
import LabelRequired from "../../../component/LabelRequired";
import { IBenhNhanV3CDHA } from "../../models/ModelsPhanHeCDHAVaTDCN";
import { ChangeEvent, useContext, useEffect, useRef, useState } from "react";
import ModalDSMauKetQuaThucHien from "../../components/modals/ModalDSMauKetQuaThucHien";
import ModalPickTimeBDThucHien from "../../components/modals/ModalPickTimeBDThucHien";
import ModalPickTimeKTThucHien from "../../components/modals/ModalPickTimeKTThucHien";
import { toast } from "react-toastify";
import InfoPatientRight from "../../../phan-he-kham-benh/components/InfoPatientRight";
import TextField from "../../../component/TextField";
import { Formik } from "formik";
import * as Yup from "yup";
import { AppContext } from "../../../appContext/AppContext";
import {
  createObsValue,
  getObsValue,
} from "../../services/PhanHeCDHAVaTDCNService";
import { CODE, RESPONSE_MESSAGE } from "../../../utils/Constant";
import WebcamCapture from "../../../component/webcam-image/WebcamCapture";
import { renderAsync } from "docx-preview";
import { asBlob } from "html-docx-js-typescript";
import { saveAs } from "file-saver";

type Props = {
  handleClose: () => void;
  handleTraKetQua: () => void;
  infoBenhNhan?: IBenhNhanV3CDHA;
  isViewModal?: boolean;
  workFlowStatus?: any;
  handleSubmitForm: (values: any) => void;
  resultData?: any;
};

const initialValues: any = {};

export default function ModalThucHienCDHA({
  handleClose,
  handleTraKetQua,
  infoBenhNhan,
  isViewModal,
  handleSubmitForm,
  resultData
}: Props) {
  const { setIsLoading } = useContext(AppContext);
  const [inforResult, setInforResult] = useState<any>({});
  const [
    shouldOpenModalDSMauKetQuaThucHien,
    setShouldOpenModalDSMauKetQuaThucHien,
  ] = useState<boolean>(false);

  const refForm = useRef<any>();
  const uploadFileRef = useRef<HTMLInputElement>(null);
  const htmlElementRef = useRef<HTMLDivElement>(null);
  const styleElementRef = useRef<HTMLDivElement>(null);
  const editorRef = useRef<CKEditor<ClassicEditor>>(null);
  const importFilenameRef = useRef<string>("");

  const [openWebcamCapture, setOpenWebcamCapture] = useState<boolean>();
  const [imgSrcs, setImageSrcs] = useState([]);
  const [
    shouldOpenModalPickTimeBDThucHien,
    setShouldOpenModalPickTimeBDThucHien,
  ] = useState<boolean>(false);
  const [
    shouldOpenModalPickTimeKTThucHien,
    setShouldOpenModalPickTimeKTThucHien,
  ] = useState<boolean>(false);
  const [noiDungMauKetQua, setNoiDungMauKetQua] = useState<string>("");
  const [ngay, setNgay] = useState({
    batDau: "",
    ketThuc: "",
  });
  const handleCloseModalMauKetQuaThucHien = () => {
    setShouldOpenModalDSMauKetQuaThucHien(false);
  };
  const handleCloseModalPickTimeBDThucHien = () => {
    setShouldOpenModalPickTimeBDThucHien(false);
  };
  const handleCloseModalPickTimeKTThucHien = () => {
    setShouldOpenModalPickTimeKTThucHien(false);
  };
  const handleLuuInTraKetQua = () => {
    handleTraKetQua();
    toast.success("Lưu / In / Trả kết quả thành công");
    handleClose();
  };
  const handleSelectMauKetQua = (mauKetQua: any) => {
    setShouldOpenModalDSMauKetQuaThucHien(false);
    setNoiDungMauKetQua(mauKetQua[0].original.noiDungMau);
  };

  const validationSchema = Yup.object({});

  const handleGetResult = async () => {
    let searchObject = {
      orderId: infoBenhNhan?.orders[0]?.orderId,
    };
    try {
      setIsLoading(true);
      let { data } = await getObsValue(searchObject);
      if (data?.code === CODE.SUCCESS) {
        setInforResult(data?.data);
      }
    } catch (error) {
      toast.error("Xảy ra lỗi, vui lòng thử lại!");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeInfor = (value: any, name: string) => {
    setInforResult({
      ...inforResult,
      [name]: value,
    });
  };

  useEffect(() => {
    if (resultData) {
      setInforResult(resultData);
    } else {
      handleGetResult();
    }
  }, []);

  const onImportFileKetQua = async (e: ChangeEvent<HTMLInputElement>) => {
    const fileUpload = e.target.files;

    if (!fileUpload?.length) return;

    const file = fileUpload[0];
    importFilenameRef.current = file.name;

    try {
      await renderAsync(
        file,
        htmlElementRef.current as HTMLElement,
        styleElementRef.current as HTMLElement,
        {
          useBase64URL: true,
        }
      );

      if (editorRef.current && htmlElementRef.current?.innerHTML) {
        editorRef.current.editor?.data.set(htmlElementRef.current.innerHTML);
      }
    } catch (error) {
      console.error(error);
      toast.error(RESPONSE_MESSAGE.ERROR);
    }
  };

  const onExportFileKetQua = async () => {
    try {
      if (editorRef.current?.editor?.data) {
        const blobResult = (await asBlob(
          editorRef.current.editor.data.get()
        )) as Blob;
        saveAs(blobResult, importFilenameRef.current);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Modal
        show={true}
        onHide={handleClose}
        size="xl"
        centered
        className="modal-xl-bigger"
        contentClassName="h-100"
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) =>
            handleSubmitForm({
              noiDungMauKetQua: noiDungMauKetQua,
              startTime: inforResult?.startTime || null,
              endTime: inforResult?.endTime || null,
              executionTime: inforResult?.executionTime || null,
              minimumTime: inforResult?.minimumTime || null,
              conclusion: inforResult?.conclusion || null,
            })
          }
          innerRef={refForm}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            errors,
            touched,
            resetForm,
          }) => (
            <Form>
              <Modal.Header className="p-4 header-modal header-modal-cdha">
                <Modal.Title>Kết quả chẩn đoán hình ảnh</Modal.Title>
                <button className="btn-close" onClick={handleClose}></button>
              </Modal.Header>
              <Modal.Body className="dialog-body border-bottom menu-button-cdha">
                <div
                  className={`flex gap-3 underline spaces ${
                    isViewModal ? "h-50" : ""
                  }`}
                >
                  {!isViewModal && (
                    <>
                      <div hidden ref={styleElementRef}></div>
                      <div hidden ref={htmlElementRef}></div>
                      <input
                        type="file"
                        hidden
                        ref={uploadFileRef}
                        accept=".doc, .docx"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                          onImportFileKetQua(e);
                        }}
                      />
                      <Dropdown className="dropdown-btn menu-icon">
                        <Dropdown.Toggle className="btn-outline">
                          <i className="bi bi-list m-0 p-0"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              if (uploadFileRef.current) {
                                uploadFileRef.current.click();
                              }
                            }}
                          >
                            Nhập file kết quả
                          </Dropdown.Item>
                          <Dropdown.Item onClick={onExportFileKetQua}>
                            Xuất file kết quả
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item>Lấy ảnh từ máy tính</Dropdown.Item>
                          <Dropdown.Item>
                            Lấy ảnh từ kết nối chẩn đoán hình ảnh
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item>
                            Cấu hình thư viện nhận ảnh
                          </Dropdown.Item>
                          <Dropdown.Item>
                            Cấu hình tự động crop ảnh
                          </Dropdown.Item>
                          <Dropdown.Divider />
                          <Dropdown.Item>Xuất raw kết quả</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <Button
                        className="btn-fill min-w-50px"
                        onClick={() =>
                          setShouldOpenModalDSMauKetQuaThucHien(true)
                        }
                      >
                        Mẫu kết quả
                        <i className="bi bi-caret-down-fill font-size-12px spaces ms-2"></i>
                      </Button>
                      <Button className="btn-fill min-w-50px">PACS</Button>
                      <Button
                        className="btn-fill min-w-50px"
                        onClick={() => handleSubmit()}
                      >
                        Lưu
                      </Button>
                      <Button
                        className="btn-fill min-w-50px"
                        onClick={handleLuuInTraKetQua}
                      >
                        Lưu / In / Trả kết quả
                      </Button>
                      <Button className="btn-fill min-w-50px">
                        In kết quả
                      </Button>
                      <Button
                        className="btn-fill min-w-50px"
                        onClick={() => setOpenWebcamCapture(true)}
                      >
                        Chụp ảnh
                      </Button>
                    </>
                  )}
                  <div className="text-break fw-500 py-2 px-5 text-end position-absolute top-0 bottom-0 end-0 spaces w-40">
                    <InfoPatientRight benhNhanInfo={infoBenhNhan} />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Body className="dialog-body padding-3-20">
                <Row className="h-100">
                  <Col xs={4} className="flex border">
                    <div className="flex-item-start pt-5 spaces w-100">
                      <Row>
                        <Col xs={12}>
                          <div className="label-input-group mt-3 d-flex">
                            <LabelRequired
                              label="Bắt đầu"
                              className="label fw-500 spaces fw-bold w-30"
                            />
                            <TextField
                              className="input flex-1"
                              name="startTime"
                              labelClassName="max-w-90px"
                              type="dateTime-Local"
                              value={inforResult?.startTime || ""}
                              disabled={isViewModal}
                              onChange={(e: any) =>
                                handleChangeInfor(e.target.value, "startTime")
                              }
                            />
                          </div>
                        </Col>
                        <Col xs={12}>
                          <div className="label-input-group mt-3 d-flex">
                            <LabelRequired
                              label="Kết thúc"
                              className="label fw-500 spaces fw-bold w-30"
                            />
                            <TextField
                              className="input flex-1"
                              name="endTime"
                              labelClassName="max-w-90px"
                              type="dateTime-Local"
                              value={inforResult?.endTime || ""}
                              disabled={isViewModal}
                              onChange={(e: any) =>
                                handleChangeInfor(e.target.value, "endTime")
                              }
                            />
                          </div>
                        </Col>
                        <Col xs={12}>
                          <div className="label-input-group mt-3 d-flex">
                            <LabelRequired
                              label="TG thực hiện"
                              className="label fw-500 spaces fw-bold w-30"
                            />
                            <TextField
                              className="input flex-1"
                              name="executionTime"
                              labelClassName="max-w-90px"
                              type="text"
                              value={inforResult?.executionTime || ""}
                              disabled={isViewModal}
                              onChange={(e: any) =>
                                handleChangeInfor(
                                  e.target.value,
                                  "executionTime"
                                )
                              }
                            />
                          </div>
                        </Col>
                        <Col xs={12}>
                          <div className="label-input-group mt-3 d-flex">
                            <LabelRequired
                              label="TG tối thiểu"
                              className="label fw-500 spaces fw-bold w-30"
                            />
                            <TextField
                              className="input flex-1"
                              name="minimumTime"
                              labelClassName="max-w-90px"
                              type="text"
                              value={inforResult?.minimumTime || ""}
                              disabled={isViewModal}
                              onChange={(e: any) =>
                                handleChangeInfor(e.target.value, "minimumTime")
                              }
                            />
                          </div>
                        </Col>
                        <Col xs={12}>
                          <div className="label-input-group mt-3 d-flex">
                            <LabelRequired
                              label="Máy thực hiện"
                              className="label fw-500 spaces fw-bold w-30"
                            />
                            <Autocomplete
                              className="input flex-1"
                              options={[]}
                              name="mayThucHien"
                              isDisabled={isViewModal}
                            />
                          </div>
                        </Col>
                        <Col
                          xs={12}
                          className="d-flex w-100 spaces mt-10 gap-3 img-group"
                        >
                          {/* <div className=""> */}
                          {imgSrcs.map((img: any, i: number) => (
                            <div className="item-img">
                              <img
                                key={i}
                                src={img?.src}
                                alt="CDHA"
                                className="w-100"
                              />
                            </div>
                          ))}
                          {/* </div> */}
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col
                    xs={8}
                    className="d-flex ckeditor-custom modal-thuc-hien-cdha pe-0"
                  >
                    <Row className="space w-100">
                      <Col xs={12}>
                        <CKEditor
                          editor={ClassicEditor}
                          onChange={(event: any, editor: any) => {
                            setNoiDungMauKetQua(editor.getData());
                          }}
                          data={
                            noiDungMauKetQua
                              ? noiDungMauKetQua
                              : inforResult?.result
                              ? inforResult?.result
                              : ""
                          }
                          disabled={isViewModal}
                          ref={editorRef}
                        />
                      </Col>

                      <Col xs={12}>
                        <div className="d-flex gap-3 mt-2">
                          <TextField
                            className="input spaces flex-10"
                            name="conclusion"
                            labelClassName="max-w-90px"
                            type="text"
                            value={inforResult?.conclusion || ""}
                            disabled={isViewModal}
                            onChange={(e: any) =>
                              handleChangeInfor(e.target.value, "conclusion")
                            }
                          />
                          {!isViewModal && (
                            <span className="spaces flex-2 d-flex gap-3">
                              <Button className="btn-fill min-w-75px me-3">
                                Gợi ý
                              </Button>
                              <Button className="btn-danger min-w-75px">
                                Xoá
                              </Button>
                            </span>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Modal.Body>
            </Form>
          )}
        </Formik>
      </Modal>
      {shouldOpenModalDSMauKetQuaThucHien && (
        <ModalDSMauKetQuaThucHien
          selectMauKetQua={handleSelectMauKetQua}
          handleClose={() => handleCloseModalMauKetQuaThucHien()}
        />
      )}
      {shouldOpenModalPickTimeBDThucHien && (
        <ModalPickTimeBDThucHien
          handleClose={() => handleCloseModalPickTimeBDThucHien()}
          ngay={ngay}
          handleSetNgay={setNgay}
        />
      )}
      {shouldOpenModalPickTimeKTThucHien && (
        <ModalPickTimeKTThucHien
          handleClose={() => handleCloseModalPickTimeKTThucHien()}
          ngay={ngay}
          handleSetNgay={setNgay}
        />
      )}
      {openWebcamCapture && (
        <WebcamCapture
          open={openWebcamCapture}
          handleClose={() => setOpenWebcamCapture(false)}
          imgSrcs={imgSrcs}
          setImageSrcs={setImageSrcs}
        />
      )}
    </>
  );
}
