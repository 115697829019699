import moment from 'moment'
import { TableCustom } from "../../component/table/table-custom/TableCustom";
import { TablePagination } from "../../component/table/components/TablePagination";
import {
    FC,
    useContext,
    useEffect,
    useState,
} from "react";
import { convertNumberPrice } from "../../utils/FormatUtils";
import { handlePagesChange, handleRowsPerPageChange, rowsForPage } from "../../utils/PageUtils";
import { AppContext } from "../../appContext/AppContext";
import { CODE, DEFAULT_PAGE_INDEX } from "../../utils/Constant";
import { deleteHopDong, searchByPage } from "../services/KhamDoanHopDongServices";
import CustomIconButton from "../../component/custom-icon-button/CustomIconButton";
import { Column } from "react-table";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { TableCustomCell } from "../../component/table/components/TableCustomCell";
import { QuanLyHopDongContext, QuanLyHopDongContextProps } from "./tab-quan-ly-hop-dong/QuanLyHopDong"
import { ConfirmDialog } from '../../component/ConfirmDialog';
import { toast } from 'react-toastify';

type Props = {
    show: boolean;
    filter?: any;
    setValues?: any;
    isResearch: boolean;
    setOpenAddEditHopDong: () => void;
    onHandlerOpenThongTinHĐ?: (row: any) => void;
    checked?: string;
    isColumnsActions?: boolean;
};

const DSHopDong: FC<Props> = (props) => {
    const { onHandlerOpenThongTinHĐ, checked = "", isColumnsActions = false, filter, isResearch, setOpenAddEditHopDong } = props;
    const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
    const { setIsLoading } = useContext(AppContext);
    const { setTTHopDong } = useContext<QuanLyHopDongContextProps>(QuanLyHopDongContext)
    const [dsHopDong, setDsHopDong] = useState<any>();
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [hopDongSelected, setHopDongSelected] = useState<any>();
    const [rowsPerPage, setRowsPerPage] = useState<number>(20);
    const columnsActions = {
        Header: (props: any) => (
            <TableCustomHeader<any>
                tableProps={props}
                title={"Thao tác"}
                className='text-center text-light min-w-80px'
            />
        ),
        id: 'actions',
        Cell: ({ ...props }) => (
            <div className="d-flex justify-content-center ">
                <>
                    <CustomIconButton
                        variant="edit"
                        onClick={() => { handlerEditHopDong(props?.row?.original) }}
                    >
                        <i className="bi bi-pencil-square text-navy"></i>
                    </CustomIconButton>

                    <CustomIconButton
                        variant="delete"
                        onClick={() => confirmDeleteHopDong(props?.row?.original)}
                    >
                        <i className="bi bi-trash3-fill text-danger"></i>
                    </CustomIconButton>
                </>
            </div>
        )
    };

    const dsHopDongColumns: ReadonlyArray<Column<any>> = [
        {
            Header: (props) => (
                <TableCustomHeader<any>
                    tableProps={props}
                    title={"STT"}
                    className="text-center text-light max-w-35"
                />
            ),
            id: "STT",
            Cell: ({ ...props }) => (
                <TableCustomCell
                    className="text-center "
                    data={String(props?.row?.index + 1)}
                />
            ),
        },
        {
            Header: (props) => (
                <TableCustomHeader<any>
                    tableProps={props}
                    title={"Tên hợp đồng"}
                    className="text-center text-light min-w-140px"
                />
            ),
            id: "contractName",
            Cell: ({ ...props }) => (
                <TableCustomCell className="text-truncate" data={props?.data[props?.row?.index].contractName} />
            ),
        },
        {
            Header: (props) => (
                <TableCustomHeader<any>
                    tableProps={props}
                    title={"Tên công ty"}
                    className="text-center text-light min-w-200px"
                />
            ),
            id: "personName",
            Cell: ({ ...props }) => (
                <TableCustomCell className="text-truncate" data={props?.data[props?.row?.index].companyName} />
            ),
        },
        {
            Header: (props) => (
                <TableCustomHeader<any>
                    tableProps={props}
                    title={"Địa chỉ công ty"}
                    className="text-center text-light min-w-200px"
                />
            ),
            id: "address",
            Cell: ({ ...props }) => (
                <TableCustomCell className="text-truncate" data={props?.data[props?.row?.index].address} />
            ),
        },
        {
            Header: (props) => (
                <TableCustomHeader<any>
                    tableProps={props}
                    title={"Mã số thuế"}
                    className="text-center text-light min-w-100px"
                />
            ),
            id: "taxNumber",
            Cell: ({ ...props }) => (
                <TableCustomCell className="text-center text-truncate" data={props?.data[props?.row?.index].taxNumber} />
            ),
        },
        {
            Header: (props) => (
                <TableCustomHeader<any>
                    tableProps={props}
                    title={"Người liên lạc"}
                    className="text-center text-light min-w-175px"
                />
            ),
            id: "contactPerson",
            Cell: ({ ...props }) => (
                <TableCustomCell className="text-truncate" data={props?.data[props?.row?.index].contactPerson} />
            ),
        },
        {
            Header: (props) => (
                <TableCustomHeader<any>
                    tableProps={props}
                    title={"Ngày bắt đầu"}
                    className="text-center text-light min-w-175px"
                />
            ),
            id: "startDate",
            Cell: ({ ...props }) => (
                <TableCustomCell className="text-center text-truncate" data={moment(props?.data[props?.row?.index].startDate).format("DD/MM/YYYY")} />
            ),
        },
        {
            Header: (props) => (
                <TableCustomHeader<any>
                    tableProps={props}
                    title={"Ngày kết thúc"}
                    className="text-center text-light min-w-175px"
                />
            ),
            id: "endDate",
            Cell: ({ ...props }) => (
                <TableCustomCell className="text-center text-truncate" data={moment(props?.data[props?.row?.index].endDate).format("DD/MM/YYYY")} />
            ),
        },
        {
            Header: (props) => (
                <TableCustomHeader<any>
                    tableProps={props}
                    title={"Giá trị hợp đồng"}
                    className="text-center text-light min-w-150px"
                />
            ),
            id: "contractValue",
            Cell: ({ ...props }) => (
                <TableCustomCell className="text-center text-truncate" data={convertNumberPrice(props?.data[props?.row?.index].contractValue || "")} />
            ),
        },
        {
            Header: (props) => (
                <TableCustomHeader<any>
                    tableProps={props}
                    title={"Ghi chú"}
                    className="text-center text-light min-w-200px"
                />
            ),
            id: "note",
            Cell: ({ ...props }) => (
                <TableCustomCell className="text-truncate custom-placeholder" data={props?.data[props?.row?.index].note} />
            )
        }
    ];
    
    const [configTable, setConfigTable] = useState<any>({
        totalPages: 0,
        totalElements: 0,
        numberOfElements: 0,
    });

    const handleSelectBenhNhan = (data: any) => {
        onHandlerOpenThongTinHĐ && onHandlerOpenThongTinHĐ(data?.original);
    }

    const handlerEditHopDong = async (value: any) => {
        setTTHopDong(value);
        setOpenAddEditHopDong();
    }

    const confirmDeleteHopDong = (data: any) => {
        setHopDongSelected(data);
        setOpenDeleteModal(true);
    }

    const handleDeleteHopDong = async () => {
        try {
            setIsLoading(true);
            const res = await deleteHopDong(hopDongSelected.id);
            if (CODE.SUCCESS === res.status) {
                updatePageData();
                toast.success('Xóa hợp đồng thành công')
            }
        } catch (error) {
            toast.error('Có lỗi xảy ra, vui lòng thử lại');
            console.error(error);
        } finally {
            setOpenDeleteModal(false);
            setIsLoading(false);
        }
    };
    
    const updatePageData = async () => {
        try {
            setIsLoading(true);
            let { data } = await searchByPage({
                ...filter,
                pageIndex: page,
                pageSize: rowsPerPage,
            });
            setDsHopDong(data?.data?.content);
            setConfigTable({
                totalPages: data?.data?.pageable?.totalPages,
                totalElements: data?.data?.pageable?.totalElements,
                numberOfElements: data?.data?.pageable?.pageNumber,
            })
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        isResearch && updatePageData();
    }, [filter?.startDate, filter?.endDate, isResearch]);

    return (
        <div>
            <div>
                <div className="spaces">
                    <TableCustom
                        data={dsHopDong || []}
                        columns={dsHopDongColumns}
                        selectionMode={checked}
                        columnsActions={isColumnsActions && columnsActions}
                        handleDoubleClick={handleSelectBenhNhan}
                        verticalScroll={true}
                        className="spaces h-calc-vh-230"
                    />
                    <TablePagination
                        handlePagesChange={handlePagesChange}
                        handleRowsPerPageChange={handleRowsPerPageChange}
                        rowsPerPage={rowsPerPage}
                        rowsForPage={rowsForPage}
                        page={page}
                        setPage={setPage}
                        setRowsPerPage={setRowsPerPage}
                        totalPages={configTable.totalPages}
                        totalElements={configTable.totalElements}
                        numberOfElements={configTable.numberOfElements}
                        className="border border-0"
                    />
                </div>
            </div>
            <ConfirmDialog
                title="Xóa hợp đồng"
                message={`Bạn chắc chắn muốn xóa hợp đồng: ${hopDongSelected?.contractName} ?`}
                cancel='Đóng'
                yes="Xóa"
                show={openDeleteModal}
                onCloseClick={() => setOpenDeleteModal(false)}
                onYesClick={handleDeleteHopDong}
            />
        </div>
    );
};
export default DSHopDong;

