import React from 'react'
import { Dropdown } from 'react-bootstrap'

type Props = {
    listMenuItems: any[]
    color?: "fill" | "outlined"
    className?: string
    menuItemsClassName?: string
    label: string
    primaryItemLabel?: string
    drop?: "up" | "down" | "start" | "end"
    onClick?: () => void
}

const CustomDropdownButton = (props: Props) => {
    let {
        listMenuItems = [],
        color = "outlined",
        drop = "down",
        className,
        menuItemsClassName,
        label,
        primaryItemLabel,
        onClick
    } = props;
    return (
        <Dropdown className="dropdown-btn menu-icon" drop={drop}>
            <Dropdown.Toggle className={`${color === "fill" ? "btn-fill" : "btn-navy-outlined"} ${className}`}>
                {label}
                <i className="bi bi-caret-down-fill text-pri ms-2"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className={` spaces min-w-200px p-0 ${drop}-100 my-10 ${menuItemsClassName}`}>
                {listMenuItems?.map((item: any, index: number) => (
                    <Dropdown.Item className="p-0 border-bottom" onClick={item?.onClick} key={index}>
                        <div className="d-flex flex-column spaces px-20 py-12">
                            <p className="text-pri mb-0 text-truncate">{item?.title || ""}</p>
                            <p className="mb-0 fw-light text-truncate">{item?.desc || ""}</p>
                        </div>
                    </Dropdown.Item>
                ))}
                <Dropdown.Item className="p-0" onClick={onClick}>
                    <div className="spaces px-20 py-12 line-height-30">
                        <span className="fw-bold text-pri text-center">{primaryItemLabel || ""}</span>
                    </div>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}

export default CustomDropdownButton