import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { useIntl } from 'react-intl'
import * as Yup from "yup";
import "../QuanLyHopDong.scss"
import { TableCustom } from "../../../../../component/table/table-custom/TableCustom";
import { Button, Modal } from "react-bootstrap";
import LabelRequired from "../../../../../component/LabelRequired";
import { FC, useContext, useEffect, useState } from "react";
import { CODE } from "../../../../../utils/Constant";
import { AppContext } from "../../../../../appContext/AppContext";
import { IconBack } from "../../../../../component/IconSvg";
import { Column } from "react-table";
import { TableCustomHeader } from "../../../../../component/table/components/TableCustomHeader";
import { TableCustomCell } from "../../../../../component/table/components/TableCustomCell";
import PageChiDinhDichVu from "./modal-chi-dinh-dich-vu/PageChiDinhDichVu";
import { addGoiKham, updateGoiKham } from "../../../../services/KhamDoanHopDongServices"
import { QuanLyHopDongContext, QuanLyHopDongContextProps } from "../../QuanLyHopDong";
import FormThemMoiGoiKham from "./FormThemMoiGoiKham";
import { convertNumberPrice, formatMoney, sumByKey } from "../../../../../utils/FormatUtils";
import TextField from "../../../../../component/TextField";

type Props = {
    show: boolean;
    onHide: () => void;
};

const ModelThemMoiGoiKham: FC<Props> = (props) => {
    const { show, onHide } = props;
    const intl = useIntl();
    const { dataEditGK, TTHopDong } = useContext<QuanLyHopDongContextProps>(QuanLyHopDongContext)
    const { setIsLoading } = useContext(AppContext);
    const [dataDichVu, setDataDichVu] = useState<any>();
    const [listDichVu, setListDichVu] = useState<any>([]);
    const [openModalChonDichVu, setOpenModalChonDichVu] = useState<boolean>(false);
    const dsDichVuColumns: ReadonlyArray<Column<any>> = [
        {
            Header: (props) => (
                <TableCustomHeader<any>
                    tableProps={props}
                    title={"Mã DV"}
                    className="text-center text-light"
                />
            ),
            id: "code",
            Cell: ({ ...props }) => (
                <TableCustomCell className="text-center text-truncate" data={props?.data[props?.row?.index].code} />
            ),
        },
        {
            Header: (props) => (
                <TableCustomHeader<any>
                    tableProps={props}
                    title={"Tên dịch vụ"}
                    className="text-center text-light min-w-140px"
                />
            ),
            id: "name",
            Cell: ({ ...props }) => (
                <TableCustomCell className="text-left text-truncate" data={props?.data[props?.row?.index].name} />
            ),
        },
        {
            Header: (props) => (
                <TableCustomHeader<any>
                    tableProps={props}
                    title={"Phòng thực hiện"}
                    className="text-center text-light min-w-200px"
                />
            ),
            id: "deptClinicName",
            Cell: ({ ...props }) => (
                <TableCustomCell className="text-truncate" data={props?.data[props?.row?.index]?.deptClinicName} />
            ),
        },
        {
            Header: (props) => (
                <TableCustomHeader<any>
                    tableProps={props}
                    title={"Đơn giá VP"}
                    className="text-center text-light min-w-150px"
                />
            ),
            id: "svcFeeHospital",
            Cell: ({ ...props }) => (
                <TableCustomCell className="text-truncate text-end" data={formatMoney(props?.data[props?.row?.index]?.svcFeeHospital)} />
            ),
        },
        {
            Header: (props) => (
                <TableCustomHeader<any>
                    tableProps={props}
                    title={"Đơn giá theo gói"}
                    className="text-center text-light min-w-150px"
                />
            ),
            id: "feePackage",
            Cell: ({ ...props }) => {
                const index = props.row.index;
                const rowData = props.data[index];
                return rowData?.editFee ? (
                    <TextField
                        name={`feePackage-${index}`}
                        key={`feePackage-${index}`}
                        id={`feePackage-${index}`}
                        type="number"
                        onBlur={(event: React.ChangeEvent<HTMLInputElement>) => handleChangeRowTable(index, event)}
                    />
                ) : (
                    <TableCustomCell className="text-truncate text-end" data={formatMoney(rowData?.feePackage)} />
                )
            },
        },
    ];
    
    const handleChangeRowTable = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const newListDichVu = [...listDichVu];
        newListDichVu[index].feePackage = event.target.value;
        newListDichVu[index].editFee = false;
        setListDichVu(newListDichVu);
    };

    const handleDoubleClick = (row: any) => {
        const newListDichVu = [...listDichVu];
        newListDichVu[row.index].editFee = true;
        setListDichVu(newListDichVu);
    }

    const convertListDichVu = (list: any[]) => {
        let convertedData: any[] = list?.map((item) => {
            return {
                ...item,    
                ...item?.attributes,
                deptClinicName: item?.attributes?.deptLaboratoryName,
                fee: item?.attributes?.svcFeeHospital,
                feePackage: item?.feePackage || item?.attributes?.svcFeeHospital,
            };
        });
        return convertedData || [];
    }
    useEffect(() => {
        const data = convertListDichVu(dataDichVu?.terms)
        setListDichVu(data || [])
    }, [dataDichVu])
    useEffect(() => {
        let convertedData = dataEditGK;
        convertedData.total = handleCaculateFee(dataEditGK?.terms)
        setDataDichVu(convertedData || [])
    }, [dataEditGK?.terms])


    const handleCaculateFee = (data: any) => {
        let objResult = {
            tong: 0,
            benhNhan: 0,
            conNo: 0
        };
        data?.map((item: any) => {
            objResult = {
                tong: objResult.tong + Number(item?.attributes?.svcFeeHospital),
                benhNhan: 0,
                conNo: 0
            };
        });
        return objResult;
    };
    const handlerOpenChonDichVu = () => {
        setOpenModalChonDichVu(true)
    }
    const handleClose = () => {
        setOpenModalChonDichVu(false)
    }

    const initialValues = {
        code: "",
        name: "",
        amount: "",
        gender: null,
    }

    const validationSchema = Yup.object({
        code: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
        name: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
        amount: Yup.number().typeError("Vui lòng nhập số").required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
    });
    const handleFormSubmit = async (values: any) => {
        if (!listDichVu.length) { 
            toast.warning('Chưa chọn dịch vụ');
            return;
        };
        const newListDichVu = listDichVu.map((item: any) => ({...item, feePackage: item?.feePackage || 0, fee: item?.svcFeeHospital}))
        let valuesGoiKham = values;
        valuesGoiKham.terms = newListDichVu;
        valuesGoiKham.contractId = TTHopDong?.id || "";
        valuesGoiKham.staffGroupId = values?.staffGroup?.id || "";
        valuesGoiKham.staffGroupName = values?.staffGroup?.name || "";
        valuesGoiKham.staffGroupId = values?.staffGroup?.id || "";
        valuesGoiKham.gender = values?.gender?.name || null;
        valuesGoiKham.divisionName = values?.division?.name || "";
        valuesGoiKham.divisionId = values?.division?.id || "";
        try {
            setIsLoading(true);
            let { data } = (values?.id)
                ? await updateGoiKham({
                    ...valuesGoiKham,
                    id: valuesGoiKham?.id
                })
                : await addGoiKham(valuesGoiKham);
            if (CODE.SUCCESS === data?.code) {
                toast.success(((values?.id) ? "Cập nhật" : "Thêm") + " gói khám thành công");
                setIsLoading(false);
                onHide()
            } else {
                let errorMesage = data?.message || "Xảy ra lỗi, vui lòng thử lại!";
                toast.warning(errorMesage);
            }
        } catch (error) {
            toast.warning("Xảy ra lỗi, vui lòng thử lại!");
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <div>
            <Formik<any>
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => handleFormSubmit(values)}
            >
                {({ values, errors, touched, handleSubmit, setFieldValue, handleChange, setValues }) => (
                    <Form onSubmit={handleSubmit} id="form-them-dich-vu" className="spaces">
                        <Modal
                            centered
                            show={show}
                            onHide={onHide}
                            size="sm"
                            fullscreen
                            className="page-full spaces h-calc-vh-45 scroll-hidden"
                        >

                            <Modal.Header className="py-3 header-modal">
                                <div className="d-flex gap-6">
                                    <div
                                        className="d-flex border-right align-items-center gap-3 cursor-pointer"
                                        onClick={onHide}
                                    >
                                        <IconBack />
                                        <span className="fw-500 ">Quay lại &nbsp;</span>
                                    </div>
                                    <Modal.Title >
                                        <div className="text-transform-none fs-3">{`${!dataEditGK.id ? 'Thêm mới' : 'Cập nhật'} gói khám`}</div>
                                    </Modal.Title>
                                </div>
                            </Modal.Header>

                            <Modal.Body className="spaces p-0 bg-white mt-5 ">
                                <FormThemMoiGoiKham />

                                <div className="spaces">
                                    <span
                                        onClick={handlerOpenChonDichVu}
                                        className="fw-bold customer-buttom-text m-4 cursor-pointer line-height-36"
                                    >
                                        Chọn dịch vụ
                                    </span>
                                    <TableCustom
                                        data={listDichVu || []}
                                        columns={dsDichVuColumns}
                                        verticalScroll={true}
                                        handleDoubleClick={handleDoubleClick}
                                        className="spaces h-calc-vh-250"
                                    />
                                    <div>

                                    </div>
                                </div>
                                
                            </Modal.Body>

                            <Modal.Footer className="d-flex flex-column align-items-end spaces h-110 scroll-hidden">
                                <div className="flex">
                                    <div className=" justify-content-between spaces mb-5 gap-4 min-w-200px">
                                        <LabelRequired
                                            label="Tổng tiền thực tế:"
                                            className="text-danger ms-0 spaces pr-16 min-w-135"
                                        />
                                        <div className="text-danger fw-500">
                                            {convertNumberPrice(sumByKey(listDichVu, 'svcFeeHospital'))}
                                        </div>
                                    </div>
                                    <div className=" justify-content-between spaces mb-5 gap-4 min-w-200px">
                                        <LabelRequired
                                            label="Tổng tiền theo gói:"
                                            className="coler-text-price ms-0 spaces pr-16 min-w-135"
                                        />
                                        <div className="coler-text-price fw-500">
                                            {convertNumberPrice(sumByKey(listDichVu, 'feePackage'))}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-content-between spaces">
                                    <Button
                                        className='btn-outline-primary min-w-80 btn-fill spaces h-29'
                                        onClick={() => handleSubmit()}
                                        type="submit"
                                    >
                                        Lưu
                                    </Button>
                                </div>
                            </Modal.Footer>

                        </Modal>
                    </Form>
                )}
            </Formik>
            {openModalChonDichVu &&
                <PageChiDinhDichVu setDataDichVu={setDataDichVu} handleClose={handleClose} listTerms={listDichVu} />
            }
        </div>
    );
};
export default ModelThemMoiGoiKham;

