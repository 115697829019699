import { Formik } from "formik";
import { FC, useContext } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AppContext } from "../appContext/AppContext";
import { CODE } from "../utils/Constant";
import { formatDateDTO } from "../utils/FormatUtils";
import { QuanLyHopDongContext, QuanLyHopDongContextProps } from "./components/tab-quan-ly-hop-dong/QuanLyHopDong";
import { initialValuesThemHD } from "./constants/PhanHeKhamDoan";
import FormThemMoiHopDong from "./FormThemMoiHopDong";
import { AddHopDong } from "./models/KhamDoanModel";
import { addHopDong, updateHopDong } from "./services/KhamDoanHopDongServices";

type Props = {
    show: boolean;
    onHide: () => void;
};

const ThemMoiHopDong: FC<Props> = (props) => {
    const { show, onHide } = props;
    const { TTHopDong} = useContext<QuanLyHopDongContextProps>(QuanLyHopDongContext)
    const { setIsLoading } = useContext(AppContext);

    const validationSchema = Yup.object({
        contractName: Yup.string().required("Bắt buộc nhập"),
        companyName: Yup.string().required("Bắt buộc nhập"),
        startDate: Yup.date().required("Bắt buộc nhập"),
        endDate: Yup.date().required("Bắt buộc nhập"),
        paymentMethod: Yup.object().nullable(false).required('Bắt buộc chọn'),
        taxNumber: Yup.string()
            .min(10, "Tối thiểu 10 ký tự")
            .max(13, "Tối đa 13 ký tự")
            .required("Bắt buộc nhập"),
        contractValue: Yup.number()
            .typeError("Vui lòng nhập số")
            .required("Bắt buộc nhập"),
      });

    const handleFormSubmit = async (values: AddHopDong) => {
        const patientValues = values;
        patientValues.startDate = formatDateDTO(values?.startDate || "");
        patientValues.endDate = formatDateDTO(values?.endDate || "");
        patientValues.paymentMethodId = patientValues?.paymentMethod?.id || "";
        patientValues.paymentMethodName = patientValues?.paymentMethod?.name || "";
        patientValues.paymentMethod = {};

        try {
            setIsLoading(true);
            let { data } = (patientValues?.id || TTHopDong?.id)
                ? await updateHopDong({
                    ...patientValues,
                    id: TTHopDong?.id || patientValues?.id
                })
                : await addHopDong(patientValues);
            if (CODE.SUCCESS === data?.code) {
                toast.success(((patientValues?.id || TTHopDong?.id) ? "Cập nhật" : "Thêm") + " hợp đồng thành công");
                onHide()
            } else {
                let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
                toast.warning(errorMesage);
            }
        } catch (error) {
            toast.warning("Xảy ra lỗi, vui lòng thử lại!");
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <Formik<AddHopDong>
            initialValues={{ ...initialValuesThemHD }}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                setValues,
                touched,
                isValid,
                errors,
                setFieldValue,
            }) => (
                <Form onSubmit={handleSubmit}>
                    <div className="receive overflow-auto" id="form-them-moi-hop-dong" >
                        <Modal centered show={show} onHide={onHide} size="xl" className=" spaces h-calc-vh-50">
                            <Modal.Header closeButton className="header-modal  min-h-50px me-2">
                                <Modal.Title>
                                    <div className="text-transform-none fs-3">Thêm mới hợp đồng</div>
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body className="border-right-0  border-left-0 border-top-0 py-2 spaces px-5 bg-white pe-2e">
                                <FormThemMoiHopDong thongTinHD={TTHopDong} touched={touched} errors={errors}/>
                            </Modal.Body >
                            <Modal.Footer>
                                <Button type="submit" className=" min-w-80px btn-fill btn mr-5" onClick={() => handleSubmit()}>
                                    Lưu
                                </Button>
                            </Modal.Footer>
                        </Modal >
                    </div>
                </Form>
            )}
        </Formik >
    );
};
export default ThemMoiHopDong;

