import { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Column } from "react-table";
import { toast } from "react-toastify";
import { AppContext } from "../../../appContext/AppContext";
import { TableCustomCell } from "../../../component/table/components/TableCustomCell";
import { TableCustomHeader } from "../../../component/table/components/TableCustomHeader";
import {
  CODE,
  DEFAULT_PAGE_INDEX,
  MAX_SIZE,
  RESPONSE_MESSAGE,
  SELECTION_MODE,
} from "../../../utils/Constant";
import {
  formatDateToYYYYMMDD,
  formatTrangThaiBenhNhan,
} from "../../../utils/FormatUtils";
import { IBenhNhanXNModel } from "../../models/DanhSachBenhNhanModels";
import {
  getListPatientXN,
  getBenhNhanInfo,
  getPhieuXetNghemInfo,
} from "../../service/tiepNhan";
import { PhanHeXetNghiemContext } from "../../context/PhanHeXetNghiemContext";
import { TableCustom } from "../../../component/table/table-custom/TableCustom";
import { getSummaryStatusByTracker } from "../../../workflows/services/WfWorkflowsService";
import { WfConstTracker } from "../../../workflows/Constant";
import { WfSummaryStatusRes } from "../../../workflows/models/WfWorkflows";
import FilterSidebar from "../../../component/filter-sidebar/FilterSidebar";
import { OctAutocomplete } from "@oceantech/oceantech-ui";
import { searchDepartment } from "../../../phan-he-quan-tri-he-thong/services/TabKhoaPhongServices";

const DsBenhNhan = (props: any) => {
  const { setPhieuXetNghiemInfo, setBenhNhanInfo } = useContext(
    PhanHeXetNghiemContext
  );
  const { setIsLoading } = useContext(AppContext);
  const [dsBenhNhanXN, setListBenhNhanXN] = useState<any>([]);
  const [summaryStatus, setSummaryStatus] = useState<WfSummaryStatusRes[]>([]);

  const getDanhSachBenhNhan = async (searchData?: any) => {
    setIsLoading(true);
    let searchObject = {
      pageIndex: DEFAULT_PAGE_INDEX,
      pageSize: 9999999,
      ...searchData,
    };
    try {
      const { data } = await getListPatientXN(searchObject);
      if (CODE.SUCCESS === data?.code) {
        setListBenhNhanXN(data?.data?.content || []);
      } else {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      }
    } catch {
      toast.warning(RESPONSE_MESSAGE.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDanhSachBenhNhan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.statusChangedEvent]);
  useEffect(() => {
    const getSummaryStatus = async () => {
      try {
        const res = await getSummaryStatusByTracker(WfConstTracker.TEST);
        if (res.data) {
          setSummaryStatus(res.data.data);
        }
      } catch {}
    };
    getSummaryStatus();
  }, [props.statusChangedEvent]);

  const handleSelectedBenhNhan = async (benhNhans: any) => {
    try {
      setIsLoading(true);
      let benhNhan = benhNhans?.[0];
      let { data: dataBN } = await getBenhNhanInfo(benhNhan?.id);
      let { data: dataXN } = await getPhieuXetNghemInfo(benhNhan?.id);
      if (CODE.SUCCESS === dataBN?.code && CODE.SUCCESS === dataXN?.code) {
        let thongTinPhieu = dataXN?.data;
        let dsXetNghiem = thongTinPhieu?.orders?.map((item: any) => ({
          ...item,
          ...item?.term,
        }));
        setPhieuXetNghiemInfo({
          thongTinPhieu,
          dsXetNghiem,
        });
        setBenhNhanInfo({
          ...dataBN?.data,
          isPerform: false,
          testGroupId: benhNhan?.id,
        });
      } else {
        toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      }
    } catch (error) {
      console.error(error);
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
    } finally {
      setIsLoading(false);
    }
  };
  const DsBenhNhanColumnV2: ReadonlyArray<Column<IBenhNhanXNModel>> = [
    {
      Header: (props) => (
        <TableCustomHeader<IBenhNhanXNModel>
          tableProps={props}
          title={"TT"}
          className="text-center text-light min-w-30px"
        />
      ),
      id: "TT",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center"
          data={formatTrangThaiBenhNhan(props?.data[props?.row?.index]?.color)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<IBenhNhanXNModel>
          tableProps={props}
          title={"Barcode"}
          className="text-center text-light min-w-70px"
        />
      ),
      id: "barcode",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center"
          data={props?.data[props?.row?.index]?.barcode}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<IBenhNhanXNModel>
          tableProps={props}
          title={"Tên khách hàng"}
          className="text-center text-light min-w-350px"
        />
      ),
      id: "patientName",
      Cell: ({ ...props }) => (
        <div className="text-system text-truncate">
          <div className="flex m-0">
            <span className="text-uppercase fs-13px">
              {props?.data[props?.row?.index]?.patientName}
            </span>
          </div>
          <div className="flex m-0">
            <span className="text-uppercase fw-semibold">
              {props?.data[props?.row?.index]?.patientCode || ""}
            </span>
            <span className="px-1 fs-13px"> - </span>
            <span>
              {props?.data[props?.row?.index]?.visitObjectTypeName || ""}
            </span>
            <span className="px-1 fs-13px"> - </span>
            <span>{props?.data[props?.row?.index]?.gender || ""}</span>
          </div>
        </div>
      ),
    },
  ];

  const [contextMenu, setContextMenu] = useState<null | {
    x: number;
    y: number;
  }>(null);

  const handleRightClick = (e: any, row: any) => {
    e.preventDefault();
    setContextMenu({ x: e.clientX, y: e.clientY });
    return contextMenu;
  };

  const doSearch = (props: any) => {
    const searchObject = {
      keyword: props.keyword,
      sortBy: props.formal?.value,
      descending: props.order?.value === "DERCEASE",
      fromDate: formatDateToYYYYMMDD(props.period?.fromDate),
      toDate: formatDateToYYYYMMDD(props.period?.toDate),
    };
    getDanhSachBenhNhan(searchObject);
  };

  const LIST_FORMAL = [
    {
      id: 1,
      value: "indicationTime",
      name: "Ngày y lệnh",
    },
    {
      id: 2,
      value: "specimenCollectionStartTime",
      name: "Ngày lấy mẫu",
    },
    {
      id: 3,
      value: "testingStartTime",
      name: "Ngày thực hiện",
    },
    {
      id: 4,
      value: "resultReportingTime",
      name: "Ngày trả kết quả",
    },
  ];

  return (
    <>
      <FilterSidebar
        title="Danh sách xét nghiệm"
        formalList={LIST_FORMAL}
        onSearch={doSearch}
        listMenuItem={[]}
        handleSelectOption={() => {}}
      />
      <div className="spaces h-calc-vh-170 d-flex flex-column justify-content-between pt-10">
        <div className="spaces h-calc-vh-333">
          <TableCustom
            data={dsBenhNhanXN || []}
            columns={DsBenhNhanColumnV2}
            selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
            getSelectedRowsData={handleSelectedBenhNhan}
            verticalScroll={true}
            className="h-100"
            handleContextMenu={handleRightClick}
          />
        </div>
        <Row className="d-flex spaces w-100">
          <Col xs={12} className="spaces ps-5 pb-3 pr-0">
            <OctAutocomplete
              options={[]}
              placeholder="Tất cả khoa phòng"
              searchFunction={searchDepartment}
              searchObject={{ pageSize: MAX_SIZE }}
              onChange={(option) => {}}
            />
          </Col>
        </Row>
        <Row className="count-status-xet-nghiem spaces w-100 gap-10 px-5 justify-content-center mx-auto">
          {summaryStatus.map((item, index) => (
            <div
              key={index}
              className="d-flex status text-white rounded spaces px-8 py-4 w-100 justify-content-center"
              style={{ backgroundColor: item.color }}
            >
              <span className="body-normal-1 spaces">{`${item.statusName}: ${item.summary}`}</span>
            </div>
          ))}
        </Row>
      </div>
    </>
  );
};

export default DsBenhNhan;
