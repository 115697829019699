import { TableCustom } from '../../../../../component/table/table-custom/TableCustom';

type Props = {
	data: any[];
	columns: any;
};

const ListDonThuoc = ({ data, columns }: Props) => {
	return (
		<TableCustom<any> className={`spaces border-bottom h-200`} data={data} columns={columns} />
	);
};

export default ListDonThuoc;
