import React from "react";
import { Column } from "react-table";
import { TableCustomHeader } from "../../../../../../component/table/components/TableCustomHeader";
import { TableCustomCell } from "../../../../../../component/table/components/TableCustomCell";
import { formatMoney } from "../../../../../../utils/FormatUtils";
import { IconCabinet, IconHome, IconMenu } from "../../../../../../component/IconSvg"
import { DanhSachBenhNhanInterface } from "../../../../../models/KhamDoanModel";
import { formatDateToDDMMYYYY } from "../../../../../../utils/FormatUtils"

const DanhSachNhanVienColumn: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Mã DV"}
        className="text-center text-transform-none text-light min-w-100px fs-8"
      />
    ),
    id: "Mã dịch vụ",
    Cell: ({ ...props }) => (
      <TableCustomCell className="text-center " data={props?.data[props?.row?.index].code} />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Tên dịch vụ"}
        className="text-center text-transform-none text-light min-w-300px fs-8"
      />
    ),
    id: "Tên dịch vụ",
    Cell: ({ ...props }) => {
      return <TableCustomCell className="" data={props?.data[props?.row?.index].name} />;
    },
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Số lượng"}
        className="text-center text-transform-none text-light min-w-100px fs-8"
      />
    ),
    id: "Số lượng",
    Cell: ({ ...props }) => {
      return <TableCustomCell className="text-center " data={props?.data[props?.row?.index].soLuong} />;
    },
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Giá BHTY"}
        className="text-center text-transform-none text-light min-w-100px fs-8"
      />
    ),
    id: "Giá BHTY",
    Cell: ({ ...props }) => {
      return <TableCustomCell className="text-end " data={props?.data[props?.row?.index].giaBHYT} />;
    },
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Giá viện phí"}
        className="text-center text-transform-none text-light min-w-120-px fs-8"
      />
    ),
    id: "Giá viện phí",
    Cell: ({ ...props }) => {
      return <TableCustomCell className="text-end " data={props?.data[props?.row?.index].giaVienPhi} />;
    },
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Giá dịch vụ"}
        className="text-center text-transform-none text-light min-w-125px fs-8"
      />
    ),
    id: "Giá dịch vụ",
    Cell: ({ ...props }) => {
      return <TableCustomCell className="text-end " data={props?.data[props?.row?.index].giaDV} />;
    },
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Đơn giá"}
        className="text-center text-transform-none text-light min-w-100px fs-8"
      />
    ),
    id: "Đơn giá",
    Cell: ({ ...props }) => {
      return <TableCustomCell className="text-end " data={props?.data[props?.row?.index].donGia} />;
    },
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Thành tiền"}
        className="text-center text-transform-none text-light min-w-125px fs-8"
      />
    ),
    id: "Thành tiền",
    Cell: ({ ...props }) => {
      return <TableCustomCell className="text-end " data={props?.data[props?.row?.index].thanhTien} />;
    },
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Phòng thực hiện"}
        className="text-center text-transform-none text-light min-w-150px fs-8"
      />
    ),
    id: "Phòng thực hiện",
    Cell: ({ ...props }) => {
      return <TableCustomCell className="" data={props?.data[props?.row?.index].phongThucHien} />;
    },
  },
];

const DsDichVuChiDinhColumn: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"STT"}
        className="text-center text-transform-none text-light min-w-40px fs-8"
      />
    ),
    id: "STT",
    Cell: ({ ...props }) => <TableCustomCell className="text-center " data={props?.row?.index + 1} />,
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Mã DV"}
        className="text-center text-transform-none text-light min-w-100px fs-8"
      />
    ),
    id: "Mã dịch vụ",
    Cell: ({ ...props }) => (
      <TableCustomCell data={props?.data[props?.row?.index].code} />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Tên dịch vụ"}
        className="text-center text-transform-none text-light min-w-250px fs-8"
      />
    ),
    id: "Tên dịch vụ",
    Cell: ({ ...props }) => {
      let data = props?.data[props?.row?.index].name;
      return <TableCustomCell className="spaces max-w-250 text-truncate" data={data} />;
    },
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Số lượng"}
        className="text-center text-transform-none text-light min-w-100px fs-8"
      />
    ),
    id: "Số lượng",
    Cell: ({ ...props }) => {
      let data = props?.data[props?.row?.index]?.attributes?.svcQuantity || "";
      return <TableCustomCell className="text-center " data={data} />;
    },
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Đơn giá"}
        className="text-center text-transform-none text-light min-w-100px fs-8"
      />
    ),
    id: "Đơn giá",
    Cell: ({ ...props }) => {
      let data = props?.data[props?.row?.index]?.attributes?.svcFee || "";
      return <TableCustomCell className="text-end " data={data ? formatMoney(data) : ""} />;
    },
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Ghi chú"}
        className="text-center text-transform-none text-light min-w-200px fs-8"
      />
    ),
    id: "Ghi chú",
    Cell: ({ ...props }) => {
      let data = props?.data[props?.row?.index].ghiChu;
      return <TableCustomCell className="" data={data} />;
    },
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Phòng thực hiện"}
        className="text-center text-transform-none text-light min-w-200px fs-8"
      />
    ),
    id: "Phòng thực hiện",
    Cell: ({ ...props }) => {
      let data = props?.data[props?.row?.index].phongThucHien;
      return <TableCustomCell className="" data={data} />;
    },
  },
];

const dsNhanVienColumnsSimple: any[] = [
  {
    name: "Mã nhân viên",
    field: "code",
    width: 100,
    minWidth: 100,
    textAlign: "center"
  },
  {
    name: "Mã BN",
    field: "code",
    width: 90,
    minWidth: 90,
    textAlign: "center"
  },
  {
    name: "Tên bệnh nhân",
    field: "name",
    width: 150,
    minWidth: 150,
    textAlign: "left"
  },
  {
    name: "Chức vụ",
    field: "svcQuantity",
    width: 90,
    minWidth: 90,
    textAlign: "center"
  },
  {
    name: "Giới tính",
    field: "svcFeeIns",
    width: 90,
    minWidth: 90,
    textAlign: "end",
  },
  {
    name: "Ngày sinh",
    field: "svcFeeHospital",
    width: 100,
    minWidth: 100,
    textAlign: "end",
  },
  {
    name: "Giá dịch vụ",
    field: "svcFee",
    width: 100,
    minWidth: 100,
    textAlign: "end",
  },
  {
    name: "CMND/CCCD",
    field: "svcPrice",
    width: 90,
    minWidth: 90,
    textAlign: "end",
  },
  {
    name: "Địa chỉ",
    field: "svcTotal",
    width: 400,
    minWidth: 250,
    textAlign: "end",
  },
  {
    name: "Tình trạng hôn nhân",
    field: "deptLaboratoryName",
    width: 250,
    minWidth: 110,
  },
  {
    name: "Số điện thoại",
    field: "deptLaboratoryName",
    width: 250,
    minWidth: 110,
  },
  {
    name: "Người giới thiệu",
    field: "deptLaboratoryName",
    width: 300,
    minWidth: 110,
  },
];
const DanhSachBenhNhanColumns: ReadonlyArray<
  Column<DanhSachBenhNhanInterface>
> = [
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachBenhNhanInterface>
          tableProps={props}
          title={"Mã nhân viên"}
          className="text-center text-white align-middle bg-pri min-w-150px"
        />
      ),
      id: "code",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index].code}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachBenhNhanInterface>
          tableProps={props}
          title={"Mã BN"}
          className="text-center text-white align-middle bg-pri min-w-150px"
        />
      ),
      id: "patientCode",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index].patientCode}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachBenhNhanInterface>
          tableProps={props}
          title={"Tên nhân viên"}
          className="text-center text-white align-middle bg-pri min-w-150px"
        />
      ),
      id: "name",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index].name}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachBenhNhanInterface>
          tableProps={props}
          title={"Chức vụ"}
          className="text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "position",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index].position}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachBenhNhanInterface>
          tableProps={props}
          title={"Giới tính"}
          className="text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "gender",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index].gender}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachBenhNhanInterface>
          tableProps={props}
          title={"Ngày sinh"}
          className="text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "dob",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={formatDateToDDMMYYYY(props.data[props.row.index].dob)}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachBenhNhanInterface>
          tableProps={props}
          title={"CMND/CCCD"}
          className="text-center text-white align-middle bg-pri min-w-150px"
        />
      ),
      id: "idNumber",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index].idNumber}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachBenhNhanInterface>
          tableProps={props}
          title={"Địa chỉ"}
          className="text-center text-white align-middle bg-pri min-w-250px"
        />
      ),
      id: "address",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index].address}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachBenhNhanInterface>
          tableProps={props}
          title={"Tình trạng hôn nhân"}
          className="text-center text-white align-middle bg-pri min-w-200px"
        />
      ),
      id: "maritalStatusName",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index].maritalStatusName}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachBenhNhanInterface>
          tableProps={props}
          title={"Số điện thoại"}
          className=" text-center text-white align-middle bg-pri min-w-150px"
        />
      ),
      id: "phone",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index]?.phone}
          className="text-center"
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<DanhSachBenhNhanInterface>
          tableProps={props}
          title={"Người giới thiệu"}
          className="text-center text-white align-middle bg-pri min-w-150px"
        />
      ),
      id: "referringPerson",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={props.data[props.row.index].referringPerson}
        />
      ),
    },
  ];


const TreeChiDinhDichVu = {
  code: "all",
  name: "Tất cả dịch vụ",
  icon: <IconMenu />,
  filter: [
    {
      code: "all.khamBenh",
      name: "Khám bệnh",
      filter: [
        { code: "khamBenh", name: "Khám bệnh" },
        { code: "khamSucKhoe", name: "Khám sứa khỏe" },
      ],
    },
    {
      code: "xetNghiem",
      name: "Xét nghiệm",
    },
    {
      code: "chanDoanHinhAnh",
      name: "Chẩn đoán hình ảnh",
    },
  ],
};
export { DanhSachNhanVienColumn, DanhSachBenhNhanColumns, DsDichVuChiDinhColumn, dsNhanVienColumnsSimple, TreeChiDinhDichVu };
