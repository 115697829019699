import { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { MAX_SIZE, SELECTION_MODE } from "../../utils/Constant";
import { TableCustom } from "../../component/table/table-custom/TableCustom";
import { formatSummaryStatus } from "../../utils/FormatUtils";
import { IBenhNhanV3ChuyenKhoa } from "../models/PhanHeChuyenKhoaModel";
import { OctAutocomplete } from "@oceantech/oceantech-ui";
import { searchDepartment } from "../../phan-he-quan-tri-he-thong/services/TabKhoaPhongServices";
import { ColumnsDsBenhNhanCDHA } from "../../phan-he-cdha-va-tdcn/constants/Columns";

type Props = {
  setInfoPhieu: (benhNhan: IBenhNhanV3ChuyenKhoa) => void;
  dataSearched: IBenhNhanV3ChuyenKhoa[];
  statusSummary: any;
};

const BangDsPhieuChuyenKhoa = (props: Props) => {
  const { setInfoPhieu, dataSearched, statusSummary = [] } = props;
  const [contextMenu, setContextMenu] = useState<null | {
    x: number;
    y: number;
  }>(null);
  const contextRef = useRef<HTMLDivElement | null>(null);
  const [codeCollapses, setCodeCollapses] = useState<string[]>([]);
  const [idSelected, setIdSelected] = useState<string>("");
  const [openDialogLichSuKhamBenh, setOpenDialogLichSuKhamBenh] =
    useState<boolean>(false);

  useEffect(() => {
    setCodeCollapses([...MenuTree.filter.map((item) => item.code)]);
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (idSelected.includes("goiBenhNhan")) {
      toast.warning("Gọi bệnh nhân thành công!");
      handleSelected("goiBenhNhan");
    }
    if (idSelected.includes("lskb")) {
      setOpenDialogLichSuKhamBenh(true);
      handleSelected("thayTheDichVuKhac");
    }
  }, [idSelected]);

  const handleSelected = (code: string) => {
    if (idSelected === code) {
      setIdSelected("");
    } else {
      setIdSelected(code);
    }
  };
  const handleClickOutside = (e: any) => {
    if (contextRef.current && !contextRef.current.contains(e.target as Node)) {
      setContextMenu(null);
    }
  };

  const MenuTree = {
    filter: [
      {
        code: "benhNhan",
        name: "Bệnh nhân",
        filter: [{ code: "goiBenhNhan", name: "Gọi bệnh nhân" }],
      },
      {
        code: "benhAn",
        name: "Bệnh án",
        filter: [{ code: "lskb", name: "Lịch sử khám bệnh" }],
      },
    ],
  };
  const handleSelectedBenhNhan = async (phieuChuyenKhoa: IBenhNhanV3ChuyenKhoa[]) => {
    setInfoPhieu(phieuChuyenKhoa[0]);
  };

  const handleContextMenu = (e: any, row: any) => {
    e.preventDefault();
    setContextMenu({ x: e.clientX, y: e.clientY });
  };

  return (
    <div className="flex flex-column flex-1 pt-3 spaces h-calc-vh-172">
      <TableCustom<IBenhNhanV3ChuyenKhoa>
        data={dataSearched}
        columns={ColumnsDsBenhNhanCDHA}
        selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
        getSelectedRowsData={handleSelectedBenhNhan}
        verticalScroll={true}
        handleContextMenu={handleContextMenu}
        className="flex-1 spaces h-calc-vh-211 pb-3"
      />
      <div className="d-flex flex-column spaces w-100 gap-5">
        <Row className="d-flex spaces w-100">
          <Col xs={12} className="spaces px-12">
            <OctAutocomplete
              options={[]}
              placeholder="Tất cả khoa phòng"
              searchFunction={searchDepartment}
              searchObject={{ pageSize: MAX_SIZE }}
              onChange={(option) => {}}
            />
          </Col>
        </Row>
        <Row className="d-flex spaces w-100">
          {statusSummary?.length > 0 &&
            statusSummary.map((item: any) => {
              return <Col xs={6}>{formatSummaryStatus(item)}</Col>;
            })}
        </Row>
      </div>
    </div>
  );
};

export default BangDsPhieuChuyenKhoa;
