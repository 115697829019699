import { ChangeEvent, KeyboardEvent, useState } from 'react';
import InputSearch from '../../../../../component/InputSearch';
import { KEY } from '../../../../../utils/Constant';

type Props = {
	onSearch: (value: string) => void;
};

const SearchField = ({ onSearch }: Props) => {
	const [value, setValue] = useState<string>('');

	const onChange = (e: ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value;
		setValue(inputValue);
	};

	const onBtnSearchClick = () => {
		onSearch(value);
	};

	const onKeydown = (e: KeyboardEvent<HTMLInputElement>) => {
		if (KEY.ENTER === e.key) {
			onSearch(value);
		}
	};

	return (
		<div className="px-10 py-5 spaces mb-4">
			<InputSearch
				value={value}
				placeholder="Tìm kiếm"
				handleChange={onChange}
				handleKeyDown={onKeydown}
				handleSearch={onBtnSearchClick}
				className="spaces h-25 radius-3"
			/>
		</div>
	);
};

export default SearchField;
