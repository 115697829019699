import React, { FC, useContext, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import CustomTabMenu from "../../../component/CustomTabMenu";
import SinhHieu from "../../../phan-he-kham-benh/components/tab-kham-benh/SinhHieu";
import { CODE, CODE_SELECT, SEARCH_OBJECT_MAX_SIZE, SIMPLE_CATEGORY_TYPE, VARIABLE_STRING } from "../../../utils/Constant";
import { IResponseData } from "../../../utils/models";
import { KEY_TAB_KHAM_BENH } from "../../constants/PhanHeTiepNhan";
import { getAllCategory } from "../../services/TiepNhanServices";
import { benhNhanProps } from "../../tab-tiep-nhan/TiepNhan";
import ChiDinhDVTiepDon from "./tab-chi-dinh-dich-vu/ChiDinhDVTiepDonForm";
import TabDSDangKy from "./tab-ds-dang-ky/TabDSDangKy";
import TabLichSu from "./tab-lich-su-kham/TabLichSu";
import TabGiaDinh from "./tabGiaDinh/TabGiaDinh";
import TabThongTinPhongKham from "./tabThongTinPhongKham/TabThongTinPhongKham";
import { iSinhHieu } from "../../../phan-he-kham-benh/models/ThongTinKhamBenhModel";
import { useFormikContext } from "formik";
import { PhanHeTiepNhanContext } from "../../PhanHeTiepNhan";

const TTKhamBenhV2: FC<benhNhanProps> = React.memo(
  ({
    setActiveTab,
    // values,
    // setFieldValue,
    handleChecked,
    errors,
    touched,
    setValues,
    handleChangeSelect,
    handleInputChangeSinhHieu,
    isDatLichHen,
    handleChangeHenKham,
  }) => {
    const {values, setFieldValue} = useFormikContext<any>();
    const { thongTinBenhNhan } = useContext(PhanHeTiepNhanContext)
    const [shouldOpenModalPK, setShouldOpenModalPK] = useState<boolean>(false);
    const [isBHYT, setIsBHYT] = useState<boolean>(false);
    const [activeKey, setActiveKey] = useState<string>(
      KEY_TAB_KHAM_BENH.DICH_VU
    );
    const [dataLoaiTiepNhan, setDataLoaiTiepNhan] = useState<any>([]);

    const getLoaiTiepNhan = async () => {
      try {
        const { data }: IResponseData = await getAllCategory({
          ...SEARCH_OBJECT_MAX_SIZE,
          type: SIMPLE_CATEGORY_TYPE.LOAI_TIEP_NHAN,
        });
        if (CODE.SUCCESS === data?.code) {
          setDataLoaiTiepNhan(data?.data?.content);
          const itemLoaiTiepNhan = data?.data?.content?.find(
            (item: any) => item?.code === CODE_SELECT.LTT_BENH_MOI
          );

          setFieldValue(
            VARIABLE_STRING.LOAI_TIEP_NHAN,
            itemLoaiTiepNhan || null
          );
          return;
        }
      } catch (error) {
        toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      }
    };

    const handleTabSelect = (key: string | null) => {
      if (key !== null) {
        setActiveKey(key);
        setActiveTab(key)

      }
    };

    const handleOpenModalPK = () => {
      setShouldOpenModalPK(true);
    };

    const handleCloseModal = () => {
      setShouldOpenModalPK(false);
    };

    const handleSaveThongTinTuyen = (value: any) => {
      setFieldValue?.("benhNhanBhyt.loaiTuyen", value);
    };
    
    const handleChangeSinhHieu = (name: string, value: any) => {
     try {
       setFieldValue("vitalSigns", {
         ...values?.vitalSigns,
         [name]: value,
       });
     } catch (e) {
      console.error(e);
     }
    };

    const listTab = [
      {
        title: 'TT phòng khám',
        component: <TabThongTinPhongKham/>
      },
      // {
      //   title: 'DS lịch hẹn',
      //   component: <TabDSDangKy/>
      // },
      {
        title: 'LS khám bệnh',
        component: <TabLichSu
          values={values}
          activeKey={activeKey}
        />
      },
      // {
      //   title: 'Danh sách chờ gọi',
      //   component: <DanhSachChoGoiV2 />
      // },
      {
        title: 'Sinh hiệu',
        component: <SinhHieu
          sinhHieu={values?.vitalSigns as iSinhHieu}
          setSinhHieu={handleChangeSinhHieu}
          className="spaces pt-10 px-10 h-calc-vh-510"
          disabled={thongTinBenhNhan?.isExamined}
        />
      },
      // {
      //   title: 'Zalo',
      //   component: <TabZalo/>
      // },
      // {
      //   title: 'Xác thực',
      //   component: <TabXacThuc />
      // },
      {
        title: 'Gia đình',
        component: <TabGiaDinh />
      },
      // {
      //   title: 'Chỉ định dịch vụ',
      //   component: <ChiDinhDVTiepDon />
      // },
      // {
      //   title: 'Phiếu thu',
      //   component: <TabPhieuThu />
      // },
      // {
      //   title: 'Danh sách gộp PCR',
      //   component: <TabDanhSachGopPCR />
      // },
    ];

    return (
      <div className="reception-list__container spaces py-0 mt-8 bg-white">
        <Row className="h-100">
          <Col xs={12} className="h-100 spaces px-0">
            <CustomTabMenu danhsachTabs={listTab} childrenTab={true}/>
          </Col>
        </Row>
      </div>
    );
  }
);
export default TTKhamBenhV2;
