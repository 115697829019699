import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState
} from 'react';

type ContextProps = {
	selectedRow: any;
	setSelectedRow: Dispatch<SetStateAction<any>>;
};

type ContextProviderProps = {
	children: ReactNode;
};

const TableSelectVatTuContext = createContext<ContextProps>({
	selectedRow: null,
	setSelectedRow: () => {},
});

export const TableSelectVatTuContextProvider = ({ children }: ContextProviderProps) => {
	const [selectedRow, setSelectedRow] = useState<any>(null);

	return (
		<TableSelectVatTuContext.Provider
			value={{
				selectedRow,
				setSelectedRow,
			}}
		>
			{children}
		</TableSelectVatTuContext.Provider>
	);
};

export const useTableSelectVatTuContext = () => useContext(TableSelectVatTuContext);
