import React, { useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import InputSearch from '../../../../component/InputSearch'
import { loaiPhieuChiDinhCuData } from '../../FakeData'
import { DsLoaiPhieuColumn, DsPhieuColumn } from './PhieuChiDinhCuColumn'
import { SELECTION_MODE } from '../../../../utils/Constant'
import { TableCustom } from '../../../../component/table/table-custom/TableCustom'

type Props = {
    handleClose: () => void
}

const ModalDsPhieuChiDinhCu = ({
    handleClose,
}: Props) => {
    const [dsPhieu, setDsPhieu] = useState<any[]>([]);

    const handleSuDungPhieu = () => {
        handleClose();
    }

    return (
        <>
            <Modal show={true} onHide={handleClose} centered className='dialog-background' size='xl' contentClassName='h-100'>
                <Modal.Header closeButton className='header-modal'>
                    <Modal.Title>
                        Danh sách phiếu chỉ định cũ
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='pe-2'>
                    <Row >
                        <div className='fw-bold pt-2 ps-4'>Phiếu chỉ định cũ</div>
                        <Col xs={12} className='mb-1'>
                            <InputSearch placeholder='Tìm kiếm' handleChange={() => { }} />
                        </Col>
                        <Col xs={7} className='pe-0'>
                            <TableCustom
                                columns={DsLoaiPhieuColumn}
                                data={loaiPhieuChiDinhCuData}
                                selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
                                getSelectedRowsData={(rowData: any[]) => {
                                    setDsPhieu(rowData?.[0]?.dichVu);
                                }}
                                minHeight={400}
                            />
                        </Col>
                        <Col xs={5} className='ps-0'>
                            <TableCustom
                                columns={DsPhieuColumn}
                                data={dsPhieu || []}
                                minHeight={400}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-end p-2 pe-5'>
                    <Button
                        className="btn-fill fs-13px"
                        onClick={handleSuDungPhieu}
                    >
                        Sử dụng phiếu + Chẩn đoán
                    </Button>
                    <Button
                        className="btn-fill fs-13px"
                        onClick={handleSuDungPhieu}
                    >
                        Sử dụng phiếu
                    </Button>
                    <Button
                        className="btn-secondary fs-13px"
                        onClick={handleClose}
                    >
                        Đóng
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalDsPhieuChiDinhCu