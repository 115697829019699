import { Form, Formik } from "formik";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import "../../PhanHeKhamBenh.scss";
import PhieuKhamBenh from "../../components/tab-kham-benh/PhieuKhamBenh";
import { createThongTinKhamBenh, updateThongTinKhamBenh } from "../../services/PhanHeTiepDonServer";
import { useContext } from "react";
import { PhanHeTiepDonContext } from "../../contexts/PhanHeTiepDonContext";
import { CODE } from "../../../utils/Constant";
import { toast } from "react-toastify";

export type KhamBenh = {
  thongTinKhamBenh?: any;
  setThongTinKhamBenh: ((data: any) => void) | undefined;
};

export const TabKhamBenh = () => {
  const { benhNhanInfo, setBenhNhanInfo } = useContext(PhanHeTiepDonContext);
  const intl = useIntl();
  const validationSchema = Yup.object({
    encounterDiagnosis: Yup.object().shape({
      reasonForEncounter: Yup.string().nullable().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
      medicalHistoryPast: Yup.string().nullable().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
    })
  });
  const initialValues = {};
  const handleSubmit = async (values: any) => {
    let encounterDiagnosisValues = values?.encounterDiagnosis;
    let obsValues = values?.obs;
    const { patient, visit, encounter, orderId } = benhNhanInfo?.thongTinKhamBenh;
    const submitData = {
      patientId: patient?.id,
      visitId: visit?.id,
      orderId: orderId,
      encounterDiagnosis: {
        reasonForEncounter: encounterDiagnosisValues?.reasonForEncounter,
        pathologicalProcess: encounterDiagnosisValues?.pathologicalProcess,
        medicalHistoryPast: encounterDiagnosisValues?.medicalHistoryPast,
        medicalHistoryFamily: encounterDiagnosisValues?.medicalHistoryFamily,
        physicalExamOfBody: encounterDiagnosisValues?.physicalExamOfBody,
        physicalExamGeneral: encounterDiagnosisValues?.physicalExamGeneral,
        provisionalDiagnosis: encounterDiagnosisValues?.provisionalDiagnosis,
        paraclinicalSummary: encounterDiagnosisValues?.paraclinicalSummary,
        comorbidity: encounterDiagnosisValues?.comorbidity,
        currentMedications: encounterDiagnosisValues?.currentMedications,
      },
      obs: {
        personId: patient?.personId,
        orderId: orderId,
        values: {
          pr: obsValues?.pr,
          bt: obsValues?.bt,
          spo2: obsValues?.spo2,
          rr: obsValues?.rr,
          bpSys: obsValues?.bpSys,
          bpDia: obsValues?.bpDia,
          weight: obsValues?.weight,
          height: obsValues?.height,
          bmi: obsValues?.bmi,
          creatinine: obsValues?.creatinine,
          crcl: obsValues?.crcl,
          egfr: obsValues?.egfr,
        }
      }
    };
    
    try {
      let { data } = encounter
        ? await updateThongTinKhamBenh({
          ...submitData,
          encounterDiagnosis: {
            ...encounterDiagnosisValues,
            ...submitData?.encounterDiagnosis,
          },
          obs: {
            ...submitData?.obs,
            encounterId: encounterDiagnosisValues?.encounterId,
          }
        })
        : await createThongTinKhamBenh(submitData);
      if (CODE.SUCCESS === data?.code) {
        let encounterDiagnosis = data?.data?.encounterDiagnosis;
        let encounter = data?.data?.encounter;
        let obs = data?.data?.obs;
        setBenhNhanInfo({
          ...benhNhanInfo,
          isExamined: true,
          thongTinKhamBenh: {
            ...benhNhanInfo?.thongTinKhamBenh,
            ...(encounter && { encounter }),
            ...(obs && { obs }),
            ...(encounterDiagnosis && { encounterDiagnosis }),
          },
          shouldUpdate: !benhNhanInfo?.shouldUpdate,
        });
        toast.success("Cập nhật thành công");
      } else {
        toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      }
    } catch (error) {
      console.error(error);
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");

    }
  };

  return (
    <Formik<any>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form className="pe-2 tab-kham-benh bg-white">
        <PhieuKhamBenh />
      </Form>
    </Formik>
  );
};

export default TabKhamBenh;
