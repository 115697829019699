import { useContext, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CODE, ORDER_TYPE_ID, RESPONSE_MESSAGE } from '../../../utils/Constant';
import '../../PhanHeKhamBenh.scss';
import { PhanHeTiepDonContext } from '../../contexts/PhanHeTiepDonContext';
import TableDonThuoc from './components/table-don-thuoc';
import ThaoTacDonThuoc from './components/thao-tac-don-thuoc';
import ThongTinPhieu from './components/thong-tin-phieu';

import { TabThuocContextProvider } from './context';
import moment from 'moment';
import { getDrugGroupListByVisitId } from '../../services/ThuocService';

export type KhamBenh = {
	thongTinKhamBenh?: any;
	setThongTinKhamBenh: ((data: any) => void) | undefined;
};

type Props = {
	danhSachPhieu?: boolean;
};

export const TabThuoc = ({ danhSachPhieu }: Props) => {
	const { benhNhanInfo } = useContext(PhanHeTiepDonContext);
	const patientVisitInfo = benhNhanInfo?.thongTinKhamBenh?.visit;

	const [danhSachDonThuoc, setDanhSachDonThuoc] = useState<object[]>([]);

	useEffect(() => {
		if (patientVisitInfo?.id) {
			getData();
		}
	}, [patientVisitInfo?.id, benhNhanInfo?.shouldUpdate]);

	const getData = async () => {
		try {
			const { code, data } = await getDrugGroupListByVisitId({
				visitId: patientVisitInfo?.id,
				type: ORDER_TYPE_ID.THUOC,
			});

			if (CODE.SUCCESS === code) {
				const result = data
					.sort(
						(a: any, b: any) =>
							new Date(b.designatedDate).getTime() -
							new Date(a.designatedDate).getTime()
					)
					.map((row: any) => {
						return {
							...row,
							date: moment(new Date(row?.designatedDate)).format('DD/MM/YYYY'),
						};
					});
				setDanhSachDonThuoc(result);
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	return (
		<Row className="h-100">
			<div>
				<TabThuocContextProvider
					values={{
						danhSachDonThuoc,
					}}
				>
					<ThongTinPhieu />
					<TableDonThuoc />
					<ThaoTacDonThuoc />
				</TabThuocContextProvider>
			</div>
		</Row>
	);
};

export default TabThuoc;
