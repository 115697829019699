import { ThanhToanHDContext } from "../ThanhToanHD";
import InputSearch from "../../../../component/InputSearch";
import { FC, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../appContext/AppContext";
import DanhSachPhieu from "../../../../component/DanhSachPhieu";
import { initialValuesFormThanhToanHD } from "../ThanhToanHDConstant";
import { createReceiptHD, getInfoPayment, getListReceiptHD } from "../../../services/ThanhToanHopDongServices";
import { formatDateDTO, formatDateToYYYYMMDD, formatMoney } from "../../../../utils/FormatUtils";
import { INV_TYPE_CODE } from "../../../../phan-he-vien-phi/const/VienPhiConstant";
import { OctTable } from "@oceantech/oceantech-ui";
import { Form } from "react-bootstrap";
import PhieuThanhToanHD from "./PhieuThanhToanHD";
import { Formik } from "formik";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import { CODE } from "../../../../utils/Constant";
import { toast } from "react-toastify";

const ThongTinThanhToanHD: FC<any> = (props) => {
  const { HDSelected, DSGoiKham, setDSGoiKham } = useContext(ThanhToanHDContext);
  const { setIsLoading } = useContext(AppContext);
  const intl = useIntl();
  const [openPhieuThu, setOpenPhieuThu] = useState(false);
  const [listDanhSachPhieu, setListDanhSachPhieu] = useState([]);
  const [initialValues, setInitialValues] = useState<any>(initialValuesFormThanhToanHD);
  const [thongTinThanhToan, setThongTinThanhToan] = useState<any>();
  const [onlyView, setOnlyView] = useState<boolean>(false);
  const [onlyViewInvTotal, setOnlyViewInvTotal] = useState<boolean>(false);

  const ThongTinThanhToanHDColumns = [
    {
      name: "Tên gói khám",
      field: "name",
      headerStyle: {
        width: '35%',
      },
      cellStyle: {
  
      },
      render: (row: any, index: number, stt: number) => (
        <div className="d-flex gap-4 spaces pl-4">
          {onlyViewInvTotal && 
            <Form className="d-flex align-items-center">
              <Form.Check
                className="d-flex align-items-center"
                onChange={e => handleCheck(e.target.checked, row, index)}
              />
            </Form>
          }
          <span className="text-fill">{row.name}</span>
        </div>
      )
    },
    {
      name: "Đơn vị",
      field: "unit",
      headerStyle: {
        width: '10%',
      },
      cellStyle: {
        textAlign: "center",
      },
    },
    {
      name: "Số lượng",
      field: "quantity",
      headerStyle: {
        width: '10%',
      },
      cellStyle: {
        textAlign: "end",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row.quantity),
    },
    {
      name: "Đơn giá VP",
      field: "fee",
      headerStyle: {
        width: '10%',
      },
      cellStyle: {
        textAlign: "end",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row.fee),
    },
    {
      name: "Đơn giá theo gói",
      field: "feePackage",
      headerStyle: {
        width: '15%',
      },
      cellStyle: {
        textAlign: "end",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row.feePackage),
    },
    {
      name: "Thành tiền",
      field: "totalFee",
      headerStyle: {
        width: '10%',
      },
      cellStyle: {
        textAlign: "end",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row.totalFee),
    },
    {
      name: "Đã thu",
      field: "totalFeePaid",
      headerStyle: {
        width: '10%',
      },
      cellStyle: {
        textAlign: "end",
        paddingRight: "20px",
      },
      render: (row: any, index: number, stt: number) => formatMoney(row.totalFeePaid),
    },
  ];

  const validationSchema = Yup.object({
    loaiPhieu: Yup.object().nullable().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
    hinhThuc: Yup.object().nullable().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
    // invDate: Yup.date().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
    // invCashier: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
    invTotal: Yup.number().typeError("Vui lòng nhập số").required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
  });

  const handleCheck = (checked: boolean, row: any, index: number) => {
    const newListGoiKham = [...DSGoiKham];
    newListGoiKham[index].checked = checked;
    setDSGoiKham(newListGoiKham);
  };

  const handleSelectPhieuThanhToan = (item: any) => {
    setOpenPhieuThu(true);
    setOnlyView(true);
    setInitialValues(item);
  };

  const updateDSGoiKham = async () => {
    try {
      setIsLoading(true);
      const { data } = await getInfoPayment(HDSelected?.id);
      const listGoiKham = data?.data?.paymentPackage?.map((item: any) => ({
        ...item,
        unit: "Gói",
      }))
      setDSGoiKham(listGoiKham || []);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateListReceiptHD = async () => {
    try {
      setIsLoading(true);
      const { data } = await getListReceiptHD(HDSelected.id);
      const dsPhieuThu = data?.data?.map((item: any) => {
        return {
          ...item,
          invDate: formatDateToYYYYMMDD(item.invDate),
          price: `(${item.invTypeCode !== INV_TYPE_CODE.PHIEU_HOAN.code ? '+' : '-'}) ${formatMoney(item?.invTotal)}`,
          hinhThuc: {
            id: item.invHinhThucId,
            name: item.invHinhThucName,
          },
          loaiPhieu: {
            id: item.invTypeId,
            code: item.invTypeCode,
            name: item.invTypeName,
          },
        }
      });
      setListDanhSachPhieu(dsPhieuThu || [])
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (HDSelected?.id) {
      updateDSGoiKham();
      updateListReceiptHD();
      setOnlyView(false);
      setInitialValues(initialValuesFormThanhToanHD);
      updateInfoPayment();
    }
  }, [HDSelected?.id]);

  useEffect(() => {
    setOnlyViewInvTotal(false);
    setInitialValues({...initialValuesFormThanhToanHD, invDate: new Date()});
  }, [HDSelected])

  const updateInfoPayment = async () => {
    try {
      const { data } = await getInfoPayment(HDSelected.id);
      if (CODE.SUCCESS === data?.code) {
        setThongTinThanhToan(data?.data);
      } else {
        setThongTinThanhToan(null);
      }
    } catch (error) {
      toast.error('Có lỗi xảy ra, vui lòng thử lại')
      console.error(error);
    }
  };

  const handleSubmitForm = async (values: any) => {
    if (parseFloat(values?.invTotal) <= 0) {
      toast.warning('Chưa chọn gói khám hoặc số tiền không hợp lệ !');
      return;
    };

    const dataSubmit = {
      ...values,
      contractId: HDSelected.id,
      invDate: formatDateDTO(values?.invDate),
      invTypeId: values?.loaiPhieu?.id,
      invTypeCode: values?.loaiPhieu?.code,
      invTypeName: values?.loaiPhieu?.name,
      invHinhThucId: values?.hinhThuc?.id,
      invHinhThucName: values?.hinhThuc?.name,
    };
    
    try {
      setIsLoading(true);
      const res = await createReceiptHD(dataSubmit);
      if (CODE.SUCCESS === res?.status) {
        setOpenPhieuThu(false);
        updateInfoPayment();
        updateListReceiptHD();
        updateDSGoiKham();
        setInitialValues({...initialValuesFormThanhToanHD, invDate: new Date()});
        toast.success('Tạo phiếu thu thành công');
      }
    } catch (error) {
      console.error(error); 
      toast.error('Có lỗi xảy ra, vui lòng thử lại')
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white">
      <div className="spaces px-8 pb-10">
        <DanhSachPhieu
          dsPhieu={HDSelected.id ? listDanhSachPhieu : []}
          handleSelectRowData={handleSelectPhieuThanhToan}
          title="price"
          className="min-vh-52px"
        />
      </div>
      <div className="spaces pl-10 pr-20 pb-10">
        <InputSearch
          placeholder="Tìm kiếm"
          // value=""
          handleChange={() => {}}
        />
      </div>
      <OctTable
        columns={ThongTinThanhToanHDColumns}
        data={DSGoiKham}
        noPagination={true}
        noToolbar={true}
        clearToolbar={true}
        className={`spaces ${
          openPhieuThu ? "h-calc-514" : "h-calc-416"
        }`}
      />
      <div className="spaces pl-10 pr-20 pt-10 bg-white">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmitForm(values)}
      >
        <PhieuThanhToanHD
          HDSelected={HDSelected}
          openPhieuThu={openPhieuThu}
          setOpenPhieuThu={setOpenPhieuThu}
          onlyView={onlyView}
          setOnlyView={setOnlyView}
          setInitialValues={setInitialValues}
          onlyViewInvTotal={onlyViewInvTotal}
          setOnlyViewInvTotal={setOnlyViewInvTotal}
          thongTinThanhToan={thongTinThanhToan}
        />
        </Formik>
      </div>
    </div>
  );
};

export default ThongTinThanhToanHD;
