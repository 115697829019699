type Props = {
  compArray: any;
  nameComp: string | undefined;
};

const DynamicModal = ({ compArray, nameComp = "" }: Props) => {
  return compArray[nameComp];
};

export default DynamicModal;
