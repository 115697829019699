import { Button, Modal } from 'react-bootstrap';
import { FC } from 'react';
import PageChiDinhDichVu from '../modals/modal-chi-dinh-dich-vu/PageChiDinhDichVu';
import { ConfirmDialog } from '../../component/ConfirmDialog';
interface DataType {
    code?: string;
    show?: boolean;
    setShow?: (show: boolean) => void;
    content?: string;
    onOK?: () => void;
}

const DynamicComponentKB: FC<{
    data: any;
    setData: (data: any) => void;
    onConfirm: () => void;
}> = (props) => {
    const { data, setData, onConfirm } = props;
    const Default = () => {
        return <div>Không có nội dung</div>;
    };

    const Confirm = () => {
        
        return (
            <ConfirmDialog
                show={data?.componentName === "Confirm"}
                title='Xác nhận'
                message='Bạn có chắc chắn muốn xóa phiếu không?'
                yes='Có'
                close='Không'
                onYesClick={() => {
                    props?.onConfirm();
                    setData(null);
                }}
                onCloseClick={() => setData(null)}
            />
        );
    };

    const ChiDinhDichVuDialog = () => {
        return (
            <PageChiDinhDichVu 
                show={data?.componentName === 'ChiDinhDichVu'}
                handleClose={() => setData(null)}
                isSuaPhieu={true}
            />
        )
    }

    const components: { [key: string]: FC<DataType> } = {
        Confirm: Confirm,
        ChiDinhDichVu: ChiDinhDichVuDialog
    };

    const DynamicContent = components[data?.componentName || ""] || Default;

    return <DynamicContent {...data} />;
};

export default DynamicComponentKB;
