import InfoPatientRight from '../../../../components/InfoPatientRight';
import { IBenhNhan } from '../../../../models/DSBenhNhanKhamBenhModels';
import Actions from './Actions';

type Props = {
	benhNhanInfo: IBenhNhan;
	onClose: () => void;
};

const VatTuHeader = ({ benhNhanInfo, onClose }: Props) => {
	return (
		<div className="d-flex justify-content-between gap-6 w-100">
			<Actions onClose={onClose} />
			<InfoPatientRight benhNhanInfo={benhNhanInfo} />
		</div>
	);
};

export default VatTuHeader;
