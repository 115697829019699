import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CODE, ORDER_TYPE_ID, PATH_NAME, RESPONSE_MESSAGE } from '../../../utils/Constant';
import { PhanHeTiepDonContext } from '../../contexts/PhanHeTiepDonContext';
import { getDrugGroupListByVisitId } from '../../services/ThuocService';
import TableVatTu from './components/table-vat-tu';
import ThaoTacPhieuVatTu from './components/thao-tac-phieu-vat-tu';
import ThongTinPhieu from './components/thong-tin-phieu-vat-tu';
import { TabVatTuContextProvider } from './context';

type Props = {
	danhSachPhieu?: boolean;
};

const TabVatTu = ({ danhSachPhieu = true }: Props) => {
	const { pathname } = useLocation();
	const { benhNhanInfo } = useContext(PhanHeTiepDonContext);
	const { visit } = benhNhanInfo?.thongTinKhamBenh || {};

	const [donVatTuList, setDonVatTuList] = useState([]);

	useEffect(() => {
		if (visit?.id) {
			getDanhSachDonVatTu();
		}
	}, [benhNhanInfo?.shouldUpdate, visit?.id]);

	const getDanhSachDonVatTu = async () => {
		try {
			const { data, code } = await getDrugGroupListByVisitId({
				visitId: visit?.id,
				type: ORDER_TYPE_ID.VAT_TU,
			});

			if (CODE.SUCCESS === code) {
				const result = data
					.sort(
						(a: any, b: any) =>
							new Date(b.designatedDate).getTime() -
							new Date(a.designatedDate).getTime()
					)
					.map((row: any) => {
						return {
							...row,
							date: moment(new Date(row?.designatedDate)).format('DD/MM/YYYY'),
						};
					});
				setDonVatTuList(result);
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		}
	};

	return (
		<Row className={`spaces h-calc-vh-${PATH_NAME.KHAM_BENH === pathname ? '85' : '123'}`}>
			<div>
				<TabVatTuContextProvider
					initialValues={{
						initialDonVatTuList: donVatTuList,
					}}
				>
					<ThongTinPhieu />
					<TableVatTu />
					<ThaoTacPhieuVatTu />
				</TabVatTuContextProvider>
			</div>
		</Row>
	);
};

export default TabVatTu;
