import clsx from 'clsx';
import moment from 'moment';
import React, { useState } from 'react';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

type IProps = {
	label?: string;
	name: string;
	required?: boolean;
	placeholderText?: string;
	dateFormat?: string;
	dateFormatOutput?: string;
	labelClassName?: string;
	inputClassName?: string;
	className?: string;
	setDateValue?: (date: string | null) => void;
	handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	errors?: string;
	touched?: boolean;
	value: string | null;
	minDate?: Date;
	maxDate?: Date;
};

const DatePickerCustom = ({
	placeholderText = '__/__/____',
	dateFormat = 'dd/MM/yyyy',
	dateFormatOutput = 'YYYY-MM-DD',
	label,
	required,
	labelClassName,
	inputClassName,
	className,
	name,
	value,
	setDateValue,
	touched,
	errors,
	handleBlur,
	...props
}: IProps) => {
	const date = moment(value, [dateFormatOutput, 'DD/MM/YYYY']);
	const hasError = touched && !!errors;

	const [invalid, setInvalid] = useState(false);

	const handleChange = (date: Date | null, event: React.SyntheticEvent<any> | undefined) => {
		if (event?.currentTarget?.value) return;
		setDateValue?.(moment(date).isValid() ? moment(date).format(dateFormatOutput) : null);
		setInvalid(false);
	};

	const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
		const inputValue = event.target.value;
		if (inputValue === '') {
			setDateValue?.(null);
			setInvalid(false);
			return;
		}

		const inputDate = moment(inputValue, 'DD/MM/YYYY');
		if (!inputDate.isValid()) {
			setDateValue?.(null);
			setInvalid(true);
			return;
		}

		setDateValue?.(inputDate.format(dateFormatOutput));
		setInvalid(false);
	};

	const renderTooltip = (props: any) =>
		(errors || invalid) && touched ? (
			<Tooltip id="button-tooltip" {...props}>
				<div className="text-danger">{errors || (invalid && 'Ngày không hợp lệ')}</div>
			</Tooltip>
		) : (
			<></>
		);

	return (
		<Form.Group className={clsx('d-flex align-items-center w-100', className)}>
			{label && (
				<Form.Label
					className={clsx(
						'spaces text-lable-input max-content-width mb-0',
						labelClassName
					)}
				>
					{required ? (
						<span>
							{label}
							<span className="text-danger"> *</span>
						</span>
					) : (
						label
					)}
				</Form.Label>
			)}
			<OverlayTrigger
				placement="top"
				delay={{ show: 200, hide: 200 }}
				overlay={renderTooltip}
			>
				<DatePicker
					className={clsx('form-control customs-input w-100', {
						'is-invalid': hasError || invalid,
						inputClassName,
					})}
					selected={date.isValid() ? date.toDate() : null}
					dateFormat={dateFormat}
					placeholderText={placeholderText}
					wrapperClassName="w-100"
					name={name}
					onChange={handleChange}
					onBlur={onBlur}
					{...props}
				/>
			</OverlayTrigger>
		</Form.Group>
	);
};

export default DatePickerCustom;
