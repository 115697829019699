import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import LabelRequired from '../../../component/LabelRequired';
import NumberField from '../../../component/number-field';

type Props = {
	open: boolean;
	onClose: () => void;
	onSave: (value: number) => void;
};

const ModalDonThuocCu = ({ open, onClose, onSave }: Props) => {
	const [value, setValue] = useState<number>(0);

	return (
		<Modal
			className="dialog-background"
			size="sm"
			show={open}
			animation
			centered
			backdropClassName="spaces top-50"
		>
			<Modal.Header className="py-3 header-modal">
				<Modal.Title className="text-pri">Số ngày dùng thuốc</Modal.Title>
				<Button className="btn-close bg-white" onClick={onClose}></Button>
			</Modal.Header>

			<Modal.Body className="pt-16 pb-10 px-24 spaces">
				<div className="d-flex align-items-center justify-content-between">
					<LabelRequired label="Số ngày dùng thuốc" className="spaces min-w-135" />
					<NumberField
						name="soNgayDungThuoc"
						value={value}
						onChange={(value: number) => setValue(value)}
						className="spaces w-80px"
					/>
				</div>
			</Modal.Body>

			<Modal.Footer className="d-flex justify-content-end py-1 px-1">
				<Button className="btn-fill" onClick={() => onSave(value)}>
					<i className="bi bi-check-lg"></i>
					Lưu
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default ModalDonThuocCu;
