import { Form, useFormikContext } from "formik";
import { FC, useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import TextField from "../../../../component/TextField";
import { formatDateToDDMMYYYY, formatMoney } from "../../../../utils/FormatUtils";
import LabelRequired from "../../../../component/LabelRequired";
import AutocompleteObjectV2 from "../../../../component/AutocompleteObjectV2";
import { DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE } from "../../../../utils/Constant";
import { getDanhSachHinhThucService } from "../../../services/KhamDoanHopDongServices";
import { AppContext } from "../../../../appContext/AppContext";
import { getDanhSachLoaiPhieuService } from "../../../../phan-he-vien-phi/services/PhanHeVienPhiServices";
import { CATEGORY_TYPE } from "../../../../phan-he-vien-phi/const/VienPhiConstant";
import { initialValuesFormThanhToanHD } from "../ThanhToanHDConstant";
import { ThanhToanHDContext } from "../ThanhToanHD";
import { TYPE_RECEIPT } from "../../../constants/PhanHeKhamDoan";

interface FormValues {
  orderSetDataIds: string[];
  loaiPhieu: any;
  hinhThuc: any;
  invDate: string;
  invCode: string;
  invCashier: string;
  invTotal: number;
}

const PhieuThanhToanHD: FC<any> = (props) => {
  const {
    openPhieuThu,
    setOpenPhieuThu,
    HDSelected,
    onlyView,
    setOnlyView,
    setInitialValues,
    onlyViewInvTotal,
    setOnlyViewInvTotal,
    thongTinThanhToan,
  } = props;
  const { DSGoiKham } = useContext(ThanhToanHDContext);
  const { setIsLoading } = useContext(AppContext);
  const  { values, setFieldValue, touched, errors, handleSubmit } = useFormikContext<FormValues>();
  const [dsHinhThuc, setDSHinhThuc] = useState<any[]>([]);
  const [dsLoaiPhieu, setDSLoaiPhieu] = useState<any[]>([]);

  const updateTotalPayment = () => {
    let orderIds: any = [];
    let invTotal: any = 0;
    let goiKhamSelected: any = [];

    DSGoiKham.forEach((goiKham: any) => {
      const newOrderIds = goiKham.idOrderSetData || [];
      if (goiKham.checked) {
        orderIds = orderIds.concat(newOrderIds);
        invTotal = invTotal + parseFloat(goiKham.totalFee || '0') - parseFloat(goiKham.totalFeePaid || '0')
        goiKhamSelected.push(goiKham.name);
      }
    });
    
    if (DSGoiKham.some((goiKham: any) => goiKham.checked)) {
      setFieldValue('trangThai', `Tên gói khám: ${goiKhamSelected.join(", ")} - Tổng thành tiền: ${formatMoney(invTotal)} đ`);
    } else {
      setFieldValue('trangThai', '');
    };

    setFieldValue('orderSetDataIds', orderIds);
    setFieldValue('invTotal', invTotal);
  };

  useEffect(() => {
    updateTotalPayment();
  }, [DSGoiKham])

  const updatePageData = async () => {
    try {
      setIsLoading(true);
      const searchObject = {
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: MAX_PAGE_SIZE,
      };
      const resHinhThuc = await getDanhSachHinhThucService({...searchObject, type: CATEGORY_TYPE.HINH_THUC.code});
      const resLoaiPhieu = await getDanhSachLoaiPhieuService({...searchObject, type: CATEGORY_TYPE.LOAI_PHIEU.code});
      setDSHinhThuc(resHinhThuc?.data?.data?.content || []);
      setDSLoaiPhieu(resLoaiPhieu?.data?.data?.content || []);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updatePageData();
  },[]);

  useEffect(()=>{
    setOpenPhieuThu(false);
  },[HDSelected])

  return (
    <Form id="form-phieu-thu-hd" onSubmit={handleSubmit}>
      <Row>
        <Col xs="12" className="d-flex gap-10">
          <div className="d-flex justify-content-between min-w-200px">
            <span className="fw-bold text-error">Tổng chi phí: </span>
            <span className="fw-bold text-error">{formatMoney(thongTinThanhToan?.ttTong) || 0}</span>
          </div>
          <div className="d-flex justify-content-between min-w-200px">
            <span className="fw-bold">Tạm ứng: </span>
            <span className="text-fill">{formatMoney(thongTinThanhToan?.ttTamUng) || 0}</span>
          </div>
          <div className="d-flex justify-content-between min-w-200px">
            <span className="fw-bold">Đã thu: </span>
            <span className="text-fill">{formatMoney(thongTinThanhToan?.ttDaThu) || 0}</span>
          </div>
          <div className="d-flex justify-content-between min-w-200px">
            <span className="fw-bold">Còn nợ: </span>
            <span className="text-fill">{formatMoney(thongTinThanhToan?.ttConNo) || 0}</span>
          </div>
          <div className="flex-grow-1"></div>
        </Col>
        <Col xs="12" className="line-bottom spaces pt-10 mb-10 w-2000px"></Col>
        {openPhieuThu && (
          <>
            <Col xs="4" className="d-flex">
              {/* <TextField
                label="Số phiếu"
                labelClassName="min-w-105px"
                className="input"
                name="invCode"
                disabled={onlyView}
              /> */}
              <LabelRequired label="Số phiếu" className="min-w-105px"/>
              <span className="text-gray spaces mt-1">{values.invCode}</span>
            </Col>
            <Col xs="4" className="d-flex">
              {/* <TextField
                label={
                  <div>
                    Người thu
                    <span className="text-danger"> (*)</span>
                  </div>
                }
                labelClassName="min-w-100px"
                className="input"
                name="invCashier"
                disabled={onlyView}
              /> */}
              <LabelRequired label="Người thu" className="min-w-100px"/>
              <span className="text-gray spaces mt-1">{values.invCashier}</span>
            </Col>
            <Col xs="4" className="d-flex">
              {/* <TextField
                label={
                  <div>
                    Ngày thu
                    <span className="text-danger"> (*)</span>
                  </div>
                }
                type="date"
                labelClassName="min-w-90px"
                className="input"
                name="invDate"
                disabled={onlyView}
              /> */}
              <LabelRequired label="Ngày thu" className="min-w-100px"/>
              <span className="text-gray spaces mt-4">{formatDateToDDMMYYYY(values.invDate)}</span>
            </Col>
            <Col xs="4" className="spaces py-10 d-flex">
              <LabelRequired
                isRequired
                className="min-w-100px"
                label="Loại phiếu"
              />
              <AutocompleteObjectV2
                options={dsLoaiPhieu || []}
                name="loaiPhieu"
                value={values.loaiPhieu || ""}
                onChange={(selectedOption: any) => {
                  if (selectedOption.code === TYPE_RECEIPT.PHIEU_THU) {
                    setFieldValue("invTotal", 0);
                  } else {
                    setFieldValue("invTotal", "");
                  }
                  setOnlyViewInvTotal(selectedOption.code === TYPE_RECEIPT.PHIEU_THU);
                  setFieldValue("loaiPhieu", selectedOption);
                }}
                isClearable={false}
                className="spaces autocomplete-custom-tiep-nhan h-29 ml-6"
                touched={touched.loaiPhieu}
                errors={errors.loaiPhieu}
                isDisabled={onlyView}
              />
            </Col>
            <Col xs="4" className="spaces py-10">
              <TextField
                label={
                  <div>
                    Số tiền
                    <span className="text-danger"> (*)</span>
                  </div>
                }
                labelClassName="min-w-100px"
                className="input"
                name="invTotal"
                disabled={onlyView || onlyViewInvTotal}
              />
            </Col>
            <Col xs="4" className="spaces py-10">
              <span className="text-error text-decoration-underline cursor-pointer">
                {values.invTotal ? formatMoney(values.invTotal) : 0} VNĐ
              </span>
            </Col>
            <Col xs="4" className="d-flex">
              <LabelRequired
                isRequired
                className="min-w-100px"
                label="Hình thức"
              />
              <AutocompleteObjectV2
                options={dsHinhThuc || []}
                name="hinhThuc"
                value={values.hinhThuc || ""}
                onChange={(selectedOption: any) => {
                  setFieldValue("hinhThuc", selectedOption);
                }}
                isClearable={false}
                className="spaces autocomplete-custom-tiep-nhan h-29 ml-6"
                touched={touched.hinhThuc}
                errors={errors.hinhThuc}
                isDisabled={true}
              />
            </Col>
            <Col xs="8">
              <TextField
                label="Ghi chú"
                labelClassName="min-w-100px"
                className="input"
                name="trangThai"
                disabled={onlyView}
              />
            </Col>
          </>
        )}
        <Col xs="12" className="spaces h-50 py-10 d-flex justify-content-end gap-5">
          {(!openPhieuThu && HDSelected.id) && (
            <Button className="btn-fill" onClick={() => {
              setFieldValue('hinhThuc', {
                id: HDSelected.paymentMethodId,
                name: HDSelected.paymentMethodName,
              })
              setOpenPhieuThu(true);
            }}>
              Tạo phiếu
            </Button>
          )}
          {openPhieuThu && (
            <>
              {!onlyView && 
                <Button className="btn-fill" onClick={() => handleSubmit()}>
                  Lưu
                </Button>
              }
              <Button
                className="btn-outline"
                onClick={() => {
                  setOpenPhieuThu(false);
                  setOnlyViewInvTotal(false);
                  setOnlyView(false);
                  setInitialValues({...initialValuesFormThanhToanHD, invDate: new Date()})
                }}
              >
                { onlyView ? "Đóng" : "Hủy" }
              </Button>
            </>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default PhieuThanhToanHD;