//@ts-nocheck
import "../../../../../app/modules/styles/index.scss"
import { MenuItem } from './MenuItem';
import { useRef, useState } from 'react';
import { listModule } from "../../../../../app/pages/Homepage/constant";
import { checkMenuByPermissions } from "../../../../../app/modules/utils/Permission";

export function MenuInner() {
  const containerRef = useRef(null);
  const [dragStart, setDragStart] = useState(0);
  const [scrollStart, setScrollStart] = useState(0);
  const menusByPermission = checkMenuByPermissions(listModule);

  const handleMouseDown = (e:any) => {
    setDragStart(e.clientX);
    setScrollStart(containerRef?.current.scrollLeft);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e:any) => {
    const dragDelta = e.clientX - dragStart;
    containerRef.current.scrollLeft = scrollStart - dragDelta;
  };
  const scrollToLeft = () => {
    containerRef.current.scrollLeft -= 200;
  };

  const scrollToRight = () => {
    containerRef.current.scrollLeft += 200;
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

    return (
        <div className="d-flex align-items-center">
            <div className="d-flex align-items-center">
                <MenuItem
                  to='/home'
                  icon='./media/icons/menu.svg'
                />
                <i className="bi bi-chevron-bar-left fs-2 cursor-pointer" onClick={scrollToLeft}></i>
                <div className='header-menu-container' ref={containerRef} onMouseDown={handleMouseDown}>
                  {menusByPermission?.map((module: any, index: number) => {
                     return (
                      <MenuItem 
                        key={index} 
                        title={module?.name} 
                        to={module.to}
                      />
                    )
                  })}
                </div>
            </div>
            <i className="bi bi-chevron-bar-right fs-2 cursor-pointer" onClick={scrollToRight}></i>
        </div>
    );
}