import React, { useContext } from "react";
import {Form, Button } from "react-bootstrap";
import "./QuanLyHopDong.scss";
import { Formik } from "formik";
import { AppContext } from "../../../../appContext/AppContext";
import { CODE } from "../../../../utils/Constant";
import FormKetLuan from "./FormKetLuan"
import { saveFormGenerate } from "../../../services/KhamDoanHopDongServices"
import { QuanLyHopDongContext, QuanLyHopDongContextProps } from "../../tab-quan-ly-hop-dong/QuanLyHopDong"
import { valueStatus } from "../../../constants/PhanHeKhamDoan"
import { formatDateToDDMMYYYY } from "../../../../utils/FormatUtils";
import { toast } from "react-toastify";
import { updateWfWorkflows } from "../../../../workflows/services/WfWorkflowsService";
import { PhanHeKhamDoanContext, PhanHeKhamDoanContextProps } from "../../../PhanHeKhamDoan";

type Props = {};

const initialValues: any = {
  examStartDate: "",
  examEndDate: "",
  examStatus: "",
  examConclusion: "",
  neededSolve: "",
  examClassification: "",
};
const TabKetLuan = ({ }: Props) => {
  const { setIsLoading } = useContext(AppContext);
  const { TTNhanVien } = useContext<QuanLyHopDongContextProps>(QuanLyHopDongContext);
  const { setWorkFlowStatus } = useContext<PhanHeKhamDoanContextProps>(PhanHeKhamDoanContext);

  const handlerSave = async (values: any) => {
    let dataValue = {
      ...values,
      examEndDate: handlerConvert(values) || ""
    }
    try {
      setIsLoading(true);
      let { data } = await saveFormGenerate(dataValue, "ketLuan", TTNhanVien?.visitId);
      if (CODE.SUCCESS === data?.code) {
        let resWf;
        if (values?.examStatus === valueStatus.DA_KHAM_XONG) {
          resWf = await updateWfWorkflows('phy_exam', { objectId: TTNhanVien?.id, status: 15 })
        } else if (values?.examStatus === valueStatus.DUYET_BENH_AN) {
          resWf = await updateWfWorkflows('phy_exam', { objectId: TTNhanVien?.id, status: 16 })
        }
        setWorkFlowStatus(resWf?.data?.data);
      } else {
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
        toast.error(errorMesage);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }

  }
  const handlerConvert = (values: any) => {
    let day = "";
    if (values?.examEndDate) {
      day = values?.examEndDate
    } else {
      if ([valueStatus.DA_KHAM_XONG, valueStatus.DUYET_BENH_AN].includes(values?.examStatus)) {
        day = formatDateToDDMMYYYY(new Date()) || ""
      } else day = ""
    }
    return day
  }

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handlerSave}>
        {({
          values,
          setValues,
          handleSubmit,
          setFieldValue,
          errors,
          touched,
          resetForm,
        }) => (
          <Form id="form-Ket-Luan" onSubmit={handleSubmit}>
            <FormKetLuan values={values} setValues={setValues} setFieldValue={setFieldValue} />
            {TTNhanVien?.visitId &&
              <div className="flex bg-white spaces justify-content-end gap-6 py-10 pr-15  btn-luu">
                <Button className="btn-fill spaces min-w-86" onClick={() => handlerSave(values)}>
                  <span>Lưu lại</span>
                </Button>
              </div>
            }
          </Form>
        )}
      </Formik>
    </>
  );
};

export default TabKetLuan;
