export const mangBenhNhan = [
  {
    maBn: "BN001",
    tenBenhNhan: "Người Bệnh 1",
    soBHYT: "BHYT001",
    gioiTinh: "Nam",
    tuoi: "25",
    khoaDieuTri: "Khoa 1",
    phongDieuTri: "Phòng 101",
    giuong: "Giuong 1",
    bacSiDieuTri: "Bác sĩ A",
    batDauBHYT: "01/01/2022",
    ketThucBHYT: "01/01/2024",
  },
  {
    maBn: "BN002",
    tenBenhNhan: "Người Bệnh 2",
    soBHYT: "BHYT002",
    gioiTinh: "Nữ",
    tuoi: "35",
    khoaDieuTri: "Khoa 2",
    phongDieuTri: "Phòng 102",
    giuong: "Giuong 2",
    bacSiDieuTri: "Bác sĩ B",
    batDauBHYT: "01/02/2021",
    ketThucBHYT: "01/02/2023",
  },
  {
    maBn: "BN003",
    tenBenhNhan: "Người Bệnh 3",
    soBHYT: "BHYT003",
    gioiTinh: "Nam",
    tuoi: "45",
    khoaDieuTri: "Khoa 3",
    phongDieuTri: "Phòng 103",
    giuong: "Giuong 3",
    bacSiDieuTri: "Bác sĩ C",
    batDauBHYT: "01/03/2020",
    ketThucBHYT: "01/03/2022",
  },
];

export const mangPhieu = [
  {
    trangThaiPhieu: "Chưa duyệt",
    maPhieu: "P001",
    maPhieuTH: "PTH001",
    loaiPhieu: "Xét nghiệm",
    maBN: "BN001",
    tenBenhNhan: "Người Bệnh 1",
    gioiTinh: "Nam",
    tuoi: "25",
    ngayChiDinh: "01/02/2024",
    ngayYLenh: "02/02/2024",
    khoaPhong: "Khoa 1",
    khoThuoc: "Kho 1",
  },
  {
    trangThaiPhieu: "Đã duyệt",
    maPhieu: "P002",
    maPhieuTH: "PTH002",
    loaiPhieu: "Siêu âm",
    maBN: "BN002",
    tenBenhNhan: "Người Bệnh 2",
    gioiTinh: "Nữ",
    tuoi: "35",
    ngayChiDinh: "02/02/2024",
    ngayYLenh: "03/02/2024",
    khoaPhong: "Khoa 2",
    khoThuoc: "Kho 2",
  },
  {
    trangThaiPhieu: "Chưa duyệt",
    maPhieu: "P003",
    maPhieuTH: "PTH003",
    loaiPhieu: "X-quang",
    maBN: "BN003",
    tenBenhNhan: "Người Bệnh 3",
    gioiTinh: "Nam",
    tuoi: "45",
    ngayChiDinh: "03/02/2024",
    ngayYLenh: "04/02/2024",
    khoaPhong: "Khoa 3",
    khoThuoc: "Kho 3",
  },
];

export const mangPhieuThuoc = [
  {
    trangThai: "Mới tạo",
    ma: "M001",
    ten: "Paracetamol",
    donVi: "Viên",
    tenKho: "Kho A",
    slYeuCau: "100",
    slPhat: "80",
    donGia: "5000",
    thanhTien: "400000",
    sdk: "SDK001",
    soLo: "L001",
    hanSuDung: "01/02/2023",
  },
  {
    trangThai: "Mới tạo",
    ma: "M002",
    ten: "Ibuprofen",
    donVi: "Viên",
    tenKho: "Kho B",
    slYeuCau: "150",
    slPhat: "120",
    donGia: "7000",
    thanhTien: "840000",
    sdk: "SDK002",
    soLo: "L002",
    hanSuDung: "01/03/2023",
  },
  {
    trangThai: "Mới tạo",
    ma: "M003",
    ten: "Aspirin",
    donVi: "Viên",
    tenKho: "Kho C",
    slYeuCau: "120",
    slPhat: "100",
    donGia: "6000",
    thanhTien: "600000",
    sdk: "SDK003",
    soLo: "L003",
    hanSuDung: "01/04/2023",
  },
];

export const mangPhieuVatTu = [
  {
    maVatTu: "M001",
    tenVatTu: "Paracetamol",
    donVi: "Viên",
    sl: "80",
    donGia: "5000",
    thanhTien: "400000",
    sdk: "SDK001",
    soLo: "L001",
    hanSuDung: "01/02/2023",
  },
  {
    maVatTu: "M002",
    tenVatTu: "Ibuprofen",
    donVi: "Viên",
    sl: "120",
    donGia: "7000",
    thanhTien: "840000",
    sdk: "SDK002",
    soLo: "L002",
    hanSuDung: "01/03/2023",
  },
  {
    maVatTu: "M003",
    tenVatTu: "Aspirin",
    donVi: "Viên",
    sl: "100",
    donGia: "6000",
    thanhTien: "600000",
    sdk: "SDK003",
    soLo: "L003",
    hanSuDung: "01/04/2023",
  },
];

export const dsBenhNhan = [
  {
    tt: 1,
    tenBenhNhan: "Trần Quang Bá",
    tuoi: "18",
    giuong: "buồng 1, giường 1",
    bhyt: 2,
    tamUng: 1,
  },
  {
    tt: 2,
    tenBenhNhan: "Trần Quang Đạo",
    tuoi: "18",
    giuong: "buồng 2, giường 1",
    bhyt: 0,
    tamUng: 2,
  },
  {
    tt: 2,
    tenBenhNhan: "Trần Quang Dịu",
    tuoi: "18",
    giuong: "buồng 3, giường 1",
    bhyt: 0,
    tamUng: 1,
  },
]