import { useContext } from 'react';
import { PhanHeTiepDonContext } from '../../../../contexts/PhanHeTiepDonContext';
import { useTabThuocContext } from '../../context';
import ThongTinPhieu from './ThongTinPhieu';

const ThongTinPhieuWrapper = () => {
	const { donThuocInfo } = useTabThuocContext();
	const { benhNhanInfo } = useContext(PhanHeTiepDonContext);

	return (
		<ThongTinPhieu
			thongTinPhieu={donThuocInfo}
			thongTinBenhNhan={benhNhanInfo?.thongTinKhamBenh}
		/>
	);
};

export default ThongTinPhieuWrapper;
