import React, { FC, useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../../../appContext/AppContext";
import { TableCollapseCustom } from "../../../../component/table/table-collapse/TableCollapseCustom";
import { CODE, DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE, RESPONSE_MESSAGE } from "../../../../utils/Constant";
import { formatDateToDDMMYYYY } from "../../../../utils/FormatUtils";
import { columnThongTinGoiKham } from "../../../constants/PhanHeKhamDoan";
import { getByIdHopDong, getListGoiKhamDichVu } from "../../../services/KhamDoanHopDongServices";
import { QuanLyHopDongContext, QuanLyHopDongContextProps } from "../../tab-quan-ly-hop-dong/QuanLyHopDong";
import "./QuanLyHopDong.scss";


type ThongTinHopDongProps = {
};
const ThongTinHopDong: FC<ThongTinHopDongProps> = React.memo(
  ({
  }) => {
    const { setIsLoading } = useContext(AppContext);
    const { setDataEditGK, setIsClickOpenEditGK, isSearchGK, TTHopDong, isClickOpenEditGK } = useContext<QuanLyHopDongContextProps>(QuanLyHopDongContext);
    const [summaryHD, setSummaryHD] = useState<any[]>([]);
    const [dsGoiKham, setDsGoiKham] = useState<any>({
      data: [],
      totalElements: 1,
      totalPages: 1,
    });

    const updatePageData = async () => {
      try {
        setIsLoading(true);
        const { data } = await getByIdHopDong(TTHopDong.id);
        setSummaryHD(data?.data?.summary || []);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }

    console.log("isSearchGK", isSearchGK);
    

    useEffect(() => {
      TTHopDong.id && updatePageData();
      isSearchGK && getDanhSachGoiKham();
    }, [TTHopDong?.id, isSearchGK, isClickOpenEditGK]);

    const getDanhSachGoiKham = async (filter?: any) => {
      if (TTHopDong?.id) {
        setIsLoading(true);
        let searchObject = {
          pageIndex: DEFAULT_PAGE_INDEX,
          pageSize: MAX_PAGE_SIZE,
          keyword: filter?.keyword,
          contractId: TTHopDong?.id,
          isIncludeTerms: true,
        };
        try {
          const { data } = await getListGoiKhamDichVu(searchObject);
          if (CODE.SUCCESS === data?.code) {
            setDsGoiKham({
              data: data?.data?.content || [],
              totalElements: data?.data?.totalElements,
              totalPages: data?.data?.totalPages,
            });

          } else {
            toast.warning(RESPONSE_MESSAGE.ERROR);
          }
        } catch {
          toast.warning(RESPONSE_MESSAGE.ERROR);
        } finally {
          setIsLoading(false);
        }
      } else {
        setDsGoiKham({
          data: [],
          totalElements: 1,
          totalPages: 1,
        });
      }
    };
    const handlerEditGoiKham = (values: any) => {
      setDataEditGK(values);
      setIsClickOpenEditGK(true);
    }

    return (
      <div className="bg-white position-relative spaces gap-6 spaces w-100 mb-6 fl-1">
        <div className="spaces w-100 justify-content-center mt-5">
          <Row className="border-bottom mx-0">
            <Col xs="4">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">Tên hợp đồng:</span>
                <span className="fw-light text-truncate">
                  {TTHopDong?.contractName || ""}
                </span>
              </div>
            </Col>
            <Col xs="4">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">Tên công ty:</span>
                <span className="fw-light text-truncate">
                  {TTHopDong?.companyName || ""}
                </span>
              </div>
            </Col>
            <Col xs="4">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">Mã số thuế:</span>
                <span className="fw-light text-truncate">
                  {TTHopDong?.taxNumber || ""}
                </span>
              </div>
            </Col>
            <Col xs="8">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">Địa chỉ:</span>
                <span className="fw-light text-truncate">
                  {TTHopDong?.address || ""}
                </span>
              </div>
            </Col>
            <Col xs="4">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">Người liên lạc:</span>
                <span className="fw-light text-truncate">
                  {TTHopDong?.contactPerson || ""}
                </span>
              </div>
            </Col>
            <Col xs="4">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">Ngày bắt đầu:</span>
                <span className="fw-light text-truncate">
                  {formatDateToDDMMYYYY(TTHopDong?.startDate)}
                </span>
              </div>
            </Col>
            <Col xs="4">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">Ngày kết thúc:</span>
                <span className="fw-light text-truncate">
                  {formatDateToDDMMYYYY(TTHopDong?.endDate)}
                </span>
              </div>
            </Col>
            <Col xs="4">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">
                  Ghi chú:
                </span>
                <span className="fw-light text-truncate">
                  {TTHopDong?.note || ""}
                </span>
              </div>
            </Col>
          </Row>
          <Row className="spaces  p-10">
            {summaryHD.map((item: any, index: number) => (
              <Col key={`sumary-hd-${index}`} xs="4">
                <div className="spaces fw-5 mb-8 d-flex">
                    <span className="d-inline-block text-danger spaces min-w-130">{item?.statusName}</span>
                    <span className="fw-light text-truncate text-danger">{item?.summary}</span>
                </div>
              </Col>
            ))}
          </Row>
          <div className="bg-white spaces  pb-10 spaces fl-1">

            <TableCollapseCustom
              columns={columnThongTinGoiKham}
              data={dsGoiKham?.data || []}
              childrenField="terms"
              parentField="false"
              handleDoubleClick={handlerEditGoiKham}
              className={`overflow-auto spaces h-calc-vh-100`}
              startColumn={0}
            />
          </div>
        </div>
      </div >
    );
  }
);
export default ThongTinHopDong;
