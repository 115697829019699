import { useFormikContext } from 'formik';
import GenerateFormHTMLNoValid from '../../../../component/generate-form/GenerateFormHTMLNoValid';

type Props = {
  title: string;
  formName: string; 
  dataGenerate?: any;
}

const ThongTinGiaDinh = (props: Props) => {
  const { values, setFieldValue } = useFormikContext<any>();
  const { dataGenerate, formName } = props;

  const handleChange = (name: string, value: any, type?: string) => {
    if (type === "select") {
      let stringName = name + "Name";
      let stringCode = name;
      setFieldValue("familyRecords", {
        ...values?.familyRecords,
        [formName]: {
          ...values?.familyRecords?.[formName],
          [stringName]: value?.name,
          [stringCode]: value?.code,
        }
      });
    } else {
      setFieldValue("familyRecords", {
        ...values?.familyRecords,
        [formName]: {
          ...values?.familyRecords?.[formName],
          [name]: value
        }
      });
    }
  }

  return (
    <div className='tt-gia-dinh h-100'>
      <div className="title">
        {props?.title}
      </div>
      <div className='content'>
        <div className="spaces W-240">
          <GenerateFormHTMLNoValid
            isView={false}
            listFieldAuto={dataGenerate}
            isSave={false}
            isUpdate={true}
            onChange={handleChange}
            values={values?.familyRecords}
            modelID={formName}
          />
        </div>
      </div>
    </div>
  )
}

export default ThongTinGiaDinh