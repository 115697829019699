import Fuse from 'fuse.js';
import { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { IconButtonSave } from '../../../../component/IconSvg';
import { RESPONSE_MESSAGE } from '../../../../utils/Constant';
import { IKetQuaCanLamSangResponse, Term } from '../../../models/KetQuaCanLamSangModel';
import { KetQuaDichVu } from '../../../models/ThongTinKhamBenhModel';
import { getDsKetQuaDichVu } from '../../../services/PhanHeTiepDonServer';
import { KetQuaDichVuColumns } from './components/Columns';
import SearchField from './components/SearchField';
import TableGroupSelection from './components/TableGroupSelection';

interface Props {
	open: boolean;
	handleClose: () => void;
	onSave: (data: KetQuaDichVu[]) => void;
	visitId: string;
}

interface KetQuaDichVuNode extends KetQuaDichVu {
	parentId?: number;
	subRows?: KetQuaDichVuNode[] | null;
}

const ModalChonKetQuaDichVu = ({ open, handleClose, onSave, visitId }: Props) => {
	const [selectedRows, setSelectRows] = useState<KetQuaDichVuNode[]>([]);
	const [treeData, setTreeData] = useState<any>([]);
	const initialTreeDataRef = useRef<any>([]);

	useEffect(() => {
		updatePageData();
	}, []);

	const updatePageData = async () => {
		try {
			const { data } = await getDsKetQuaDichVu({
				visitId,
			});
			const dataConverted = convertDataToTree(data);
			setTreeData(dataConverted);
			initialTreeDataRef.current = dataConverted;
		} catch (error) {
			toast.error(RESPONSE_MESSAGE.ERROR);
			console.error(error);
		}
	};

	const convertDataToTree = (data: IKetQuaCanLamSangResponse[]) => {
		const result: KetQuaDichVuNode[] = data.map(item => {
			const addtionData = {
				khoaChiDinh: item.fulfillDeptName,
				ngayYLenh: item.indicationTime,
				ngayTraKetQua: item.resultReportingTime,
			};

			return termConvert([item.term], addtionData)[0];
		});
		return result;
	};

	const termConvert = (terms: Term[], addtionData: any) => {
		const result: KetQuaDichVuNode[] = terms.map(term => ({
			id: term.id.toString(),
			parentId: term.parentId,
			tenDichVu: term.name,
			ketQua: term.obs?.value || '',
			khoaChiDinh: addtionData.khoaChiDinh,
			ngayYLenh: addtionData.ngayYLenh,
			ngayTraKetQua: addtionData.ngayTraKetQua,
			subRows: term.subs?.length ? termConvert(term.subs, addtionData) : null,
		}));
		return result;
	};

	const handleSubmit = () => {
		if (!selectedRows.length) {
			toast.warning('Chưa chọn kết quả cận lâm sàng');
			return;
		}
		onSave(selectedRows);
		handleClose();
	};

	
	const onSearch = (keySearch: string) => {
		if (!keySearch.trim()) {
			setTreeData(initialTreeDataRef.current);
			return;
		}

		const fuse = new Fuse(treeData, {
			includeMatches: true,
			keys: ['tenDichVu', 'subRows.tenDichVu'],
		});

		const result = fuse.search(keySearch).map(record => record.item);
		setTreeData(result);
	};

	return (
		<Modal size="xl" show={open} animation centered className="dialog-background">
			<div className="timKiemBenhNhan">
				<Modal.Header className="py-3 header-modal">
					<Modal.Title className="text-pri">Kết quả dịch vụ</Modal.Title>
					<Button className="btn-close bg-white" onClick={handleClose}></Button>
				</Modal.Header>

				<Modal.Body className="py-16 px-24 spaces">
					<SearchField onSearch={onSearch} />
					<TableGroupSelection<KetQuaDichVu>
						data={treeData}
						containerClassName="h-400px max-h-500px"
						onSelect={rows => setSelectRows(rows)}
						columns={KetQuaDichVuColumns}
					/>
				</Modal.Body>

				<Modal.Footer className="d-flex justify-content-center py-1">
					<Button className="btn-fill" type="submit" onClick={handleSubmit}>
						<IconButtonSave />
						<span>Lưu</span>
					</Button>
					<Button className="btn-outline" onClick={handleClose}>
						Đóng
					</Button>
				</Modal.Footer>
			</div>
		</Modal>
	);
};

export default ModalChonKetQuaDichVu;
