import { useContext } from 'react';
import { PhanHeTiepDonContext } from '../../../../contexts/PhanHeTiepDonContext';
import ThongTinPhieu from '../../../tab-thuoc/components/thong-tin-phieu/ThongTinPhieu';
import { useTabVatTuContext } from '../../context';

const ThongTinPhieuVatTu = () => {
	const { donVatTuDetail } = useTabVatTuContext();
	const { benhNhanInfo } = useContext(PhanHeTiepDonContext);

	return <ThongTinPhieu thongTinPhieu={donVatTuDetail} thongTinBenhNhan={benhNhanInfo} />;
};

export default ThongTinPhieuVatTu;
