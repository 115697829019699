import { Modal } from 'react-bootstrap';
import { KeVatTuContextProvider } from './context';
import FormKeVatTu from './FormKeVatTu';
import './style.scss';

type Props = {
	open: boolean;
	handleClose: () => void;
	itemEdit?: any;
	onSave?: () => void;
};

const ModalKeVatTu = ({ open, handleClose, itemEdit, onSave }: Props) => {
	return (
		<Modal
			className="page-full"
			fullscreen
			dialogClassName="h-modal"
			size="xl"
			show={open}
			animation={false}
			centered
			backdropClassName="spaces top-50"
		>
			<KeVatTuContextProvider>
				<FormKeVatTu onClose={handleClose}  itemEdit={itemEdit} onSave={onSave}/>
			</KeVatTuContextProvider>
		</Modal>
	);
};

export default ModalKeVatTu;
