import { Form, Formik, useFormikContext } from "formik";
import { useContext, useEffect, useState } from "react";
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "react-bootstrap";
import { useIntl } from "react-intl";
import * as Yup from "yup";
import AutocompleteV2 from "../../../component/AutocompleteObjectV2";
import { IconButtonSave } from "../../../component/IconSvg";
import LabelRequired from "../../../component/LabelRequired";
import { ObjectSelectAutocomplete } from "../../../phan-he-tiep-nhan-thanh-toan/models/PhanHeTiepNhanModel";
import InfoPatient from "../../components/InfoPatient";
import { PhanHeTiepDonContext } from "../../contexts/PhanHeTiepDonContext";
import TextField from "../../../component/TextField";
import { getTermClasses } from "../../../utils/ServicesUtils";
import { TERM_CLASS_IDS } from "../../../utils/Constant";
import { getListPhongKham } from "../../../phan-he-tiep-nhan-thanh-toan/services/TiepNhanServices";
type Props = {
  open: boolean;
  handleCloseModal: () => void;
  handleSubmit: (values: any) => void;
  modalityDetails?: any;
  setModalityDetails?: (values: any) => void;
};
interface IChuyenPhongKham {
  lyDoChuyen?: string;
  yeuCauKham?: ObjectSelectAutocomplete | null;
  tenPhongKham?: ObjectSelectAutocomplete | null;
  examinationRequest?: ObjectSelectAutocomplete | null;
  clinic?: ObjectSelectAutocomplete | null;
}
const ChuyenPhongKhamModal = ({ open, handleCloseModal, handleSubmit, modalityDetails, setModalityDetails = () => {}}: Props) => {
  const { benhNhanInfo } = useContext(PhanHeTiepDonContext);
  const intl = useIntl()
  let infoBenhNhan: any = benhNhanInfo;
  const [listYCK, setListYCK] = useState<any>([]);
  const [listPK, setListPK] = useState<any>([]);
  let { values, setFieldValue } = useFormikContext<any>();

  const handleGetListYCK = async () => {
    const { data } = await getTermClasses({ classIds: TERM_CLASS_IDS.KHAM_BENH });
    setListYCK(data?.data[0]?.terms || []);
  };

  const handleGetListPK = async () => {
    const { data } = await getListPhongKham();
    setListPK(data?.data?.content|| []);
  };

  useEffect(() => {
    handleGetListYCK();
    handleGetListPK();
    return () => {};
  }, []);


  const validationSchema = Yup.object({
  });
  
  return (
    <Modal centered className="modal fade" role="dialog" show={open} dialogClassName="modal-lg" aria-hidden="true">
      <Formik<IChuyenPhongKham>
        initialValues={{ }}
        validationSchema={validationSchema}
        onSubmit={(valuesSubmit) => {
          let dataSubmit = {
            ...modalityDetails
          };
          handleSubmit(dataSubmit);
        }}
      >
          <Form className="h-100">
            <ModalHeader closeButton onHide={handleCloseModal} className="header-modal">
              <Modal.Title>Chuyển phòng khám</Modal.Title>
            </ModalHeader>

            <ModalBody className="overflow-hidden spaces p-0 ">
              <InfoPatient infoBenhNhan={infoBenhNhan} />
              <Row className="spaces pt-10 px-10">
                <em className="text-color-note">Chú ý: </em>
                <Col xs={12} className="d-flex mb-3 flex-column text-color-note">
                  <em>- Sau khi chuyển phòng, hồ sơ bệnh án tại phòng khám này sẽ kết thúc</em>
                  <em>- Toàn bộ quyền kê đơn, xử trí sẽ thuộc về phòng khám tiếp theo</em>
                </Col>
              </Row>

              <Row className="spaces pt-10 px-10">
                <Col xs={12}>
                  <LabelRequired label="Lý do chuyển phòng" />
                  <TextField
                    className="w-100 d-flex flex-column align-items-start flex-auto spaces  pre-line"
                    name="transferReason"
                    labelClassName="ms-0 min-w-145px"
                    as="textarea"
                    value={modalityDetails?.transferReason || ""}
                    onChange={(e: any) => {
                      setModalityDetails({
                        ...modalityDetails,
                        transferReason: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="spaces pt-10 px-10">
                <LabelRequired label="Yêu cầu khám" />
                <AutocompleteV2
                  options={listYCK}
                  onChange={(option) => {
                    setFieldValue("examinationRequestOption", option)
                    setModalityDetails({
                      ...modalityDetails,
                      examinationRequestOption: option,
                      examinationRequest: option?.id,
                    });
                  }}
                  className="spaces "
                  name="examinationRequestOption"
                  value={modalityDetails.examinationRequestOption}
                />
              </Row>
              <Row className="spaces pt-10 px-10">
                <LabelRequired label="Phòng khám" />
                <AutocompleteV2
                  options={listPK}
                  menuPlacement="top"
                  className="spaces "
                  name="clinicOption"
                  onChange={(option) => {
                    setModalityDetails({
                      ...modalityDetails,
                      clinicOption: option,
                      clinic: option?.name,
                    });
                    setFieldValue("clinicOption", option)
                  }}
                  getOptionLabel={(option) => `${option.code} - ${option.name}`}
                  value={modalityDetails.clinicOption}
                />
              </Row>
            </ModalBody>

            <ModalFooter className="d-flex justify-content-end py-2">
              <Button className="btn-fill" type="submit">
                <IconButtonSave /> Lưu
              </Button>
            </ModalFooter>
          </Form>
      </Formik>
    </Modal>
  );
};

export default ChuyenPhongKhamModal;
