import { useState } from "react";
import FilterSidebar from "../../component/filter-sidebar/FilterSidebar";
import { LIST_FORMAL, LIST_FORMAL_OBJECT } from "../const/VienPhiConstant";
import { IMenuVienPhi } from "../models/VienPhiInfoModels";
import "../PhanHeVienPhi.scss";
import DSPhieuTable from "./DSPhieuTable";
import { ModalChonSoThu } from "./ModalChonSoThu";
import ModalChotSoLieuPhieu from "./ModalChotSoLieuPhieu";
import { ModalDanhSachSoThu } from "./ModalDanhSachSoThu";
import { ModalTaoSoThu } from "./ModalTaoSoThu";
import { TableDanhSachBenhNhan } from "./TableDanhSachBenhNhan";

type Props = {
  selectedRow: any;
  setSelectedRow: (row: any) => void;
  dsBenhNhan: any;
  setDsBenhNhan: (row: any) => void;
  setFilter: (data: any) => void;
  countPaid: any;
  setCountPaid: (data: any) => void;
  getDanhSachBenhNhan: (filter?: any) => void
  filter?: any
};

const listMenuItem = [
  {
    listItem: [
      {
        name: "Chọn sổ thu tiền",
        code: "CSTT",
      },
      {
        name: "Chốt số liệu phiếu thu",
        code: "CSLPT",
      },
      {
        name: "Danh sách sổ thu tiền",
        code: "DSSTT",
      },
      {
        name: "Danh sách phiếu thu",
        code: "DSPT",
      },
      {
        name: "Màn hình chờ",
        code: "MHC",
      },
      {
        name: "Cấu hình phát loa",
        code: "CHPL",
      },
      {
        name: "In",
        code: "IN",
      },
    ],
    // groupName: "Group 1",
  },
];

const DanhSachBenhNhan = (props: Props) => {
  let {
    selectedRow,
    setSelectedRow,
    dsBenhNhan,
    setDsBenhNhan,
    setFilter,
    countPaid,
    setCountPaid,
    getDanhSachBenhNhan,
    filter
  } = props;

  const [shouldOpenSoThu, setShouldOpenSoThu] = useState<IMenuVienPhi>({
    openChonSoThu: false,
    openDanhSachSoThu: false,
    openTaoMoiSoThu: false,
    openDanhSachPhieu: false,
    openChotSoLieuPhieuThu: false,
  });

  const [openDanhSachSoThu, setOpenDanhSachSoThu] = useState<boolean>(false);
  const [dataSoThu, setDataSoThu] = useState<any>();

  const doSearch = (data: any) => {
    let dataFilter = {...filter, ...data}
    setFilter(dataFilter);
    getDanhSachBenhNhan(dataFilter);
  };
  const handleSelectOption = (item: any) => {
    switch (item?.code) {
      case "CSTT":
        setShouldOpenSoThu({
          ...shouldOpenSoThu,
          openChonSoThu: true,
        });
        break;
      case "DSSTT":
        setShouldOpenSoThu({
          ...shouldOpenSoThu,
          openDanhSachSoThu: true,
        });
        break;
      case "DSPT":
        setShouldOpenSoThu({
          ...shouldOpenSoThu,
          openDanhSachPhieu: true,
        });
        break;
      case "CSLPT":
        setShouldOpenSoThu({
          ...shouldOpenSoThu,
          openChotSoLieuPhieuThu: true,
        });
        break;
      default:
        break;
    }
  };
  return (
    <div className="bangDSBenhNhan position-relative spaces pt-10 gap-8">
      <FilterSidebar
        title="Danh sách bệnh nhân"
        formalList={LIST_FORMAL}
        onSearch={doSearch}
        isFilterByType={true}
        listMenuItem={listMenuItem}
        handleSelectOption={handleSelectOption}
        formalDefault={LIST_FORMAL_OBJECT.NGAY_VAO_VIEN}
      />
      <TableDanhSachBenhNhan
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
        dsBenhNhan={dsBenhNhan}
        countPaid={countPaid}
        filter={filter}
        doSearch={doSearch}
      />
      {shouldOpenSoThu?.openChonSoThu && (
        <ModalChonSoThu show={shouldOpenSoThu} onHide={setShouldOpenSoThu} setDataSoThu={setDataSoThu} />
      )}
      {shouldOpenSoThu.openDanhSachSoThu && (
        <ModalDanhSachSoThu
          show={shouldOpenSoThu.openDanhSachSoThu}
          onHide={setShouldOpenSoThu}
          setOpenDanhSachSoThu={setOpenDanhSachSoThu}
          setDataSoThu={setDataSoThu}
        />
      )}
      {shouldOpenSoThu.openDanhSachPhieu && (
        <DSPhieuTable
          show={shouldOpenSoThu.openDanhSachPhieu}
          onHide={setShouldOpenSoThu}
          dsPhieu={[]}
        />
      )}
      {shouldOpenSoThu.openChotSoLieuPhieuThu && (
        <ModalChotSoLieuPhieu
          show={shouldOpenSoThu.openChotSoLieuPhieuThu}
          onHide={setShouldOpenSoThu}
          dsChotSoLieu={[]}
        />
      )}
      {shouldOpenSoThu.openTaoMoiSoThu && (
        <ModalTaoSoThu
          show={shouldOpenSoThu.openTaoMoiSoThu}
          onHide={(statusModal) => setShouldOpenSoThu({ ...statusModal })}
          openDanhSachSoThu={openDanhSachSoThu}
          setOpenDanhSachSoThu={setOpenDanhSachSoThu}
          dataSoThu={dataSoThu}
        />
      )}
    </div>
  );
};

export default DanhSachBenhNhan;
