import { useState } from 'react';
import InputSearch from '../../../../../component/InputSearch';
import { KEY } from '../../../../../utils/Constant';

type SearchFieldProps = {
	onSearch: (value: string) => void;
};

const SearchField = ({ onSearch }: SearchFieldProps) => {
	const [keySearch, setKeySearch] = useState<string>('');

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value;
		setKeySearch(inputValue);
	};

	const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (KEY.ENTER === event.key) {
			onSearch(keySearch);
		}
	};

	return (
		<div className="pb-2">
			<InputSearch
				value={keySearch}
				className="w-100"
				handleChange={onChange}
				handleSearch={() => onSearch(keySearch)}
				handleKeyDown={onKeyDown}
				placeholder="Tìm kiếm"
				type="text"
			/>
		</div>
	);
};

export default SearchField;
