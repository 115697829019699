import axios from 'axios';
import { OctResponse } from '../../models/OctResponse';
import { IInPhieuParams } from '../../models/params';
import {
	IKhoVatTuItem,
	IVatTuItem,
	IWarehouseResponse,
	SearchVatTuParams,
} from '../models/VatTuModels';

const BASE_URL = process.env.REACT_APP_WMS_API_URL;
const CIS_BASE_URL = process.env.REACT_APP_EMR_API_URL;

const KHO_VAT_TU_TERM_ID = 151;

//---------------WAREHOUSE--------------------
export const getTreeKhoVatTu = async (): Promise<OctResponse<IKhoVatTuItem>> => {
	const { data } = await axios.get<OctResponse<IKhoVatTuItem>>(`${BASE_URL}/term-sets/tree`, {
		params: {
			termId: KHO_VAT_TU_TERM_ID,
		},
	});
	return data;
};

export const searchVatTu = async (params: SearchVatTuParams): Promise<IVatTuItem[]> => {
	const { data } = await axios.post<OctResponse<IWarehouseResponse<IVatTuItem[]>>>(
		`${BASE_URL}/item/search`,
		params
	);
	return data.data.content;
};
