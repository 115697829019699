import { useEffect, useState, FocusEvent, useContext } from "react";
import { toast } from "react-toastify";
import { LIST_CODE_DIALOG_BANG_CDHA } from "../constants/Constants";
import { IDanhSachDVCdhaTdcn } from "../models/ModelsPhanHeCDHAVaTDCN";
import { TableCollapseCustom } from "../../component/table/table-collapse/TableCollapseCustom";
import { DATA_TYPE_XN } from "../../phan-he-xet-nghiem/const/constants";
import TextField from "../../component/TextField";
import AutocompleteV2 from "../../component/AutocompleteObjectV2";
import { Formik } from "formik";
import { CODE, SELECTION_MODE } from "../../utils/Constant";
import {
  createObsValue,
  getInforPhieuCDHA,
  updateObsValue,
} from "../services/PhanHeCDHAVaTDCNService";
import { AppContext } from "../../appContext/AppContext";
import { TableCustom } from "../../component/table/table-custom/TableCustom";
import { Column } from "react-table";
import { TableCustomCell } from "../../component/table/components/TableCustomCell";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";

type Props = {
  dataDanhSachDVCdha: IDanhSachDVCdhaTdcn[];
  infoBnSelectedCdha?: any;
  setDsDichVu?: any;
  handleDoubleClick?: () => void;
  isViewModal?: boolean;
  handleGetInfoBn: (item?: any) => void;
};

const BangDsDichVuCDHA = (props: Props) => {
  let {
    infoBnSelectedCdha = {},
    setDsDichVu = () => {},
    handleDoubleClick = () => {},
    isViewModal,
    handleGetInfoBn
  } = props;
  const [contextMenu, setContextMenu] = useState<null | {
    x: number;
    y: number;
  }>(null);
  const [openPTTTDialog, setOpenPTTTDialog] = useState(false);
  const [openQuanLyThuocVatTuDialog, setOpenQuanLyThuocVatTuDialog] =
    useState(false);
  const [openChiDinhThuocVatTuDialog, setOpenChiDinhThuocVatTuDialog] =
    useState(false);
  const [openHoanTraVatTuDialog, setOpenHoanTraVatTuDialog] = useState(false);
  const [openChiDinhThuocDialog, setOpenChiDinhThuocDialog] = useState(false);
  const [openHoanTraThuocDialog, setOpenHoanTraThuocDialog] = useState(false);
  const [openKhongThucHienDVDialog, setOpenKhongThucHienDVDialog] =
    useState(false);
  const [openMayThucHienCLS, setOpenMayThucHienCLS] = useState(false);
  const [rowSelect, setRowSelect] = useState<any>([]);
  const [dataSubmit, setDataSubmit] = useState(props.dataDanhSachDVCdha);
  const [fieldLoading, setFieldLoading] = useState<any>({});
  const { setIsLoading } = useContext(AppContext);

  const handleContextMenu = (e: any, row: any) => {
    e.preventDefault();
    setRowSelect(row);
    setContextMenu({ x: e.clientX, y: e.clientY });
  };

  const handleClickOptionContextMenu = (value: any) => {
    const menuActions = {
      [LIST_CODE_DIALOG_BANG_CDHA.NHAP_THONG_TIN_PTTT]: () =>
        setOpenPTTTDialog(true),
      [LIST_CODE_DIALOG_BANG_CDHA.QUAN_LY_THUOC_VAT_TU]: () =>
        setOpenQuanLyThuocVatTuDialog(true),
      [LIST_CODE_DIALOG_BANG_CDHA.CHI_DINH_THUOC_VAT_TU]: () =>
        setOpenChiDinhThuocVatTuDialog(true),
      [LIST_CODE_DIALOG_BANG_CDHA.HOAN_TRA_VAT_TU]: () =>
        setOpenHoanTraVatTuDialog(true),
      [LIST_CODE_DIALOG_BANG_CDHA.CHI_DINH_THUOC]: () =>
        setOpenChiDinhThuocDialog(true),
      [LIST_CODE_DIALOG_BANG_CDHA.HOAN_TRA_THUOC]: () =>
        setOpenHoanTraThuocDialog(true),
      [LIST_CODE_DIALOG_BANG_CDHA.KHONG_THUC_HIEN]: () =>
        setOpenKhongThucHienDVDialog(true),
      [LIST_CODE_DIALOG_BANG_CDHA.MAY_THUC_HIEN_CLS]: () =>
        setOpenMayThucHienCLS(true),
      [LIST_CODE_DIALOG_BANG_CDHA.SUA_SO_LUONG]: () =>
        toast.warning("Không thể sửa số lượng dịch vụ này"),
    };
    menuActions[value?.code]?.();
  };

  const handleChonMayCLS = (mayCLS: any) => {
    setOpenMayThucHienCLS(false);
    for (let i = 0; i < dataSubmit.length; i++) {
      if (dataSubmit[i].mayThucHien === rowSelect.original.mayThucHien) {
        dataSubmit[i].mayThucHien = mayCLS?.[0]?.original?.tentb;
        break;
      }
    }
    toast.success("Chọn máy cận lâm sàng thành công");
    setDataSubmit(dataSubmit);
  };

  useEffect(() => {
    setDataSubmit(props.dataDanhSachDVCdha);
  }, [props.dataDanhSachDVCdha]);

  const handleSaveObs = async (rowData: any, value: any) => {
    try {
      if (value !== rowData?.obs?.conclusion) {
        setFieldLoading({ [rowData?.id]: true });
        const submitData = {
          personId: infoBnSelectedCdha?.patient?.personId,
          orderId: rowData?.orderId,
          values: {
            conclusion: value || null
          },
        };

        let res = await createObsValue(submitData);
        toast.success( "Lưu kết luận thành công");
        handleGetInfoBn(infoBnSelectedCdha);
      }
    } catch (error) {
      toast.warning("Lỗi lưu kết luận, vui lòng nhập lại!");
    } finally {
      setFieldLoading({});
    }
  };

  const generateInput = (rowData: any) => {
    return (
      <div className="d-flex align-items-center spaces w-100">
        <TextField
          className="spaces w-100"
          as="textarea"
          name="conclusion"
          defaultValue={rowData?.obs?.conclusion || ""}
          disabled={false}
          onBlur={(e: FocusEvent<HTMLInputElement>) => {
            handleSaveObs(rowData, e.target.value);
          }}
        />
        {fieldLoading[rowData.id] && <span className="loading-spinner"></span>}
      </div>
    );
  };

  const columnsDSDVCdhaTdcn : ReadonlyArray<
  Column<any>
> = [
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"STT"}
          className=" text-center text-white align-middle bg-pri"
        />
      ),
      id: "STT",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={String(props?.row?.index + 1)}
          className="text-center fs-14"
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"TÊN DỊCH VỤ"}
          className="text-center text-white align-middle bg-pri min-w-200px"
        />
      ),
      id: "name",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2 fs-14"
          data={props.data[props.row.index]?.name}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"SL"}
          className=" text-center text-white align-middle bg-pri"
        />
      ),
      id: "SL",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index]?.quantity}
          className="text-center fs-14"
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"#ACC"}
          className=" text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "ACC",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index]?.acc}
          className="text-center fs-14"
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"KẾT LUẬN"}
          className="text-center text-white align-middle bg-pri min-w-200px"
        />
      ),
      id: "Kết luận",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2"
          data={<Formik
            initialValues={{}}
            onSubmit={() => {}}
            className="spaces w-100 fs-14"
          >
            {isViewModal ? <span>{props.data[props.row.index]?.obs?.conclusion}</span> : generateInput(props.data[props.row.index])}
          </Formik>}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"MÁY THỰC HIỆN"}
          className=" text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "Máy thực hiện",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index]?.mayThucHien}
          className="text-center fs-14"
        />
      ),
    },
  ];

  return (
    <>
      <TableCustom<any>
        className={"h-bang-ds-bn spaces h-calc-vh-310"}
        data={props.dataDanhSachDVCdha || []}
        columns={columnsDSDVCdhaTdcn}
        selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
        handleDoubleClick={handleDoubleClick}
        verticalScroll={true}
        // rowSelected={selectedRow?.length === 0 ? thongTinBenhNhan?.caseId : null}
      />
    </>
  );
};

export default BangDsDichVuCDHA;
