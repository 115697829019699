import React, { useContext, useState, useEffect } from "react";
import "./KhamLamSang.scss";
import { Col, Row, Form, Button } from "react-bootstrap";
import LabelRequired from "../../../../component/LabelRequired";
import TextValidator from "../../../../component/TextValidator";
import TextField from "../../../../component/TextField";
import { Formik } from "formik";
import { AppContext } from "../../../../appContext/AppContext";
import { KhamLamSangContext, KhamLamSangContextProps } from "../KhamLamSang";
import { CODE } from "../../../../utils/Constant";
import { saveKhamBenh } from "../services/KhamLamSangSevice";
import FormKhamTaiMuiHong from "./FormKhamTaiMuiHong";
import { PhanHeKhamDoanContext, PhanHeKhamDoanContextProps } from "../../../PhanHeKhamDoan";
import { WF_STATUS } from "../../../constants/PhanHeKhamDoan";

type Props = {
  // selectedRow: any;
};
const initialValues: any = {
  LEUncorrected: "",
  LRWithGlasses: "",
  LRUncorrected: "",
  LEWithGlasses: "",
  otherEyeConditions: "",
  eyeClassification: "",
  eyeConclusion: "",
};
const TabKhamTaiMuiHong = ({ }: Props) => {


  const { setWorkFlowStatus, workFlowStatus } = useContext<PhanHeKhamDoanContextProps>(PhanHeKhamDoanContext);
  const { TTNhanVien,TTDetailKhamBenh } = useContext<KhamLamSangContextProps>(KhamLamSangContext)
  const { setIsLoading } = useContext(AppContext);
  const [isView, setIsView] = useState<any>(false);


  const handlerSave = async (values: any) => {
    let dataValue = {
      ...values,
    }
    try {
      setIsLoading(true);
      let { data } = await saveKhamBenh(dataValue, "khamTaiMuiHong", TTNhanVien?.encounter?.id);
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
      } else {
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }

  }
  useEffect(() => {
    handlerCheckDisable()
  }, [TTNhanVien?.encounter?.id,workFlowStatus?.currStatusCode])

  const handlerCheckDisable = () => {
    if (TTNhanVien?.encounter?.id && workFlowStatus?.currStatusCode !== WF_STATUS.KET_THUC_KHAM.CODE) { setIsView(false) } else {
      setIsView(true)
    }
  }

  return (
    <Formik initialValues={initialValues} onSubmit={handlerSave}>
      {({
        values,
        setValues,
        handleSubmit,
        setFieldValue,
        errors,
        touched,
        resetForm,
      }) => (
        <Form id="form-ket-luan" onSubmit={handleSubmit}>
          <div className="bg-white  modelKhamBenh spaces h-calc-vh-340 mb-6 fl-1">
            <FormKhamTaiMuiHong setValues={setValues} isView={isView}values={values} />
            <div className="flex bg-white spaces border-top justify-content-end gap-6 py-10 pr-10 mt-0  btn-luu">
              {!isView &&
                <Button className="btn-fill spaces min-w-86" onClick={() => handlerSave(values)}>
                  <span>Lưu lại</span>
                </Button>
              }
            </div>
            {/* </div> */}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default TabKhamTaiMuiHong;
