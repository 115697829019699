const randomArr = (arr: any) => {
    let soLuongPhanTuCanCat = Math.floor(Math.random() * (arr.length + 1));
    let mangSaoChep = [...arr];
    let ketQua = [];

    for (let i = 0; i < soLuongPhanTuCanCat; i++) {
        let indexNgauNhien = Math.floor(Math.random() * mangSaoChep.length);
        ketQua.push(mangSaoChep[indexNgauNhien]);
        mangSaoChep.splice(indexNgauNhien, 1);
    }

    return ketQua;
}

export const dataDsThuocVatTu: any[] = [
    {
        ma: '001',
        tenThuocVatTu: 'Thuốc giảm đau',
        donVi: 'Viên',
        slTon: 100,
        yeuCau: 20,
        duyet: true,
        donGia: 5000,
        vat: 10,
        donGiaVat: 5500,
        thanhTien: 110000,
        huongDanSuDung: 'Uống sau ăn 30 phút',
        hoatChat: 'Paracetamol',
        sdk: 'DK001',
        soLo: 'L001',
        hsd: '2025-12-31',
    },
    {
        ma: '002',
        tenThuocVatTu: 'Vitamin C',
        donVi: 'Chai',
        slTon: 50,
        yeuCau: 5,
        duyet: true,
        donGia: 15000,
        vat: 10,
        donGiaVat: 16500,
        thanhTien: 82500,
        huongDanSuDung: 'Uống mỗi ngày 1 viên',
        hoatChat: 'Ascorbic Acid',
        sdk: 'DK002',
        soLo: 'L002',
        hsd: '2024-06-30',
    },

    {
        ma: '004',
        tenThuocVatTu: 'Thuốc tiêu hóa',
        donVi: 'Hộp',
        slTon: 75,
        yeuCau: 15,
        duyet: true,
        donGia: 30000,
        vat: 10,
        donGiaVat: 33000,
        thanhTien: 495000,
        huongDanSuDung: 'Uống trước bữa ăn 10 phút',
        hoatChat: 'Digestive Enzymes',
        sdk: 'DK004',
        soLo: 'L004',
        hsd: '2025-01-01',
    },
    {
        ma: '005',
        tenThuocVatTu: 'Thuốc an thần',
        donVi: 'Lọ',
        slTon: 20,
        yeuCau: 2,
        duyet: false,
        donGia: 100000,
        vat: 10,
        donGiaVat: 110000,
        thanhTien: 220000,
        huongDanSuDung: 'Uống trước khi đi ngủ 30 phút',
        hoatChat: 'Diazepam',
        sdk: 'DK005',
        soLo: 'L005',
        hsd: '2023-11-30',
    }, {
        ma: '006',
        tenThuocVatTu: 'Antibiotic',
        donVi: 'Hộp',
        slTon: 40,
        yeuCau: 5,
        duyet: true,
        donGia: 80000,
        vat: 10,
        donGiaVat: 88000,
        thanhTien: 440000,
        huongDanSuDung: 'Uống mỗi 8 giờ/lần',
        hoatChat: 'Amoxicillin',
        sdk: 'DK006',
        soLo: 'L006',
        hsd: '2024-05-15',
    },
    {
        ma: '007',
        tenThuocVatTu: 'Thuốc hạ sốt',
        donVi: 'Viên',
        slTon: 150,
        yeuCau: 30,
        duyet: false,
        donGia: 2000,
        vat: 10,
        donGiaVat: 2200,
        thanhTien: 66000,
        huongDanSuDung: 'Uống khi có triệu chứng sốt',
        hoatChat: 'Ibuprofen',
        sdk: 'DK007',
        soLo: 'L007',
        hsd: '2023-12-20',
    },
    {
        ma: '009',
        tenThuocVatTu: 'Thuốc giảm cân',
        donVi: 'Hộp',
        slTon: 25,
        yeuCau: 3,
        duyet: false,
        donGia: 300000,
        vat: 10,
        donGiaVat: 330000,
        thanhTien: 990000,
        huongDanSuDung: 'Uống trước bữa ăn 15 phút',
        hoatChat: 'Orlistat',
        sdk: 'DK009',
        soLo: 'L009',
        hsd: '2025-03-10',
    },
    {
        ma: '010',
        tenThuocVatTu: 'Thuốc bổ mắt',
        donVi: 'Chai',
        slTon: 70,
        yeuCau: 7,
        duyet: true,
        donGia: 120000,
        vat: 10,
        donGiaVat: 132000,
        thanhTien: 924000,
        huongDanSuDung: 'Nhỏ mắt mỗi ngày 2 lần',
        hoatChat: 'Vitamin A',
        sdk: 'DK010',
        soLo: 'L010',
        hsd: '2024-11-11',
    },
];


export const dataLSPhieu = [
    {
        id: "1",
        ngay: "21/2/24",
        loaiPhieu: "Khám Bệnh",
        taoPhieu: "Quản trị hệ thống lúc 21/02/2024 lúc 17:29 21/02/2024",
        duyetPhieu: "Quản trị hệ thống lúc 21/02/2024 lúc 17:29 21/02/2024",
        chanDoan: "Chẩn đoán 001",
        kho: "Kho Thuốc 001",

        soPhieu: "PT001",
        nguoiThu: "Nguyễn Văn Thuận",
        ngayThu: "2024-02-21",
        soTien: 500000,
        mienGiam: 20000,
        hinhThuc: "Tiền mặt",
        noiDungThu: "Thu phí khám bệnh",
        lyDoMienGiam: "Khách hàng thân quen",
        ghiChu: "Phiếu thu số 001",
        chiTietGiam: "Giảm 5% cho khách hàng thân quen",
        tongChiPhi: 480000,
        thucThu: 480000,
        conNo: 0,

        dataChiTietPhieu: randomArr(dataDsThuocVatTu),

        daThu: 10000
    },
    {
        id: "2",
        ngay: "22/2/24",
        loaiPhieu: "Thuốc",
        taoPhieu: "Quản trị hệ thống lúc 21/02/2024 lúc 17:29 21/02/2024",
        duyetPhieu: "Quản trị hệ thống lúc 21/02/2024 lúc 17:29 21/02/2024",
        chanDoan: "Chẩn đoán 001",
        kho: "Kho Thuốc 002",

        soPhieu: "PT002",
        nguoiThu: "Phạm Thị Hoa",
        ngayThu: "2024-02-22",
        soTien: 700000,
        mienGiam: 30000,
        hinhThuc: "Chuyển khoản",
        noiDungThu: "Thu tiền mua thuốc",
        lyDoMienGiam: "Khuyến mãi",
        ghiChu: "Phiếu thu số 002",
        chiTietGiam: "Khuyến mãi 5%",
        tongChiPhi: 670000,
        thucThu: 670000,
        conNo: 0,

        dataChiTietPhieu: randomArr(dataDsThuocVatTu),

        daThu: 10000
    },
    {
        id: "3",
        ngay: "23/2/24",
        loaiPhieu: "Dịch vụ Xét nghiệm",
        taoPhieu: "Quản trị hệ thống lúc 21/02/2024 lúc 17:29 21/02/2024",
        duyetPhieu: "Quản trị hệ thống lúc 21/02/2024 lúc 17:29 21/02/2024",
        chanDoan: "Chẩn đoán 001",
        kho: "Kho Thuốc 003",

        soPhieu: "PT003",
        nguoiThu: "Trần Văn A",
        ngayThu: "2024-02-23",
        soTien: 900000,
        mienGiam: 50000,
        hinhThuc: "Thẻ tín dụng",
        noiDungThu: "Thu phí xét nghiệm máu",
        lyDoMienGiam: "Học sinh",
        ghiChu: "Phiếu thu số 003",
        chiTietGiam: "Giảm 10% cho học sinh",
        tongChiPhi: 850000,
        thucThu: 850000,
        conNo: 0,
        dataChiTietPhieu: dataDsThuocVatTu,
        daThu: 10000,
        ngayHuy: "23/2/24",
        nguoiHuy: "Quản trị hệ thống",
        lyDoHuy: "Sai lệ phí"
    },
    {
        id: "4",
        ngay: "24/2/24",
        loaiPhieu: "Phí Khoa học",
        taoPhieu: "Quản trị hệ thống lúc 21/02/2024 lúc 17:29 21/02/2024",
        duyetPhieu: "Quản trị hệ thống lúc 21/02/2024 lúc 17:29 21/02/2024",
        chanDoan: "Chẩn đoán 001",
        kho: "Kho Thuốc 001",
        soPhieu: "PT004",
        nguoiThu: "Lê Thị Lan",
        ngayThu: "2024-02-24",
        soTien: 1200000,
        mienGiam: 100000,
        hinhThuc: "Tiền mặt",
        noiDungThu: "Thu phí nghiên cứu khoa học",
        lyDoMienGiam: "Người già",
        ghiChu: "Phiếu thu số 004",
        chiTietGiam: "Giảm 15% cho người già",
        tongChiPhi: 1100000,
        thucThu: 1100000,
        conNo: 0,
        dataChiTietPhieu: randomArr(dataDsThuocVatTu),
        daThu: 10000
    },
    {
        id: "5",
        ngay: "25/2/24",
        loaiPhieu: "Khám Sức khỏe",
        taoPhieu: "Quản trị hệ thống lúc 21/02/2024 lúc 17:29 21/02/2024",
        duyetPhieu: "Quản trị hệ thống lúc 21/02/2024 lúc 17:29 21/02/2024",
        chanDoan: "Chẩn đoán 001",
        kho: "Kho Thuốc 001",
        soPhieu: "PT005",
        nguoiThu: "Nguyễn Thị Mai",
        ngayThu: "2024-02-25",
        soTien: 600000,
        mienGiam: 30000,
        hinhThuc: "Chuyển khoản",
        noiDungThu: "Thu phí khám sức khỏe định kỳ",
        lyDoMienGiam: "Khách hàng thân quen",
        ghiChu: "Phiếu thu số 005",
        chiTietGiam: "Giảm 5% cho khách hàng thân quen",
        tongChiPhi: 570000,
        thucThu: 570000,
        conNo: 0,
        dataChiTietPhieu: randomArr(dataDsThuocVatTu),
        daThu: 10000
    },
    {
        id: "6",
        ngay: "26/2/24",
        loaiPhieu: "Thuốc",
        taoPhieu: "Quản trị hệ thống lúc 21/02/2024 lúc 17:29 21/02/2024",
        duyetPhieu: "Quản trị hệ thống lúc 21/02/2024 lúc 17:29 21/02/2024",
        chanDoan: "Chẩn đoán 001",
        kho: "Kho Thuốc 001",
        soPhieu: "PT006",
        nguoiThu: "Nguyễn Văn Tùng",
        ngayThu: "2024-02-26",
        soTien: 800000,
        mienGiam: 40000,
        hinhThuc: "Thẻ tín dụng",
        noiDungThu: "Thu tiền mua thuốc",
        lyDoMienGiam: "Khuyến mãi",
        ghiChu: "Phiếu thu số 006",
        chiTietGiam: "Khuyến mãi 5%",
        tongChiPhi: 760000,
        thucThu: 760000,
        conNo: 0,
        dataChiTietPhieu: randomArr(dataDsThuocVatTu),
        daThu: 10000
    },
    {
        id: "7",
        ngay: "27/2/24",
        loaiPhieu: "Dịch vụ Xét nghiệm",
        taoPhieu: "Quản trị hệ thống lúc 21/02/2024 lúc 17:29 21/02/2024",
        duyetPhieu: "Quản trị hệ thống lúc 21/02/2024 lúc 17:29 21/02/2024",
        chanDoan: "Chẩn đoán 001",
        kho: "Kho Thuốc 004",
        soPhieu: "PT007",
        nguoiThu: "Trần Thị Ngọc",
        ngayThu: "2024-02-27",
        soTien: 950000,
        mienGiam: 60000,
        hinhThuc: "Chuyển khoản",
        noiDungThu: "Thu phí xét nghiệm máu",
        lyDoMienGiam: "Học sinh",
        ghiChu: "Phiếu thu số 007",
        chiTietGiam: "Giảm 10% cho học sinh",
        tongChiPhi: 890000,
        thucThu: 890000,
        conNo: 0,
        dataChiTietPhieu: randomArr(dataDsThuocVatTu),
        daThu: 10000
    },
    {
        id: "8",
        ngay: "28/2/24",
        loaiPhieu: "Phí Khoa học",
        taoPhieu: "Quản trị hệ thống lúc 21/02/2024 lúc 17:29 21/02/2024",
        duyetPhieu: "Quản trị hệ thống lúc 21/02/2024 lúc 17:29 21/02/2024",
        chanDoan: "Chẩn đoán 001",
        kho: "Kho Thuốc 001",

        soPhieu: "PT008",
        nguoiThu: "Lê Thị Hương",
        ngayThu: "2024-02-28",
        soTien: 1100000,
        mienGiam: 90000,
        hinhThuc: "Tiền mặt",
        noiDungThu: "Thu phí nghiên cứu khoa học",
        lyDoMienGiam: "Người già",
        ghiChu: "Phiếu thu số 008",
        chiTietGiam: "Giảm 15% cho người già",
        tongChiPhi: 1010000,
        thucThu: 1010000,
        conNo: 0,
        dataChiTietPhieu: randomArr(dataDsThuocVatTu),
        daThu: 10000
    },
    {
        id: "9",
        ngay: "29/2/24",
        loaiPhieu: "Khám Sức khỏe",
        taoPhieu: "Quản trị hệ thống lúc 21/02/2024 lúc 17:29 21/02/2024",
        duyetPhieu: "Quản trị hệ thống lúc 21/02/2024 lúc 17:29 21/02/2024",
        chanDoan: "Chẩn đoán 001",
        kho: "Kho Thuốc 005",
        soPhieu: "PT009",
        nguoiThu: "Nguyễn Văn Hòa",
        ngayThu: "2024-02-29",
        soTien: 620000,
        mienGiam: 32000,
        hinhThuc: "Chuyển khoản",
        noiDungThu: "Thu phí khám sức khỏe định kỳ",
        lyDoMienGiam: "Khách hàng thân quen",
        ghiChu: "Phiếu thu số 009",
        chiTietGiam: "Giảm 5% cho khách hàng thân quen",
        tongChiPhi: 588000,
        thucThu: 588000,
        conNo: 0,
        dataChiTietPhieu: randomArr(dataDsThuocVatTu),
        daThu: 10000
    },
    {
        id: "10",
        ngay: "1/3/24",
        loaiPhieu: "Thuốc",
        taoPhieu: "Quản trị hệ thống lúc 21/02/2024 lúc 17:29 21/02/2024",
        duyetPhieu: "Quản trị hệ thống lúc 21/02/2024 lúc 17:29 21/02/2024",
        chanDoan: "Chẩn đoán 001",
        kho: "Kho Thuốc 006",
        soPhieu: "PT010",
        nguoiThu: "Nguyễn Thị Lệ",
        ngayThu: "2024-03-01",
        soTien: 780000,
        mienGiam: 40000,
        hinhThuc: "Thẻ tín dụng",
        noiDungThu: "Thu tiền mua thuốc",
        lyDoMienGiam: "Khuyến mãi",
        ghiChu: "Phiếu thu số 010",
        chiTietGiam: "Khuyến mãi 5%",
        tongChiPhi: 740000,
        thucThu: 740000,
        conNo: 0,
        dataChiTietPhieu: randomArr(dataDsThuocVatTu),
        daThu: 10000
    },
]


export const dataDSBN = [
    {
        TT: 3,
        hoTen: "Nguyễn Văn chung",
        barCode: "BC003",
        age: 40,
        diaChi: "789 Đường DEF, Quận 3, TP.HCM",
        tongTien: 700000,
        bacSi: "Dr. D",
        ghiChu: "Khám mắt",
        trangThai: 1,
        lsPhieu: randomArr(dataLSPhieu),
        dataChiTietPhieu: randomArr(dataDsThuocVatTu)
    },
    {
        TT: 4,
        hoTen: "Nguyễn Thanh Tùng",
        barCode: "BC004",
        age: 22,
        diaChi: "101 Đường GHI, Quận 4, TP.HCM",
        tongTien: 800000,
        bacSi: "Dr. E",
        ghiChu: "Khám da liễu",
        trangThai: 2,
        lsPhieu: randomArr(dataLSPhieu),
        dataChiTietPhieu: randomArr(dataDsThuocVatTu)
    },
    {
        TT: 5,
        hoTen: "Nguyễn Thị Huy",
        barCode: "BC005",
        age: 35,
        diaChi: "202 Đường JKL, Quận 5, TP.HCM",
        tongTien: 900000,
        bacSi: "Dr. F",
        ghiChu: "Khám tim mạch",
        trangThai: 3,
        lsPhieu: randomArr(dataLSPhieu),
        dataChiTietPhieu: randomArr(dataDsThuocVatTu)

    },
    {
        TT: 6,
        hoTen: "Lê Bá Long",
        barCode: "BC006",
        age: 29,
        diaChi: "303 Đường MNO, Quận 6, TP.HCM",
        tongTien: 1000000,
        bacSi: "Dr. G",
        ghiChu: "Khám nội tiết",
        trangThai: 4,
        lsPhieu: randomArr(dataLSPhieu),
        dataChiTietPhieu: randomArr(dataDsThuocVatTu)
    },
    {
        TT: 7,
        hoTen: "Đặng Thị Gap",
        barCode: "BC007",
        age: 32,
        diaChi: "404 Đường PQRS, Quận 7, TP.HCM",
        tongTien: 1100000,
        bacSi: "Dr. H",
        ghiChu: "Khám phụ sản",
        trangThai: 2,
        lsPhieu: randomArr(dataLSPhieu),
        dataChiTietPhieu: randomArr(dataDsThuocVatTu)
    },
    {
        TT: 8,
        hoTen: "Trần Văn Hùng",
        barCode: "BC008",
        age: 28,
        diaChi: "505 Đường TUV, Quận 8, TP.HCM",
        tongTien: 1200000,
        bacSi: "Dr. I",
        ghiChu: "Khám tổng quát",
        trangThai: 3,
        lsPhieu: randomArr(dataLSPhieu),
        dataChiTietPhieu: randomArr(dataDsThuocVatTu)
    },
    {
        TT: 9,
        hoTen: "Phan Thị Im",
        barCode: "BC009",
        age: 45,
        diaChi: "606 Đường WXYZ, Quận 9, TP.HCM",
        tongTien: 1300000,
        bacSi: "Dr. J",
        ghiChu: "Khám nhi",
        trangThai: 4,
        lsPhieu: randomArr(dataLSPhieu),
        dataChiTietPhieu: randomArr(dataDsThuocVatTu)
    },
    {
        TT: 10,
        hoTen: "Lưu Văn Ka",
        barCode: "BC010",
        age: 50,
        diaChi: "707 Đường ABCD, Quận 10, TP.HCM",
        tongTien: 1400000,
        bacSi: "Dr. K",
        ghiChu: "Khám tai mũi họng",
        trangThai: 6,
        lsPhieu: randomArr(dataLSPhieu),
        dataChiTietPhieu: randomArr(dataDsThuocVatTu)
    },
    {
        TT: 5,
        hoTen: "Nguyễn Thị E",
        barCode: "BC005",
        age: 35,
        diaChi: "202 Đường JKL, Quận 5, TP.HCM",
        tongTien: 900000,
        bacSi: "Dr. F",
        ghiChu: "Khám tim mạch",
        trangThai: 1,
        lsPhieu: randomArr(dataLSPhieu),
        dataChiTietPhieu: randomArr(dataDsThuocVatTu)
    },
    {
        TT: 6,
        hoTen: "Vũ Văn F",
        barCode: "BC006",
        age: 29,
        diaChi: "303 Đường MNO, Quận 6, TP.HCM",
        tongTien: 1000000,
        bacSi: "Dr. G",
        ghiChu: "Khám nội tiết",
        trangThai: 2,
        lsPhieu: randomArr(dataLSPhieu),
        dataChiTietPhieu: randomArr(dataDsThuocVatTu)
    },
    {
        TT: 7,
        hoTen: "Đặng Thị G",
        barCode: "BC007",
        age: 32,
        diaChi: "404 Đường PQRS, Quận 7, TP.HCM",
        tongTien: 1100000,
        bacSi: "Dr. H",
        ghiChu: "Khám phụ sản",
        trangThai: 3,
        lsPhieu: randomArr(dataLSPhieu),
        dataChiTietPhieu: randomArr(dataDsThuocVatTu)
    },
    {
        TT: 8,
        hoTen: "Trần Văn H",
        barCode: "BC008",
        age: 28,
        diaChi: "505 Đường TUV, Quận 8, TP.HCM",
        tongTien: 1200000,
        bacSi: "Dr. I",
        ghiChu: "Khám tổng quát",
        trangThai: 4,
        lsPhieu: randomArr(dataLSPhieu),
        dataChiTietPhieu: randomArr(dataDsThuocVatTu)
    },
    {
        TT: 9,
        hoTen: "Phan Thị I",
        barCode: "BC009",
        age: 45,
        diaChi: "606 Đường WXYZ, Quận 9, TP.HCM",
        tongTien: 1300000,
        bacSi: "Dr. J",
        ghiChu: "Khám nhi",
        trangThai: 1,
        lsPhieu: randomArr(dataLSPhieu),
        dataChiTietPhieu: randomArr(dataDsThuocVatTu)
    },
    {
        TT: 10,
        hoTen: "Lưu Văn K",
        barCode: "BC010",
        age: 50,
        diaChi: "707 Đường ABCD, Quận 10, TP.HCM",
        tongTien: 1400000,
        bacSi: "Dr. K",
        ghiChu: "Khám tai mũi họng",
        trangThai: 2,
        lsPhieu: randomArr(dataLSPhieu),
        dataChiTietPhieu: randomArr(dataDsThuocVatTu)
    },
];
export const CODE_MG = "mg";
export const CODE_MG_KHAC = "mg_khac";

export const menuDsChiTietPhieu = [
    {
        title: "Dịch vụ",
    },
    {
        code: "0",
        name: "Miễn giảm",
        children: [
            {
                code: CODE_MG,
                name: "0%"
            },
            {
                code: CODE_MG,
                name: "5%"
            },
            {
                code: CODE_MG,
                name: "10%"
            },
            {
                code: CODE_MG,
                name: "15%"
            },
            {
                code: CODE_MG,
                name: "20%"
            },
            {
                code: CODE_MG,
                name: "50%"
            },
            {
                code: CODE_MG_KHAC,
                name: "Nhập miễn giảm"
            },

        ]

    },
]

export const menuSuaChiTietPhieu = [
    {
        title: "Thuốc",
    },
    {
        code: "0",
        name: "Xóa thuốc",

    },
]

export const codeMenuSuaChiTietPhieu = {
    XOA_THUOC: "0"
}