import { Button, Col, Row } from "react-bootstrap"
import ThongTinGiaDinh from "./ThongTinGiaDinh"
import TextField from "../../../../component/TextField"
import { useEffect, useState } from "react"
import ModalDSBenhNhan from "./ModalDSBenhNhan"
import GenerateFormHTMLNoValid from "../../../../component/generate-form/GenerateFormHTMLNoValid"
import { getJsonGenerateForms } from "../../../../utils/ServicesUtils"

const JSON_CODE = {
  FAMILYRECORDS1: "familyRecords1",
  FAMILYRECORDS2: "familyRecords2",
  GUARDIANRECORDS: "guardianRecords",
};

type Props = {}

const   TabGiaDinh = (props: Props) => {
  const [openDSBenhNhanDialog, setOpenDSBenhNhanDialog] = useState(false);
  const [dataGenerate, setDataGenerate] = useState<any>();
  const [searchObject, setSearchObject] = useState({
    tenBN: "",
    maBN: "",
    khoa: "",
  })

  const handleChangeValueSearch = (e: any) => {
   const {name, value} = e.target;
        setSearchObject({...searchObject, [name]: value});
  }

  const handleOpenDSBenhNhanDialog = () => {
    setOpenDSBenhNhanDialog(true);
  }

  const handleGetJsonGenerateForms = async () => {
    try {
      let codes = `${JSON_CODE.FAMILYRECORDS1},${JSON_CODE.FAMILYRECORDS2},${JSON_CODE.GUARDIANRECORDS}`
      let { data } = await getJsonGenerateForms(codes);
      setDataGenerate(data?.data);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    handleGetJsonGenerateForms();
  }, []);

  return (
    <Row className="spaces h-calc-vh-510 p-12 ">
      <Col xl="3" className="h-100">
        <ThongTinGiaDinh title="Thông tin người nhà 1" formName={JSON_CODE.FAMILYRECORDS1}
          dataGenerate={JSON.parse(dataGenerate?.[JSON_CODE.FAMILYRECORDS1] as string || "{}")}
        />
      </Col>

      <Col xl="3" className="h-100">
        <ThongTinGiaDinh title="Thông tin người nhà 2" formName={JSON_CODE.FAMILYRECORDS2}
          dataGenerate={JSON.parse(dataGenerate?.[JSON_CODE.FAMILYRECORDS2] as string || "{}")}
        />
      </Col>

      <Col xl="3" className="h-100">
        <ThongTinGiaDinh title="Thông tin người nuôi dưỡng, giám hộ" formName={JSON_CODE.GUARDIANRECORDS}
          dataGenerate={JSON.parse(dataGenerate?.[JSON_CODE.GUARDIANRECORDS] as string || "{}")}
        />
      </Col>

      <Col xl="3" className="h-100 tt-gia-dinh">
        <div className="title">
          Người nhà bệnh nhân
        </div>

        <div className="content pt-3">
          <div className="spaces W-240">
            <TextField
              label="Mã BN"
              name="maBN"
              value={searchObject.maBN}
              onChange={handleChangeValueSearch}
              labelClassName="ms-0 min-w-75px"
              className="spaces mb-3"
            />
            <TextField
              label="Tên BN"
              name="tenBN"
              value={searchObject.tenBN}
              onChange={handleChangeValueSearch}
              labelClassName="ms-0 min-w-75px"
              className="spaces mb-3"
            />
            <TextField
              label="Khoa"
              name="khoa"
              value={searchObject.khoa}
              onChange={handleChangeValueSearch}
              labelClassName="ms-0 min-w-75px"
              className="spaces mb-3"
            />
            <div className="d-flex justify-content-center spaces mt-20">
              <Button className="btn-fill" onClick={handleOpenDSBenhNhanDialog}>
                <i className="bi bi-search"></i>
                Tìm BN
              </Button>
            </div>
          </div>
        </div>

      </Col>

      <ModalDSBenhNhan show={openDSBenhNhanDialog} handleCloseDialog={() => setOpenDSBenhNhanDialog(false)}/>
    </Row>
  )
}

export default TabGiaDinh