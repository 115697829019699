import { createContext, FC, SetStateAction, useState } from "react";
import { Col, Row } from "react-bootstrap";
import DSHopDongThanhToan from "./component/DSHopDong";
import ThongTinHD from "./component/ThongTinHD";
import ThongTinThanhToanHD from "./component/ThongTinThanhToanHD";
import "../../PhanHeKhamDoan.scss";

export type ThanhToanHDProps = {
  values?: any;
  activeKey?: string;
  heightTable?: string;
};

export interface ThanhToanHDContextProps {
  HDSelected: any;
  setHDSelected: SetStateAction<any>;
  DSGoiKham: any;
  setDSGoiKham: SetStateAction<any>;
}

const initialContext = {
  HDSelected: {},
  setHDSelected: () => {},
  DSGoiKham: {},
  setDSGoiKham: () => {},
};

export const ThanhToanHDContext =
  createContext<ThanhToanHDContextProps>(initialContext);

const ThanhToanHD: FC<any> = (props) => {
  const [HDSelected, setHDSelected] = useState([]);
  const [DSGoiKham, setDSGoiKham] = useState([]);

  return (
    <ThanhToanHDContext.Provider
      value={{
        HDSelected,
        setHDSelected,
        DSGoiKham,
        setDSGoiKham,
      }}
    >
      <Row className="bg-gray">
        <Col xs="3" className="tabThanhToan flex-1 flex flex-column spaces mt-8">
          <DSHopDongThanhToan />
        </Col>
        <Col xs="9" className="spaces mt-8 p-0 h-100">
          <ThongTinHD />
          <ThongTinThanhToanHD />
        </Col>
      </Row>
    </ThanhToanHDContext.Provider>
  );
};

export default ThanhToanHD;
