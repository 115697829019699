import { useState } from "react";
import { Column } from "react-table";
import { toast } from "react-toastify";
import ContextMenu from "../../component/ContextMenuV3";
import { TableCustomCell } from "../../component/table/components/TableCustomCell";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { TableCustom } from "../../component/table/table-custom/TableCustom";
import { IContextMenu } from "../../phan-he-tiep-nhan-thanh-toan/models/ModelTabDSDangKy";
import { CODE, RESPONSE_MESSAGE, SELECTION_MODE } from "../../utils/Constant";
import { formatDateToDDMMYYYY } from "../../utils/FormatUtils";
import { ChotSoLieuPhieu } from "../models/VienPhiInfoModels";
import { cancelChotSoLieuPhieuThu } from "../services/PhanHeVienPhiServices";

const TableChotSoLieu = (props: any) => {
  let { dataChotSoLieu, handleDoubleClick, setDsChotSoLieu, updatePageData } = props;
  const [contextMenu, setContextMenu] = useState<null | {
    x: number;
    y: number;
  }>(null);
  const [selectedRow, setSelectedRow] = useState<any>({});
  const CODE_MENU = {
    HUY_CHOT: "huyChot",
  };
  const dropListDsKhamBenh: IContextMenu[] = [
    {
      code: CODE_MENU.HUY_CHOT,
      name: "Hủy chốt",
    },
  ];
  const handleContextMenu = (e: any, row: any) => {
    e.preventDefault();
    setContextMenu({ x: e.clientX, y: e.clientY });
    setSelectedRow(row?.original);
  };

  const handleClickOptionContextMenu = (value: any) => {
    const menuActions = {
      [CODE_MENU.HUY_CHOT]: handleCancelChotSoLieu,
    };
    menuActions[value?.code]?.();
    setContextMenu(null);
  };

  const handleCancelChotSoLieu = async () => {
    try {
      let { data } = await cancelChotSoLieuPhieuThu(selectedRow?.id);
      if (data?.code === CODE.SUCCESS) {
        updatePageData();
        toast.success("Hủy chốt thành công");
      } else {
        toast.error(RESPONSE_MESSAGE.ERROR);
      }
    } catch (error) {
      toast.error(RESPONSE_MESSAGE.ERROR);
      console.error(error);
    }
  }

  const handleGetDsChotSoLieu = (data: any[]) => {
    setDsChotSoLieu(data);
  };

  const danhSachPhieuColumn: ReadonlyArray<Column<ChotSoLieuPhieu>> = [
    {
      Header: (props) => (
        <TableCustomHeader<ChotSoLieuPhieu>
          tableProps={props}
          title={"Tên sổ thu"}
          className="text-center min-w-150px"
        />
      ),
      id: "Tên sổ thu",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props?.data[props?.row?.index]?.invLedgerName || ""}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ChotSoLieuPhieu>
          tableProps={props}
          title={"Số phiếu"}
          className="text-center min-w-100px"
        />
      ),
      id: "Số phiếu",
      Cell: ({ ...props }) => (
        <TableCustomCell data={props?.data[props?.row?.index]?.invCode || ""} />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ChotSoLieuPhieu>
          tableProps={props}
          title={"Loại phiếu"}
          className="text-center min-w-125px"
        />
      ),
      id: "Loại phiếu",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props?.data[props?.row?.index]?.invTypeName || ""}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ChotSoLieuPhieu>
          tableProps={props}
          title={"Ngày tạo"}
          className="text-center text-white align-middle min-w-100px"
        />
      ),
      id: "Ngày tạo",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props?.data[props?.row?.index]?.createdDate ? formatDateToDDMMYYYY(props?.data[props?.row?.index]?.createdDate) : ""}
          className="text-center"
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ChotSoLieuPhieu>
          tableProps={props}
          title={"Người tạo"}
          className="text-center text-white align-middle min-w-150px"
        />
      ),
      id: "Người tạo",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props?.data[props?.row?.index]?.invCashier || ""}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ChotSoLieuPhieu>
          tableProps={props}
          title={"Số tiền"}
          className="text-center min-w-100px"
        />
      ),
      id: "Số tiền",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center"
          data={(props?.data[props?.row?.index]?.invTotal || props?.data[props?.row?.index]?.invTotal === 0) ? props?.data[props?.row?.index]?.invTotal : ""}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ChotSoLieuPhieu>
          tableProps={props}
          title={"Ngày chốt"}
          className="text-center text-white align-middle min-w-100px"
        />
      ),
      id: "Ngày chốt",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props?.data[props?.row?.index]?.createdDate ? formatDateToDDMMYYYY(props?.data[props?.row?.index]?.finalizedDate) : ""}
          className="text-center"
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<ChotSoLieuPhieu>
          tableProps={props}
          title={"Người chốt"}
          className="text-center text-white align-middle min-w-150px"
        />
      ),
      id: "Người chốt",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props?.data[props?.row?.index]?.finalizedBy || ""}
        />
      ),
    },
  ];

  return (
    <>
      <TableCustom<ChotSoLieuPhieu>
        className="h-100"
        handleDoubleClick={handleDoubleClick}
        hasToolbar={false}
        minHeight={350}
        data={dataChotSoLieu}
        columns={danhSachPhieuColumn}
        verticalScroll={true}
        handleContextMenu={handleContextMenu}
        selectionMode={SELECTION_MODE.MULTI}
        getSelectedRowsData={handleGetDsChotSoLieu}
      />
      {contextMenu && (
        <ContextMenu
          handleClickOptionContextMenu={handleClickOptionContextMenu}
          handleCloseMenu={() => setContextMenu(null)}
          data={dropListDsKhamBenh}
          target={contextMenu}
        />
      )}
    </>
  );
};

export default TableChotSoLieu;
