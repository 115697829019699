import { Button, Modal } from 'react-bootstrap';
import { FC, SetStateAction } from 'react';

interface DynamicProps {
  data: any,
  setData: SetStateAction<any>,
}

const DynamicComponentXN: FC<DynamicProps> = (props) => {
  const { data, setData } = props;
  const Default = () => {
    return <div>Không có nội dung</div>;
  };

  const Confirm = () => {
    const onOK = () => {
      data.submit();
      setData(null);
    };

    return (
      <Modal centered show={data?.componentName === 'Confirm'} size="sm" onHide={() => setData(null)}>
        <Modal.Title className="py-5 header-modal"> Xác nhận </Modal.Title>
        <Modal.Body>{`Bạn chắc chắn muốn ${data.nameReq} ?`}</Modal.Body>
        <Modal.Footer>
          <Button className="btn-fill" onClick={onOK}>
            Có
          </Button>
          <Button className="btn-outline" onClick={() => setData(null)}>
            Không
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const components: { [key: string]: FC<any> } = {
    Confirm: Confirm,
  };

  const DynamicContent = components[data?.componentName] || Default;

  return <DynamicContent {...data} />;
};

export default DynamicComponentXN;