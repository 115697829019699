import axios from 'axios';


function wfAxiosGenerator(baseEndpoint : string, configString: string) {
  function convertToJSON(configString : string) {
    try {
      const jsonObject = JSON.parse(configString);
      return jsonObject;
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return null;
    }
  }

  const getValueFromTemplate = (keyStr: string, props: any) => {
    return keyStr.split('.').reduce((acc, currKey) => acc?.[currKey], props);
  };

  const fillTemplate = (templates: any, props: any) => {
    const result: Record<string, any> = {};

    templates?.forEach((template: any) => {
      const [key, value] = template.includes('=') ? 
        template.split('=') : 
        template.split('-');

      result[key] = template.includes('=') ? value : getValueFromTemplate(value, props);
    });

    return result;
  };

  const generateAxiosRequest = (buttonConfig: any) => {
    const { code, name, componentName, method, url, payloadTemplate, paramsTemplate, pathVariables } = buttonConfig;

    const handleClick = async (props:any) => {
      
      try {
        const payload = fillTemplate(payloadTemplate, props);
        const params = fillTemplate(paramsTemplate, props); // Chưa cần sử dụng
        let finalUrl = url;
        if (pathVariables) {
          for (const [key, value] of Object.entries(pathVariables)) {
            finalUrl = finalUrl.replace(`{${key}}`, value);
          }
        }

        if (typeof method === 'string' && "POST".toLowerCase() === method.toLowerCase()) {
          await axios.post(`${baseEndpoint}${finalUrl}`, payload);
        }
        if (typeof method === 'string' && "PUT".toLowerCase() === method.toLowerCase()) {
          await axios.put(`${baseEndpoint}${finalUrl}`, payload);
        }

        if (buttonConfig.actions) {
          const lstActions = generateActions(buttonConfig);
          const actions = lstActions?.[code];
          if (actions) {
            actions.forEach((func: (props: any) => void) => func(props));
          }
        }
        
      } catch (error) {
        console.error(`[${code}] ${name} - Error:`, error);
      }
    };
   
    return { code, name, componentName, handleClick };
  };

  const generateActions = (actionsConfig: any) => {
    let { code, actions } = actionsConfig;
    
    actions = actions.map((action: any) => {
      const { method, url, payloadTemplate, pathVariables } = action;
      const handleClick = async (props: any) => {
        try {
          let finalUrl = url;
          let payload = fillTemplate(payloadTemplate, props);
            
          if (pathVariables) {
            for (const pathInfo of pathVariables) {
              const pathKey = pathInfo.split('-');
              finalUrl = finalUrl.replace(`{${pathKey[0]}}`, getValueFromTemplate(pathKey[1], props));
            }
          }
          if (typeof method === 'string' && "POST".toLowerCase() === method.toLowerCase()) {
            await axios.post(`${baseEndpoint}${finalUrl}`, payload);
          }
          if (typeof method === 'string' && "PUT".toLowerCase() === method.toLowerCase()) {
            await axios.put(`${baseEndpoint}${finalUrl}`,  payload);
          } 
          if (typeof method === 'string' && "DELETE".toLowerCase() === method.toLowerCase()) {
            await axios.delete(`${baseEndpoint}${finalUrl}`);
          } 
        } catch (error) {
          console.error(`[${code}] - Error:`, error);
        }
      };

      return handleClick;
    })
    
    return { [code]: actions };
  }

  if (convertToJSON(configString)) {
    const axiosRequests = convertToJSON(configString)?.additionalButton?.map(generateAxiosRequest);
    let actions = {};
    convertToJSON(configString)?.additionalAction?.forEach((actionConfig: any) => {
      const action = generateActions(actionConfig);
      actions = { ...actions, ...action}
    });
    return { axiosRequests, actions };
  }
}

export default wfAxiosGenerator; 