import { FC } from "react";
import { TableCustom } from "../../component/table/table-custom/TableCustom";
import { benhNhan, danhSachChoKham } from "../models/datLichHenModels";
import { benhNhanData } from "../models/datLichHenModels";
import { fakeDataDsChoKham } from "../constants/fakeData";
import { ChoKhamColumns } from "../columns/ChoKhamColumns";
import TextField from "../../component/TextField";
import { useFormikContext } from "formik";

type IDSChoKham = {
  benhNhanData?: benhNhanData;
  updatePageData?: () => void;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleUpdate?: (row: any) => void;
  handlePay?: (row: any) => void;
  page?: number;
  rowsPerPage?: number;
  handleContextMenu?: (e: any, row: any) => void;
};

export const DanhSachChoKhamTable: FC<IDSChoKham> = (props) => {
  let { benhNhanData, updatePageData, handleContextMenu } =
    props;
  const { values, handleChange } = useFormikContext<benhNhan>();
  return (
    <>
      <div className="d-flex flex-column spaces w-30 bg-white ds-cho pt-10 gap-8">
        <div className="d-flex spaces pr-10 gap-8">
          <div className="text-info fs-4 spaces w-50 fw-bold px-16">
            Thông tin phòng khám
          </div>

            <div className="position-relative spaces w-50 ml-8">
              <TextField
                name="soDienThoai"
                labelClassName="min-w-40px"
                className="ps-29px"
                value={values?.soDienThoai}
                placeholder={"Tìm kiếm"}
              />
              <i className="fa-solid fa-magnifying-glass position-absolute cursor-pointer search-icon"></i>
            </div>
        </div>
        <div className="overflow-auto spaces h-calc-vh-485">
          <TableCustom<danhSachChoKham>
            hasToolbar={false}
            // maxHeight={462}
            data={fakeDataDsChoKham || benhNhanData?.data}
            columns={ChoKhamColumns}
            handleSearchByPage={updatePageData}
            handleChangeValueInput={handleChange}
            verticalScroll={true}
            handleContextMenu={handleContextMenu}
          />
        </div>
      </div>
    </>
  );
};

export default DanhSachChoKhamTable;
