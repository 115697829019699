import { useFormikContext } from 'formik';
import { KeyboardEvent, useState } from 'react';
import TextField from '../../../component/TextField';
import { IThuoc } from '../../models/ThuocModels';
import { COLUMNS } from './Column';
import SelectModal from './Modal';
import { SelectModalType, SelectTableButtonProps } from './type';
import { toast } from 'react-toastify';

const SelectTableButton = ({ value, maKhoThuoc, setThuocSelected }: SelectTableButtonProps) => {
	const { values, setFieldValue } = useFormikContext<any>();

	const [selectModal, setSelectModal] = useState<SelectModalType>({
		mode: 'radio',
		open: false,
	});

	const onSelect = (selectedRecord: IThuoc) => {
		setFieldValue('maThuoc', selectedRecord.code);
		setFieldValue('tenThuoc', selectedRecord.name);
		setThuocSelected({
			...selectedRecord,
			roa: selectedRecord.ROA
		});
	};

	const onOpenModalSelect = () => { 
		if (!maKhoThuoc) {
			toast.warning('Chưa chọn kho thuốc');
			return;
		}
		setSelectModal({
			mode: 'radio',
			open: true,
			value,
		});
	};

	return (
		<>
			<input type="hidden" name="maThuoc" value={values.maThuoc} />
			<TextField
				label="Tên thuốc"
				value={values.tenThuoc}
				name="tenThuoc"
				className="w-100"
				labelClassName="ms-0 min-w-120-px"
				disabled={!maKhoThuoc}
				onClick={onOpenModalSelect}
				onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
					if (e.key === 'Enter') {
						onOpenModalSelect();
					}
				}}
			/>
			<div className="spaces width-4">
				<h6
					className="position-absolute muc-huong cursor-pointer pe-10"
					onClick={onOpenModalSelect}
				>
					<i className="bi bi-search fs-4 text-pri d-block spaces mt-5"></i>
				</h6>
			</div>

			{selectModal.open && (
				<SelectModal
					selectedValue={value}
					columns={COLUMNS}
					onSave={data => onSelect(data as IThuoc)}
					onClose={() => {
						setSelectModal({
							mode: 'radio',
							open: false,
							value: null,
						});
					}}
					maKhoThuoc={maKhoThuoc}
				/>
			)}
		</>
	);
};

export default SelectTableButton;
