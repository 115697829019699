import moment from "moment";
import { templateMauKetQua } from "../components/templates/TemplateMauKQ";
import { IDanhSachDVChuyenKhoa, IEkip } from "./PhanHeChuyenKhoaModel";

export const dataDSDVCdhaChuyenKhoa: IDanhSachDVChuyenKhoa[] = [
    {
        trangThai: 1,
        tenDichVu: "Chụp Xquang hàm chếch một bên",
        soLuong: "1",
        ketLuan: "",
        traKetQua: <>
            <div className="d-flex flex-column">
                <span className="text-nowrap spaces line-height-20">Quản trị hệ thống <br />15:16 08/11/2023</span>
            </div>
        </>,
        mayThucHien: "X-Quang",
    },
    {
        trangThai: 1,
        tenDichVu: "Siêu âm Schuller",
        soLuong: "1",
        ketLuan: "",
        traKetQua: "",
        mayThucHien: "Siêu âm",
    },
    {
        trangThai: 1,
        tenDichVu: "Siêu âm Schuller",
        soLuong: "1",
        ketLuan: "",
        traKetQua: "",
        mayThucHien: "Siêu âm",
    },
    {
        trangThai: 1,
        tenDichVu: "Siêu âm Schuller",
        soLuong: "1",
        ketLuan: "",
        traKetQua: "",
        mayThucHien: "Siêu âm",
    },
    {
        trangThai: 1,
        tenDichVu: "Siêu âm Schuller",
        soLuong: "1",
        ketLuan: "",
        traKetQua: "",
        mayThucHien: "Siêu âm",
    },
];

export const fakeListMauDSKetQuaThucHien = [
    {
        tenMau: "Mẫu chấn thương chỉnh hình",
        noiDungMau: templateMauKetQua
    },
    {
        tenMau: "Mẫu khám răng",
        noiDungMau: "Khám răng"
    },
    {
        tenMau: "Mẫu nội soi",
        noiDungMau: "Nội soi"
    }
]

export const fakeDataEkip: IEkip[] = [
    {
        vaiTro: "Bác sĩ phẫu thuật 1",
        nhanVien: null,
        chiTra: "",
    },
    {
        vaiTro: "Bác sĩ phẫu thuật 2",
        nhanVien: null,
        chiTra: "",
    },
    {
        vaiTro: "Phụ mổ 1",
        nhanVien: null,
        chiTra: "",
    },
    {
        vaiTro: "Phụ mổ 2",
        nhanVien: null,
        chiTra: "",
    },
    {
        vaiTro: "Phụ mổ 3",
        nhanVien: null,
        chiTra: "",
    },
    {
        vaiTro: "Phụ mổ 4",
        nhanVien: null,
        chiTra: "",
    },
    {
        vaiTro: "Phụ mổ 5",
        nhanVien: null,
        chiTra: "",
    },
    {
        vaiTro: "Bác sĩ gây mê",
        nhanVien: null,
        chiTra: "",
    },
    {
        vaiTro: "Phụ mê 1",
        nhanVien: null,
        chiTra: "",
    },
    {
        vaiTro: "Phụ mê 2",
        nhanVien: null,
        chiTra: "",
    },
    {
        vaiTro: "Giúp việc",
        nhanVien: null,
        chiTra: "",
    },
    {
        vaiTro: "Dụng cụ viên",
        nhanVien: null,
        chiTra: "",
    },
    {
        vaiTro: "Kỹ thuật viên",
        nhanVien: null,
        chiTra: "",
    },
];

export const LIST_NHAN_VIEN = [
    {
        code: "NV1",
        name: "Nhân viên 1"
    },
    {
        code: "NV2",
        name: "Nhân viên 2"
    },
    {
        code: "NV3",
        name: "Nhân viên 3"
    },
    {
        code: "NV4",
        name: "Nhân viên 4"
    },
    {
        code: "NV5",
        name: "Nhân viên 5"
    },
]

export const fakeDataBNCDHA = {
        "isKhamBenh": false,
        "isExamined": false,
        "thongTinKhamBenh": {
            "patient": {
                "personName": "Phan Thảo Đan",
                "birthDate": "2001-09-24",
                "gender": "Nữ",
                "countryName": "Việt Nam",
                "code": "BN0329290161",
                "phoneNumber": "0329290161",
                "idNumber": "123456789",
                "ethnicityName": "Kinh",
                "address": "445 Nguyễn Trãi",
                "administrativeUnit": "Phường Quân Thánh, Quận Ba Đình, TP Hà Nội",
                "provinceName": "Thành phố Hà Nội",
                "districtName": "Quận Thanh Xuân",
                "communeName": "Phường Thanh Xuân Nam",
                "occupationName": "Nhân dân",                
                "dobYear": 2001,
            },
            "visit": {
                "visitObjectTypeName": "Chụp X-Quang"
            }
        }
}