import { Formik } from "formik";
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { Button, Row } from "react-bootstrap";
import TextField from "../../component/TextField";
import BangLayMauBenhPham from "../components/tab-lay-mau-benh-pham/BangLayMauBenhPham";
import DsBenhNhan from "../components/tab-lay-mau-benh-pham/DsBenhNhan";
import { PhanHeXetNghiemContext } from "../context/PhanHeXetNghiemContext";
import WfButton from "../../workflows/components/WfButton";
import { WfConstTracker } from "../../workflows/Constant";
import { calculateAge } from "../../utils/FormatUtils";
import { WF_STEP } from "../const/constants";
import { CODE, KEY_DS_DANH_MUC_TIEP_DON } from "../../utils/Constant";
import { getPhieuXetNghemInfo } from "../service/tiepNhan";
import DynamicComponentXN from "../components/DynamicComponentXN";
import { handleConvertAddress } from "../../utils/AppFunction";
import { IDanhSachDanhMuc } from "../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel";
import { getIndexedDBItem } from "../../utils/IndexedDB";
import { getWfWorkflows } from "../../workflows/services/WfWorkflowsService";

type Props = {};
const LayMauBenhPham = (props: Props) => {
  const {
    benhNhanInfo,
    setBenhNhanInfo,
    phieuXetNghiemInfo,
    setPhieuXetNghiemInfo,
  } = useContext(PhanHeXetNghiemContext);
  const { patient, visit } = benhNhanInfo || {};
  const [statusChangedEvent, setStatusChangedEvent] = useState<number>();
  const [dataDynamic, setDataDynamic] = useState<any>();
  const [listICD10, setListICD10] = useState<any>([]);
  const [icdXPrimaryName, setIcdXPrimaryName] = useState<string>("");

  const actionChanged = useCallback(
    (currStatusCode: string | undefined) => {
      setBenhNhanInfo({
        ...benhNhanInfo,
        isPerform: WF_STEP.TEST_START === currStatusCode,
      });
      setStatusChangedEvent(Math.random());
      handleGetPhieuXetNghiem();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [benhNhanInfo, statusChangedEvent]
  );
  const actionClick = (data: any) => {
    setDataDynamic({ ...data });
  };

  const updatePageData = async () => {
    try {
      let { data } = await getWfWorkflows(
        WfConstTracker.TEST,
        benhNhanInfo?.testGroupId
      );
      if (data?.code === CODE.SUCCESS) {
        setBenhNhanInfo({
          ...benhNhanInfo,
          isPerform: WF_STEP.TEST_START === data?.data?.currStatusCode,
        });
      }
    } catch (e) {}
  };

  useEffect(() => {
    if (benhNhanInfo?.testGroupId) updatePageData();
  }, [benhNhanInfo?.testGroupId]);

  const handleGetPhieuXetNghiem = async () => {
    const { data: dataXN } = await getPhieuXetNghemInfo(
      benhNhanInfo?.testGroupId
    );
    if (CODE.SUCCESS === dataXN?.code) {
      const thongTinPhieu = dataXN?.data;
      const dsXetNghiem = thongTinPhieu?.orders?.map((item: any) => ({
        ...item,
        ...item?.term,
      }));
      setPhieuXetNghiemInfo({
        thongTinPhieu,
        dsXetNghiem,
      });
    }
  };

  const handleGetListICD10 = async () => {
    try {
      let res: IDanhSachDanhMuc = await getIndexedDBItem(
        KEY_DS_DANH_MUC_TIEP_DON
      );
      setListICD10(res?.listICD10 || []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetListICD10();
  }, []);

  useEffect(() => {
    let icdXPrimary = listICD10?.find(
      (item: any) =>
        item?.code === phieuXetNghiemInfo?.thongTinPhieu?.icdXPrimary
    );
    setIcdXPrimaryName(icdXPrimary?.name);
  }, [phieuXetNghiemInfo?.thongTinPhieu]);

  return (
    <>
      <div className="reception-list bg-gray ">
        <Row className="phanHeVienPhi h-100">
          <div className="pe-0 dsBenhNhan spaces width-27_4 h-calc-vh-35 bg-white spaces pt-10">
            <div className="flex-1">
              <DsBenhNhan statusChangedEvent={statusChangedEvent} />
            </div>
          </div>
          <div className="pl-0 dsBenhNhan ml-pt-0_6 spaces border width-72 ">
            <div className="grid-6-6">
              <div className="bg-white">
                <h4 className="text-title fw-bold fs-4 spaces mb-0 py-5 px-10">
                  Thông tin bệnh nhân
                </h4>
              </div>
              <div className="bg-white">
                <h4 className="text-title fw-bold fs-4 spaces mb-0 py-5 px-10">
                  Thông tin phiếu
                </h4>
              </div>
            </div>
            <div className="grid-6-6 spaces mb-6">
              <div className="bg-white spaces p-10 ">
                <div className="grid-8-4 spaces gap-16 mb-5">
                  <div className="d-flex spaces">
                    <div className="spaces w-20 min-w-31-percent fw-500 text-title fw-bold fs-4">
                      Barcode:
                    </div>
                    <div className="spaces w-75 color-disable text-truncate text-title fw-bold fs-4">
                      {phieuXetNghiemInfo?.thongTinPhieu?.barcode || "..."}
                    </div>
                  </div>
                  <Button className="btn-fill spaces min-w-100px py-2">
                    In Barcode
                  </Button>
                </div>
                <div className="bg-white">
                  <div className="d-flex spaces mb-5">
                    <div className="spaces w-20 min-w-76 fw-500">
                      Họ và tên:
                    </div>
                    <div className="spaces w-75 color-disable text-truncate">
                      {patient?.personName || "..."}
                    </div>
                  </div>
                  <div className="d-flex spaces mb-5">
                    <div className="spaces w-20 min-w-76 fw-500">
                      Ngày sinh:
                    </div>
                    <div className="spaces w-75 color-disable text-truncate">
                      {patient?.dobYear
                        ? `${patient?.dobYear} - ${calculateAge(
                            patient?.dobDay,
                            patient?.dobMonth,
                            patient?.dobYear
                          )}`
                        : "..."}
                    </div>
                  </div>
                  <div className="d-flex spaces mb-5">
                    <div className="spaces w-20 min-w-76 fw-500">
                      Giới tính:
                    </div>
                    <div className="spaces w-75 color-disable text-truncate">
                      {patient?.gender || "..."}
                    </div>
                  </div>
                  <div className="d-flex grid-2_4-9_6 spaces mb-5">
                    <div className="spaces fw-500">Địa chỉ:</div>
                    <div className="spaces color-disable text-truncate">
                      {(patient?.provinceName || patient?.address)
                        ? handleConvertAddress(patient)
                        : "..."}
                    </div>
                  </div>
                  <div className="d-flex spaces mb-0">
                    <div className="spaces w-20 min-w-76 fw-500">
                      Chẩn đoán:
                    </div>
                    <div className="spaces w-75 color-disable text-truncate">
                      {icdXPrimaryName || "..."}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white spaces p-10">
                <div className="d-flex spaces mb-6">
                  <div className="spaces min-w-125 fw-500">Mã phiếu:</div>
                  <div className="spaces w-100 color-disable text-truncate">
                    {phieuXetNghiemInfo?.thongTinPhieu?.code || "..."}
                  </div>
                </div>
                <div className="d-flex spaces mb-6">
                  <div className="spaces min-w-125 fw-500">Người chỉ định:</div>
                  <div className="spaces w-100 color-disable text-truncate">
                    {`${
                      phieuXetNghiemInfo?.thongTinPhieu?.indicationPerson &&
                      phieuXetNghiemInfo?.thongTinPhieu?.indicationDeptName
                        ? `${phieuXetNghiemInfo?.thongTinPhieu?.indicationPerson} - ${phieuXetNghiemInfo?.thongTinPhieu?.indicationDeptName}`
                        : "..."
                    }`}
                  </div>
                </div>
                <div className="d-flex spaces mb-6">
                  <div className="spaces min-w-125 fw-500">
                    Thời gian chỉ định:
                  </div>
                  <div className="spaces w-100 color-disable text-truncate">
                    {phieuXetNghiemInfo?.thongTinPhieu?.indicationTime
                      ? moment(
                          phieuXetNghiemInfo?.thongTinPhieu?.indicationTime
                        ).format("HH:mm DD/MM/YYYY")
                      : "..."}
                  </div>
                </div>
                <div className="d-flex spaces mb-6">
                  <div className="spaces min-w-125 fw-500">Lấy mẫu:</div>
                  <div className="spaces w-100 color-disable text-truncate">
                    {`${
                      phieuXetNghiemInfo?.thongTinPhieu?.specimenCollector ||
                      "..."
                    } - ${
                      phieuXetNghiemInfo?.thongTinPhieu
                        ?.specimenCollectionStartTime
                        ? moment(
                            phieuXetNghiemInfo?.thongTinPhieu
                              ?.specimenCollectionStartTime
                          ).format("HH:mm DD/MM/YYYY")
                        : "..."
                    } - ${
                      phieuXetNghiemInfo?.thongTinPhieu
                        ?.deptSpecimenCollectionDept || "..."
                    }`}
                  </div>
                </div>
                <div className="d-flex spaces mb-6">
                  <div className="spaces min-w-125 fw-500">Xét nghiệm:</div>
                  <div className="spaces w-100 color-disable text-truncate">
                    {`${
                      phieuXetNghiemInfo?.thongTinPhieu?.testingExecutor ||
                      "..."
                    } - ${
                      phieuXetNghiemInfo?.thongTinPhieu?.testingStartTime
                        ? moment(
                            phieuXetNghiemInfo?.thongTinPhieu?.testingStartTime
                          ).format("HH:mm DD/MM/YYYY")
                        : "..."
                    } - ${
                      phieuXetNghiemInfo?.thongTinPhieu?.fulfillDeptCode ||
                      "..."
                    }`}
                  </div>
                </div>
                <div className="d-flex spaces mb-6">
                  <div className="spaces min-w-125 text-danger fw-500">
                    Kết quả:
                  </div>
                  <div className="spaces w-100 text-danger fw-500 text-truncate">
                    {`${
                      phieuXetNghiemInfo?.thongTinPhieu?.resultReporter || "..."
                    } - ${
                      phieuXetNghiemInfo?.thongTinPhieu?.resultReportingTime
                        ? moment(
                            phieuXetNghiemInfo?.thongTinPhieu
                              ?.resultReportingTime
                          ).format("HH:mm DD/MM/YYYY")
                        : "..."
                    } - ${
                      phieuXetNghiemInfo?.thongTinPhieu
                        ?.deptResultReportingDept || "..."
                    }`}
                  </div>
                </div>
              </div>
            </div>
            <div className="spaces pt-6 bg-white">
              <BangLayMauBenhPham />
            </div>
            <div className="d-flex flex-end spaces px-12 pb-12 pt-5 gap-10 bg-white">
              {benhNhanInfo && (
                <WfButton
                  tracker={WfConstTracker.TEST}
                  objectId={benhNhanInfo?.testGroupId}
                  additionalFunc={actionChanged}
                  handleClick={actionClick}
                ></WfButton>
              )}
              <Button className="btn-fill spaces min-w-100px">
                Phiếu chỉ định
              </Button>
            </div>
          </div>
          {dataDynamic && (
            <DynamicComponentXN data={dataDynamic} setData={setDataDynamic} />
          )}
        </Row>
      </div>
    </>
  );
};

export default LayMauBenhPham;
