import { FunctionComponent } from "react";
import { IBenhNhan } from "../models/DSBenhNhanKhamBenhModels";
import { calculateAge } from "../../utils/FormatUtils";
import { handleConvertAddress } from "../../utils/AppFunction";

interface InfoPatientRightProps {
  benhNhanInfo: IBenhNhan | undefined;
}

const InfoPatientRight: FunctionComponent<InfoPatientRightProps> = ({ benhNhanInfo }) => {
  let { patient, visit } = benhNhanInfo?.thongTinKhamBenh || benhNhanInfo || {};
  
  return (
    <>
      {benhNhanInfo ? (
        <div>
          <div className="text-break fw-500 p-1 text-end">
            <span className="text-uppercase fw-600 fs-7">
              {patient?.personName}&nbsp;|&nbsp;{patient?.code}&nbsp;|&nbsp;{calculateAge(
                patient?.dobDay,
                patient?.dobMonth,
                patient?.dobYear
              )}
              &nbsp;|&nbsp;{patient?.gender}
            </span>
            <div className="text-truncate fs-7">
              {visit?.visitObjectTypeName}&nbsp;|&nbsp;{handleConvertAddress(patient)}
            </div>
          </div>
        </div>
      ) : (
        <div className="info-patient spaces h-90"></div>
      )}
    </>
  );
};

export default InfoPatientRight;
