import moment from "moment";
import { benhNhan, ObjectSelectAutocomplete } from "../../phan-he-tiep-nhan-thanh-toan/models/PhanHeTiepNhanModel";

export interface BenhNhan {
    mpi: string;
    hoTen: string;
    ngaySinh?: number | null;
    thangSinh?: number | null;
    namSinh?: number | null;
    soVaoVien?: string;
    gioiTinh: any;
    soDinhDanh: string;
    tuoi?: string;
    tenantId?: number;
    soDienThoai?: string;
    maDanToc: string;
    maQuocTich: string;
    soNha: string;
    diaChi?: string;
    wardId: string;
    districtId: string;
    provinceId: string;
    professionCode: string;
    province: ObjectSelectAutocomplete | null,
    district: ObjectSelectAutocomplete | null,
    ward: ObjectSelectAutocomplete | null,
    danToc: ObjectSelectAutocomplete | null,
    quocTich: ObjectSelectAutocomplete | null,
    gender: ObjectSelectAutocomplete | null,
    job: ObjectSelectAutocomplete | null
    quanHe: ObjectSelectAutocomplete | null;
    loaiDoiTuong: ObjectSelectAutocomplete | null;
    loaiTiepNhan: ObjectSelectAutocomplete | null;
    tenDichVu: any;
    phongKham?: any;
    avatar?: any;
    caseId?: string;
    id?: string;
    danhSachDichVu?: any,
    email?: string | null,
    isHenKham?: boolean,
    maHBBA?: string;
    nguoiNhap?: string;
    thoiDiemTaiNan: string;
    noiXayRaTaiNan: string;
    thx?: string;
    tinhTaiNan: ObjectSelectAutocomplete | null;
    tinhTaiNanId: string;
    huyenTaiNan: ObjectSelectAutocomplete | null;
    huyenTaiNanId: string;
    xaTaiNan: ObjectSelectAutocomplete | null;
    xaTaiNanId: string;
    thongTinDieuTri?: string;
    tinhTrangThuongTich?: string;
    tinhTrangThuongTichRaVien?: string;
    diaDiemXayRa: ObjectSelectAutocomplete | null;
    boPhanChanThuong: ObjectSelectAutocomplete | null;
    nguyenNhanTaiNan: ObjectSelectAutocomplete | null;
    loaiNgoDoc?: ObjectSelectAutocomplete | null;
    loaiTaiNanGiaoThong?: ObjectSelectAutocomplete | null;
    loaiTaiNanLaoDong?: ObjectSelectAutocomplete | null;
    xuTriTaiNan: ObjectSelectAutocomplete | null;
    dienBienSauTaiNan: ObjectSelectAutocomplete | null;
    muBaoHiem?: ObjectSelectAutocomplete | null;
    suDungChatKichThich?: ObjectSelectAutocomplete | null;
    // test
    noiSinh?: ObjectSelectAutocomplete | null;
    noiSong?: ObjectSelectAutocomplete | null;
    dangKyKham?: ObjectSelectAutocomplete | null;
  }
  
  export interface bangLichSuKham {
    lan: string
    ngayVao: string
    hinhThuc: number
    bhyt: string
    ngaySinh: string
    ngayRa: string
    xuTri: string
    // dichVus: string
    ghiChu?: string
    bacSi: string
    khoa: string
    phong: string
    chuanDoan: string
  }

  export const initialValuesTiepNhan: benhNhan = {
    mpi: "",
    hoTen: "",
    ngaySinh: null,
    namSinh: null,
    thangSinh: null,
    gioiTinh: "",
    soDinhDanh: "",
    soDienThoai: "",
    maDanToc: "",
    maQuocTich: "",
    soNha: "",
    tenantId: 0,
    tuoi: "",
    diaChi: "",
    wardId: "",
    districtId: "",
    provinceId: "",
    professionCode: "",
    benhNhanMqh: {
      hoTen: "",
      maQuanHe: "",
      diaChi: "",
      soDienThoai: "",
    },
    benhNhanBhyt: {
      soBhyt: "",
      noiDangKy: "",
      tuNgay: "",
      denNgay: "",
      mienDongCt: "",
      loaiTuyen: "",
      maKv: "",
      is5nam: false,
      is6thang: false,
    },
    benhNhanCase: {
      loaiDoiTuong: "",
      loaiTiepNhan: "",
      thoiGianTiepNhan: moment().format("DD/MM/YYYY HH:mm:ss"),
      isUuTien: false,
      isCapCuu: false,
      yeuCauKham: [],
    },
    province: null,
    district: null,
    ward: null,
    danToc: null,
    quocTich: null,
    gender: null,
    job: null,
    quanHe: null,
    loaiDoiTuong: null,
    loaiTiepNhan: null,
    tenDichVu: null,
    phongKham: null,
    thoiDiemTaiNan: "",
    noiXayRaTaiNan: "",
    tinhTaiNan: null,
    tinhTaiNanId: "",
    huyenTaiNan: null,
    huyenTaiNanId: "",
    xaTaiNan: null,
    xaTaiNanId: "",
    diaDiemXayRa: null,
    boPhanChanThuong: null,
    nguyenNhanTaiNan: null,
    xuTriTaiNan: null,
    dienBienSauTaiNan: null,
    obs: {
      mach: {
        code: "",
        datatype: "",
        value: "",
        datetime: "",
      },
      nhietDo: {
        code: "",
        datatype: "",
        value: "",
        datetime: "",
      },
      huyetApTren: {
        code: "",
        datatype: "",
        value: "",
        datetime: "",
      },
      huyetApDuoi: {
        code: "",
        datatype: "",
        value: "",
        datetime: "",
      },
      nhipTho: {
        code: "",
        datatype: "",
        value: "",
        datetime: "",
      },
      canNang: {
        code: "",
        datatype: "",
        value: "",
        datetime: "",
      },
      chieuCao: {
        code: "",
        datatype: "",
        value: "",
        datetime: "",
      },
      SPO2: {
        code: "",
        datatype: "",
        value: "",
        datetime: "",
      },
      BMI: {
        code: "",
        datatype: "",
        value: "",
        datetime: "",
      },
    },
    sangLocCovid: false,
    uuTien: false,
    thongTinNguoiNha1: {
      hoVaTen: "",
      ngaySinh: "",
      thangSinh: "",
      namSinh: "",
      ngheNghiep: "",
      nguoiNha: null,
      trinhDoVanHoa: "",
      cccd: "",
      sdt: "",
      diaChi: "",
    },
    thongTinNguoiNha2: {
      hoVaTen: "",
      ngaySinh: "",
      thangSinh: "",
      namSinh: "",
      ngheNghiep: "",
      nguoiNha: null,
      trinhDoVanHoa: "",
      cccd: "",
      sdt: "",
      diaChi: "",
    },
    thongTinNguoiGiamHo: {
      hoVaTen: "",
      ngaySinh: "",
      thangSinh: "",
      namSinh: "",
      ngheNghiep: "",
      nguoiNha: null,
      trinhDoVanHoa: "",
      cccd: "",
      sdt: "",
      diaChi: "",
    },
    BHYT: {
      soThe1: "",
      soThe2: "",
      soThe3: "",
      soThe4: "",
      KCBBD: "",
      hanThe: {
        ngayStart: "",
        thangStart: "",
        namStart: "",
        ngayEnd: "",
        thangEnd: "",
        namEnd: "",
      },
      noiSong:null,
      tuyenKCB:null,
      chungNhanKhongCungChiTra: false,
      TECoMaBHXH: false,
      noTheBHYT: false,
      khongGiuTheBHYT: false,
    }
  };