import moment from "moment";
import { DanhSachSoThu, IDsNhanVien } from "../models/SoThuModel";
import { NoiTruCapCuuInterface } from "../models/NoiTruCapCuuModel";
import { bangLichSuKham } from "../models/PhanHeTiepNhanModel";
import { IColumnTree, ITreeItem } from "../../component/tree-explorer/TreeExplorerModel";
import { convertNumberPrice } from "../../utils/FormatUtils";
import { TimKiemBenhNhanModel } from "../../component/modal-tim-kiem-benh-nhan/ModelsTimKiemBenhNhan";
import { gioiTinh } from "../../phan-he-kham-benh/constants/BenhAnNgoaiTruConst";

export const TreeSoThu = {
  code: "all",
  name: "Tất cả",
  filter: [
    {
      code: "1",
      name: "Loại sổ thu",
      filter: [{ code: "1.1", name: "Tổng hợp", quantity: 2 }],
    },
    {
      code: "2",
      name: "Trạng thái sổ thu",
      filter: [
        { code: "2.1", name: "Đang khóa", quantity: 1 },
        { code: "2.2", name: "Đang mở", quantity: 1 },
      ],
    },
    {
      code: "3",
      name: "Loại sổ thu",
      filter: [
        { code: "3.1", name: "Thu ngân 1", quantity: 1 },
        { code: "3.2", name: "Thu ngân 2", quantity: 2 },
      ],
    },
  ],
};

export const danhSachSoThu: DanhSachSoThu[] = [
  {
    maSoThu: "S01",
    tenSoThu: "Sổ 1",
    ngayTao: moment().format("DD/MM/YYYY"),
    nguoiTao: "admin",
    daSuDung: 20,
    soBatDau:1,
    tongSoPhieu:20,
    vat: 0,
    ghiChu: "",
  },
  {
    maSoThu: "S02",
    tenSoThu: "Sổ 2",
    ngayTao: moment().format("DD/MM/YYYY"),
    nguoiTao: "admin2",
    daSuDung: 10,
    soBatDau:1,
    tongSoPhieu:20,
    vat: 0,
    ghiChu: "",
  },
];

export const NoiTruCapCuuFakeData: NoiTruCapCuuInterface[] = [
  {
    maBN: 'BN22020001',
    tenBN: "Nguyễn Vũ Thanh Tùng",
    CCCD: '9089325345',
    SDT: '09944386456',
    diaChi: "Mễ Trì Hạ, Nam Từ Liêm, Hà Nội",
    yeuCauKham: 'Khám mắt',
    phongKham: 'Phòng khám số 2',
    ngayHen: '05/06/2023',
    trangThai: 'Chờ tiếp nhận'
  },
  {
    maBN: 'BN22020001',
    tenBN: "Nguyễn Vũ Thanh Tùng",
    CCCD: '9089325345',
    SDT: '09944386456',
    diaChi: "Mễ Trì Hạ, Nam Từ Liêm, Hà Nội",
    yeuCauKham: 'Khám mắt',
    phongKham: 'Phòng khám số 2',
    ngayHen: '05/06/2023',
    trangThai: 'Chờ tiếp nhận'
  },
  {
    maBN: 'BN22020001',
    tenBN: "Nguyễn Vũ Thanh Tùng",
    CCCD: '9089325345',
    SDT: '09944386456',
    diaChi: "Mễ Trì Hạ, Nam Từ Liêm, Hà Nội",
    yeuCauKham: 'Khám mắt',
    phongKham: 'Phòng khám số 2',
    ngayHen: '05/06/2023',
    trangThai: 'Chờ tiếp nhận'
  },
]

export const Tree = {
  name: "Tất cả bệnh nhân",
  code: "all",
  filter: [
    {
      name: "Độ tuổi",
      code: "Age",
      filter: [{ name: "Trẻ em dưới 6 tuổi", code: "nhoHonSau", quantity: 4 }],
    },
    {
      name: "Giới tính",
      code: "gioiTinh",
      filter: [
        { name: "Nam", code: "nam", quantity: 12 },
        { name: "Nữ", code: "nu", quantity: 3 },
      ],
    },
    {
      name: "Đối tượng",
      code: "doiTuong",
      filter: [{ name: "Thu phí", code: "thuPhi", quantity: 4 }],
    },
    {
      name: "Trạng thái",
      code: "trangThai",
      filter: [
        { name: "BN chờ khám", code: "choKham", quantity: 4 },
        { name: "BN đang khám", code: "dangKham", quantity: 5 },
      ],
    },
    {
        name: "Phòng khám",
        code: "phongKham",
        filter: [
          { name: "P101 - Cấp cứu", code: "p101", quantity: 2 },
          { name: "P102 - Khám nội BHYT", code: "p102", quantity: 3 },
        ],
      },
  ],
};

export const columnChinhSuaThoiGian: IColumnTree[] = [
  {
    title: "Nội dung",
    field: "noiDung",
    className: "min-w-200px",
  },
  {
    title: "Bắt đầu",
    field: "batDau",
    className: "min-w-75px",
  },
  {
    title: "Kết thúc",
    field: "ketThuc",
    className: "min-w-75px",
  },
  {
    title: "TG Y Lệnh",
    field: "tgYLenh",
    className: "min-w-75px",
  },
  {
    title: "Khoa",
    field: "khoa",
    className: "min-w-150px",
  },
  {
    title: "Phòng",
    field: "phong",
    className: "min-w-150px",
  },
]

export const directoryTree: ITreeItem[] = [
  {
    noiDung: 'Đón tiếp',
    batDau: '16:20 30/01/2023',
    ketThuc: '16:20 30/01/2024',
    tgYLenh: '16:20 30/01/2024',
    khoa: 'khoa 1',
    phong: 'phong 1',
    children: [
      {
        noiDung: 'Khám bệnh',
        batDau: '17:20 30/01/2023',
        ketThuc: '18:20 30/01/2023',
        tgYLenh: '17:20 30/01/2023',
        khoa: 'khoa 2',
        phong: 'phong 2',
        children: [
          {
            noiDung: 'Phiếu khám bệnh',
            batDau: '17:20 30/01/2023',
            ketThuc: '18:20 30/01/2023',
            tgYLenh: '17:20 30/01/2023',
            khoa: 'khoa 2',
            phong: 'phong 2',
            children: [
              {
                noiDung: 'Ngoại khám',
                batDau: '17:20 30/01/2023',
                ketThuc: '18:20 30/01/2023',
                tgYLenh: '17:20 30/01/2023',
                khoa: 'khoa 2',
                phong: 'phong 2',
              },
            ],
          },
        ],
      },
    ],
  },
];

export const LOAI_PHIEU =[
  {
    code: "TT",
    name: "Thu tiền"
  },
  {
    code: "HT",
    name: "Hoàn tiền"
  },
  {
    code: "TU",
    name: "Tạm ứng"
  },
  {
    code: "HU",
    name: "Hoàn ứng"
  },
  {
    code: "TT0D",
    name: "Thu tiền 0 đồng"
  },
]

export const dataDSBenhNhan = [
  {
    hoTen: "Nguyễn Trần Trung Quân",
    age: 25,
    barCode: "1042",
    maBenhPham: "XN2321152",
    trangThai: 5,
    gioiTinh: "Nam",
    loaiDoiTuong: 0,
    loaiTiepNhan: 1,
    maBn: "BN20023",
    cccd: "00323335967",
    uuTien: 1,
    diaChi: "29 Cát Linh, P. Giảng Võ, Q.Ba Đình, Hà Nội",
    dsDichVu: [
      {
        tenDichVu: 'Khám bệnh',
        items: [
          {
            tenDichVu: 'Khám Bóng',
            donVi: 'Lần',
            doiTuong: 'Dịch vụ',
            sl: '1',
            donGia: 84500,
            tt: '100%',
            thanhTien: 84500,
            bhytTra: 84500,
            bh: '100%',
            bnTra: 0,
            bncct: 0,
            bntt: 0,
            daThu: 0,
            daThuCtt: 0,
            daThuBntt: 0,
            yeuCauThu: 0,
          },
        ],
      },
      {
        tenDichVu: 'Xét nghiệm',
        items: [
          {
            tenDichVu: 'Beta HCG',
            donVi: 'Lần',
            doiTuong: 'Dịch vụ',
            sl: '1',
            donGia: 100000,
            tt: '100%',
            thanhTien: 100000,
            mienGiam: 10000,
            bhytTra: 0,
            bh: '100%',
            bnTra: 90000,
            bncct: 0,
            bntt: 0,
            daThu: 0,
            daThuCtt: 0,
            daThuBntt: 0,
            yeuCauThu: 0,
          },
        ],
      },
    ],
  },
  {
    hoTen: "Nguyễn Trần Trung Bình",
    age: 25,
    barCode: "1042",
    maBenhPham: "XN2321152",
    trangThai: 4,
    gioiTinh: "Nam",
    loaiDoiTuong: 3,
    loaiTiepNhan: 1,
    maBn: "BN20023",
    cccd: "00323335967",
    uuTien: 1,
    diaChi: "29 Cát Linh, P. Giảng Võ, Q.Ba Đình, Hà Nội",
    dsDichVu: [
      {
        tenDichVu: 'Khám bệnh',
        items: [
          {
            tenDichVu: 'Khám Bóng',
            donVi: 'Lần',
            doiTuong: 'Dịch vụ',
            sl: '1',
            donGia: 84500,
            tt: '100%',
            thanhTien: 84500,
            bh: '100%',
            bnTra: 84500,
            bncct: 0, 
            bntt: 0, 
            daThu: 0, 
            daThuCtt: 0, 
            daThuBntt: 0, 
            yeuCauThu: 0, 
          },
        ],
      },
      {
        tenDichVu: 'Xét nghiệm',
        items: [
          {
            tenDichVu: 'Beta HCG',
            donVi: 'Lần',
            doiTuong: 'Dịch vụ',
            sl: '1',
            donGia: 100000,
            tt: '100%',
            thanhTien: 100000,
            bh: '100%',
            bnTra: 100000,
            bncct: 0,
            bntt: 0,
            daThu: 0,
            daThuCtt: 0,
            daThuBntt: 0,
            yeuCauThu: 0,
          },
        ],
      },
    ],
  },
  {
    hoTen: "Nguyễn Trần Trung Quốc",
    age: 25,
    barCode: "1042",
    maBenhPham: "XN2321152",
    trangThai: 1,
    gioiTinh: "Nam",
    loaiDoiTuong: 1,
    loaiTiepNhan: 1,
    maBn: "BN20023",
    cccd: "00323335967",
    uuTien: 1,
    diaChi: "29 Cát Linh, P. Giảng Võ, Q.Ba Đình, Hà Nội",
    dsDichVu: [
      {
        tenDichVu: 'Khám bệnh',
        items: [
          {
            tenDichVu: 'Khám Bóng',
            donVi: 'Lần',
            doiTuong: 'Dịch vụ',
            sl: '1',
            donGia: 84500,
            tt: '100%',
            thanhTien: 84500,
            bh: '100%',
            bnTra: 84500,
            bncct: 0,
            bntt: 0,
            daThu: 0,
            daThuCtt: 0,
            daThuBntt: 0,
            yeuCauThu: 0,
          },
        ],
      },
      {
        tenDichVu: 'Xét nghiệm',
        items: [
          {
            tenDichVu: 'Beta HCG',
            donVi: 'Lần',
            doiTuong: 'Dịch vụ',
            sl: '1',
            donGia: 100000,
            tt: '100%',
            thanhTien: 100000,
            bh: '100%',
            bnTra: 100000,
            bncct: 0,
            bntt: 0,
            daThu: 0,
            daThuCtt: 0,
            daThuBntt: 0,
            yeuCauThu: 0,
          },
        ],
      },
    ],
  },
]

export const LIST_HINH_THUC = [
  {
    code: "TM",
    name: "Tiền mặt"
  },
  {
    code: "CK",
    name: "Chuyển khoản"
  },
]

export const LIST_NGUOI_NHA = [
  {
    code: "BO",
    name: "Bố"
  },
  {
    code: "ME",
    name: "Mẹ"
  },
  {
    code: "ONG",
    name: "Ông"
  },
  {
    code: "BA",
    name: "Bà"
  },
  {
    code: "ANHTRAI",
    name: "Anh trai"
  },
  {
    code: "EMTRAI",
    name: "Em trai"
  },
  {
    code: "CHIGAI",
    name: "Chị gái"
  },
  {
    code: "EMGAI",
    name: "Em gái"
  },
]

export const fakeDataDsNhanVien: IDsNhanVien[] = [
  {
    maNV: "admin",
    tenNV: "Nguyễn Văn B",
    khoa: "Phòng kế hoạch tổng hợp",
    module: "Viện phí, chuẩn đoán hình ảnh, lấy mẫu",
    items: [
      {
        maNV: "1",
        tenNV: "Nguyễn Văn D",
        khoa: "Phòng kế hoạch tổng hợp",
        module: "Viện phí, chuẩn đoán hình ảnh, lấy mẫu"
      },
      {
        maNV: "2",
        tenNV: "Nguyễn Văn G",
        khoa: "Phòng kế hoạch tổng hợp",
        module: "Viện phí, chuẩn đoán hình ảnh, lấy mẫu"
      },
      {
        maNV: "3",
        tenNV: "Nguyễn Văn F",
        khoa: "Phòng kế hoạch tổng hợp",
        module: "Viện phí, chuẩn đoán hình ảnh, lấy mẫu"
      },
    ],
  },
  {
    maNV: "Nhân viên",
    tenNV: "Nguyễn Văn A",
    khoa: "Phòng kế hoạch tổng hợp",
    module: "Viện phí, chuẩn đoán hình ảnh, lấy mẫu",
    items: [
      {
        maNV: "1",
        tenNV: "Nguyễn Văn D",
        khoa: "Phòng kế hoạch tổng hợp",
        module: "Viện phí, chuẩn đoán hình ảnh, lấy mẫu"
      },
      {
        maNV: "2",
        tenNV: "Nguyễn Văn D",
        khoa: "Phòng kế hoạch tổng hợp",
        module: "Viện phí, chuẩn đoán hình ảnh, lấy mẫu"
      },
    ]
  },
  {
    maNV: "admin2",
    tenNV: "Nguyễn Văn C",
    khoa: "Phòng kế hoạch tổng hợp",
    module: "Viện phí, chuẩn đoán hình ảnh, lấy mẫu"
  },
]

export const fakeDataDsPhieu = [
  {
    tenSoThu: "Sổ thu 1",
    soPhieu: "12345678",
    loaiPhieu: "Tạm ứng",
    ngayTao: "30/01/2023",
    nguoiTao: "Nguyễn Văn A",
    soTien: "1.000.000.000",
    ghiChu: "Ghi chú 1",
  }
]

export const fakeDataDSBenhNhan = [
  {
    maBN: "12345678",
    tenBN: "Nguyễn Văn A",
    soBHYT: "HD1234567890111",
    gioiTinh: "Nam",
    tuoi: "20",
    khoaDieuTri: "Khoa Sản",
    phongDieuTri: "Điều trị khoa sản",
    giuong: "Giường T001",
    bacSiDieuTri: "Nguyễn Thị B",
    batDauBHYT: "01/01/2024",
    ketThucBHYT: "31/12/2024",
  }
]

export const listPhongKham = [
  {
    ten: "Phòng khám 1",
    dangKy: "2",
    cho: "1",
    kham: "1",
  },
  {
    ten: "Phòng khám 2",
    dangKy: "2",
    cho: "1",
    kham: "1",
  },
  {
    ten: "Phòng khám 3",
    dangKy: "2",
    cho: "1",
    kham: "1",
  },
  {
    ten: "Phòng khám 4",
    dangKy: "2",
    cho: "1",
    kham: "1",
  },
  {
    ten: "Phòng khám 5",
    dangKy: "2",
    cho: "1",
    kham: "1",
  },
  {
    ten: "Phòng khám 6",
    dangKy: "2",
    cho: "1",
    kham: "1",
  },
  {
    ten: "Phòng khám 7",
    dangKy: "2",
    cho: "1",
    kham: "1",
  },
  {
    ten: "Phòng khám 8",
    dangKy: "2",
    cho: "1",
    kham: "1",
  },
  {
    ten: "Phòng khám 9",
    dangKy: "2",
    cho: "1",
    kham: "1",
  },
  {
    ten: "Phòng khám 10",
    dangKy: "2",
    cho: "1",
    kham: "1",
  },
  {
    ten: "Phòng khám 11",
    dangKy: "2",
    cho: "1",
    kham: "1",
  },
  {
    ten: "Phòng khám 12",
    dangKy: "2",
    cho: "1",
    kham: "1",
  },
  {
    ten: "Phòng khám 13",
    dangKy: "2",
    cho: "1",
    kham: "1",
  },
  {
    ten: "Phòng khám 14",
    dangKy: "2",
    cho: "1",
    kham: "1",
  },
  {
    ten: "Phòng khám 15",
    dangKy: "2",
    cho: "1",
    kham: "1",
  },
  {
    ten: "Phòng khám 16",
    dangKy: "2",
    cho: "1",
    kham: "1",
  },
]

export const dataPTTT = [
  { ma: "", ten: "Không phân loại" }, 
  { ma: "PDB", ten: "Phẫu thuật loại đặc biệt" }, 
  { ma: "P1", ten: "Phẫu thuật loại 1" }
]

export const locDuLieuData = [
  { ten: "Lọc đối tượng dịch vụ", duLieu: "" }, 
  { ten: "Lọc khoa", duLieu: "" }, 
]

export const DICH_VU = [
  {
    tenDichVu: 'Khám bệnh',
    items: [
      {
        tenDichVu: 'Khám Bóng',
        donVi: 'Lần',
        doiTuong: 'Dịch vụ',
        sl: '1',
        donGia: 84500,
        tt: '100%',
        thanhTien: 84500,
        bhytTra: 84500,
        bh: '100%',
        bnTra: 0,
        bncct: 0,
        bntt: 0,
        daThu: 0,
        daThuCtt: 0,
        daThuBntt: 0,
        yeuCauThu: 0,
      },
    ],
  },
  {
    tenDichVu: 'Xét nghiệm',
    items: [
      {
        tenDichVu: 'Beta HCG',
        donVi: 'Lần',
        doiTuong: 'Dịch vụ',
        sl: '1',
        donGia: 100000,
        tt: '100%',
        thanhTien: 100000,
        mienGiam: 10000,
        bhytTra: 0,
        bh: '100%',
        bnTra: 90000,
        bncct: 0,
        bntt: 0,
        daThu: 0,
        daThuCtt: 0,
        daThuBntt: 0,
        yeuCauThu: 0,
      },
    ],
  },
]