import { FC, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { ThanhToanHDContext } from "../ThanhToanHD";
import { formatDateToDDMMYYYY } from "../../../../utils/FormatUtils";

const ThongTinHD: FC<any> = (props) => {
  const { HDSelected } = useContext(ThanhToanHDContext);

  return (
    <div className="bg-white spaces px-10 pb-10 pt-6">
      <div className="line-bottom">
        <h4 className="text-title fs-4 fw-bold spaces mb-2">{`Thanh toán ${
          HDSelected?.contractName ? `| ${HDSelected?.contractName}` : ""
        }`}</h4>
      </div>
      <Row>
        <Col xs="4" className="spaces py-8 d-flex">
          <span className="fw-bold min-w-80px">Công ty: </span>
          <span className="text-ellipsis">{HDSelected?.companyName}</span>
        </Col>
        <Col xs="4" className="spaces py-8 d-flex">
          <span className="fw-bold min-w-80px">Mã số thuế: </span>
          <span className="text-ellipsis">{HDSelected?.taxNumber}</span>
        </Col>
        <Col xs="4" className="spaces py-8 d-flex">
          <span className="fw-bold min-w-80px">Địa chỉ: </span>
          <span className="text-ellipsis">{HDSelected?.address}</span>
        </Col>
        <Col xs="4" className="d-flex">
          <span className="fw-bold min-w-80px">Đại diện: </span>
          <span className="text-ellipsis">{HDSelected?.referenceName}</span>
        </Col>
        <Col xs="4" className="d-flex">
          <span className="fw-bold min-w-80px">Email: </span>
          <span className="text-ellipsis">{HDSelected?.referenceEmail}</span>
        </Col>
        <Col xs="4" className="d-flex">
          <span className="fw-bold min-w-80px">Thời gian: </span>
          <span className="text-ellipsis">
            {formatDateToDDMMYYYY(HDSelected?.startDate)}
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default ThongTinHD;
