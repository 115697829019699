import { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import InputSearch from "../../../component/InputSearch";
import SimpleTable from "../../../component/table/simple-table/SimpleTable";
import { getICD10Columns } from "./Column";
import { handleSearchByFuse } from "../../../utils/AppFunction";
interface Props {
  data: any[];
  handleClose: () => void;
  setMauSelected?: (data: any) => void;
  selectionMode?: "checkbox" | "radio";
  dataChecked?: any;
}

const ModalICD10: FC<Props> = ({
  data,
  handleClose,
  setMauSelected,
  selectionMode = "radio",
  dataChecked,
}) => {
  const [selectedRow, setSelectedRow] = useState<any[]>([]);
  const [dataDanhSachMau, setDataDanhSachMau] = useState<any[]>([]);
  const [textSearch, setTextSearch] = useState<string>("");
  const [listICD10, setListICD10] = useState<any>([]);

  const updatePageData = () => {
    try {
      let newData: any[] = JSON.parse(JSON.stringify(data));
      setDataDanhSachMau(newData || []);
      setListICD10(newData || []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    updatePageData();
  }, []);

  const handleRenderCell = (columnData: any, dataKey: string, rowData: any) => {
    if (typeof rowData.get === "function") {
      return rowData.get(dataKey);
    } else {
      return rowData[dataKey];
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): any => {
    const value = (event.target as HTMLInputElement).value;
    setTextSearch(value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    handleSearchByFuse(event, listICD10, textSearch, setDataDanhSachMau);
  };

  return (
    <>
      <Modal
        show
        centered
        size="xl"
        className="dialog-background"
        onHide={() => {
          handleClose();
        }}
      >
        <Modal.Header closeButton className="modal-header">
          <Modal.Title className="text-transform-none">Bệnh ICD10</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="spaces py-5">
            <InputSearch
              placeholder="Tìm kiếm"
              handleChange={handleChange}
              handleKeyDown={handleKeyDown}
              className="spaces h-25 pr-4 radius-3"
            />
          </div>
          <SimpleTable
            data={dataDanhSachMau || []}
            columns={getICD10Columns(selectionMode)}
            height={450}
            width={1092}
            scrollable={true}
            // type={SELECTION_MODE[selectionMode]}
            setDataChecked={setSelectedRow}
            dataChecked={dataChecked}
            handleRenderCell={handleRenderCell}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-fill spaces W-80"
            onClick={() => {
              setMauSelected?.(
                selectionMode === "radio" ? selectedRow[0] || null : selectedRow
              );
              handleClose();
            }}
          >
            Lưu
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ModalICD10;
