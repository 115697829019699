import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

type KeVatTuContextProps = {
	treeNodeValue: any[];
	setTreeNodeValue: Dispatch<SetStateAction<object[]>>;
	vatTuChecked: any[];
	setVatTuChecked: Dispatch<SetStateAction<any[]>>;
	treeValue: any;
	setTreeValue: Dispatch<SetStateAction<any>>;
};

const initialContextValue: KeVatTuContextProps = {
	vatTuChecked: [],
	setVatTuChecked() {},
	treeNodeValue: [],
	setTreeNodeValue() {},
	treeValue: {},
	setTreeValue: () => {}
};

const KeVatTuContext = createContext<KeVatTuContextProps>(initialContextValue);

export const KeVatTuContextProvider = ({ children }: { children: ReactNode }) => {
	const [vatTuChecked, setVatTuChecked] = useState<any[]>([]);
	const [treeValue, setTreeValue] = useState<any>();
	const [treeNodeValue, setTreeNodeValue] = useState<object[]>([]);

	return (
		<KeVatTuContext.Provider
			value={{
				vatTuChecked,
				setVatTuChecked,
				treeNodeValue,
				setTreeNodeValue,
				treeValue,
				setTreeValue
			}}
		>
			{children}
		</KeVatTuContext.Provider>
	);
};

export const useKeVatTuContext = () => useContext<KeVatTuContextProps>(KeVatTuContext);
