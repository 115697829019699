import { FC, useContext, useEffect, useState } from "react";
import { Button, Col, InputGroup, Modal, Row, Stack } from "react-bootstrap";
import { AppContext } from "../../../appContext/AppContext";
import { searchByPage } from "../../services/TiepNhanServices";
import { DEFAULT_PAGE_INDEX, KEY, overflowHiddenTable, textSystem, TYPE } from "../../../utils/Constant";
import TextValidator from "../../../component/TextValidator";
import SelectTree from "../../../component/SelectTree";
import { IconMenu } from "../../../component/IconSvg";
import { toast } from "react-toastify";
import LabelRequired from "../../../component/LabelRequired";
import { OctDatePickerCustom, OctTable } from "@oceantech/oceantech-ui";
import moment from "moment";
import { handleConvertAddress } from "../../../utils/AppFunction";

type Props = {
  show: boolean;
  onHide: () => void;
  handleGetThongTinBenhNhan: (id: string) => void;
};

const dsBenhNhanColumns = [
  {
    name: "STT",
    field: "",
    cellStyle: {
      minWidth: "50px",
      textAlign: "center",
      ...textSystem
    },
    render: (row: any, index: number, stt: number) => <span>{stt}</span>
  },
  {
    name: "Thời gian đón tiếp",
    field: "",
    cellStyle: {
      minWidth: "150px",
      textAlign: "center",
      ...textSystem
    },
    render: (rowData: any) =>
      rowData?.encounterDatetime
        ? moment(rowData?.encounterDatetime || "").format("DD/MM/YYYY | HH:mm")
        : ""
  },
  {
    name: "Mã BN",
    field: "code",
    cellStyle: {
      minWidth: "100px",
      textAlign: "center",
      ...textSystem
    }
  },
  {
    name: "Họ và tên",
    field: "personName",
    cellStyle: {
      minWidth: "200px",
      ...textSystem
    }
  },
  {
    name: "SĐT",
    field: "phoneNumber",
    cellStyle: {
      ...overflowHiddenTable,
      minWidth: "100px",
      textAlign: "center",
      ...textSystem
    }
  },
  {
    name: "Giới tính",
    field: "gender",
    cellStyle: {
      minWidth: "120px",
      textAlign: "center",
      ...textSystem
    }
  },
  {
    name: "Đối tượng",
    field: "visitObjectTypeName",
    cellStyle: {
      ...overflowHiddenTable,
      minWidth: "100px",
      ...textSystem
    }
  },
  {
    name: "BHYT",
    field: "insCode",
    cellStyle: {
      minWidth: "150px",
      textAlign: "center",
      ...textSystem
    },
    render: (rowData: any) =>
      rowData?.insCode ? rowData?.insCode.split(",") : ""
  },
  {
    name: "Lý do khám",
    field: "visitReasonName",
    cellStyle: {
      ...overflowHiddenTable,
      minWidth: "100px",
      maxWidth: "200px",
      ...textSystem
    }
  },
  {
    name: "ĐK khám",
    field: "visitTypeName",
    cellStyle: {
      ...overflowHiddenTable,
      maxWidth: "200px",
      ...textSystem
    }
  },
  {
    name: "Dịch vụ",
    field: "termSvcName",
    cellStyle: {
      ...overflowHiddenTable,
      maxWidth: "230px",
      ...textSystem
    }
  },
  {
    name: "Phòng khám",
    field: "deptName",
    cellStyle: {
      maxWidth: "200px",
      ...overflowHiddenTable,
      ...textSystem
    }
  },
  {
    name: "Địa chỉ",
    field: "administrativeUnit",
    cellStyle: {
      maxWidth: "300px",
      ...overflowHiddenTable,
      ...textSystem
    },
    render: (rowData: any) =>
      handleConvertAddress(rowData)
  }
];

const treePhongDonTiep = {
  code: "all",
  name: "Tất cả bệnh nhân",
  icon: <IconMenu />,
  filter: []
};
const DSBenhNhan: FC<Props> = (props) => {
  const { show, onHide, handleGetThongTinBenhNhan } = props;
  const { setIsLoading } = useContext(AppContext);
  const [dsBenhNhan, setDsBenhNhan] = useState<any>([]);
  const [configTable, setConfigTable] = useState<any>({
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0
  });
  let [objectSearch, setObjectSearch] = useState<any>({
    pageIndex: DEFAULT_PAGE_INDEX,
    pageSize: 15,
  });
  const [keyword, setKeyword] = useState<string>("");
  const [fromDateSearch, setFromDateSearch] = useState<any>();
  const [toDateSearch, setToDateSearch] = useState<any>();

  //
  const [codeCollapses, setCodeCollapses] = useState<string[]>([]);
  const [idSelected, setIdSelected] = useState<string>("");
  const [treeData, setTreeData] = useState<any>(treePhongDonTiep);
  const [selectedPatient, setSelectedBenhNhan] = useState<any>([]);
  const [itemSelectedTree, setItemSelectedTree] = useState<any>(null)
  
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === KEY.ENTER) {
      updatePageData();
    }
  };

  const updatePageData = async () => {
    try {
      setIsLoading(true);
      let { data: { data } } = await searchByPage({
        pageIndex: objectSearch.pageIndex,
        pageSize: objectSearch.pageSize,
        fromDate: fromDateSearch ? fromDateSearch : null,
        toDate: toDateSearch ? toDateSearch : null,
        keyword,
        filterBy: itemSelectedTree?.parrentCode,
        value: itemSelectedTree?.name
      });
      const newArr = data?.admissions?.content.map((item: any) => {
        return {
          ...item,
          id: item?.encounterId
        }
      })
      setDsBenhNhan(newArr);
      setTreeData({
        code: "all",
        name: "Tất cả bệnh nhân",
        icon: <IconMenu />,
        filter: data?.treeList,
      });
      setConfigTable({
        totalPages: data?.admissions?.totalPages,
        totalElements: data?.admissions?.totalElements,
        numberOfElements: data?.admissions?.numberOfElements
      });
    } catch (error) {
      console.error("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updatePageData();
  }, [objectSearch.pageIndex, objectSearch.pageSize, fromDateSearch, toDateSearch, itemSelectedTree]);

  const handleSelectBenhNhan = (data: any) => {
    onHide();
    handleGetThongTinBenhNhan(data?.encounterId);
  };

  const handleAdmission = () => {
    if (selectedPatient.length > 0) {
      onHide();
      handleGetThongTinBenhNhan(selectedPatient?.[0]?.encounterId);
    } else {
      toast.warning("Vui lòng chọn bệnh nhân tiếp đón");
    }
  };

  const getSelectedItem = (item: any) => {
    setItemSelectedTree(item)
  }

  return (
    <div>
      <Modal
        centered
        show={show}
        onHide={onHide}
        size="xl"
        fullscreen
        className="page-full spaces h-calc-vh-50"
      >
        <Modal.Header closeButton className="header-modal min-h-50px me-2">
          <Modal.Title>
            <div className="text-transform-none fs-3">Danh sách bệnh nhân</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="spaces p-0 bg-white">
          <Row className="d-flex spaces m-0 h-100">
            <Col xs="2" className="spaces p-0">
              <SelectTree
                className="w-100 h-1000 border border-0 overflow-auto"
                codeCollapses={codeCollapses}
                handleChangeCollapsesCode={setCodeCollapses}
                idSelected={idSelected}
                handleChangeSelectId={setIdSelected}
                selectTree={treeData}
                getSelectedItem={getSelectedItem}
              />
            </Col>
            <Col xs="10" className="spaces p-10">
              <div className="d-flex justify-content-end">
                <Stack
                  direction="horizontal"
                  gap={3}
                  className="justify-content-end"
                >
                  <div className="d-flex align-items-center spaces h-32 mb-3">
                    <LabelRequired
                      className="min-w-60px fw-bold"
                      label="Từ ngày"
                    />
                    <OctDatePickerCustom
                      value={fromDateSearch}
                      placeholderText="dd/mm/yyyy"
                      dateFormatOutput="YYYY-MM-DD"
                      setDateValue={setFromDateSearch}
                    />
                  </div>
                  <div className="d-flex align-items-center spaces h-32 mb-3">
                    <LabelRequired
                      className="min-w-65px fw-bold"
                      label="Đến ngày"
                    />
                    <OctDatePickerCustom
                      value={toDateSearch}
                      placeholderText="dd/mm/yyyy"
                      dateFormatOutput="YYYY-MM-DD"
                      setDateValue={setToDateSearch}
                    />
                  </div>
                  <div className="spaces w-200">
                    <InputGroup className="spaces h-32">
                      <TextValidator
                        className="spaces h-29 w-100"
                        name="keyword"
                        placeholder="Tìm kiếm"
                        value={keyword}
                        onChange={(e: any) => {
                          setKeyword(e?.target?.value);
                        }}
                        onKeyDown={handleKeyPress}
                      />
                      <Button className="btn-fill spaces h-29">
                        <span>Tìm kiếm</span>
                        <i className="bi bi-caret-down-fill ms-2 fs-7 " />
                      </Button>
                    </InputGroup>
                  </div>
                </Stack>
              </div>
              <div className="spaces h-calc-vh-300">
                <OctTable
                  id="listPatients"
                  className="h-100 mt-2"
                  data={dsBenhNhan || []}
                  columns={dsBenhNhanColumns}
                  searchObject={objectSearch}
                  setSearchObject={setObjectSearch}
                  notDelete={true}
                  notEdit={false}
                  noToolbar={true}
                  totalPages={configTable.totalPages}
                  totalElements={configTable.totalElements}
                  numberOfElements={configTable.numberOfElements}
                  handleDoubleClick={handleSelectBenhNhan}
                  setDataChecked={setSelectedBenhNhan}
                  // type={TYPE.SINGLE_NO_RADIO_BUTTON}
                  selectionMode={TYPE.SINGLE_NO_RADIO_BUTTON}
                  // height={640}
                  fixedColumnsCount={-1}
                  isShowSelection={false}
                  clearToolbar={true}
                />
              </div>
            </Col>
            <Stack
              direction="horizontal"
              gap={3}
              className="justify-content-end"
            >
              <Button className="btn-fill spaces min-w-100 mr-5">
                Báo cáo
              </Button>
            </Stack>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
};
export default DSBenhNhan;
