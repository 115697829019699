import { Column } from "react-table";
import { TableCustomHeader } from "../../../component/table/components/TableCustomHeader";
import { TableCustomCell } from "../../../component/table/components/TableCustomCell";
export const columnsDSYCKham: ReadonlyArray<Column<any>> = [
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Yêu cầu khám"}
          className=" text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "Yêu cầu khám",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2 fs-14"
          data={props.data[props.row.index]?.termSvcName}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Phòng khám"}
          className="text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "Phòng khám",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2 fs-14"
          data={props.data[props.row.index]?.deptName}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Bác sĩ"}
          className=" text-center text-white align-middle bg-pri min-w-100px"
        />
      ),
      id: "Bác sĩ",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index]?.docName}
          className="s-2 fs-14"
        />
      ),
    },
  ];

  export  const columnsDSBenh: ReadonlyArray<Column<any>> = [
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"STT"}
          className=" text-center text-white align-middle bg-pri"
        />
      ),
      id: "STT",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={String(props?.row?.index + 1)}
          className="text-center fs-14"
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Mã bệnh"}
          className="text-center text-white align-middle bg-pri min-w-200px"
        />
      ),
      id: "Mã bệnh",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="text-center fs-14"
          data={props.data[props.row.index]?.termCode}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Tên bệnh"}
          className=" text-center text-white align-middle bg-pri min-w-200px"
        />
      ),
      id: "Tên bệnh",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index]?.termName}
          className="s-2 fs-14"
        />
      ),
    },
  ];