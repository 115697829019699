import { Formik, FormikHelpers } from "formik";
import { Dispatch, FC, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AppContext } from "../appContext/AppContext";
import ComponentPrint from "../component/templateComponent/ComponentPrint";
import { phieuTiepNhan } from "../component/templateComponent/conponents/PhieuTiepNhan";
import { convertDataTiepNhan } from "../component/templateComponent/conponents/Utils";
import { CODE, KEY_DS_TAB_TIEP_NHAN, REGEX } from "../utils/Constant";
import { formatDateDTO, validateNgay } from "../utils/FormatUtils";
import { localStorageItem } from "../utils/LocalStorage";
import './PhanHeTiepNhan.scss';
import { CODE_DOI_TUONG, danhSachMenu, initialValuesTiepDon } from "./constants/PhanHeTiepNhan";
import { addBenhNhan, updateBenhNhan } from "./services/TiepNhanServices";
import TiepNhan from "./tab-tiep-nhan/TiepNhan";
import { ITiepDon } from "./models/TiepDonModel";
import { DEFAULT_CODE_SELECT_TIEPDON } from "./constants/constants";
import { KEY_LOCALSTORAGE } from "../auth/core/_consts";

interface PhanHeTiepNhanContextProps {
    DSDichVu: any[];
    thongTinBenhNhan: {
        isChangeThongTinBenhNhan?: boolean
        isExamined?: boolean
    };
    setThongTinBenhNhan: (value: any) => void;
    handleAddTab: (eventKey: string) => void;
    setDSDichVu: Dispatch<SetStateAction<any[]>>;
    isPrint?: boolean;
    setIsPrint?: (isPrint: boolean) => void;
    isPay: { boolean: boolean, page: number, pageSize: number};
    setIsPay: (isPay: { boolean: boolean, page: number, pageSize: number; }) => void;
    orderMain: object | null;
    setOrderMain: Dispatch<SetStateAction<any>>;
    convertDataBenhNhan: (data: any) => ITiepDon
}

const initialContext = {
    thongTinBenhNhan: {} ,
    setThongTinBenhNhan: () => { },
    handleAddTab: () => { },
    setDSDichVu: () => { },
    DSDichVu: [],
    isPrint: false,
    isPay: {boolean: false, page: 0, pageSize: 0},
    setIsPay: () => { },
    orderMain: null,
    setOrderMain: () => { },
    convertDataBenhNhan: () => ({} as ITiepDon),
}

export const PhanHeTiepNhanContext = createContext<PhanHeTiepNhanContextProps>(initialContext)

export const PhanHeTiepNhan: FC = () => {
    const { setBreakCrumb, setDSMenu, setEventKey, setIsLoading } = useContext(AppContext);
    let [isPrint, setIsPrint] = useState<boolean>(false);
    let [isPay, setIsPay] = useState<{ boolean: boolean, page: number, pageSize: number}>( { boolean: false, page: 0, pageSize: 0});
    const [thongTinBenhNhan, setThongTinBenhNhan] = useState<any>(initialValuesTiepDon);
    const [DSDichVu, setDSDichVu] = useState<any[]>([]);
    const [openDSBenhNhan, setOpenDSDSBenhNhan] = useState(false);
    const [orderMain, setOrderMain] = useState<object | null>(null);
    const currentUser = localStorageItem.get(KEY_LOCALSTORAGE.ACCESS_TOKEN_DECODE);

    const intl = useIntl();
    let breakCrumb = [
        { text: intl.formatMessage({ id: 'MENU.RECEIVE' }), url: '' },
        { text: intl.formatMessage({ id: 'MENU.RECEIVE' }), url: '/receive' },
    ];

    useEffect(() => {
        setBreakCrumb(breakCrumb);
        setDSMenu(danhSachMenu);
        return () => {
            setDSMenu([]);
            setBreakCrumb([]);
        };
    }, []);

    const handleAddTab = (eventKey: string) => {
        let data = localStorageItem.get(KEY_DS_TAB_TIEP_NHAN) ? localStorageItem.get(KEY_DS_TAB_TIEP_NHAN) : [];
        if (!data.includes(eventKey)) {
            data.push(eventKey);
        }
        data.sort((a: string, b: string) => a > b ? 1 : -1);
        localStorageItem.set(KEY_DS_TAB_TIEP_NHAN, data)
        setEventKey(eventKey)
    }

    let validationSchema = Yup.object({
        patient: Yup.object({
            person: Yup.object({
                fullName: Yup.string()
                    .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable()
                    .matches(REGEX.TEN, intl.formatMessage({ id: 'VALIDATE.TEN' }))
                    .matches(REGEX.CHARACTER50, intl.formatMessage({ id: 'VALIDATE.CHARACTER50' })),
                dobDay: Yup.number().typeError("Vui lòng nhập số")
                    .nullable()
                    .min(1, 'Ngày không hợp lệ')
                    .max(31, 'Ngày không hợp lệ')
                    .test('is-valid-date', 'Ngày không hợp lệ', function (value) {
                        const { dobDay, dobMonth, dobYear } = this.parent;
                        if (!value) return true;
                        return validateNgay(dobDay, dobMonth, dobYear)
                    }),
                dobMonth: Yup.number().typeError("Vui lòng nhập số")
                    .nullable()
                    .min(1, 'Tháng không hợp lệ')
                    .max(12, 'Tháng không hợp lệ'),
                dobYear: Yup.number().typeError("Vui lòng nhập số")
                    .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable()
                    .min(1900, 'Năm lớn hơn bằng 1900')
                    .max(new Date().getFullYear(), 'Năm nhỏ hiện tại'),
                idNumber: Yup.string().matches(/^\d{9}(\d{3})?$/, { message: 'Căn cước phải có độ dài 9 hoặc 12 ký tự', excludeEmptyString: true }),
                phoneNumber: Yup.string().length(10,'Số điện thoại có độ dài 10 ký tự')
            }),
            code: Yup.string().length(8,'Mã bệnh nhân có độ dài 8 ký tự'),
            province: Yup.object()
                .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
            district: Yup.object()
                .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
            commune: Yup.object()
                .required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        }),
        visit: Yup.object({
            visitObjectType: Yup.object().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
            dept: Yup.object().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
            service: Yup.object().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
            visitReason: Yup.string().when('visitObjectType.code', {
                is: DEFAULT_CODE_SELECT_TIEPDON.DOITUONG_BHYT,
                then: Yup.string().required('Vui lòng nhập lý do khám khi đối tượng là BHYT"'),
                otherwise: Yup.string(),
            }),
        }),
        // province: Yup.object().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        // district: Yup.object().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        // ward: Yup.object().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        // insurance: Yup.object().when('loaiDoiTuong.code', {
        //     is: CODE_DOI_TUONG?.BAO_HIEM,
        //     then: Yup.object().shape({
        //         insCode: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        //         soThe2: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        //         soThe3: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        //         soThe4: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        //         KCBBD: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        //         hanThe: Yup.object().shape({
        //             ngayStart: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        //             thangStart: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        //             namStart: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        //             ngayEnd: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        //             thangEnd: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        //             namEnd: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })).nullable(),
        //         }),
        //     }),
        // }),
    });

    const convertDataBenhNhan = (data: any): ITiepDon => {
        const convertToObject = (field: string, data: any) => {
            if (!data) {
                return null;
            };
            return (data[field + "Id"] && data[field + "Name"]) ? {
                id: data[field + "Id"],
                name: data[field + "Name"],
                code: data[field + "Code"],
            } : null;
        };
        return {
            ...data,
            patient: {
                ...data?.patient,
                person: {
                    ...data.patient.person,
                    dobDay: data?.patient?.dobDay,
                    dobMonth: data?.patient?.dobMonth,
                    dobYear: data?.patient?.dobYear,
                    fullName: data?.patient?.personName,
                    phoneNumber: data?.patient?.phoneNumber || '',
                    idNumber: data?.patient?.idNumber,
                    gender: { name: data?.patient?.gender },
                    ccountry: convertToObject("country", data?.patient),
                },
                ethnicity: convertToObject("ethnicity", data?.patient),
                province: convertToObject("province", data?.patient),
                district: convertToObject("district", data?.patient),
                commune: convertToObject("commune", data?.patient),
                occupation: convertToObject("occupation", data?.patient),
            },
            visit: {
                ...data?.visit,
                visitObjectType: convertToObject("visitObjectType", data?.visit) || data?.visit?.visitObjectType,
                visitReason: data?.visit?.visitReasonName,
                visitType: convertToObject("visitType", data?.visit),
                service: convertToObject("termSvc", data?.visit),
                dept: convertToObject("dept", data?.visit),
                attributes: {
                    examLevel: {
                        id: data?.visit?.attributes?.examLevel,
                        name: data?.visit?.attributes?.examLevelName,
                    }
                }
            },
            orders: data?.orders?.filter((order: any) => !order?.fulfillDeptId)
        }
    };

    const handleFormSubmit = async (values: ITiepDon, { setValues }: FormikHelpers<ITiepDon>) => {
        const currentDate = new Date();
        let patientValues = values?.patient;
        let visitValues = values?.visit;
        let ordersValues = values?.orders?.map((item: any) => {
            return {
                ...item,
                termId: item?.termId || item?.id,
                id: item?.termId ? item?.id : null
            };
        }) || [];
        let personValues = patientValues?.person;
        let insuranceValues = values?.insurance;
        let ordersKb = orderMain && typeof orderMain ==='object'
            ? {
            ...orderMain,
            termId: visitValues?.service?.id,
            fulfillDeptId: visitValues?.dept?.id || "",
            fulfillDeptName: visitValues?.dept?.name || "",
        } : {
            termId: visitValues?.service?.id,
            fulfillDeptId: visitValues?.dept?.id || "",
            fulfillDeptName: visitValues?.dept?.name || "",
        }
        let arr_orders = ordersValues.concat([ordersKb]);

        let isBHYT = CODE_DOI_TUONG.BAO_HIEM === visitValues?.visitObjectType?.code
        let userInfo = {
            indicationPerson: currentUser?.name,
            requestDeptName: currentUser?.departments?.[0]?.name || null
        };
        const submitData = {
            ...userInfo,
            patient: {
                person: {
                    ...personValues,
                    ccountry: null,
                    country: personValues?.ccountry?.code || "",
                    gender: personValues?.gender?.name || "",
                    dobDay: Number(personValues?.dobDay) || "",
                    dobMonth: Number(personValues?.dobMonth) || "",
                    dobYear: Number(personValues?.dobYear) || "",
                    phoneNumber: personValues?.phoneNumber || null,
                },
                // personCode: patientValues?.code || "",
                personName: personValues?.fullName || "",
                gender: personValues?.gender?.name || "",
                allergyStatus: patientValues?.allergyStatus || "",
                // code: patientValues?.code || "",
                phoneNumber: personValues?.phoneNumber || null,
                idNumber: personValues?.idNumber || "",
                ethnicityId: patientValues?.ethnicity?.id || "",
                ethnicityName: patientValues?.ethnicity?.name || "",
                address: patientValues?.address || "",
                administrativeUnit: patientValues?.administrativeUnit || "",
                provinceId: patientValues?.province?.id || "",
                provinceName: patientValues?.province?.name || "",
                districtId: patientValues?.district?.id || "",
                districtName: patientValues?.district?.name || "",
                communeId: patientValues?.commune?.id || "",
                communeName: patientValues?.commune?.name || "",
                occupationId: patientValues?.occupation?.id || "",
                occupationName: patientValues?.occupation?.name || "",
                appointmentDate: formatDateDTO(patientValues?.appointmentDate || currentDate),
                deleted: patientValues?.deleted,
                deletedBy: patientValues?.deletedBy,
                deletedDate: patientValues?.deletedDate,
                deletedReason: patientValues?.deletedReason,
                id: patientValues?.id || "",
            },
            visit: {
                visitObjectTypeId: visitValues?.visitObjectType?.id || "",
                visitObjectTypeName: visitValues?.visitObjectType?.name || "",
                visitObjectTypeCode: visitValues?.visitObjectType?.code || "",
                visitTypeId: visitValues?.visitType?.id || "",
                visitTypeName: visitValues?.visitType?.name || "",
                visitReasonName: visitValues?.visitReason || "",
                termSvcId: visitValues?.service?.id || "",
                termSvcName: visitValues?.service?.name || "",
                deptId: visitValues?.dept?.id || "",
                deptName: visitValues?.dept?.name || "",
                deptCode: visitValues?.dept?.code || "",
                attributes: {
                    ...visitValues?.attributes,
                    examLevel: visitValues?.attributes?.examLevel?.id,
                    examLevelName: visitValues?.attributes?.examLevel?.name
                },
            },
            orders: arr_orders,
            ...(isBHYT && {
                insurance: {
                    insCode: `${insuranceValues?.insCodeDoiTuong || ''}${insuranceValues?.insCodeMucHuong || ''}${insuranceValues?.insCodeNoiPhatHanh || ''}${insuranceValues?.insCodeMaBhxh || ''}`,
                    insKcbbdCode: insuranceValues?.insKcbbdCode,
                    insKcbbdName: insuranceValues?.insKcbbdName,
                    insFromYear: insuranceValues?.insFromYear,
                    insFromMonth: !insuranceValues?.insFromMonth ? null : Number(insuranceValues?.insFromMonth),
                    insFromDay: !insuranceValues?.insFromDay ? null : Number(insuranceValues?.insFromDay),
                    insToYear: !insuranceValues?.insToYear ? null : Number(insuranceValues?.insToYear),
                    insToMonth: !insuranceValues?.insToMonth ? null : Number(insuranceValues?.insToMonth),
                    insToDay: !insuranceValues?.insToDay ? null : Number(insuranceValues?.insToDay),
                    insNgaydu5namYear: !insuranceValues?.insNgaydu5namYear ? null : Number(insuranceValues?.insNgaydu5namYear),
                    insNgaydu5namMonth: !insuranceValues?.insNgaydu5namMonth ? null : Number(insuranceValues?.insNgaydu5namMonth),
                    insNgaydu5namDay: !insuranceValues?.insNgaydu5namDay ? null : Number(insuranceValues?.insNgaydu5namDay),
                    insNgaymienchitraYear: !insuranceValues?.insNgaymienchitraYear ? null : Number(insuranceValues?.insNgaymienchitraYear),
                    insNgaymienchitraMonth: !insuranceValues?.insNgaymienchitraMonth ? null : Number(insuranceValues?.insNgaymienchitraMonth),
                    insNgaymienchitraDay: !insuranceValues?.insNgaymienchitraDay ? null : Number(insuranceValues?.insNgaymienchitraDay),
                    insCoveragePercentage: !insuranceValues?.insCoveragePercentage ? null : Number(insuranceValues?.insCoveragePercentage),
                    //
                    ptName: personValues?.fullName,
                    ptBodDay: !personValues?.dobDay ? null : Number(personValues?.dobDay),
                    ptBodMonth: !personValues?.dobMonth ? null : Number(personValues?.dobMonth),
                    ptBodYear: !personValues?.dobYear ? null : Number(personValues?.dobYear),
                    ptGender: personValues?.gender?.name || "",
                    ptGenderName: personValues?.gender?.name || "",
                    insAddress: patientValues?.administrativeUnit || ""
                }
            }),
            ...(values?.vitalSigns && {
                vitalSigns: values?.vitalSigns
            }),
            ...(values?.familyRecords && {
                familyRecords: values?.familyRecords
            })
        };
        try {
            setIsLoading(true);
            let { data } = (patientValues?.id && visitValues?.id)
                ? await updateBenhNhan({
                    ...submitData,
                    patient: {
                        ...patientValues,
                        ...submitData?.patient,
                        isUpdatePerson: true,
                        id: patientValues?.id
                    },
                    visit: {
                        ...visitValues,
                        ...submitData?.visit,
                        id: visitValues?.id
                    },
                    orders: [...submitData?.orders],
                    ...(isBHYT && {
                        insurance: {
                            ...insuranceValues,
                            ...submitData?.insurance,
                            id: insuranceValues?.id
                        }
                    }),
                    encounter: values?.encounter ? values?.encounter : null
                })
                : await addBenhNhan(submitData);
            if (CODE.SUCCESS === data?.code) {
                toast.success(((patientValues?.id && visitValues?.id) ? "Cập nhật" : "Thêm") + " bệnh nhân thành công");
                let dataConverted = convertDataBenhNhan(data?.data);
                let orderMain = data?.data?.orders?.find((order: any) => order?.fulfillDeptId);
                if (orderMain) {
                    setOrderMain(orderMain);
                }
                setValues(dataConverted);
                setThongTinBenhNhan({
                    ...thongTinBenhNhan,
                    isChangeThongTinBenhNhan: !thongTinBenhNhan?.isChangeThongTinBenhNhan
                });
            } else {
                let errorMesage = data?.data?.[0]?.errorMessage || "Xảy ra lỗi, vui lòng thử lại!";
                toast.warning(errorMesage);
            }
        } catch (error) {
            toast.warning("Xảy ra lỗi, vui lòng thử lại!");
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    }

    const handleClosePrint = () => {
        setIsPrint(false)
    }
    return (
        <Formik<ITiepDon>
            initialValues={{ ...initialValuesTiepDon }}
            validationSchema={validationSchema}
            onSubmit={handleFormSubmit}
        >
            <PhanHeTiepNhanContext.Provider
                value={{
                    thongTinBenhNhan, setThongTinBenhNhan,
                    isPrint, setIsPrint,
                    handleAddTab,
                    DSDichVu, setDSDichVu,
                    isPay, setIsPay,
                    orderMain, setOrderMain,
                    convertDataBenhNhan
                }}
            >
                <div className="reception-list spaces mt-6">
                    <div className="reception__header spaces py-4 border-bottom">
                        <Button className="btn-navy-outlined ms-4"
                            onClick={() => setOpenDSDSBenhNhan(true)}
                        ><i className="bi bi-people icon-call text-pri fs-4"></i><span>Danh sách BN</span></Button>
                        <div className="call-patient spaces top-5">
                            <Button className="btn-fill mr-5"><i className="bi bi-telephone icon-call me-2"></i><span>Gọi</span></Button>
                        </div>
                        <div className="call-again spaces top-5 mt-0">
                            <Button className="btn-fill btn mr-5"><i className="bi bi-arrow-clockwise icon-call-again me-2"></i><span>Gọi lại</span></Button>
                        </div>
                    </div>
                    <TiepNhan
                        openDSBenhNhan={openDSBenhNhan}
                        handleCloseDSBenhNhan={() => setOpenDSDSBenhNhan(false)}
                    />
                </div>
                {isPrint &&
                    <ComponentPrint
                        open={isPrint}
                        size="sm"
                        title="Phiếu tiếp đón"
                        handleClose={handleClosePrint}
                        data={convertDataTiepNhan({...thongTinBenhNhan, DSDichVu: DSDichVu})}
                        template={phieuTiepNhan}
                    />
                }
            </PhanHeTiepNhanContext.Provider>
        </Formik>
    )
}

export default PhanHeTiepNhan;
