import React, { useContext } from "react";
import { Column } from "react-table";
import { TableCustomCell } from "../../../component/table/components/TableCustomCell";
import { TableCustomHeader } from "../../../component/table/components/TableCustomHeader";
import { TableCustom } from "../../../component/table/table-custom/TableCustom";
import { SELECTION_MODE } from "../../../utils/Constant";
import WfStatusSummary from "../../../workflows/components/WfStatusSummary";
import { DanhSachBenhNhanInterface } from "../../models/KhamDoanModel";
import { QuanLyHopDongContext, QuanLyHopDongContextProps } from "../tab-quan-ly-hop-dong/QuanLyHopDong";
import "./quan-ly-hop-dong/QuanLyHopDong.scss";

interface Props {
  setSelectedRow: React.Dispatch<any>;
  selectedRow: any;
  dsBenhNhan: any;
  countPaid: any;
  dataSummary: any;
}
const TableDanhSachBenhNhan = (props: Props) => {
  const { dsBenhNhan, dataSummary } = props;
  const { setTTNhanVien, setIsSearchNV } = useContext<QuanLyHopDongContextProps>(QuanLyHopDongContext)

  const handlerSelectData = (value: any) => {
    setTTNhanVien(value[0]);
    setIsSearchNV(false);
  }

  const getPatientName = (patient: any) => {
    return (
      <div className="fs-3">
        <div>{patient?.name}</div>
        <div>{`${patient?.patientCode || ""} - ${
          patient?.packageName || ""
        }`}</div>
      </div>
    );
  };

  const DanhSachBenhNhanColumns: ReadonlyArray<
    Column<DanhSachBenhNhanInterface>
  > = [
      {
        Header: (props) => (
          <TableCustomHeader<DanhSachBenhNhanInterface>
            tableProps={props}
            title={"TT"}
            className=" text-center text-white align-middle bg-pri"
          />
        ),
        id: "TT",
        Cell: ({ ...props }) => (
          <TableCustomCell
            data = {
              <i className={`bi bi-circle-fill status-${props.data[props.row.index].color}-color`}></i>
            }
            className="text-center"
          />
        ),
      },
      {
        Header: (props) => (
          <TableCustomHeader<DanhSachBenhNhanInterface>
            tableProps={props}
            title={"STT"}
            className=" text-center text-white align-middle bg-pri"
          />
        ),
        id: "stt",
        Cell: ({ ...props }) => (
          <TableCustomCell
            data={
              props.row.index + 1}
            className="text-center"
          />
        ),
      },
      {
        Header: (props) => (
          <TableCustomHeader<DanhSachBenhNhanInterface>
            tableProps={props}
            title={"Tên nhân viên"}
            className="text-center text-white align-middle bg-pri min-w-150px"
          />
        ),
        id: "name",
        Cell: ({ ...props }) => (
          <TableCustomCell
            className="s-2"
            data={getPatientName(props.data[props.row.index])}
          />
        ),
      },
      // {
      //   Header: (props) => (
      //     <TableCustomHeader<DanhSachBenhNhanInterface>
      //       tableProps={props}
      //       title={"Tên gói khám"}
      //       className="text-center text-white align-middle bg-pri min-w-150px"
      //     />
      //   ),
      //   id: "packageName",
      //   Cell: ({ ...props }) => (
      //     <TableCustomCell
      //       className="s-2"
      //       data={props.data[props.row.index].packageName}
      //     />
      //   ),
      // },
      {
        Header: (props) => (
          <TableCustomHeader<DanhSachBenhNhanInterface>
            tableProps={props}
            title={"Tuổi"}
            className=" text-center text-white align-middle bg-pri min-w-50px"
          />
        ),
        id: "age",
        Cell: ({ ...props }) => (
          <TableCustomCell
            data={props.data[props.row.index]?.age}
            className="text-center"
          />
        ),
      },
    ];

  return (
    <div className="d-flex bg-white flex-column justify-content-between h-100">
      <div className="spaces h-calc-vh-310">
        <TableCustom<any>
          data={dsBenhNhan}
          className="h-100"
          columns={DanhSachBenhNhanColumns}
          selectionMode={SELECTION_MODE.SINGLE_NO_RADIO_BUTTON}
          getSelectedRowsData={handlerSelectData}
          verticalScroll={true}
        />
      </div>
      <div className="spaces mt-5">
        <WfStatusSummary summaryStatus={dataSummary} />
      </div>
    </div>
  );
};

export { TableDanhSachBenhNhan };
