import { Column } from 'react-table';
import { TableCustomCell } from '../../../component/table/components/TableCustomCell';
import { LIST_COLOR_TRANG_THAI_PHIEU } from '../../../utils/Constant';
import { TableCustomHeader } from '../../../component/table/components/TableCustomHeader';

const chiDinhChuyenKhoaColumn: ReadonlyArray<Column<any>> = [
    {
        Header: (props) => (<></>),
        id: "dv",
        Cell: ({ ...props }) => (
            <TableCustomCell
                tableProps={props}
                listColorPhieu={LIST_COLOR_TRANG_THAI_PHIEU}
                className='d-flex justify-content-between'
                data={
                    <div className='ms-2'>
                        <p className='my-1'>{props.data[props.row.index]?.date}</p>
                        <p className='my-1'>{props.data[props.row.index]?.title}</p>
                    </div>
                } />
        ),
    },
];

const columnsDSChuyenKhoa = [
    { title: "Tên dịch vụ", field: "name", className: "spaces width-40" },
    {
        title: "SL", field: "quantity", className: "spaces width-10 text-center", 
        render: (rowData: any) => {
            const DEFAULT_QUANTITY = 1;
            return DEFAULT_QUANTITY;
        }
    },
    { title: "Kết quả", field: "ketQua", className: "spaces width-20",
        render: (rowData: any) => {
            return rowData?.obs?.value || "";
        }
     },
    { title: "Loại bệnh phẩm", field: "type", className: "spaces width-15" },
    { title: "Đối tượng", field: "doiTuong", className: "spaces width-15" },
];

const chuyenKhoaColumn: ReadonlyArray<Column<any>> = [
    {
        Header: (props) => (
            <TableCustomHeader
                tableProps={props}
                title={"STT"}
                className=" text-center text-white"
            />
        ),
        id: "STT",
        Cell: ({ ...props }) => (
            <TableCustomCell className="text-center" data={(props.row.index + 1).toString()} />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title='Tên dịch vụ'
                className=' min-w-250px text-center '
            />
        ),
        id: 'Tên dịch vụ',
        Cell: ({ ...props }) => (
            <TableCustomCell className='min-w-150px ' data={props.data[props.row.index]?.serviceName || ""} />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title='SL'
                className=' text-center '
            />
        ),
        id: 'Số lượng',
        Cell: ({ ...props }) => (
            <TableCustomCell className='min-w-50px text-center ' data={props.data[props.row.index]?.soLuong} />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title='Kết quả'
                className='min-w-200px  text-center'
            />
        ),
        id: 'Kết quả',
        Cell: ({ ...props }) => (
            <TableCustomCell className='min-w-200px text-start ' data={props.data[props.row.index]?.loaiCongKham} />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title='Loại bệnh phẩm'
                className='min-w-200px  text-center'
            />
        ),
        id: 'Loại bệnh phẩm',
        Cell: ({ ...props }) => (
            <TableCustomCell className='min-w-200px text-start ' data={props.data[props.row.index]?.loaiCongKham} />
        ),
    },
    {
        Header: (props) => (
            <TableCustomHeader<any>
                tableProps={props}
                title='Đối tượng'
                className='min-w-200px text-center'
            />
        ),
        id: 'Đối tượng',
        Cell: ({ ...props }) => (
            <TableCustomCell className='min-w-200px text-start ' data={props.data[props.row.index]?.ghiChu} />
        ),
    },
]



export { chiDinhChuyenKhoaColumn, columnsDSChuyenKhoa, chuyenKhoaColumn };

