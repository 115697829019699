import { FunctionComponent, useContext, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { PhanHeTiepDonContext } from "../contexts/PhanHeTiepDonContext";
import PageKeThuoc from "../modals/modal-ke-thuoc/PageKeThuoc";
import PageChiDinhDichVu from "../modals/modal-chi-dinh-dich-vu/PageChiDinhDichVu";
import CapToaChoVeModal from "../modals/modal-tab-xu-tri/ModalCapToaChoVe";
import ModalChuyenTuyenVien from "../modals/modal-tab-xu-tri/ModalChuyenTuyenVien";
import { Formik } from "formik";
import PageKeVatTu from "../modals/modal-ke-vat-tu/ModalKeVatTu";
import PageThanhToan from "../modals/modal-thanh-toan/ModalThanhToan";
import moment from "moment";
import { startMedicalExam } from "../services/PhanHeTiepDonServer";
import { CODE_SUCCESS, KEY_DS_DANH_MUC_TIEP_DON } from "../../utils/Constant";
import WfButton from "../../workflows/components/WfButton";
import { WfConstTracker } from "../../workflows/Constant";
import { calculateAge } from "../../utils/FormatUtils";
import { localStorageItem } from "../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../auth/core/_consts";
import { IDanhSachDanhMuc } from "../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel";
import { getIndexedDBItem } from "../../utils/IndexedDB";

interface ThongTinBenhNhanProps {
  statusChangedEventFunc: () => void;
}

const initialValues = {};

const ThongTinBenhNhan: FunctionComponent<ThongTinBenhNhanProps> = (props) => {
  const { benhNhanInfo, setBenhNhanInfo } =
    useContext(PhanHeTiepDonContext);
  const [openCapNhatMauChiDinhDialog, setOpenCapNhatMauChiDinhDialog] =
    useState<boolean>(false);
  const [shouldOpenChiDinhDichVuModal, setShouldOpenChiDinhDichVuModal] =
    useState<boolean>(false);
  const [shouldOpenCapToaModal, setShouldOpenCapToaModal] =
    useState<boolean>(false);
  const [shouldOpenChuyenVienModal, setShouldOpenChuyenVienModal] =
    useState<boolean>(false);
  const [shouldOpenModalKeVatTu, setShouldOpenModalKeVatTu] =
    useState<boolean>(false);
  const [shouldOpenModalThanhToan, setShouldOpenModalThanhToan] =
    useState<boolean>(false);

  const [listICD10, setListICD10] = useState<any[]>([]);

  let patient = benhNhanInfo?.thongTinKhamBenh?.patient;
  let visit = benhNhanInfo?.thongTinKhamBenh?.visit;
  let encounterDiagnosis = benhNhanInfo?.thongTinKhamBenh?.encounterDiagnosis;
  let orderId = benhNhanInfo?.thongTinKhamBenh?.orderId;
  let currentUser = localStorageItem.get(KEY_LOCALSTORAGE.ACCESS_TOKEN_DECODE);
  let isEnableBtn = encounterDiagnosis?.reasonForEncounter && encounterDiagnosis?.medicalHistoryPast;
  const ICDXPrimaryValue = benhNhanInfo?.thongTinKhamBenh?.encounterDiagnosis?.icdXPrimary;

  useEffect(() => {
    handleGetListICD10();
  }, []);
  
  useEffect(() => {
		if (listICD10.length && ICDXPrimaryValue) {
			setBenhNhanInfo({
				...benhNhanInfo,
				thongTinKhamBenh: {
					...benhNhanInfo?.thongTinKhamBenh,
					encounterDiagnosis: {
						...benhNhanInfo?.thongTinKhamBenh?.encounterDiagnosis,
						ICDXPrimaryLabel: listICD10.find(
							record => record.code === ICDXPrimaryValue
						)?.name ?? '',
					},
				},
			});
		}
  }, [ICDXPrimaryValue, listICD10.length]);

  const startStartClinical = async () => {
    let { data } = await startMedicalExam({
      patientId: patient.id,
      visitId: visit.id,
      orderId: orderId,
    });
    setBenhNhanInfo({
      ...benhNhanInfo,
      thongTinKhamBenh: {
        ...benhNhanInfo?.thongTinKhamBenh,
        isExamined: data.data?.isExamined,
        encounter: data.data?.encounter,
        encounterDiagnosis: data.data?.encounterDiagnosis,
      },
    });
    props.statusChangedEventFunc();
  };

  const handleGetListICD10 = async () => {
    try {
      let res: IDanhSachDanhMuc = await getIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON);
      setListICD10(res?.listICD10 || []);
    } catch (error) {
      console.error(error);
    }
  }

  const updatePatientInfo = () => { 
    setBenhNhanInfo({
      ...benhNhanInfo,
      shouldUpdate: !benhNhanInfo.shouldUpdate
    });
  };

  return (
    <Formik<any> initialValues={initialValues} onSubmit={() => {}}>
      <div className="bg-white mb-2">
        <div className="d-flex justify-content-between align-items-center border-bottom spaces h-38 px-10 py-4">
          <h4 className="text-pri fw-bold mb-0">Thông tin bệnh nhân</h4>
          <div className=" justify-content-start pl-4 spaces d-flex gap-4">
            {(benhNhanInfo?.thongTinKhamBenh?.isExamined && !benhNhanInfo?.thongTinKhamBenh?.isConclusion)? (
              <>
                <Button
                  className="btn-fill btn-kham-benh min-w-120 spaces mx-3"
                  onClick={() => setShouldOpenChiDinhDichVuModal(true)}
                  disabled={!isEnableBtn}
                >
                  Chỉ định DV
                </Button>
                <Button
                  className="btn-fill btn-kham-benh min-w-120 spaces mx-3"
                  onClick={() => setOpenCapNhatMauChiDinhDialog(true)}
                  disabled={!isEnableBtn}
                >
                  <span>Kê thuốc</span>
                </Button>
                <Button
                  className="btn-fill btn-kham-benh min-w-120 spaces mx-3"
                  onClick={() => setShouldOpenModalKeVatTu(true)}
                  disabled={!isEnableBtn}
                >
                  <span>Kê vật tư</span>
                </Button>
                <Button
                  className="btn-fill btn-kham-benh min-w-120 spaces mx-3"
                  onClick={() => setShouldOpenModalThanhToan(true)}
                  disabled={!benhNhanInfo?.thongTinKhamBenh?.isExamined}
                >
                  <span>Thanh toán</span>
                </Button>
              </>
            ) : (
              <>
                <WfButton
                  tracker={WfConstTracker.CLINICAL}
                  objectId={orderId}
                  additionalFunc={startStartClinical}
                ></WfButton>
              </>
            )}
          </div>
        </div>
        <div className="p-3">
          <Row>
            <Col xs="4">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">Mã BN</span>
                <span className="fw-light text-truncate">
                  {patient?.code || "..."}
                </span>
              </div>
            </Col>
            <Col xs="8">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">
                  Bệnh nhân
                </span>
                <span className="fw-light text-truncate">
                  {patient?.personName ?
                    <>
                      <span>{patient?.personName || "..."}</span>
                      &nbsp;-&nbsp;<span className="text-danger fw-normal">{patient?.gender || "..."}</span>&nbsp;-&nbsp;
                      <span className="text-danger fw-bold">{visit?.visitObjectTypeName || "..."}</span>
                    </>
                    : "..."}
                </span>
              </div>
            </Col>
            <Col xs="4">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">
                  Ngày sinh
                </span>
                <span className="fw-light text-truncate">
                  {patient?.dobYear
                    ? `${patient?.dobYear} - ${calculateAge(
                        patient?.dobDay,
                        patient?.dobMonth,
                        patient?.dobYear
                      )}`
                    : "..."}
                </span>
              </div>
            </Col>
            <Col xs="4">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">
                  Quốc tịch
                </span>
                <span className="fw-light text-truncate">
                  {patient?.countryName || "..."}
                </span>
              </div>
            </Col>
            <Col xs="4">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">
                  Dân tộc
                </span>
                <span className="fw-light text-truncate">
                  {patient?.ethnicityName || "..."}
                </span>
              </div>
            </Col>

            <Col xs="4">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">
                  Nghề nghiệp
                </span>
                <span className="fw-light text-truncate">
                  {patient?.occupationName || "..."}
                </span>
              </div>
            </Col>
            <Col xs="4">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">
                  Thời điểm tới
                </span>
                <span className="fw-light text-truncate">
                  {visit?.dateStarted
                    ? moment(visit?.dateStarted).format("DD/MM/YYYY - HH:mm")
                    : "..."}
                </span>
              </div>
            </Col>
            <Col xs="4">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">
                  Người nhà
                </span>
                <span className="fw-light text-truncate">
                  {patient?.fmlName || "..."}
                  {patient?.fmlRelativeName ? " - " + patient?.fmlRelativeName : ""}
                  {patient?.fmlPhoneNumber ? " - " + patient?.fmlPhoneNumber : ""}
                </span>
              </div>
            </Col>
            <Col xs="4">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">
                  Bệnh án
                </span>
                <span className="fw-light text-truncate">
                  {patient?.code ? "Khám bệnh" : "..."}
                </span>
              </div>
            </Col>
            <Col xs="8">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">
                  TT liên lạc
                </span>
                <span className="fw-light text-truncate">
                  {patient?.phoneNumber? patient?.phoneNumber + " - " : ""}
                  {patient?.provinceName
                    ? `${patient?.address ? patient?.address + ", " : ""} ${
                        patient?.communeName
                      }, ${patient?.districtName}, ${patient?.provinceName}`
                    : "..."}
                </span>
              </div>
            </Col>
            <Col xs="4">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">
                  Lý do khám
                </span>
                <span className="fw-light text-truncate">
                  {visit?.visitReasonName || "..."}
                </span>
              </div>
            </Col>
            <Col xs="8">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">
                  TTPK
                </span>
                <span className="fw-light text-truncate">
                  {currentUser?.name ? currentUser?.name + " - " : ""}
                  {currentUser?.departments?.[0]?.name ? currentUser?.departments?.[0]?.name + " > " : ""}
                  {visit?.deptName || "..."}
                </span>
              </div>
            </Col>
            <Col xs="4">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">Xử trí</span>
                <span className="fw-light text-truncate">
                  {benhNhanInfo?.thongTinKhamBenh?.isConclusion ? (
                    `${encounterDiagnosis?.medicalConclusionModalityName
                      ? encounterDiagnosis?.medicalConclusionModalityName + " - "
                      : ""
                    } 
                      ${encounterDiagnosis?.medicalConclusionName || ""}`
                  ) : "..."}
                </span>
              </div>
            </Col>
            <Col xs="8">
              <div className="spaces fw-5 mb-8 d-flex">
                <span className="d-inline-block spaces min-w-100">
                  Chẩn đoán
                </span>
                <span className="fw-light text-truncate">
                  {benhNhanInfo?.thongTinKhamBenh?.isConclusion &&
                  encounterDiagnosis?.icdXPrimary
                    ? listICD10?.find(
                        (item: any) =>
                          item?.code ===
                          encounterDiagnosis?.icdXPrimary
                      )?.name
                    : "..."}
                </span>
              </div>
            </Col>
          </Row>
        </div>
        {openCapNhatMauChiDinhDialog && (
          <PageKeThuoc
            open={openCapNhatMauChiDinhDialog}
            handleClose={() => {
              setOpenCapNhatMauChiDinhDialog(false);
            }}
            onSave={() => {
              updatePatientInfo();
              setOpenCapNhatMauChiDinhDialog(false);
            }}
          />
        )}

        {shouldOpenModalKeVatTu && (
          <PageKeVatTu
            open={shouldOpenModalKeVatTu}
            handleClose={() => {
              setShouldOpenModalKeVatTu(false);
            }}
            onSave={() => {
              updatePatientInfo();
            }}
          />
        )}

        {shouldOpenChiDinhDichVuModal && (
          <PageChiDinhDichVu
            show={shouldOpenChiDinhDichVuModal}
            handleClose={() => {
              setShouldOpenChiDinhDichVuModal(false);
            }}
          />
        )}

        {shouldOpenCapToaModal && (
          <CapToaChoVeModal
            open={shouldOpenCapToaModal}
            handleCloseModal={() => setShouldOpenCapToaModal(false)}
            handleSubmit={() => {}}
          />
        )}

        {shouldOpenChuyenVienModal && (
          <ModalChuyenTuyenVien
            open={shouldOpenChuyenVienModal}
            handleCloseModal={() => setShouldOpenChuyenVienModal(false)}
            handleSubmit={() => {}}
          />
        )}

        {shouldOpenModalThanhToan && (
          <PageThanhToan
            open={shouldOpenModalThanhToan}
            handleClose={() => {
              setShouldOpenModalThanhToan(false);
            }}
          />
        )}
      </div>
    </Formik>
  );
};

export default ThongTinBenhNhan;
