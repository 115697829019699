export const getICD10Columns = (selectionMode: "checkbox" | "radio") => {
  return [
    {
      name: "",
      field: selectionMode,
      width: 50,
      textAlign: "center",
    },
    {
      name: "ICD10",
      field: "code",
      width: 200,
      textAlign: "center",
    },
    {
      name: "Tên bệnh",
      field: "name",
      width: 892,
      textAlign: "left",
    },
  ];
};
