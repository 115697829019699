import { useFormikContext } from 'formik';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { FormCheck } from 'react-bootstrap';
import LabelRequired from '../../../../../component/LabelRequired';
import TextField from '../../../../../component/TextField';
import { IDanhSachDanhMuc } from '../../../../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel';
import { KEY_DS_DANH_MUC_TIEP_DON } from '../../../../../utils/Constant';
import { getIndexedDBItem } from '../../../../../utils/IndexedDB';
import ModalICD10 from '../../../../components/modal-icd10/ModalICD10';
import { PhanHeTiepDonContext } from '../../../../contexts/PhanHeTiepDonContext';
import { SelectICD10 } from '../../../../models/VatTuModels';

const ThongTinBenh = () => {
	const { values, setFieldValue, errors } = useFormikContext<any>();
	const { benhNhanInfo } = useContext(PhanHeTiepDonContext);
	const [listICD10, setListICD10] = useState<any>([]);
	const [selectICD10, setSelectICD10] = useState<SelectICD10>({
		field: '',
		mode: 'radio',
		open: false,
	});

	useEffect(() => {
		getDanhMucICD10();
	}, []);

	const getDanhMucICD10 = async () => {
		const danhMuc: IDanhSachDanhMuc = await getIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON);
		setListICD10(danhMuc.listICD10);
	};

	const onChangeICD10 = (field: string, data: any) => {
		switch (field) {
			case 'provisionalDiagnosis':
				setFieldValue(field, data?.code);
				break;
			case 'comorbidity':
				const comorbidityName = data?.map((item: any) => item?.name).join('; ');
				setFieldValue(field, comorbidityName);
				break;
			default:
				break;
		}
	};

	return (
		<>
			<div className="d-flex align-items-center pt-3">
				<div className="spaces mb-4">
					<TextField
						label="Ngày y lệnh"
						name="ngayYLenh"
						labelClassName="ms-0 min-w-120-px"
						value={moment(values?.ngayYLenh ? values?.ngayYLenh : new Date()).format(
							'DD/MM/YYYY - HH:mm'
						)}
						disabled
					/>
				</div>
				<div className="ps-3">
					<FormCheck
						label="Thu tiền"
						type="checkbox"
						name="thuTien"
						checked={values.thuTien}
						onChange={e => setFieldValue('thuTien', e.target.checked)}
						isInvalid={!!errors.thuTien}
					/>
				</div>
			</div>

			<div className="d-flex flex-wrap w-80">
				<div className="spaces position-relative">
					<TextField
						label="Chẩn đoán"
						className="spaces w-100"
						inputClassName="w-100"
						name="provisionalDiagnosis"
						labelClassName="ms-0 min-w-120-px"
					/>
					<div className="spaces width-4">
						<h6
							className="position-absolute muc-huong cursor-pointer"
							onClick={() => {
								if (benhNhanInfo?.thongTinKhamBenh.isExamined) {
									setSelectICD10({
										field: 'provisionalDiagnosis',
										mode: 'radio',
										open: true,
									});
								}
							}}
						>
							<i className="bi bi-three-dots fs-4 text-pri d-block spaces mt-5"></i>
						</h6>
					</div>
				</div>
				<div className="flex-grow-1">
					<TextField
						inputClassName="w-100"
						name="provisionalDiagnosisLabel"
						value={
							Boolean(listICD10.length)
								? listICD10.find(
										(item: any) => item.code === values.provisionalDiagnosis
								  )?.name
								: ''
						}
						labelClassName="ms-0 min-w-95px"
					/>
				</div>
			</div>

			<div className="spaces mt-4 w-80">
				<div className="d-flex">
					<div className="d-flex">
						<LabelRequired
							label="Bệnh kèm theo"
							className="d-inline-block spaces min-w-120-px text-truncate"
						/>
					</div>
					<div className="spaces position-relative w-100">
						<TextField
							className="w-100 d-flex flex-column align-items-start"
							inputClassName="flex-auto spaces h-25 radius-3px pre-line"
							name="comorbidity"
							labelClassName="ms-0 min-w-145px"
						/>
						<div className="spaces width-4">
							<h6
								className="position-absolute muc-huong cursor-pointer"
								onClick={() => {
									if (benhNhanInfo?.thongTinKhamBenh.isExamined) {
										setSelectICD10({
											field: 'comorbidity',
											mode: 'checkbox',
											open: true,
										});
									}
								}}
							>
								<i className="bi bi-three-dots fs-4 text-pri d-block spaces mt-5"></i>
							</h6>
						</div>
					</div>
				</div>
			</div>

			{selectICD10.open && (
				<ModalICD10
					data={listICD10}
					handleClose={() =>
						setSelectICD10({
							field: '',
							mode: 'radio',
							open: false,
						})
					}
					setMauSelected={data => onChangeICD10(selectICD10.field, data)}
					selectionMode={selectICD10.mode}
				/>
			)}
		</>
	);
};

export default ThongTinBenh;
