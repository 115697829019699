import axios from 'axios'
import { urlParamsConfig } from '../../utils/ParamsUtils';
const BASE_URL = process.env.REACT_APP_EMR_API_URL;

export const getRispacsGroups = (searchObject: any) => {
  let url = BASE_URL + `/rispacs-groups/search`
  return axios.get(urlParamsConfig(url, searchObject));
}

export const getInforBnCDHA = (id: string) => {
  let url = BASE_URL + `/rispacs-groups/${id}/patient`
  return axios.get(url);
}

export const getInforPhieuCDHA = (id: string) => {
  let url = BASE_URL + `/rispacs-groups/${id}/detail`
  return axios.get(url);
}

export const updateObsValue = (data: any) => {
  let url = BASE_URL + `/rispacs-obs`
  return axios.put(url, data);
};

export const createObsValue = (data : any) => {
  let url = BASE_URL + `/rispacs-obs`
  return axios.post(url, data);
};

export const getObsValue = (searchObject : any) => {
  let url = BASE_URL + `/rispacs-obs`
  return axios.get(urlParamsConfig(url, searchObject));
}

export const getStatusSummary = () => {
  let url = BASE_URL + `/wf-workflows/rispacs/status/summary`
  return axios.get(url);
}


