import React from "react";
import { Column } from "react-table";
import { TableCustomHeader } from "../../../component/table/components/TableCustomHeader";
import { TableCustomCell } from "../../../component/table/components/TableCustomCell";
import { formatMoney } from "../../../utils/FormatUtils";
const DsDichVuColumn: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Mã DV"}
        className="text-center text-transform-none text-light min-w-100px fs-8"
      />
    ),
    id: "Mã dịch vụ",
    Cell: ({ ...props }) => (
      <TableCustomCell className="text-center " data={props?.data[props?.row?.index].code} />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Tên dịch vụ"}
        className="text-center text-transform-none text-light min-w-300px fs-8"
      />
    ),
    id: "Tên dịch vụ",
    Cell: ({ ...props }) => {
      return <TableCustomCell className="" data={props?.data[props?.row?.index].name} />;
    },
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Số lượng"}
        className="text-center text-transform-none text-light min-w-100px fs-8"
      />
    ),
    id: "Số lượng",
    Cell: ({ ...props }) => {
      return <TableCustomCell className="text-center " data={props?.data[props?.row?.index].soLuong} />;
    },
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Giá BHTY"}
        className="text-center text-transform-none text-light min-w-100px fs-8"
      />
    ),
    id: "Giá BHTY",
    Cell: ({ ...props }) => {
      return <TableCustomCell className="text-end " data={props?.data[props?.row?.index].giaBHYT} />;
    },
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Giá viện phí"}
        className="text-center text-transform-none text-light min-w-120-px fs-8"
      />
    ),
    id: "Giá viện phí",
    Cell: ({ ...props }) => {
      return <TableCustomCell className="text-end " data={props?.data[props?.row?.index].giaVienPhi} />;
    },
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Giá dịch vụ"}
        className="text-center text-transform-none text-light min-w-125px fs-8"
      />
    ),
    id: "Giá dịch vụ",
    Cell: ({ ...props }) => {
      return <TableCustomCell className="text-end " data={props?.data[props?.row?.index].giaDV} />;
    },
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Đơn giá"}
        className="text-center text-transform-none text-light min-w-100px fs-8"
      />
    ),
    id: "Đơn giá",
    Cell: ({ ...props }) => {
      return <TableCustomCell className="text-end " data={props?.data[props?.row?.index].donGia} />;
    },
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Thành tiền"}
        className="text-center text-transform-none text-light min-w-125px fs-8"
      />
    ),
    id: "Thành tiền",
    Cell: ({ ...props }) => {
      return <TableCustomCell className="text-end " data={props?.data[props?.row?.index].thanhTien} />;
    },
  },
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Phòng thực hiện"}
        className="text-center text-transform-none text-light min-w-150px fs-8"
      />
    ),
    id: "Phòng thực hiện",
    Cell: ({ ...props }) => {
      return <TableCustomCell className="" data={props?.data[props?.row?.index].phongThucHien} />;
    },
  },
];

const DsDichVuChiDinhColumn = (handleRenderPrice: (data: any) => number): ReadonlyArray<Column<any>> => {
  return [
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"STT"}
          className="text-center text-transform-none text-light min-w-40px fs-8"
        />
      ),
      id: "STT",
      Cell: ({ ...props }) => <TableCustomCell className="text-center " data={props?.row?.index + 1} />,
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Mã DV"}
          className="text-center text-transform-none text-light min-w-100px fs-8"
        />
      ),
      id: "Mã dịch vụ",
      Cell: ({ ...props }) => (
        <TableCustomCell data={props?.data[props?.row?.index].code} />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Tên dịch vụ"}
          className="text-center text-transform-none text-light min-w-250px fs-8"
        />
      ),
      id: "Tên dịch vụ",
      Cell: ({ ...props }) => {
        let data = props?.data[props?.row?.index].name;
        return <TableCustomCell className="spaces max-w-250 text-truncate" data={data} />;
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Số lượng"}
          className="text-center text-transform-none text-light min-w-100px fs-8"
        />
      ),
      id: "Số lượng",
      Cell: ({ ...props }) => {
        let data = props?.data[props?.row?.index]?.attributes?.svcQuantity || 1;
        return <TableCustomCell className="text-center " data={data} />;
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Đơn giá"}
          className="text-center text-transform-none text-light min-w-100px fs-8"
        />
      ),
      id: "Đơn giá",
      Cell: ({ ...props }) => {
        let data = handleRenderPrice(props?.data[props?.row?.index]);
        return <TableCustomCell className="text-end " data={formatMoney(data) || 0} />;
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Thành tiền"}
          className="text-center text-transform-none text-light min-w-100px fs-8"
        />
      ),
      id: "Thành tiền",
      Cell: ({ ...props }) => {
        let data = handleRenderPrice(props?.data[props?.row?.index]) * (props?.data[props?.row?.index]?.attributes?.svcQuantity || 1);
        return <TableCustomCell className="text-end " data={data ? formatMoney(data) : 0} />;
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Ghi chú"}
          className="text-center text-transform-none text-light min-w-200px fs-8"
        />
      ),
      id: "Ghi chú",
      Cell: ({ ...props }) => {
        let data = props?.data[props?.row?.index].ghiChu;
        return <TableCustomCell className="" data={data} />;
      },
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Phòng thực hiện"}
          className="text-center text-transform-none text-light min-w-200px fs-8"
        />
      ),
      id: "Phòng thực hiện",
      Cell: ({ ...props }) => {
        let data = props?.data[props?.row?.index]?.attributes?.deptLaboratoryName;
        return <TableCustomCell className="" data={data} />;
      },
    },
  ]
};

const dsDichVuColumnsSimple: any[] = [
  {
    name: "",
    field: "checkbox",
    width: 50,
    minWidth: 27,
  },
  {
    name: "Mã DV",
    field: "code",
    width: 200,
    minWidth: 75,
    textAlign: "center"
  },
  {
    name: "Tên dịch vụ",
    field: "name",
    width: 500,
    minWidth: 200,
    textAlign: "left"
  },
  {
    name: "SL",
    field: "svcQuantity",
    width: 90,
    minWidth: 50,
    textAlign: "center"
  },
  {
    name: "Giá BHYT",
    field: "svcFeeIns",
    width: 180,
    minWidth: 75,
    textAlign: "end",
  },
  {
    name: "Giá viện phí",
    field: "svcFeeHospital",
    width: 180,
    minWidth: 80,
    textAlign: "end",
  },
  {
    name: "Giá dịch vụ",
    field: "svcFee",
    width: 180,
    minWidth: 80,
    textAlign: "end",
  },
  {
    name: "Đơn giá",
    field: "svcPrice",
    width: 150,
    minWidth: 70,
    textAlign: "end",
  },
  {
    name: "Phòng thực hiện",
    field: "deptLaboratoryName",
    width: 250,
    minWidth: 110,
  },
];

export { DsDichVuColumn, DsDichVuChiDinhColumn, dsDichVuColumnsSimple };
