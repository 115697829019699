import axios from "axios";
import { IParamsSearchLocation, IParamsSimpleCategory, IParamsTermClasses } from "../models/params";
import { DEFAULT_PAGE_INDEX, SEARCH_OBJECT_MAX_SIZE } from "./Constant";

const API_PATH_EMR = process.env.REACT_APP_EMR_API_URL;
const API_PATH_TERM_CLASSES = API_PATH_EMR + "/term-classes";
const API_PATH_CATEGORY = process.env.REACT_APP_XADMIN_API_URL + "/simple-categories";
const API_PATH_PROVINCES = process.env.REACT_APP_XADMIN_API_URL + "/provinces";
const API_PATH_DISTRICTS = process.env.REACT_APP_XADMIN_API_URL + "/districts";
const API_PATH_SUB_DISTRICTS = process.env.REACT_APP_XADMIN_API_URL + "/subdistricts";

export const getSimpleCategory = (params: IParamsSimpleCategory) => {
    let url = API_PATH_CATEGORY + "/search";
    return axios.get(url, { params: {
        ...params,
        ...SEARCH_OBJECT_MAX_SIZE
    } });
};

export const getTermByClassId = (params: {
    classId: number
}) => {
    let url = API_PATH_EMR + "/terms";
    return axios.get(url, { params });
};

export const getTermClasses = (params: IParamsTermClasses) => {
    let url = API_PATH_TERM_CLASSES + "/terms";
    return axios.get(url, { params });
};

export const getAllProvinces = () => {
    const MAX_PAGE_SIZE_PROVINCE = 63;
    let params = {
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: MAX_PAGE_SIZE_PROVINCE
    };
    let url = API_PATH_PROVINCES + "/search";
    return axios.get(url, { params });
};

export const getAllDistricts = () => {
    let params = SEARCH_OBJECT_MAX_SIZE;
    let url = API_PATH_DISTRICTS + "/search";
    return axios.get(url, { params });
};

export const getAllSubDistricts = () => {
    let params = SEARCH_OBJECT_MAX_SIZE;
    let url = API_PATH_SUB_DISTRICTS + "/search";
    return axios.get(url, { params });
};

export const generateCode = () => {
    let url = API_PATH_EMR + `/generate/codes/patient`
    return axios.get(url);
};

export const getJsonGenerateForms = async (codes: string) => {
    let url = API_PATH_EMR + `/term-models/model-json`;
    return axios.get(url, { params: { codes } });
};