import { Column } from "react-table";
import { IPhongKham } from "../../../models/PhongKhamModel";
import { TableCustomHeader } from "../../../../component/table/components/TableCustomHeader";
import { TableCustomCell } from "../../../../component/table/components/TableCustomCell";

export const columnsThongTinPhongKham: ReadonlyArray<Column<IPhongKham>> = [
  {
    Header: (props) => (
      <TableCustomHeader
        tableProps={props}
        title={"Phòng"}
        className="text-center text-white ps-4 min-w-225px"
      />
    ),
    id: "ten",
    Cell: ({ ...props }) => (
      <TableCustomCell className="py-8" data={props.row.original?.name?.toString() || ""} />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader
        tableProps={props}
        title={"Hẹn"}
        className="text-center text-white ps-4"
      />
    ),
    id: "hen",
    Cell: ({ ...props }) => (
      <TableCustomCell className="text-start spaces py-2" data={props.row.original?.appointments?.toString() || ""} />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader
        tableProps={props}
        title={"ĐK"}
        className="text-center text-white ps-4"
      />
    ),
    id: "dangKy",
    Cell: ({ ...props }) => (
      <TableCustomCell className="text-start spaces py-2" data={props.row.original?.admissions?.toString() || ""} />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader
        tableProps={props}
        title={"Khám"}
        className="text-center text-white ps-4"
      />
    ),
    id: "kham",
    Cell: ({ ...props }) => (
      <TableCustomCell className="text-start spaces py-2" data={props.row.original?.examinations?.toString() || ""} />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader
        tableProps={props}
        title={"Tổng"}
        className="text-center text-white ps-4"
      />
    ),
    id: "tong",
    Cell: ({ ...props }) => (
      <TableCustomCell className="text-start spaces py-2" data={props.row.original?.total?.toString() || ""} />
    ),
  },
]