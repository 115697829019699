import { createContext } from "react";
import { IBenhNhan, iBangKhamBenh } from "../models/DSBenhNhanKhamBenhModels";

type ContextType = {
  thongTinChiPhi?: any | undefined;
  benhNhanInfo: any | undefined;
  setBenhNhanInfo: React.Dispatch<React.SetStateAction<IBenhNhan | undefined>>;
  thongTinKhamBenh?: any;
  setThongTinKhamBenh?: React.Dispatch<React.SetStateAction<any>>;
  benhNhanList?: iBangKhamBenh[];
  setBenhNhanList?: React.Dispatch<React.SetStateAction<iBangKhamBenh[]>>;
  setThongTinChiPhi?: any;
  statusChangedEventFunc?: () => void;
};

const initValue: ContextType = {
  benhNhanInfo: undefined,
  setBenhNhanInfo: () => {},
  thongTinKhamBenh: undefined,
  setThongTinKhamBenh: () => {},
  benhNhanList: [],
  setBenhNhanList: () => {},
  setThongTinChiPhi: () => {},
  thongTinChiPhi: undefined,
  statusChangedEventFunc: () => {},
};

export const PhanHeTiepDonContext = createContext<ContextType>(initValue);
