export const dsPhieuDieuTri = [
  {
    ngay: "07-03-2024",
    khoa: "Khoa Truyền nhiễm"
  },
  {
    ngay: "07-03-2024",
    khoa: "Khoa Truyền nhiễm"
  },
  {
    ngay: "07-03-2024",
    khoa: "Khoa Truyền nhiễm"
  },
]