import { Column } from 'react-table';
import { TableCustomCell } from '../../../component/table/components/TableCustomCell';
import { TableCustomHeader } from '../../../component/table/components/TableCustomHeader';
import { columnNamesType } from '../../../component/table/simple-table/SimpleTable';
import { formatMoney } from '../../../utils/FormatUtils';

type ColumnType = columnNamesType & {
	width: number;
	textAlign?: 'left' | 'center' | 'right';
};

export const columnsKeVatTu: ColumnType[] = [
	{
		name: '',
		field: 'radio',
		width: 50,
		textAlign: 'center',
	},
	{
		name: 'Mã vật tư',
		field: 'maVatTu',
		width: 150,
		textAlign: 'center',
	},
	{
		name: 'Tên vật tư',
		field: 'tenVatTu',
		width: 450,
		flexGrow: 1
	},
	{
		name: 'Đơn vị',
		field: 'donVi',
		width: 150,
		textAlign: 'center',
	},
	{
		name: 'Tồn kho',
		field: 'tonKho',
		width: 100,
		textAlign: 'center',
	},
	{
		name: 'Giá BHYT',
		field: 'giaBhyt',
		width: 150,
		textAlign: 'right',
	},
	{
		name: 'Giá viện phí',
		field: 'giaVienPhi',
		width: 150,
		textAlign: 'right',
	},
	{
		name: 'Giá dịch vụ',
		field: 'giaDichVu',
		width: 150,
		textAlign: 'right',
	},
];

const columnsDSMauChiDinhChiTiet: ReadonlyArray<Column<any>> = [
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title={'Mã chỉ định'}
				className="p-table text-center min-w-150px "
			/>
		),
		id: 'Mã chỉ định',
		Cell: ({ ...props }) => (
			<TableCustomCell
				data={props?.data[props?.row?.index]?.maChiDinh}
				className="p-table cell-first-child text-center ps-0"
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title={'Tên chỉ định'}
				className="p-table text-center min-w-175px"
			/>
		),
		id: 'Tên chỉ định',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className="p-table text-center text-uppercase"
				data={props?.data[props?.row?.index]?.tenChiDinh || ''}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title={'Nhóm dịch vụ'}
				className="p-table text-center min-w-175px"
			/>
		),
		id: 'Nhóm dịch vụ',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className="p-table text-center text-uppercase"
				data={props?.data[props?.row?.index]?.nhomDichVu || ''}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title={'Loại dịch vụ'}
				className="p-table text-center min-w-175px"
			/>
		),
		id: 'Loại dịch vụ',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className="p-table text-center text-uppercase"
				data={props?.data[props?.row?.index]?.loaiDichVu || ''}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title={'Mẫu riêng của bác sĩ'}
				className="p-table text-center min-w-250px"
			/>
		),
		id: 'Mẫu riêng của bác sĩ',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className="p-table text-center text-uppercase"
				data={props?.data[props?.row?.index]?.mauRiengCuaBacSi || ''}
			/>
		),
	},
];

const columnsPhieuChiDinhCu: ReadonlyArray<Column<any>> = [
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title={'Ngày y lệnh'}
				className="p-table text-center min-w-150px "
			/>
		),
		id: 'Ngày y lệnh',
		Cell: ({ ...props }) => (
			<TableCustomCell
				data={props?.data[props?.row?.index]?.ngayYLenh}
				className="p-table cell-first-child text-center ps-0"
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title={'Người kê'}
				className="p-table text-center min-w-150px"
			/>
		),
		id: 'Người kê',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className="p-table text-center text-uppercase"
				data={props?.data[props?.row?.index]?.nguoiKeKhai || ''}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title={'Phòng khám'}
				className="p-table text-center min-w-150px"
			/>
		),
		id: 'Phòng khám',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className="p-table text-center text-uppercase"
				data={props?.data[props?.row?.index]?.phongKham || ''}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title={'Chẩn đoán'}
				className="p-table text-center min-w-150px"
			/>
		),
		id: 'Chẩn đoán',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className="p-table text-center text-uppercase"
				data={props?.data[props?.row?.index]?.chanDoan || ''}
			/>
		),
	},
];

const columnsPhieuChiDinhCuVatTu: ReadonlyArray<Column<any>> = [
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title={'STT'}
				className="p-table text-center min-w-40px "
			/>
		),
		id: 'STT',
		Cell: ({ ...props }) => (
			<TableCustomCell
				data={props?.data[props?.row?.index]?.maChiDinh}
				className="p-table cell-first-child text-center ps-0"
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title={'Tên vật tư'}
				className="p-table text-center min-w-175px "
			/>
		),
		id: 'Tên vật tư',
		Cell: ({ ...props }) => (
			<TableCustomCell
				data={props?.data[props?.row?.index]?.tenVatTu}
				className="p-table cell-first-child text-center ps-0"
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title={'Đơn vị'}
				className="p-table text-center min-w-150px"
			/>
		),
		id: 'Đơn vị',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className="p-table text-center text-uppercase"
				data={props?.data[props?.row?.index]?.donVi || ''}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title={'Số lượng'}
				className="p-table text-center min-w-150px"
			/>
		),
		id: 'Số lượng',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className="p-table text-center text-uppercase"
				data={props?.data[props?.row?.index]?.soLuong || ''}
			/>
		),
	},
];

export const columnsPhieuChiDinhKeVatTu: ReadonlyArray<Column<any>> = [
		{
			Header: props => (
				<TableCustomHeader
					tableProps={props}
					title="STT"
					className="p-table text-center min-w-50"
				/>
			),
			id: 'STT',
			Cell: ({ ...props }) => (
				<TableCustomCell className="p-table text-center" data={+props.row.id + 1} />
			),
		},
		{
			Header: props => (
				<TableCustomHeader
					tableProps={props}
					title="Mã vật tư"
					className="p-table text-center min-w-150px"
				/>
			),
			id: 'Mã vật tư',
			Cell: ({ ...props }) => (
				<TableCustomCell
					className="p-table text-center"
					data={props?.data[props?.row?.index].maVatTu}
				/>
			),
		},
		{
			Header: props => (
				<TableCustomHeader
					tableProps={props}
					title="Tên vật tư"
					className="p-table text-center min-w-400px"
				/>
			),
			id: 'Tên vật tư',
			Cell: ({ ...props }) => (
				<TableCustomCell
					className="p-table"
					data={props?.data[props?.row?.index].tenVatTu}
				/>
			),
		},
		{
			Header: props => (
				<TableCustomHeader
					tableProps={props}
					title="Đơn vị"
					className="p-table text-center min-w-150px"
				/>
			),
			id: 'Đơn vị',
			Cell: ({ ...props }) => (
				<TableCustomCell
					className="p-table text-center"
					data={props?.data[props?.row?.index].donVi}
				/>
			),
		},
		{
			Header: props => (
				<TableCustomHeader
					tableProps={props}
					title="Số lượng"
					className="p-table text-center min-w-100px"
				/>
			),
			id: 'Số lượng',
			Cell: ({ ...props }) => (
				<TableCustomCell
					className="p-table text-center"
					data={props?.data[props?.row?.index]?.soLuong}
				/>
			),
		},
		{
			Header: props => (
				<TableCustomHeader
					tableProps={props}
					title="Đơn giá"
					className="p-table text-center min-w-100px"
				/>
			),
			id: 'Đơn giá',
			Cell: ({ ...props }) => (
				<TableCustomCell
					className="p-table text-end"
					data={
						props?.data[props?.row?.index]?.donGia
						? formatMoney(props?.data[props?.row?.index]?.donGia)
						: 0
					}
				/>
			),
		},
		{
			Header: props => (
				<TableCustomHeader
					tableProps={props}
					title="Thành tiền"
					className="p-table text-center min-w-100px"
				/>
			),
			id: 'Thành tiền',
			Cell: ({ ...props }) => (
				<TableCustomCell
					className="p-table text-end"
					data={
						props?.data[props?.row?.index]?.thanhTien
						? formatMoney(props?.data[props?.row?.index]?.thanhTien)
						: 0
					}
				/>
			),
		},
		{
			Header: props => (
				<TableCustomHeader
					tableProps={props}
					title="Đối tượng dịch vụ"
					className="p-table text-center min-w-150px"
				/>
			),
			id: 'Đối tượng dịch vụ',
			Cell: ({ ...props }) => (
				<TableCustomCell
					className="p-table"
					data={props?.data[props?.row?.index]?.doiTuong}
				/>
			),
		},
];

export { columnsDSMauChiDinhChiTiet, columnsPhieuChiDinhCu, columnsPhieuChiDinhCuVatTu };

