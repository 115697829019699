import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import NumberField from '../../../../../component/number-field';
import TextField from '../../../../../component/TextField';

type Props = {
	data: any;
	onSave: (value: any) => void;
};

const TableSelectVatTuRowEdit = ({ data, onSave }: Props) => {
	const { values, setValues } = useFormikContext<any>();

	useEffect(() => {
		setValues({
			...values,
			tenVatTu: data?.tenVatTu,
			donVi: data?.donVi,
			tonKho: data?.tonKho,
			soLuong: data?.soLuong,
		});
	}, [data?.id]);

	const onSaveRow = () => {
		if (!values?.soLuong) {
			toast.warn('Chưa nhập số lượng vật tư cần kê');
			return;
		}

		onSave({
			...data,
			tenVatTu: values.tenVatTu,
			donVi: values.donVi,
			tonKho: values.tonKho,
			soLuong: values.soLuong,
		});

		setValues({
			...values,
			tenVatTu: '',
			donVi: '',
			tonKho: 0,
			soLuong: 0,
		});
	};

	return (
		<Row>
			<Col xs={4}>
				<TextField
					label="Tên vật tư:"
					className="spaces w-100"
					inputClassName="w-100"
					name="tenVatTu"
					labelClassName="ms-0 min-w-80px"
					disabled
				/>
			</Col>
			<Col xs={2}>
				<TextField
					label="Đơn vị:"
					className="spaces w-100"
					inputClassName="w-100"
					name="donVi"
					labelClassName="ms-0 min-w-60px"
					disabled={!values?.tenVatTu}
				/>
			</Col>
			<Col xs={2}>
				<NumberField
					label="Tồn kho:"
					className="spaces w-100"
					inputClassName="w-100"
					name="tonKho"
					labelClassName="ms-0 min-w-70px"
					disabled={!values?.tenVatTu}
				/>
			</Col>
			<Col xs={2}>
				<NumberField
					label="Số lượng:"
					className="spaces w-100"
					inputClassName="w-100"
					name="soLuong"
					labelClassName="ms-0 min-w-70px"
					disabled={!values?.tenVatTu}
				/>
			</Col>
			<Col xs={2}>
				<Button className="btn-fill" onClick={onSaveRow} disabled={!values?.tenVatTu}>
					<i className="bi bi-check-lg"></i>Xác nhận
				</Button>
			</Col>
		</Row>
	);
};

export default TableSelectVatTuRowEdit;
