import axios from "axios";

const API_PATH = process.env.REACT_APP_EMR_API_URL;
const API_PE_INVOICE = API_PATH + "/pe-invoices";

export const createReceiptHD = (data: any) => {
  const url = API_PE_INVOICE;
  return axios.post(url, data);
};

export const getListReceiptHD = (contractId: string) => {
  const url = API_PE_INVOICE;
  const params = {
    contractId: contractId,
  };
  return axios.get(url, { params: params });
};

export const getInfoPayment = (contractId: string) => {
  const url = API_PE_INVOICE + "/payment";
  const params = {
    contractId: contractId,
  };
  return axios.get(url, { params: params });
};
