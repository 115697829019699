import { Column } from "react-table";
import { TableCustomHeader } from "../../../component/table/components/TableCustomHeader";
import { TableCustomCell } from "../../../component/table/components/TableCustomCell";
import { TableCustom } from "../../../component/table/table-custom/TableCustom";

type Props = {
  dataKetQuaCLS: any;
  handleDoubleClick?: () => void;
};

const BangDsKetQuaCLS = (props: Props) => {
  let {
    dataKetQuaCLS,
    handleDoubleClick = () => {},
  } = props;

  const columnsDSKetQuaCLD : ReadonlyArray<
  Column<any>
> = [
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"STT"}
          className=" text-center text-white align-middle bg-pri"
        />
      ),
      id: "STT",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={String(props?.row?.index + 1)}
          className="text-center fs-14"
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"DỊCH VỤ"}
          className="text-center text-white align-middle bg-pri min-w-200px"
        />
      ),
      id: "name",
      Cell: ({ ...props }) => (
        <TableCustomCell
          className="s-2 fs-14"
          data={props.data[props.row.index]?.name}
        />
      ),
    },
    {
      Header: (props) => (
        <TableCustomHeader<any>
          tableProps={props}
          title={"Kết quả"}
          className=" text-center text-white align-middle bg-pri min-w-200px"
        />
      ),
      id: "Kết quả",
      Cell: ({ ...props }) => (
        <TableCustomCell
          data={props.data[props.row.index]?.result}
          className="text-center fs-14"
        />
      ),
    },
  ];

  return (
    <>
      <TableCustom<any>
        className={"h-bang-ds-bn spaces h-calc-vh-480"}
        data={dataKetQuaCLS || []}
        columns={columnsDSKetQuaCLD}
        handleDoubleClick={handleDoubleClick}
        verticalScroll={true}
      />
    </>
  );
};

export default BangDsKetQuaCLS;
