import { useRef, useState } from 'react';
import { Row } from 'react-table';
import { ConfirmDialog } from '../../../../../component/ConfirmDialog';
import ContextMenu from '../../../../../component/ContextMenuV3';
import { TableCustom } from '../../../../../component/table/table-custom/TableCustom';
import { columnsPhieuChiDinhKeVatTu } from '../../../../columns/modal-ke-vat-tu/ColumnModalKeVatTu';
import { contextMenuKeVatTuOptions, CONTEXT_MENU_KE_VAT_TU_KEY } from '../../../../constants/VatTu';
import { useKeVatTuContext } from '../../context';
import { useTableSelectVatTuContext } from '../../context/TableSelectVatTuContext';

const TablePhieuChiDinhVatTu = () => {
	const { vatTuChecked, setVatTuChecked } = useKeVatTuContext();
	const { setSelectedRow } = useTableSelectVatTuContext();

	const [contextMenu, setContextMenu] = useState<null | {
		x: number;
		y: number;
	}>(null);
	const rowSelectedRef = useRef<any>();
	const [openCofirmDeleteDialog, setOpenCofirmDeleteDialog] = useState<boolean>(false);

	const onClickContextMenu = (value: any) => {
		const actionCode = value.code;
		switch (actionCode) {
			case CONTEXT_MENU_KE_VAT_TU_KEY.XOA_VAT_TU:
				setOpenCofirmDeleteDialog(true);
				break;
			default:
				break;
		}
		setContextMenu(null);
	};

	const handleContextMenu = (e: any, row: Row) => {
		e.preventDefault();
		setContextMenu({ x: e.clientX, y: e.clientY });
		rowSelectedRef.current = row.original;
	};

	const onDeleteVatTu = () => {
		let listTemp = [];

		if (rowSelectedRef.current.idBackend) {
			listTemp = vatTuChecked.map((row: any) => {
				if (row.idBackend === rowSelectedRef.current.idBackend) {
					return {
						...row,
						deleted: true,
					};
				}
				return row;
			});
		} else {
			listTemp = vatTuChecked.filter(row => row.id !== rowSelectedRef.current.id);
		}
		setVatTuChecked(listTemp);
		setOpenCofirmDeleteDialog(false);
	};

	return (
		<div className="d-flex flex-column flex-grow-1 mt-2 bg-white">
			<div className="d-flex spaces pt-10 pb-6 text-pri fw-bold">Phiếu chỉ định</div>
			<div className="flex-grow-1 spaces h-calc-vh-710">
				<TableCustom
					columns={columnsPhieuChiDinhKeVatTu}
					data={vatTuChecked.filter(row => !row.deleted)}
					handleContextMenu={handleContextMenu}
					height={220}
					maxHeight={220}
					handleDoubleClick={(row: any) => {
						setSelectedRow(row.original);
					}}
				/>
			</div>

			{contextMenu && (
				<ContextMenu
					handleClickOptionContextMenu={onClickContextMenu}
					handleCloseMenu={() => setContextMenu(null)}
					data={contextMenuKeVatTuOptions}
					target={contextMenu}
				/>
			)}
			{openCofirmDeleteDialog && (
				<ConfirmDialog
					className="z-index-1060"
					show={openCofirmDeleteDialog}
					title="Thông báo"
					message="Bạn có chắc chắn muốn xóa vật tư này không ?"
					yes="Xác nhận"
					close="Đóng"
					onCloseClick={() => {
						setOpenCofirmDeleteDialog(false);
						rowSelectedRef.current = null;
					}}
					onYesClick={onDeleteVatTu}
				/>
			)}
		</div>
	);
};

export default TablePhieuChiDinhVatTu;
