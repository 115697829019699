import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { FormCheck, FormCheckProps } from 'react-bootstrap';

function IndeterminateCheckbox({
	indeterminate,
	className = '',
	...rest
}: { indeterminate?: boolean } & FormCheckProps) {
	const ref = useRef<HTMLInputElement>(null!);

	useEffect(() => {
		if (typeof indeterminate === 'boolean') {
			ref.current.indeterminate = !rest.checked && indeterminate;
		}
	}, [ref, indeterminate, rest.checked]);

	return (
		<FormCheck
			type="checkbox"
			ref={ref}
			className={clsx('cursor-pointer', className)}
			{...rest}
		/>
	);
}
export default IndeterminateCheckbox;
