import Fuse from 'fuse.js';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { IconMenu } from '../../../../../component/IconSvg';
import InputSearch from '../../../../../component/InputSearch';
import SelectTree from '../../../../../component/SelectTree';
import { CODE, RESPONSE_MESSAGE } from '../../../../../utils/Constant';
import { TreeChiDinhDichVu } from '../../../../constants/KhamBenh';
import { getTreeKhoVatTu, searchVatTu } from '../../../../services/VatTuService';
import { useKeVatTuContext } from '../../context';
import { IKeVatTu, IVatTuItem } from '../../../../models/VatTuModels';
import { AppContext } from '../../../../../appContext/AppContext';

const TreeVatTu = () => {
	const { setIsLoading } = useContext(AppContext);
	const { setTreeNodeValue, setTreeValue } = useKeVatTuContext();

	const [nodeIdSelected, setNodeIdSelected] = useState<string>('');
	const [treeData, setTreeData] = useState<any>(TreeChiDinhDichVu);
	const [codeCollapses, setCodeCollapses] = useState<string[]>([]);

	useEffect(() => {
		getTreeData();
	}, []);

	const getTreeData = async () => {
		try {
			setIsLoading(true);
			const { code, data } = await getTreeKhoVatTu();
			if (CODE.SUCCESS === code && data) {
				const { terms, ...treeInfo } = data;
				setTreeValue(treeInfo);

				const dataConverted = {
					code: data.code,
					name: data.name,
					icon: <IconMenu />,
					filter: convertTreeData([
						{
							name: 'Tất cả vật tư',
							code: 'all',
							terms: data.terms,
						},
					]),
				};
				setTreeData(dataConverted);
			}
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		} finally {
			setIsLoading(false);
		}
	};

	const convertTreeData = (data: any[]) => {
		const convertedData: any[] = data.map(item => {
			return {
				...item,
				code: item?.code || item?.name,
				name: item?.name,
				...(item?.terms &&
					item?.terms?.length && {
						filter: convertTreeData(item?.terms || []),
					}),
			};
		});
		return convertedData;
	};

	const onNodeSelected = async (data: any) => {
		try {
			setIsLoading(true);
			const response: IVatTuItem[] = await searchVatTu({
				materialType: data.code,
			});
			const result: IKeVatTu[] = response.map((vatTu: IVatTuItem) => ({
				id: vatTu.id.toString(),
				donVi: vatTu.unitofMeasure,
				maVatTu: vatTu.code,
				tenVatTu: vatTu.name,
				giaBhyt: Number(vatTu.BHYTPrice),
				giaDichVu: Number(vatTu.sellingPrice),
				giaVienPhi: Number(vatTu.hospitalFeePrice),
				tonKho: 0,
			}));
			setTreeNodeValue(result);
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
		const keySearch = e.target.value;
	};

	return (
		<>
			<div className="spaces px-12 py-5 h-42">
				<InputSearch
					placeholder="Tìm kiếm"
					handleChange={onSearch}
					className="spaces h-32 pr-4 radius-3"
				/>
			</div>
			<SelectTree
				className="w-100 py-2 ps-4 flex-grow-1 border border-top-0 overflow-auto"
				idSelected={nodeIdSelected}
				handleChangeSelectId={setNodeIdSelected}
				getSelectedItem={onNodeSelected}
				selectTree={treeData}
				codeCollapses={codeCollapses}
				handleChangeCollapsesCode={setCodeCollapses}
			/>
		</>
	);
};

export default TreeVatTu;
