import { Column } from "react-table";
import { TableCustomHeader } from "../../component/table/components/TableCustomHeader";
import { TableCustomCell } from "../../component/table/components/TableCustomCell";
import { statusOptions } from "../../phan-he-tiep-nhan-thanh-toan/constants/constants";
import { formatTrangThaiBenhNhanCDHA } from "../../phan-he-cdha-va-tdcn/constants/Constants";
import { IBenhNhanModel } from "../../phan-he-xet-nghiem/models/DanhSachBenhNhanModels";
import { formatTrangThaiBenhNhan } from "../../utils/FormatUtils";
import { UU_TIEN } from "../../phan-he-kham-benh/constants/BenhNhanConst";

export const DanhSachBenhNhanColumns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title=" " className="spaces W-20" />
    ),
    id: "TT",
    Cell: ({ ...props }) => (
      <TableCustomCell
        data={formatTrangThaiBenhNhanCDHA(props?.row?.original?.trangThai)}
        className="text-center"
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader
        tableProps={props}
        title={"STT"}
        className=" text-center spaces W-30"
      />
    ),
    id: "stt",
    Cell: ({ ...props }) => (
      <TableCustomCell data={props?.row?.index + 1} className="text-center" />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader
        tableProps={props}
        title={"Tên bệnh nhân"}
        className=" text-center min-w-200px"
      />
    ),
    id: "tenBenhNhan",
    Cell: ({ ...props }) => (
      <TableCustomCell
        data={props?.row?.original?.hoTen}
        className="text-center"
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader
        tableProps={props}
        title={"Tuổi"}
        className=" text-center text-white align-middle bg-pri"
      />
    ),
    id: "tuoi",
    Cell: ({ ...props }) => (
      <TableCustomCell
        data={props?.row?.original?.age}
        className="text-center"
      />
    ),
  },
];

export const DanhSachDichVuColumns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <TableCustomHeader tableProps={props} title=" " className="spaces W-20" />
    ),
    id: "TT",
    Cell: ({ ...props }) => (
      <TableCustomCell
        data={
          props.data[props.row.index].status ===
          statusOptions.chuaThanhToan.name ? (
            <i className="bi bi-circle-fill text-danger"></i>
          ) : props.data[props.row.index].status ===
            statusOptions.daThanhToan.name ? (
            <i className="bi bi-circle-fill text-success"></i>
          ) : (
            <i className="bi bi-circle-fill text-primary"></i>
          )
        }
        className="text-center"
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader
        tableProps={props}
        title={"STT"}
        className=" text-center spaces W-40"
      />
    ),
    id: "stt",
    Cell: ({ ...props }) => (
      <TableCustomCell data={props?.row?.index + 1} className="text-center" />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader
        tableProps={props}
        title={"Tên dịch vụ"}
        className="min-w-450px"
      />
    ),
    id: "tenDichVu",
    Cell: ({ ...props }) => (
      <TableCustomCell data={props?.row?.original?.tenDichVu} />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader
        tableProps={props}
        title={"Số lượng"}
        className=" text-center "
      />
    ),
    id: "soLuong",
    Cell: ({ ...props }) => (
      <TableCustomCell
        data={props?.row?.original?.soLuong}
        className="text-center"
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader
        tableProps={props}
        title={"Kết luận"}
        className=" text-center min-w-275px"
      />
    ),
    id: "ketLuan",
    Cell: ({ ...props }) => (
      <TableCustomCell data={props?.row?.original?.ketLuan} />
    ),
  },
];

export const ColumnsDsBenhNhanChuyenKhoa: ReadonlyArray<
  Column<IBenhNhanModel>
> = [
  {
    Header: (props) => (
      <TableCustomHeader<IBenhNhanModel>
        tableProps={props}
        title={"TT"}
        className="text-light min-w-30px"
      />
    ),
    id: "TT",
    Cell: ({ ...props }) => (
      <TableCustomCell
        className="text-center"
        data={formatTrangThaiBenhNhan(props?.data[props?.row?.index]?.color)}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<IBenhNhanModel>
        tableProps={props}
        title={"STT"}
        className="text-center text-light min-w-30px"
      />
    ),
    id: "STT",
    Cell: ({ ...props }) => (
      <TableCustomCell className="text-center" data={+props.row.id + 1} />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<IBenhNhanModel>
        tableProps={props}
        title={"Tên bệnh nhân"}
        className="text-center text-light min-w-300px"
      />
    ),
    id: "tenKH",
    Cell: ({ ...props }) => (
      <div className="text-system">
        <div className="flex m-0">
          <span className="text-uppercase fs-14px">
            {props?.data[props?.row?.index]?.patientName}
          </span>
          {props?.data[props?.row?.index]?.uuTien === UU_TIEN.uuTien.code ? (
            <span>
              <i className="bi bi-check-circle-fill text-cyan ms-2"></i>
            </span>
          ) : (
            ""
          )}
        </div>
        <div className="flex m-0">
          <span className="text-uppercase fw-semibold fs-14px">
            {props?.data[props?.row?.index]?.patientCode}
          </span>
          <span className="px-1 fs-14px"> - </span>
          <span className="fs-14px">
            {props?.data[props?.row?.index]?.visitObjectTypeName}
          </span>
          <span className="px-1 fs-14px"> - </span>
          <span className="fs-14px">
            {props?.data[props?.row?.index]?.gender}
          </span>
        </div>
      </div>
    ),
  },
];

export const columnMauDSKetQuaThucHien: ReadonlyArray<Column<any>> = [
  {
    Header: (props: any) => (
      <div className="text-center text-dark min-w-30px p-2 border-x">
        <span>Tên mẫu</span>
      </div>
    ),
    id: "Tên mẫu",
    Cell: ({ ...props }: any) => {
      return (
        <TableCustomCell
          tableProps={props}
          className="px-3 spaces line-height-30 border"
          data={props.row.original.tenMau}
        />
      );
    },
  },
];
