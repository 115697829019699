import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "react-bootstrap";
import LabelRequired from "../../../component/LabelRequired";
import AutocompleteV2 from "../../../component/AutocompleteObjectV2";
import { Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import TextField from "../../../component/TextField";
import { getTermClasses } from "../../../utils/ServicesUtils";
import { TERM_CLASS_IDS } from "../../../utils/Constant";
import ModalICD10 from "../../components/modal-icd10/ModalICD10";
import { PhanHeTiepDonContext } from "../../contexts/PhanHeTiepDonContext";

type Props = {
  open: boolean;
  handleCloseModal: () => void;
  handleSubmit: (values: any) => void;
  modalityDetails?: any;
  setModalityDetails?: (values: any) => void;
};

interface ICapToaChoVe {
  loiDanBacSi?: string;
  diagnosis?: any;
}

const ModalChuyenTuyenVien = ({
  open,
  handleCloseModal,
  handleSubmit,
  modalityDetails,
  setModalityDetails = () => {},
}: Props) => {
  const { benhNhanInfo, thongTinKhamBenh } = useContext(PhanHeTiepDonContext);

  let { values, setFieldValue } = useFormikContext<any>();
  const validationSchema = Yup.object({});
  const [icd10Field, setICD10Field] = useState<string>("");
  const [selectionModeICD10, setSelectionModeICD10] = useState<
    "checkbox" | "radio"
  >();
  const [shouldOpenModalICD10, setShouldOpenModalICD10] =
    useState<boolean>(false);
  const [listICD10, setListICD10] = useState<any>([]);

  const [selectionModeSummaryResult, setSelectionModeSummaryResult] = useState<
    "checkbox" | "radio"
  >();
  const [shouldOpenSummaryResultsModal, setShouldOpenSummaryResultsModal] =
    useState<boolean>(false);

  let encounterDiagnosisValues = values?.diagnosis;
  
  useEffect(() => {
    setListICD10(thongTinKhamBenh?.listICD10 || []);
  }, [thongTinKhamBenh?.listICD10]);

  const handleChangeEncounterDiagnosis = (name: string, value: any) => {
    setModalityDetails({
      ...modalityDetails,
      name: value,
    });
    setFieldValue("diagnosis", {
      ...encounterDiagnosisValues,
      [name]: value,
    });
  };

  const handleChangeICD10 = (data: any) => {
    switch (icd10Field) {
      case "diagnosis":
        handleChangeEncounterDiagnosis(`${icd10Field}`, data?.code || "");
        break;
      case "comorbidity":
        let comorbidity = data?.map((item: any) => item?.name).join("; ");
        handleChangeEncounterDiagnosis(`${icd10Field}`, comorbidity || "");
        break;
      default:
        break;
    }
  };
  return (
    <Modal
      centered
      className="modal fade"
      role="dialog"
      show={open}
      dialogClassName="modal-xl"
      aria-hidden="true"
    >
      <Formik<ICapToaChoVe>
        initialValues={{}}
        validationSchema={validationSchema}
        onSubmit={(valuesSubmit) => {
          let dataSubmit = {
            ...valuesSubmit,
            ...modalityDetails,
            diagnosis: values?.diagnosis?.diagnosis,
            comorbidity: values?.diagnosis?.comorbidity,
          };
          handleSubmit(dataSubmit);
        }}
      >
        <Form className="h-100">
          <ModalHeader closeButton onHide={handleCloseModal} className="py-4">
            <Modal.Title>Chuyển tuyến/viện</Modal.Title>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col
                xs={3}
                className="d-flex mb-3 align-items-center text-lable-input"
              >
                <LabelRequired label="Bệnh nhân" className="min-w-80px" />
                <TextField
                  name="tenBenhNhan"
                  value={benhNhanInfo?.thongTinKhamBenh?.patient?.personName}
                  type="text"
                />
              </Col>
              <Col
                xs={3}
                className="d-flex mb-3 align-items-center text-lable-input"
              >
                <LabelRequired label="Số lưu trữ" className="min-w-80px" />
                <TextField
                  name="recordNumber"
                  value={modalityDetails?.recordNumber || ""}
                  type="text"
                  onChange={(e: any) => {
                    setModalityDetails({
                      ...modalityDetails,
                      recordNumber: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col
                xs={3}
                className="d-flex mb-3 align-items-center text-lable-input"
              >
                <LabelRequired label="Chuyển viện" className="min-w-90px" />
                {/* <AutocompleteV2
                  options={[]}
                  className="name autocomplete-custom radius spaces width-100 "
                  name="transferFrom"
                /> */}
                <TextField
                  name="transferFrom"
                  type="text"
                  value={modalityDetails?.transferFrom || ""}
                  onChange={(e: any) => {
                    setModalityDetails({
                      ...modalityDetails,
                      transferFrom: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col
                xs={3}
                className="d-flex mb-3 align-items-center text-lable-input"
              >
                <LabelRequired label="Chuyển đến" className="min-w-90px" />
                {/* <AutocompleteV2
                  options={[]}
                  className="name autocomplete-custom radius spaces width-100 "
                  name="transferTo"
                /> */}
                <TextField
                  name="transferTo"
                  type="text"
                  value={modalityDetails?.transferTo || ""}
                  onChange={(e: any) => {
                    setModalityDetails({
                      ...modalityDetails,
                      transferTo: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <div className="color-subtitle spaces py-12">Tóm tắt bệnh án</div>
            <Row className="mb-4">
              <Col xs={6}>
                <LabelRequired label="Dấu hiệu lâm sàng" />
                <TextField
                  name="clinicalSigns"
                  as={"textarea"}
                  rows={3}
                  value={modalityDetails?.clinicalSigns || ""}
                  onChange={(e: any) => {
                    setModalityDetails({
                      ...modalityDetails,
                      clinicalSigns: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col xs={6}>
                <LabelRequired label="Tình trạng người bệnh lúc chuyển viện" />
                <TextField
                  name="patientCondition"
                  as={"textarea"}
                  rows={3}
                  value={modalityDetails?.patientCondition || ""}
                  onChange={(e: any) => {
                    setModalityDetails({
                      ...modalityDetails,
                      patientCondition: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xs={6}>
                <LabelRequired label="" />
                <div className="spaces d-flex flex-row justify-content-between">
                  <div className="d-flex ">
                    <LabelRequired
                      label="Tóm tắt kết quả CLS có giá trị chẩn đoán"
                      className="min-w-150px"
                    />
                  </div>
                  <Button
                    className="bg-white padding-0"
                    onClick={() => {
                      setSelectionModeSummaryResult("checkbox");
                      setShouldOpenSummaryResultsModal(true);
                    }}
                    disabled={false}
                  >
                    <u className="color-17A2B8">Chọn kết quả</u>
                  </Button>
                </div>
                <Row className="spaces pe-0 align-items-center">
                  <TextField
                    name="paraclinicalResults"
                    as="textarea"
                    rows={5}
                    className="spaces h-96"
                    value={modalityDetails?.paraclinicalResults || ""}
                    onChange={(e: any) => {
                      setModalityDetails({
                        ...modalityDetails,
                        paraclinicalResults: e.target.value,
                      });
                    }}
                  />
                </Row>
              </Col>
              <Col xs={6}>
                <div className="mb-2">
                  <div className="d-flex ">
                    <LabelRequired label="Chẩn đoán" className="min-w-150px" />
                  </div>
                  <Row className="spaces pe-0 align-items-center">
                    <Col xs="3" className="pe-0 position-relative">
                      <TextField
                        name="diagnosis"
                        value={
                          encounterDiagnosisValues?.diagnosis ||
                          modalityDetails?.diagnosis || ""
                        }
                      />
                      <div className="spaces width-4">
                        <h6
                          className="position-absolute muc-huong cursor-pointer"
                          onClick={() => {
                            setICD10Field("diagnosis");
                            setSelectionModeICD10("radio");
                            setShouldOpenModalICD10(true);
                          }}
                        >
                          <i className="bi bi-three-dots fs-4 text-pri d-block spaces mt-5"></i>
                        </h6>
                      </div>
                    </Col>
                    <Col xs="9" className="ps-0">
                      <TextField
                        name="diagnosis"
                        value={
                          listICD10.find(
                            (item: any) =>
                              item.code ===
                                encounterDiagnosisValues?.diagnosis ||
                              item.code === modalityDetails?.diagnosis || ""
                          )?.name || ""
                        }
                      />
                    </Col>
                  </Row>
                </div>

                <div className="mb-2">
                  <div className="spaces d-flex flex-row justify-content-between">
                    <div className="d-flex ">
                      <LabelRequired
                        label="Bệnh kèm theo"
                        className="min-w-150px"
                      />
                    </div>
                    <Button
                      className="bg-white padding-0"
                      onClick={() => {
                        setICD10Field("comorbidity");
                        setSelectionModeICD10("checkbox");
                        setShouldOpenModalICD10(true);
                      }}
                      disabled={false}
                    >
                      <u className="color-17A2B8">Chọn bệnh</u>
                    </Button>
                  </div>
                  <Row className="spaces pe-0 align-items-center">
                    <TextField
                      name="comorbidity"
                      as="textarea"
                      rows={2}
                      value={
                        encounterDiagnosisValues?.comorbidity ||
                        modalityDetails?.diagnosis || ""
                      }
                    />
                  </Row>
                </div>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xs={6}>
                <LabelRequired label="Hướng điều trị" />
                <TextField
                  name="treatmentPlan"
                  value={modalityDetails?.treatmentPlan || ""}
                  as={"textarea"}
                  rows={3}
                  onChange={(e: any) => {
                    setModalityDetails({
                      ...modalityDetails,
                      treatmentPlan: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col xs={6}>
                <LabelRequired label="Thuốc đã dùng" />
                <TextField
                  name="medications"
                  as={"textarea"}
                  value={modalityDetails?.medications || ""}
                  rows={3}
                  onChange={(e: any) => {
                    setModalityDetails({
                      ...modalityDetails,
                      medications: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row className="mb-4">
              <Col xs={6}>
                <LabelRequired label="Lý do chuyển viện" />
                {/* <AutocompleteV2
                  options={[]}
                  className="name autocomplete-custom radius spaces width-100 "
                  name="transferReason"
                /> */}
                <TextField
                  name="transferReason"
                  type="text"
                  value={modalityDetails?.transferReason || ""}
                  onChange={(e: any) => {
                    setModalityDetails({
                      ...modalityDetails,
                      transferReason: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col xs={3}>
                <LabelRequired label="Phương tiện vận chuyển" />
                {/* <AutocompleteV2
                  options={[]}
                  className="name autocomplete-custom radius spaces width-100 "
                  name="transportation"
                /> */}
                <TextField
                  name="transportation"
                  type="text"
                  value={modalityDetails?.transportation || ""}
                  onChange={(e: any) => {
                    setModalityDetails({
                      ...modalityDetails,
                      transportation: e.target.value,
                    });
                  }}
                />
              </Col>
              <Col xs={3}>
                <LabelRequired label="Người đưa đi" />
                <TextField
                  name="escort"
                  type="text"
                  value={modalityDetails?.escort || ""}
                  onChange={(e: any) => {
                    setModalityDetails({
                      ...modalityDetails,
                      escort: e.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="d-flex justify-content-end spaces py-6 px-10 gap-10 m-0">
            <Button className="btn-fill w-80px" type="submit">
              Lưu
            </Button>
            <Button className="btn-fill w-80px">In</Button>
            <Button className="btn-secondary w-80px" onClick={handleCloseModal}>
              Thoát
            </Button>
          </ModalFooter>
        </Form>
      </Formik>
      {shouldOpenModalICD10 && (
        <ModalICD10
          data={listICD10}
          handleClose={() => setShouldOpenModalICD10(false)}
          setMauSelected={handleChangeICD10}
          selectionMode={selectionModeICD10}
        />
      )}
    </Modal>
  );
};

export default ModalChuyenTuyenVien;
