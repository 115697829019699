// @ts-nocheck
import { Col, Row } from 'react-bootstrap';
import { Column } from 'react-table';
import { TableCustomCell } from '../../../component/table/components/TableCustomCell';
import { TableCustomHeader } from '../../../component/table/components/TableCustomHeader';
import { IDanhSachMauThuocCu, IKeThuocRequest, IMauThuocCu } from '../../models/ThuocModels';
import { formatMoney } from '../../../utils/FormatUtils';

const chiDinhThuocColumn: ReadonlyArray<Column<any>> = [
	{
		Header: props => <></>,
		id: 'dv',
		Cell: ({ ...props }) => (
			<TableCustomCell
				data={
					<div className="ms-2">
						<Row>
							<Col xs="12">
								<div>
									<span className="my-1">
										{props.data[props.row.index]?.maPhieu}
									</span>{' '}
									-{' '}
									<span className="my-1">
										{props.data[props.row.index]?.ngayChiDinh}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs="12">
								<span className="my-1">{props.data[props.row.index]?.benhAn}</span>{' '}
								-{' '}
								<span className="my-1">
									{props.data[props.row.index]?.thoiGian}
								</span>
							</Col>
						</Row>
					</div>
				}
			/>
		),
	},
];

const columnsDSThuoc = [
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'STT'}
				className=" text-center min-w-30px "
			/>
		),
		id: 'STT',
		accessor: 'stt',
		Cell: ({ ...props }) => (
			<TableCustomCell
				data={String(props?.row?.index + 1)}
				className=" cell-first-child text-center ps-0"
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Tên thuốc'}
				className=" text-center min-w-250px"
			/>
		),
		id: 'Tên thuốc',
		accessor: 'tenThuoc',
		Cell: ({ value }: any) => <TableCustomCell className="text-start" data={value} />,
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Mã thuốc'}
				className=" text-center spaces min-w-110"
			/>
		),
		id: 'Mã thuốc',
		accessor: 'maThuoc',
		Cell: ({ value }: any) => <TableCustomCell className="text-start" data={value} />,
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Đơn vị'}
				className=" text-center spaces min-w-80"
			/>
		),
		id: 'Đơn vị',
		accessor: 'donVi',
		Cell: ({ value }: any) => <TableCustomCell className="text-start" data={value} />,
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Kê'}
				className=" text-center spaces min-w-50"
			/>
		),
		id: 'Kê',
		accessor: 'ke',
		Cell: ({ value }: any) => <TableCustomCell className="text-start" data={value} />,
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Lĩnh'}
				className=" text-center spaces min-w-65"
			/>
		),
		id: 'Lĩnh',
		accessor: 'linh',
		Cell: ({ value }: any) => <TableCustomCell className="text-start" data={value} />,
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Đường dùng'}
				className=" text-center min-w-150px"
			/>
		),
		id: 'Đường dùng',
		accessor: 'duongDung',
		Cell: ({ value }: any) => <TableCustomCell className="text-start" data={value} />,
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'HDSD'}
				className=" text-center min-w-200px"
			/>
		),
		id: 'HDSD',
		accessor: 'huongDanSuDungThuoc',
		Cell: ({ value }: any) => <TableCustomCell className="text-start" data={value} />,
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Số lượng'}
				className=" text-center min-w-200px"
			/>
		),
		id: 'Số lượng',
		accessor: 'soLuong',
		Cell: ({ value }: any) => <TableCustomCell className="text-start" data={value} />,
	},
];

export const columnsDanhSachDonThuoc: ReadonlyArray<Column<IKeThuocRequest>> = [
	{
		Header: (props: any) => (
			<TableCustomHeader tableProps={props} title="STT" className="text-center min-w-30px" />
		),
		id: 'stt',
		Cell: ({ ...props }) => (
			<TableCustomCell data={props?.row?.index + 1} className="text-center" />
		),
	},
	{
		Header: (props: any) => (
			<TableCustomHeader
				tableProps={props}
				title="Tên thuốc"
				className="text-center min-w-300px"
			/>
		),
		id: 'tenThuoc',
		Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].name} />,
	},
	{
		Header: (props: any) => (
			<TableCustomHeader
				tableProps={props}
				title="Mã thuốc"
				className="text-center min-w-100px"
			/>
		),
		id: 'maThuoc',
		Cell: ({ ...props }) => <TableCustomCell data={props.data[props.row.index].medicalItemCode} />,
	},
	{
		Header: (props: any) => (
			<TableCustomHeader
				tableProps={props}
				title="Đơn vị"
				className="text-center min-w-80px"
			/>
		),
		id: 'donVi',
		Cell: ({ ...props }) => (
			<TableCustomCell className="text-center" data={props.data[props.row.index].unit} />
		),
	},
	{
		Header: (props: any) => (
			<TableCustomHeader tableProps={props} title="Kê" className="text-center min-w-80px" />
		),
		id: 'ke',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className="text-center"
				data={props.data[props.row.index].totalQuantity}
			/>
		),
	},
	{
		Header: (props: any) => (
			<TableCustomHeader tableProps={props} title="Lĩnh" className="text-center min-w-80px" />
		),
		id: 'linh',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className="text-center"
				data={props.data[props.row.index].totalQuantity}
			/>
		),
	},
	{
		Header: (props: any) => (
			<TableCustomHeader
				tableProps={props}
				title="Ngày dùng"
				className="text-center min-w-120-px"
			/>
		),
		id: 'ngayDung',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className="text-center"
				data={props.data[props.row.index].numberOfDays}
			/>
		),
	},
	{
		Header: (props: any) => (
			<TableCustomHeader
				tableProps={props}
				title="Đường dùng"
				className="text-center min-w-120-px"
			/>
		),
		id: 'duongDung',
		Cell: ({ ...props }) => (
			<TableCustomCell className="text-center" data={props.data[props.row.index].route} />
		),
	},
	{
		Header: (props: any) => (
			<TableCustomHeader
				tableProps={props}
				title="Hướng dẫn sử dụng"
				className="text-center min-w-200px"
			/>
		),
		id: 'hdsd',
		Cell: ({ ...props }) => (
			<TableCustomCell data={props.data[props.row.index].usageInstructions} 
				className='max-w-200 text-truncate'
			/>
		),
	},
	{
		Header: (props: any) => (
			<TableCustomHeader
				tableProps={props}
				title="Đơn giá"
				className="text-center min-w-100px"
			/>
		),
		id: 'donGia',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className="text-end"
				data={
					props.data[props.row.index].unitPrice
						? formatMoney(props.data[props.row.index].unitPrice)
						: 0
				}
			/>
		),
	},
	{
		Header: (props: any) => (
			<TableCustomHeader
				tableProps={props}
				title="Thành tiền"
				className="text-center min-w-100px"
			/>
		),
		id: 'thanhTien',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className="text-end"
				data={
					props.data[props.row.index].intoMoney
						? formatMoney(props.data[props.row.index].intoMoney)
						: 0
				}
			/>
		),
	},
];

const columnsDSChiTietMau = [
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Mã chỉ định'}
				className=" text-center min-w-150px "
			/>
		),
		id: 'Mã chỉ định',
		Cell: ({ ...props }) => (
			<TableCustomCell
				data={props?.data[props?.row?.index]?.maChiDinh}
				className=" cell-first-child text-center ps-0"
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Tên chỉ định'}
				className=" text-center min-w-175px"
			/>
		),
		id: 'Tên chỉ định',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className=" text-center text-uppercase"
				data={props?.data[props?.row?.index]?.tenChiDinh || ''}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Nhóm dịch vụ'}
				className=" text-center min-w-175px"
			/>
		),
		id: 'Nhóm dịch vụ',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className=" text-center text-uppercase"
				data={props?.data[props?.row?.index]?.nhomDichVu || ''}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Loại dịch vụ'}
				className=" text-center min-w-175px"
			/>
		),
		id: 'Loại dịch vụ',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className=" text-center text-uppercase"
				data={props?.data[props?.row?.index]?.loaiDichVu || ''}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Mẫu riêng của bác sĩ'}
				className=" text-center min-w-250px"
			/>
		),
		id: 'Mẫu riêng của bác sĩ',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className=" text-center text-uppercase"
				data={props?.data[props?.row?.index]?.mauRienCuaBacSi || ''}
			/>
		),
	},
];
const columnsPhieuChiDinhCu = [
	{
		Header: props => (
			<TableCustomHeader<IDanhSachMauThuocCu>
				tableProps={props}
				title='Ngày y lệnh'
				className="text-center min-w-100px "
			/>
		),
		id: 'Ngày y lệnh',
		Cell: ({ ...props }) => (
			<TableCustomCell
				data={props?.data[props?.row?.index]?.doctorOrderDay}
				className="cell-first-child text-center ps-0"
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader<IDanhSachMauThuocCu>
				tableProps={props}
				title='Người kê'
				className="text-center min-w-150px"
			/>
		),
		id: 'nguoiKe',
		Cell: ({ ...props }) => (
			<TableCustomCell
				data={props?.data[props?.row?.index]?.designatedPerson}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader<IDanhSachMauThuocCu>
				tableProps={props}
				title='Phòng khám'
				className="text-center min-w-150px"
			/>
		),
		id: 'Phòng khám',
		Cell: ({ ...props }) => (
			<TableCustomCell
				data={props?.data[props?.row?.index]?.designatedPlace}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader<IDanhSachMauThuocCu>
				tableProps={props}
				title='Chẩn đoán'
				className="text-center min-w-300px"
			/>
		),
		id: 'Chẩn đoán',
		Cell: ({ ...props }) => (
			<TableCustomCell
				data={props?.data[props?.row?.index]?.chanDoan}
			/>
		),
	},
];

const columnsPhieuChiDinhCuThuoc = [
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title='STT'
				className="text-center min-w-40px"
			/>
		),
		id: 'STT',
		Cell: ({ ...props }) => (
			<TableCustomCell
				data={String(props?.row?.index + 1)}
				className="cell-first-child text-center ps-0"
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title='Tên thuốc'
				className="text-center min-w-250px"
			/>
		),
		id: 'Tên thuốc',
		Cell: ({ ...props }) => (
			<TableCustomCell
				data={props?.data[props?.row?.index]?.name}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title='Đơn vị'
				className="text-center min-w-150px"
			/>
		),
		id: 'Đơn vị',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className="text-center"
				data={props?.data[props?.row?.index]?.unit}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title='Số lượng'
				className="text-center min-w-100px"
			/>
		),
		id: 'Số lượng',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className="text-center"
				data={props?.data[props?.row?.index]?.totalQuantity}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title='Ngày dùng'
				className="text-center min-w-100px"
			/>
		),
		id: 'Ngày dùng',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className="text-center"
				data={props?.data[props?.row?.index]?.numberOfDays}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title='Đường dùng'
				className="text-center min-w-150px"
			/>
		),
		id: 'Đường dùng',
		Cell: ({ ...props }) => (
			<TableCustomCell
				data={props?.data[props?.row?.index]?.route}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader
				tableProps={props}
				title='Đối tượng DV'
				className="text-center min-w-120px"
			/>
		),
		id: 'Đối tượng DV',
		Cell: ({ ...props }) => (
			<TableCustomCell
				data={props?.data[props?.row?.index]?.visitObjectTypeName}
			/>
		),
	},
];

const columnsDSMauChiDinhThuoc = [
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Mã thuốc'}
				className=" text-center min-w-150px "
			/>
		),
		id: 'Mã thuốc',
		Cell: ({ ...props }) => (
			<TableCustomCell
				data={props?.data[props?.row?.index]?.maThuoc}
				className=" cell-first-child text-center ps-0"
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Tên thuốc'}
				className=" text-center min-w-175px"
			/>
		),
		id: 'Tên thuốc',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className=" text-start text-uppercase"
				data={props?.data[props?.row?.index]?.tenThuoc || ''}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Đơn vị'}
				className=" text-center min-w-175px"
			/>
		),
		id: 'Đơn vị',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className=" text-center text-uppercase"
				data={props?.data[props?.row?.index]?.donVi || ''}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Nồng độ'}
				className=" text-center min-w-175px"
			/>
		),
		id: 'Nồng độ',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className=" text-center text-uppercase"
				data={props?.data[props?.row?.index]?.nongDo || ''}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Quy cách'}
				className=" text-center min-w-250px"
			/>
		),
		id: 'Quy cách',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className=" text-center text-uppercase"
				data={props?.data[props?.row?.index]?.quyCach || ''}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Hoạt chất'}
				className=" text-center min-w-250px"
			/>
		),
		id: 'Hoạt chất',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className=" text-start text-uppercase"
				data={props?.data[props?.row?.index]?.hoatChat || ''}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Giá BHYT'}
				className=" text-center min-w-250px"
			/>
		),
		id: 'Giá BHYT',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className=" text-end text-uppercase"
				data={props?.data[props?.row?.index]?.giaBhyt || ''}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Giá viện phí'}
				className=" text-center min-w-250px"
			/>
		),
		id: 'Giá viện phí',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className=" text-end text-uppercase"
				data={props?.data[props?.row?.index]?.giaVienPhi || ''}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Giá dịch vụ'}
				className=" text-center min-w-250px"
			/>
		),
		id: 'Giá dịch vụ',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className=" text-end text-uppercase"
				data={props?.data[props?.row?.index]?.giaDichVu || ''}
			/>
		),
	},
];
const columnsPhieuChiDinh = [
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'STT'}
				className=" text-center min-w-80x"
			/>
		),
		id: 'STT',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className=" text-center text-uppercase"
				data={props?.data[props?.row?.index]?.tenThuoc || ''}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Tên thuốc'}
				className=" text-center min-w-175px"
			/>
		),
		id: 'Tên thuốc',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className=" text-center text-uppercase"
				data={props?.data[props?.row?.index]?.tenThuoc || ''}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Đơn vị'}
				className=" text-center min-w-175px"
			/>
		),
		id: 'Đơn vị',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className=" text-center text-uppercase"
				data={props?.data[props?.row?.index]?.donVi || ''}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Ngày dùng'}
				className=" text-center min-w-175px"
			/>
		),
		id: 'Nồng độ',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className=" text-center text-uppercase"
				data={props?.data[props?.row?.index]?.nongDo || ''}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Số lượng'}
				className=" text-center min-w-250px"
			/>
		),
		id: 'Quy cách',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className=" text-center text-uppercase"
				data={props?.data[props?.row?.index]?.quyCach || ''}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Đường dùng'}
				className=" text-center min-w-250px"
			/>
		),
		id: 'Hoạt chất',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className=" text-center text-uppercase"
				data={props?.data[props?.row?.index]?.hoatChat || ''}
			/>
		),
	},
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'HDSD'}
				className=" text-center min-w-250px"
			/>
		),
		id: 'Giá BHYT',
		Cell: ({ ...props }) => (
			<TableCustomCell
				className=" text-center text-uppercase"
				data={props?.data[props?.row?.index]?.giaBhyt || ''}
			/>
		),
	},
];

const columnsDSMau: ReadonlyArray<Column<any>> = [
	{
		Header: props => (
			<TableCustomHeader<danhSachHenKham>
				tableProps={props}
				title={'Tất cả các mẫu chỉ định'}
				className=" text-start min-w-150px "
			/>
		),
		id: 'dv',
		Cell: ({ ...props }) => (
			<TableCustomCell
				data={
					<div className="ms-2">
						<Row>
							<Col xs="12">
								<div>
									<span className="my-1">
										{props.data[props.row.index]?.maPhieu}
									</span>{' '}
									-{' '}
									<span className="my-1">
										{props.data[props.row.index]?.ngayChiDinh}
									</span>
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs="12">
								<span className="my-1">{props.data[props.row.index]?.benhAn}</span>{' '}
								-{' '}
								<span className="my-1">
									{props.data[props.row.index]?.thoiGian}
								</span>
							</Col>
						</Row>
					</div>
				}
			/>
		),
	},
];

const columnDanhSachMauChiDinhThuoc = [
	{ title: 'Mã chỉ định', field: 'maChiDinh', className: 'spaces w-15 text-center' },
	{ title: 'Tên chỉ định', field: 'tenChiDinh', className: 'spaces w-30' },
	{ title: 'Nhóm dịch vụ', field: 'nhomDichVu', className: 'spaces w-20 text-center' },
	{ title: 'Loại dịch vụ', field: 'loaiDichVu', className: 'spaces w-15 text-center' },
	{ title: 'Mẫu riêng của BS', field: 'mauRiengCuaBS', className: 'spaces w-20' },
];

const DsThuocChiDinhColumn: ReadonlyArray<Column<any>> = [
	{
		Header: props => (
			<TableCustomHeader<any>
				tableProps={props}
				title={'STT'}
				className="text-center  text-light min-w-40px"
			/>
		),
		id: 'STT',
		Cell: ({ ...props }) => (
			<TableCustomCell className="text-center " data={props?.row?.index + 1} />
		),
	},
	{
		Header: props => (
			<TableCustomHeader<any>
				tableProps={props}
				title={'Tên thuốc'}
				className="text-center  text-light min-w-200px"
			/>
		),
		id: 'Tên thuốc',
		Cell: ({ ...props }) => {
			let data = props?.data[props?.row?.index].tenThuoc;
			return <TableCustomCell className="text-start " data={data} />;
		},
	},
	{
		Header: props => (
			<TableCustomHeader<any>
				tableProps={props}
				title={'Đơn vị'}
				className="text-center  text-light min-w-60px"
			/>
		),
		id: 'Đơn vị',
		Cell: ({ ...props }) => {
			let data = props?.data[props?.row?.index].donVi;
			return <TableCustomCell className="text-center " data={data} />;
		},
	},
	{
		Header: props => (
			<TableCustomHeader<any>
				tableProps={props}
				title={'Ngày dùng'}
				className="text-center  text-light min-w-60px"
			/>
		),
		id: 'Ngày dùng',
		Cell: ({ ...props }) => {
			let data = props?.data[props?.row?.index].ngayDung;
			return <TableCustomCell className="text-center " data={data} />;
		},
	},
	{
		Header: props => (
			<TableCustomHeader<any>
				tableProps={props}
				title={'Số lượng'}
				className="text-center  text-light min-w-100px"
			/>
		),
		id: 'Số lượng',
		Cell: ({ ...props }) => {
			let data = props?.data[props?.row?.index].soLuong;
			return <TableCustomCell className=" text-center" data={data} />;
		},
	},
	{
		Header: props => (
			<TableCustomHeader<any>
				tableProps={props}
				title={'Đường dùng'}
				className="text-center  text-light min-w-100px"
			/>
		),
		id: 'Đường dùng',
		Cell: ({ ...props }) => {
			let data = props?.data[props?.row?.index].duongDung;
			return <TableCustomCell className="" data={data} />;
		},
	},
	{
		Header: props => (
			<TableCustomHeader<any>
				tableProps={props}
				title={'Hướng dẫn sử dụng'}
				className="text-center  text-light min-w-100px"
			/>
		),
		id: 'Hướng dẫn sử dụng',
		Cell: ({ ...props }) => {
			let data = props?.data[props?.row?.index].huongDanSuDungThuoc;
			return <TableCustomCell className="" data={data} />;
		},
	},
];

export {
	chiDinhThuocColumn,
	columnDanhSachMauChiDinhThuoc,
	columnsDSChiTietMau,
	columnsDSMau,
	columnsDSMauChiDinhThuoc,
	columnsDSThuoc,
	columnsPhieuChiDinh,
	columnsPhieuChiDinhCu,
	columnsPhieuChiDinhCuThuoc,
	DsThuocChiDinhColumn,
};
