import { Form, Formik, FormikProps } from 'formik';
import moment from 'moment';
import { ChangeEvent, useRef, useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'react-bootstrap';
import * as Yup from 'yup';
import LabelRequired from '../../../component/LabelRequired';
import TextField from '../../../component/TextField';
import ModalChonKetQuaDichVu from '../../components/tab-kham-benh/modal-chon-ket-qua-dich-vu/ModalChonKetQuaDichVu';
import { KetQuaDichVu } from '../../models/ThongTinKhamBenhModel';

type Props = {
	open: boolean;
	handleCloseModal: () => void;
	handleSubmit: (values: any) => void;
	benhNhanInfo?: {
		visitId?: string;
	};
};

interface ICapToaChoVe {
	visitStoppedDate: string;
	patientCondition: string;
	clinicalSigns: string;
	treatmentPlan: string;
	paraclinicalResults: string;
	doctorComment: string;
	treatmentMethod: string;
}

const CapToaChoVeModal = ({ open, handleCloseModal, handleSubmit, benhNhanInfo }: Props) => {
	const formikRef = useRef<FormikProps<ICapToaChoVe>>(null);

	const [openParaclinicalResults, setOpenParaclinicalResults] = useState(false);

	const formatToInputDate = (value: string) => {
		return moment(value).format('YYYY-MM-DDTHH:mm');
	};

	const initialValues: ICapToaChoVe = {
		visitStoppedDate: formatToInputDate(new Date().toString()),
		patientCondition: '',
		clinicalSigns: '',
		treatmentPlan: '',
		paraclinicalResults: '',
		doctorComment: '',
		treatmentMethod: '',
	};

	const validationSchema = Yup.object({});

	const onSelectKetQuaCanLamSang = (values: KetQuaDichVu[]) => {
		const result = values
			.map(item => `${item.tenDichVu} ${item.ketQua ? `(${item.ketQua})` : ''}`)
			.join(', ');
		formikRef.current?.setFieldValue('paraclinicalResults', result);
	};

	const onSubmit = (values: ICapToaChoVe) => {
		handleSubmit(values);
	};

	return (
		<Modal
			centered
			className="modal fade dialog-background"
			role="dialog"
			show={open}
			dialogClassName="modal-xl"
		>
			<Formik<ICapToaChoVe>
				initialValues={initialValues}
				validationSchema={validationSchema}
				onSubmit={onSubmit}
				innerRef={formikRef}
			>
				{({ values, setFieldValue }) => (
					<Form className="h-100">
						<ModalHeader closeButton onHide={handleCloseModal} className="py-4">
							<Modal.Title>Cấp toa cho về</Modal.Title>
						</ModalHeader>

						<ModalBody>
							<Row className="mb-4">
								<Col
									xs={4}
									className="d-flex mb-3 align-items-center text-lable-input"
								>
									<LabelRequired
										label="Thời gian ra viện"
										className="min-w-125px"
									/>
									<TextField
										className="input"
										name="visitStoppedDate"
										type="datetime-local"
										value={values.visitStoppedDate}
										onChange={(e: ChangeEvent<HTMLInputElement>) => {
											const value = e.target.value;
											setFieldValue('visitStoppedDate', value);
										}}
									/>
								</Col>
							</Row>

							<Row className="mb-4">
								<Col xs={6}>
									<LabelRequired label="Tình trạng bệnh nhân" />
									<TextField name="patientCondition" as="textarea" rows={3} />
								</Col>
								<Col xs={6}>
									<LabelRequired label="Quá trình bệnh lý và diễn biến lâm sàng" />
									<TextField name="clinicalSigns" as="textarea" rows={3} />
								</Col>
							</Row>

							<Row className="mb-4">
								<Col xs={6}>
									<LabelRequired label="Hướng điều trị tiếp theo" />
									<TextField name="treatmentPlan" as="textarea" rows={3} />
								</Col>
								<Col xs={6}>
									<div className="d-flex justify-content-between">
										<LabelRequired label="Tóm tắt kết quả CLS có giá trị chẩn đoán" />
										<Button
											className="btn bg-white spaces p-0"
											onClick={() => {
												setOpenParaclinicalResults(true);
											}}
										>
											<u className="color-17A2B8">Chọn kết quả</u>
										</Button>
									</div>
									<TextField name="paraclinicalResults" as="textarea" rows={3} />
								</Col>
							</Row>

							<Row className="mb-4">
								<Col xs={6}>
									<LabelRequired label="Lời dặn của bác sĩ" />
									<TextField name="doctorComment" as="textarea" rows={3} />
								</Col>
								<Col xs={6}>
									<LabelRequired label="Phương pháp điều trị" />
									<TextField name="treatmentMethod" as="textarea" rows={3} />
								</Col>
							</Row>
						</ModalBody>

						<ModalFooter className="d-flex justify-content-between">
							<div>
								<Button className="btn-outline">Đặt lịch hẹn</Button>
							</div>

							<div className="d-flex justify-content-end py-2 gap-2">
								<Button className="btn-fill">Cập nhật từ bệnh án</Button>
								<Button className="btn-fill w-80px" type="submit">
									Lưu
								</Button>
							</div>
						</ModalFooter>
					</Form>
				)}
			</Formik>

			{openParaclinicalResults && (
				<ModalChonKetQuaDichVu
					open={openParaclinicalResults}
					handleClose={() => setOpenParaclinicalResults(false)}
					onSave={(selectedData: KetQuaDichVu[]) =>
						onSelectKetQuaCanLamSang(selectedData)
					}
					visitId={benhNhanInfo?.visitId ?? ''}
				/>
			)}
		</Modal>
	);
};

export default CapToaChoVeModal;
