import axios from "axios";
import { SearchObject } from "../models/KhamDoanModel";

import { paramsConfig, urlParamsConfig } from "../../utils/ParamsUtils";
const API_PATH = process.env.REACT_APP_EMR_API_URL;
const API_PATH_DS_HOP_DONG = API_PATH + '/contracts'
const API_PATH_CATEGORY = process.env.REACT_APP_XADMIN_API_URL + "/simple-categories";
const API_PATH_DS_NHAN_VIEN = API_PATH + '/pestaff'
const API_PATH_DS_GOI_KHAM_DICH_VU = API_PATH + '/exam-package'
const API_PATH_DS_ORDER_GROUP = API_PATH + '/peorders/order-groups'
const API_PATH_DETAIL_ORDER_GROUP = API_PATH + '/peorders/terms'
const API_PATH_DS_ORDER_KHAM_BENH = API_PATH + '/peorders/orders'
const API_PATH_START_KHAM_BENH = API_PATH + '/pestaff/arrive-exam'
const API_PATH_DS_NHAN_VIEN_KLS = API_PATH + '/pe'
const API_PATH_FORM_GENERATE = API_PATH + '/pe-obs'

// export const searchByPage = (searchObject: SearchObject) => {
//   const { pageIndex, pageSize, keyword } = searchObject;

//   let url = API_PATH + "/benh-nhan";
//   return axios.get(url, {
//     params: {
//       pageIndex,
//       pageSize,
//       keyword
//     }
//   });
// };

export const searchByPage = (searchObject: SearchObject) => {
  let url = API_PATH_DS_HOP_DONG;
  return axios.get(urlParamsConfig(url, searchObject));
};
export const addHopDong = (data: any) => {
  return axios.post(API_PATH_DS_HOP_DONG, data);
};
export const updateHopDong = (data: any) => {
  let url = API_PATH_DS_HOP_DONG;
  return axios.put(url, data);
};
export const getByIdHopDong = (id: string) => {
  let url = API_PATH_DS_HOP_DONG + `/${id}`;
  return axios.get(url);
}
export const deleteHopDong = (id: string) => {
  let url = `${API_PATH_DS_HOP_DONG}/${id}`;
  return axios.delete(url);
};
export const getDanhSachHinhThucService = (searchObject: any) => {
  let url = API_PATH_CATEGORY + `/search`;
  return axios.get(urlParamsConfig(url, searchObject));
}

export const getListDsNhanVien = (searchObject: any) => {
  let url = API_PATH_DS_NHAN_VIEN + `/search`;
  return axios.get(urlParamsConfig(url, searchObject));
};
export const getListDsNhanVienKLS = (searchObject: any) => {
  let url = API_PATH_DS_NHAN_VIEN_KLS + `/search`;
  return axios.get(urlParamsConfig(url, searchObject));
};
export const getListGoiKhamDichVu = (searchObject: any) => {
  let url = API_PATH_DS_GOI_KHAM_DICH_VU + `/search`;
  return axios.get(urlParamsConfig(url, searchObject));
};
export const addGoiKham = (data: any) => {
  return axios.post(API_PATH_DS_GOI_KHAM_DICH_VU, data);
};
export const updateGoiKham = (data: any) => {
  let url = API_PATH_DS_GOI_KHAM_DICH_VU + `/${data?.id}`;
  return axios.put(url, data);
};
export const getByIdGoiKham = (id: string) => {
  let url = API_PATH_DS_GOI_KHAM_DICH_VU + `/${id}`;
  return axios.get(url);
}
export const getDanhSachCategoriService = (searchObject: any) => {
  let url = API_PATH_CATEGORY + `/search`;
  return axios.get(urlParamsConfig(url, searchObject));
}
export const updateNhanVien = (data: any) => {
  let url = API_PATH_DS_NHAN_VIEN + `/${data?.id}`;
  return axios.put(url, data);
};
export const addNhanVien = (data: any) => {
  return axios.post(API_PATH_DS_NHAN_VIEN, data);
};
export const getByIdNhanVien = (id: string) => {
  let url = API_PATH_DS_NHAN_VIEN + `/${id}`;
  return axios.get(url);
}
export const getListDichVuCuaNhanVien = (id: any) => {
  let url = API_PATH_DS_GOI_KHAM_DICH_VU + `/get-by-staff` + `/${id}`;
  return axios.get(url);
};
export const getDsOrderGroup = (data: any, id: string) => {
  let url = API_PATH_DS_ORDER_GROUP + `/${id}`;
  return axios.get(urlParamsConfig(url, data));
};
export const getListDichVuByOrder = (data: any, id: string) => {
  let url = API_PATH_DETAIL_ORDER_GROUP + `/${id}`;
  return axios.get(urlParamsConfig(url, data));
};
export const getListOrderKhamBenh = (data: any) => {
  let url = API_PATH_DS_ORDER_KHAM_BENH+ `/${data?.id}`;
  return axios.get(urlParamsConfig(url, data));
};
export const addStartKhamBenh = (id: any) => {
  let url = API_PATH_START_KHAM_BENH + `/${id}`;
  return axios.post(url);
};
export const saveFormGenerate = (data: any, code: string, id: string) => {
  let url = API_PATH_FORM_GENERATE + `/${code}/` + id;
  return axios.put(url, data);
};
export const getDetailFormGenerate = (id: string) => {
  let url = API_PATH_FORM_GENERATE + `/${id}`;
  return axios.get(url);
};

export const getNVClasses = (id: string) => {
  let url = API_PATH_DS_NHAN_VIEN + '/get-tree-filter' + `/${id}`;
  return axios.get(url);
};

export const importDSNhanVien = (contractId: string, file: any) => {
  let url = `${API_PATH_DS_NHAN_VIEN}/import-staffs/${contractId}`;
  let formData = new FormData();
  formData.append("file", file);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(url, formData, config);
};

export const exportDSNhanVien = (contractId: string) => {
  let url = `${API_PATH_DS_NHAN_VIEN}/export-staff/${contractId}`;
  return axios.get(url, { responseType: 'blob'});
};