import { Col, Row } from 'react-bootstrap';
import LabelRequired from '../../../../../component/LabelRequired';
import { formatDateAdvanceToString } from '../../../../../utils/FormatUtils';

type Props = {
	thongTinPhieu: any;
	thongTinBenhNhan: any;
};

const ThongTinPhieu = ({ thongTinPhieu, thongTinBenhNhan }: Props) => {
	const formatDateField = (value: any) => {
		return value ? formatDateAdvanceToString(new Date(`${value}Z`)) : '...';
	};

	return (
		<div className="px-3 py-1 bg-white border-bottom border-bottom-2">
			<Row>
				<Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
					<LabelRequired label="Loại phiếu:" className="spaces min-w-110" />
					<span className="fw-light text-truncate">{thongTinPhieu?.type || '...'}</span>
				</Col>
				<Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
					<LabelRequired label="Ngày y lệnh:" className="spaces min-w-110" />
					<span className="fw-light text-truncate">
						{formatDateField(thongTinPhieu?.doctorOrderDay)}
					</span>
				</Col>
				<Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
					<LabelRequired label="Ngày chỉ định:" className="spaces min-w-110" />
					<span className="fw-light text-truncate">
						{formatDateField(thongTinPhieu?.designatedDate)}
					</span>
				</Col>
			</Row>

			<Row>
				<Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
					<LabelRequired label="Mã phiếu:" className="spaces min-w-110" />
					<span className="fw-light text-truncate">{thongTinPhieu?.code || '...'}</span>
				</Col>
				<Col xs={8} className="d-flex align-items-center text-lable-input mb-3">
					<LabelRequired label="Nơi chỉ định:" className="spaces min-w-110" />
					<span className="fw-light text-truncate">
						{thongTinPhieu?.designatedPlace || '...'}
					</span>
				</Col>
			</Row>

			<Row>
				<Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
					<LabelRequired label="Người chỉ định:" className="spaces min-w-110" />
					<span className="fw-light text-truncate">
						{thongTinPhieu?.designatedPerson || '...'}
					</span>
				</Col>
				<Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
					<LabelRequired label="Trạng thái:" className="spaces min-w-110" />
					<span className="fw-light text-truncate">{thongTinPhieu?.status || '...'}</span>
				</Col>
			</Row>

			<Row>
				<Col xs={4} className="d-flex align-items-center text-lable-input mb-3">
					<LabelRequired label="Kho:" className="spaces min-w-110" />
					<span className="fw-light text-truncate">
						{thongTinPhieu?.warehouseName || '...'}
					</span>
				</Col>
				<Col xs={8} className="d-flex align-items-center text-lable-input mb-3">
					<LabelRequired label="Chẩn đoán:" className="spaces min-w-110" />
					<span className="fw-light text-truncate">
						{thongTinPhieu?.id && thongTinBenhNhan?.encounterDiagnosis?.ICDXPrimaryLabel
							? thongTinBenhNhan?.encounterDiagnosis?.ICDXPrimaryLabel
							: '...'}
					</span>
				</Col>
			</Row>
		</div>
	);
};

export default ThongTinPhieu;
