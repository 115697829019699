import { FC, useEffect } from "react";
import LabelRequired from "../../../component/LabelRequired";
import TextField from "../../../component/TextField";
import { iSinhHieu } from "../../models/ThongTinKhamBenhModel";

type sinhHieu = {
  sinhHieu: iSinhHieu;
  setSinhHieu?: (name: string, value: any) => void;
  className?: string;
  disabled?: boolean
};
const SinhHieu: FC<sinhHieu> = ({ className, disabled = false, sinhHieu, setSinhHieu = () => {} }) => {
  const handleBMI = () => {
    let chieuCao = Number(sinhHieu?.height) / 100;
    let canNang = Number(sinhHieu?.weight);
    let bmi: string | number = "";

    if (chieuCao && canNang) {
      bmi = (canNang / (chieuCao * chieuCao)).toFixed(1);
    }
    setSinhHieu("bmi", bmi);
  };

  useEffect(() => {
    handleBMI();
  }, [sinhHieu?.height, sinhHieu?.weight]);

  return (
    <div className={className}>
      <div className="d-flex">
        <div className="spaces width-18 pr-10">
          <TextField
            className="w-100 flex-auto spaces mb-4"
            label="Mạch (lần/phút)"
            name="pr"
            labelClassName="ms-0 min-w-105 spaces px-0"
            disabled={disabled}
            value={sinhHieu?.pr || ""}
            onChange={(e: any) => setSinhHieu(e.target.name, e.target.value)}
          />
        </div>
        <div className="spaces width-18 pr-10">
          <TextField
            className="w-100 flex-auto spaces mb-4"
            label="Nhiệt độ (°C)"
            name="bt"
            labelClassName="ms-0 min-w-100 spaces px-0"
            disabled={disabled}
            value={sinhHieu?.bt || ""}
            onChange={(e: any) => setSinhHieu(e.target.name, e.target.value)}
          />
        </div>
        <div className="spaces width-28 pr-10">
          <div className="d-flex gap-2 mb-1 align-items-center text-lable-input pe-0">
            <div className="d-flex justify-content-between align-items-center">
              <LabelRequired className="ms-0 min-w-120 spaces" label="Huyết áp (mmHg)" />
              <div className="flex-auto">
                <TextField
                  name="bpSys"
                  labelClassName="ms-0"
                  disabled={disabled}
                  value={sinhHieu?.bpSys || ""}
                  onChange={(e: any) => setSinhHieu(e.target.name, e.target.value)}
                  className="spaces"
                />
              </div>
              <div className="spaces mx-4">/</div>
              <div className="flex-auto">
                <TextField
                  name="bpDia"
                  disabled={disabled}
                  value={sinhHieu?.bpDia || ""}
                  onChange={(e: any) => setSinhHieu(e.target.name, e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="spaces width-15 pr-10">
          <TextField
            className="w-100 flex-auto spaces mb-4"
            label="SPO2 (%)"
            name="spo2"
            labelClassName="ms-0 min-w-70 spaces px-0"
            disabled={disabled}
            value={sinhHieu?.spo2 || ""}
            onChange={(e: any) => setSinhHieu(e.target.name, e.target.value)}
          />
        </div>
        <div className="spaces width-21">
          <TextField
            className="w-100 flex-auto spaces mb-4"
            label="Nhịp thở (lần/phút)"
            name="rr"
            labelClassName="ms-0 min-w-125 spaces px-0"
            disabled={disabled}
            value={sinhHieu?.rr || ""}
            onChange={(e: any) => setSinhHieu(e.target.name, e.target.value)}
          />
        </div>
      </div>
      <div className="d-flex">
        <div className="spaces width-18 pr-10">
          <TextField
            className="w-100 flex-auto spaces mb-4"
            label="Cân nặng (kg)"
            name="weight"
            labelClassName="ms-0 min-w-105 spaces px-0"
            disabled={disabled}
            value={sinhHieu?.weight || ""}
            onChange={(e: any) => setSinhHieu(e.target.name, e.target.value)}
          />
        </div>
        <div className="spaces width-18 pr-10">
          <TextField
            className="w-100 flex-auto spaces mb-4"
            label="Chiều cao (cm)"
            name="height"
            labelClassName="ms-0 min-w-100 spaces px-0"
            disabled={disabled}
            value={sinhHieu?.height || ""}
            onChange={(e: any) => setSinhHieu(e.target.name, e.target.value)}
          />
        </div>
        <div className="spaces width-13 pr-10">
          <TextField
            className="w-100 flex-auto spaces mb-4"
            label="BMI"
            name="bmi"
            labelClassName="ms-0 min-w-50 spaces px-0"
            disabled={disabled}
            value={sinhHieu?.bmi || ""}
            onChange={(e: any) => setSinhHieu(e.target.name, e.target.value)}
          />
        </div>
        <div className="spaces width-15 pr-10">
          <TextField
            className="w-100 flex-auto spaces mb-4"
            label="Creatinin"
            name="creatinine"
            labelClassName="ms-0 min-w-70 spaces px-0"
            disabled={disabled}
            value={sinhHieu?.creatinine || ""}
            onChange={(e: any) => setSinhHieu(e.target.name, e.target.value)}
          />
        </div>
        <div className="spaces width-15 pr-10">
          <TextField
            className="w-100"
            inputClassName="flex-auto spaces h-26"
            label="CrCl"
            name="crcl"
            labelClassName="ms-0 min-w-70 spaces px-0"
            disabled={disabled}
            value={sinhHieu?.crcl || ""}
            onChange={(e: any) => setSinhHieu(e.target.name, e.target.value)}
          />
        </div>
        <div className="spaces width-15 pr-10">
          <TextField
            className="w-100 flex-auto spaces mb-4"
            label="eGFR"
            name="egfr"
            labelClassName="ms-0 min-w-50 spaces px-0"
            disabled={disabled}
            value={sinhHieu?.egfr || ""}
            onChange={(e: any) => setSinhHieu(e.target.name, e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default SinhHieu;
