import { Form, Formik, FormikErrors } from "formik";
import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from "react";
import { Button, Col, FormCheck, Modal, Row } from "react-bootstrap";
import * as Yup from "yup";
import AutocompleteObjectV2 from "../../../component/AutocompleteObjectV2";
import LabelRequired from "../../../component/LabelRequired";
import TextField from "../../../component/TextField";
import { IDanhSachDanhMuc } from "../../../phan-he-tiep-nhan-thanh-toan/models/TiepDonModel";
import { getIndexedDBItem } from "../../../utils/IndexedDB";
import { CODE, KEY_DS_DANH_MUC_TIEP_DON } from "../../../utils/Constant";
import { TableCustom } from "../../../component/table/table-custom/TableCustom";
import ModalICD10 from "../../components/modal-icd10/ModalICD10";
import {
  columnsDSBenh,
  columnsDSYCKham,
} from "../../columns/modal-kham-ket-hop/ColumnsModalKhamKetHop";
import ContextMenu from "../../../component/ContextMenuV3";
import { TYPE_KHAM_KET_HOP } from "../../constants/phan-he-kham-benh/ConstantPhanHeKhamBenh";
import { createKhamKetHop, getInfoBenhNhan } from "../../services/KhamBenhService";
import { toast } from "react-toastify";
import { AppContext } from "../../../appContext/AppContext";
import { PhanHeTiepDonContext } from "../../contexts/PhanHeTiepDonContext";
interface Props {
  open: boolean;
  handleClose: Dispatch<SetStateAction<boolean>>;
  patientSelectedRow: any;
}

const ModalKhamKetHop: FC<Props> = (props) => {
  let { open, handleClose, patientSelectedRow } = props;
  const { setIsLoading } = useContext(AppContext);
  const { statusChangedEventFunc, setBenhNhanInfo, benhNhanInfo } = useContext(PhanHeTiepDonContext);

  const handleSubmitForm = async (values: any) => {
    setIsLoading(true)
    try {
      let requestBody = {
        ...values,
        orderId: patientSelectedRow?.orderId,
        visitId: patientSelectedRow?.visitId
      }
      let {data} = await createKhamKetHop(requestBody)
      if(data?.code === CODE.SUCCESS) {
        toast.success("Tạo phiếu khám kết hợp thành công")
        handleClose(true)
        statusChangedEventFunc?.();
        setBenhNhanInfo({
          ...benhNhanInfo,
          shouldUpdate: !benhNhanInfo.shouldUpdate
        });
      }
    } catch (error) {
      toast.error("Xảy ra lỗi, vui lòng thử lại!")
    }
    finally {
      setIsLoading(false)
    }
  };

  const validationSchema = Yup.object().shape({});

  return (
    <Modal
      className="modal-kham-ket-hop"
      dialogClassName="h-modal"
      size="xl"
      show={open}
      animation
      centered
    >
      <Formik<any>
        initialValues={{}}
        validationSchema={validationSchema}
        validateOnChange={true}
        validate={(values) => {
          const errors: FormikErrors<any> = {};
          return errors;
        }}
        onSubmit={(values: any) => handleSubmitForm(values)}
      >
        {({
          handleSubmit,
          touched,
          errors,
          setFieldValue,
          values,
          setValues,
        }) => (
          <FormKhamKetHop
            handleSubmit={handleSubmit}
            touched={touched}
            errors={errors}
            setFieldValue={setFieldValue}
            values={values}
            handleClose={handleClose}
            setValues={setValues}
            patientSelectedRow={patientSelectedRow}
          />
        )}
      </Formik>
    </Modal>
  );
};

const FormKhamKetHop: FC<any> = (props) => {
  let {
    handleSubmit,
    touched,
    errors,
    setFieldValue,
    values,
    handleClose,
    setValues,
    patientSelectedRow,
  } = props;
  const [danhSachDanhMuc, setDanhSachDanhMuc] = useState<IDanhSachDanhMuc>();
  const [icd10Field, setICD10Field] = useState<string>("");
  const [selectionModeICD10, setSelectionModeICD10] = useState<
    "checkbox" | "radio"
  >();
  const [shouldOpenModalICD10, setShouldOpenModalICD10] =
    useState<boolean>(false);
  const [infoKhamKetHop, setInfoKhamKetHop] = useState<any>();
  const { setIsLoading } = useContext(AppContext);
  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (values?.service || values?.dept || values?.doctor) {
        let valueAdd = {
          termSvcId: values?.service?.id,
          termSvcName: values?.service?.name,
          deptId: values?.dept?.id,
          deptName: values?.dept?.name,
          docId: values?.doctor?.id,
          docName: values?.doctor?.name,
          type: TYPE_KHAM_KET_HOP.YEU_CAU_KHAM.code,
        };
        setFieldValue(
          "clinicTerms",
          values?.clinicTerms
            ? [...values?.clinicTerms, valueAdd]
            : [valueAdd]
        );
      }
      if (values?.icdXPrimary) {
        let valueAdd = {
          termName: values?.icdXPrimary?.name,
          termCode: values?.icdXPrimary?.code,
          type: TYPE_KHAM_KET_HOP.BENH.code,
        };
        setFieldValue(
          "clinicDiagnosis",
          values?.clinicDiagnosis
            ? [...values?.clinicDiagnosis, valueAdd]
            : [valueAdd]
        );
      }
      handleSetInitValue();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [values]);

  const handleFormatDataTable = (data: any) => {
    let clinicTermsFormated = data?.clinicTerms?.map((item: any) => {
      return {
        ...item,
        type: TYPE_KHAM_KET_HOP.YEU_CAU_KHAM.code
      }
    })

    let clinicDiagnosesFormated = data?.clinicDiagnosis?.map((item: any) => {
      return {
        ...item,
        type: TYPE_KHAM_KET_HOP.BENH.code
      }
    })
    return {
      clinicTermsFormated,
      clinicDiagnosesFormated
    }
  }

  const handleGetInforBenhNhan = async () => {
    try {
      setIsLoading(true);
      let { data } = await getInfoBenhNhan(patientSelectedRow?.visitId);
      if (data?.code === CODE.SUCCESS) {
        setInfoKhamKetHop(data?.data);
        let dataTableFormated = handleFormatDataTable(data?.data)
        setValues({
          ...data?.data,
          clinicTerms: dataTableFormated?.clinicTermsFormated,
          clinicDiagnosis: dataTableFormated?.clinicDiagnosesFormated,
        });
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    handleGetInforBenhNhan();
  }, []);

  const handleSetInitValue = () => {
    setFieldValue("icdXPrimary", null);
    setFieldValue("service", null);
    setFieldValue("doctor", null);
    setFieldValue("dept", null);
  };

  const handleGetDsDanhMuc = async () => {
    try {
      const responseDsDanhmuc: IDanhSachDanhMuc = await getIndexedDBItem(
        KEY_DS_DANH_MUC_TIEP_DON
      );
      handleSetDefaultData(responseDsDanhmuc);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetDsDanhMuc();
  }, []);

  const handleChangeVisit = (name: string, value: any) => {
    if (name === "service") {
      let deptObj = danhSachDanhMuc?.listPhongKham.find(
        (item: any) => item?.code === value?.attributes?.deptClinicCode
      );
      setFieldValue("dept", deptObj);
    }
    setFieldValue(name, value);
  };
  const handleChangeSelect = (data: any) => {
    switch (icd10Field) {
      case "icdXPrimary":
        setFieldValue("icdXPrimary", data);
        break;
      default:
        break;
    }
  };

  const handleSetDefaultData = async (data: IDanhSachDanhMuc) => {
    setDanhSachDanhMuc(data);
  };

  const [contextMenu, setContextMenu] = useState<null | {
    x: number;
    y: number;
  }>(null);
  const [selectedRow, setSelectedRow] = useState<any>({});

  const handleContextMenu = (e: any, row: any) => {
    e.preventDefault();
    setContextMenu({ x: e.clientX, y: e.clientY });
    setSelectedRow(row);
  };
  const handleDeleteItem = (selectedRow: any) => {
    if (selectedRow?.original?.type === TYPE_KHAM_KET_HOP.YEU_CAU_KHAM.code) {
      let dataDSYCKhamUpdated = values?.clinicTerms?.filter(
        (item: any, idx: number) => idx !== selectedRow?.index
      );
      setFieldValue("clinicTerms", dataDSYCKhamUpdated);
    } else if (selectedRow?.original?.type === TYPE_KHAM_KET_HOP.BENH.code) {
      let dataDSBenhUpdated = values?.clinicDiagnosis?.filter(
        (item: any, idx: number) => idx !== selectedRow?.index
      );
      setFieldValue("clinicDiagnosis", dataDSBenhUpdated);
    }
  };

  const handleClickOptionContextMenu = (value: any) => {
    handleDeleteItem(selectedRow);
    setContextMenu(null);
  };

  return (
    <Form id="form-thuoc">
      <div className="thuoc-dialog">
        <Modal.Header className="py-3 header-modal">
          <Modal.Title className="text-pri">Khám bệnh kết hợp</Modal.Title>
          <button
            className="btn-close"
            onClick={() => handleClose(false)}
          ></button>
        </Modal.Header>
        <Modal.Body className="py-16 px-24 spaces overflow-hidden">
          <Row className="mb-5 py-4 mx-nega-24">
            <Col xs="12" className="mb-5">
              <div className="text-break fw-500 py-2 px-5 text-end position-absolute top-0 bottom-0 end-0 spaces w-60">
                <div>
                  <div className="text-break fw-500 p-1 text-end">
                    <span className="text-uppercase fw-600 fs-14">
                      {infoKhamKetHop?.visit?.name}&nbsp;|&nbsp;
                      {infoKhamKetHop?.visit?.code}
                      &nbsp;|&nbsp;Tuổi: {infoKhamKetHop?.visit?.age}
                      &nbsp;|&nbsp;{infoKhamKetHop?.visit?.gender}
                    </span>
                    <div className="text-truncate fs-14">
                      {infoKhamKetHop?.visit?.type}&nbsp;|&nbsp;
                      {infoKhamKetHop?.visit?.address}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs="8" className="mb-5 grid-3-9">
              <LabelRequired label="Phiếu điều trị" className="" />
              <AutocompleteObjectV2
                options={[]}
                name="phieuDieuTri"
                value={values?.phieuDieuTri}
                onChange={(e: any) => {}}
              />
            </Col>
            <Col xs="4" className="mb-5 grid-8-4">
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <u className="color-17A2B8">Thêm phiếu điều trị</u>
              </a>
              <FormCheck
                type="checkbox"
                label={<span className="color-DC3545 fw-bold">Cấp cứu</span>}
                name="capCuu"
                className=" d-flex align-items-center gap-2"
              />
            </Col>
            <Col xs="12" className="mb-5 grid-2-10">
              <LabelRequired label="Lý do khám kết hợp" className="" />
              <TextField
                name="combinedVisitReason"
                className="spaces"
                labelClassName=""
                value={values?.combinedVisitReason}
              />
            </Col>
            <Col xs="6" className="mb-5 grid-4_1-7_9">
              <LabelRequired label="Yêu cầu khám" className="" />
              <AutocompleteObjectV2
                options={danhSachDanhMuc?.listDVKhamBenh || []}
                value={values?.service || null}
                name="service"
                onChange={(selectedOption) => {
                  handleChangeVisit("service", selectedOption);
                }}
                touched={touched?.service}
                errors={errors?.service}
                className="autocomplete-custom-tiep-nhan radius spaces width-100 h-29"
              />
            </Col>
            <Col xs="6" className="mb-5 grid-3-9">
              <LabelRequired label="Phòng khám" className="" />
              <AutocompleteObjectV2
                options={danhSachDanhMuc?.listPhongKham || []}
                value={values?.dept || null}
                name="dept"
                onChange={(selectedOption) => {
                  handleChangeVisit("dept", selectedOption);
                }}
                touched={touched?.dept}
                errors={errors?.dept}
                urlData={"data.data"}
                className="autocomplete-custom-tiep-nhan radius spaces width-100 h-29"
              />
            </Col>
            <Col xs="12" className="mb-5 grid-2-10">
              <LabelRequired label="Bác sĩ khám" className="" />
              <AutocompleteObjectV2
                options={danhSachDanhMuc?.listPhongKham || []}
                value={values?.doctor || null}
                name="doctor"
                onChange={(selectedOption) => {
                  handleChangeVisit("doctor", selectedOption);
                }}
                touched={touched?.doctor}
                errors={errors?.doctor}
                urlData={"data.data"}
                className="autocomplete-custom-tiep-nhan radius spaces width-100 h-29"
              />
            </Col>
            <Col xs="12" className="">
              <TableCustom<any>
                className={"h-bang-ds-bn spaces h-calc-vh-552"}
                data={values?.clinicTerms || []}
                columns={columnsDSYCKham}
                handleDoubleClick={() => {}}
                verticalScroll={true}
                handleContextMenu={handleContextMenu}
              />
            </Col>
            <Col xs="5" className="mb-5 grid-5-7">
              <LabelRequired label="Mã bệnh - ICD10" className="" />
              <Row className="">
                <Col xs="12" className=" position-relative">
                  <TextField
                    name="icdXPrimary"
                    value={values?.icdXPrimary?.code || ""}
                  />
                  <div className="spaces width-4">
                    <h6
                      className="position-absolute muc-huong cursor-pointer"
                      onClick={() => {
                        setICD10Field("icdXPrimary");
                        setSelectionModeICD10("radio");
                        setShouldOpenModalICD10(true);
                      }}
                    >
                      <i className="bi bi-three-dots fs-4 text-pri d-block spaces mt-5"></i>
                    </h6>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs="7" className="mb-5 grid-4-8">
              <LabelRequired label="Tên bệnh - ICD10" className="" />
              <Row className="">
                <Col xs="12" className="position-relative">
                  <TextField
                    name="icdXPrimary"
                    value={values?.icdXPrimary?.name || ""}
                  />
                  <div className="spaces width-4">
                    <h6
                      className="position-absolute muc-huong cursor-pointer"
                      onClick={() => {
                        setICD10Field("icdXPrimary");
                        setSelectionModeICD10("radio");
                        setShouldOpenModalICD10(true);
                      }}
                    >
                      <i className="bi bi-three-dots fs-4 text-pri d-block spaces mt-5"></i>
                    </h6>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs="12" className="mb-5 grid-2-10">
              <LabelRequired label="Tên bệnh" className="" />
              <TextField
                name="tenBenh"
                className="spaces"
                labelClassName=""
                value={values?.icdXPrimary?.name || ""}
              />
            </Col>
            <Col xs="12" className="">
              <TableCustom<any>
                className={"h-bang-ds-bn spaces h-calc-vh-552"}
                data={values?.clinicDiagnosis || []}
                columns={columnsDSBenh}
                handleDoubleClick={() => {}}
                verticalScroll={true}
                handleContextMenu={handleContextMenu}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-fill spaces width-9"
            onClick={() => handleSubmit()}
          >
            Lưu
          </Button>
        </Modal.Footer>
      </div>
      {shouldOpenModalICD10 && (
        <ModalICD10
          data={danhSachDanhMuc?.listICD10 || []}
          handleClose={() => setShouldOpenModalICD10(false)}
          setMauSelected={handleChangeSelect}
          selectionMode={selectionModeICD10}
        />
      )}
      {contextMenu && (
        <ContextMenu
          handleClickOptionContextMenu={handleClickOptionContextMenu}
          handleCloseMenu={() => setContextMenu(null)}
          data={[
            {
              code: "DELETE_ITEM",
              name: "Xóa bản ghi",
            },
          ]}
          target={contextMenu}
        />
      )}
    </Form>
  );
};

export default ModalKhamKetHop;
