import { Button, Dropdown } from 'react-bootstrap';
import { IconBack } from '../../../../../component/IconSvg';

type Props = {
	onClose: () => void;
};

const Actions = ({ onClose }: Props) => {
	return (
		<div className="d-flex align-items-center">
			<Button
				variant="bg-transparent text-hover-primary ps-0 d-flex align-items-center"
				onClick={onClose}
			>
				<IconBack />
				<span className="fw-500 ps-2">Quay lại</span>
			</Button>
			<Dropdown className="dropdown-btn menu-icon me-3">
				<Dropdown.Toggle className="btn-outline btn-dropdown-outline">
					<i className="bi bi-caret-down-fill"></i>
					Đơn vật tư mẫu
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item>
						<div className="ps-5 spaces line-height-30">Lưu vào mẫu mới</div>
					</Dropdown.Item>
					<Dropdown.Item>
						<div className="ps-5 spaces line-height-30">Danh sách các mẫu đã tạo</div>
					</Dropdown.Item>
					<Dropdown.Item className="bg-light spaces" disabled>
						<b>Tổng hợp</b>
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
			<Dropdown className="dropdown-btn menu-icon">
				<Dropdown.Toggle className="btn-outline btn-dropdown-outline">
					<i className="bi bi-caret-down-fill"></i>
					Đơn vật tư cũ
				</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item>
						<div className="ps-5 spaces line-height-30">Đơn cũ 1</div>
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</div>
	);
};

export default Actions;
