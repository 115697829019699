import { TableSelectVatTuContextProvider } from '../../context/TableSelectVatTuContext';
import ChiPhiVatTu from './ChiPhiKeVatTu';
import TablePhieuChiDinhVatTu from './TablePhieuChiDinhVatTu';
import TableSelectVatTu from './TableSelectVatTu';
import ThongTinBenh from './ThongTinBenh';

const ThongTinKeVatTu = () => {
	return (
		<>
			<div className="bg-white spaces p-10">
				<div className="text-pri fw-bold">Danh sách vật tư</div>
			</div>

			<div className="d-flex flex-wrap spaces radius-2 p-10 border-top bg-white">
				<ThongTinBenh />
			</div>
			<div className="spaces flex-grow-1 bg-white px-0 radius-2 mt-6 table-ke-thuoc">
				<div className="d-flex flex-column spaces width-100 bg-light h-100">
					<TableSelectVatTuContextProvider>
						<TableSelectVatTu />
						<TablePhieuChiDinhVatTu />
					</TableSelectVatTuContextProvider>
					<ChiPhiVatTu />
				</div>
			</div>
		</>
	);
};

export default ThongTinKeVatTu;
