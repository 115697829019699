export enum CODE {
    XET_NGHIEM = 'xetnghiem',
    CDHA = 'chandoanhinhanh',
    TDCN = 'thamdochucnang',
    PT = 'phauthuat1',
    TT = 'thuthuat',
}

export enum KEY_TAB_DICH_VU {
    CHI_DINH_DV = '0',
    DA_CHI_DINH_DV = '1',
}

export const CODE_LOAI_PHIEU = {
    MAU_PHIEU_DIEU_TRI: 1,
    PHIEU_DIEU_TRI_CU: 2,
}