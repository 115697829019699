import axios from 'axios'
import { urlParamsConfig } from '../../utils/ParamsUtils';

const BASE_URL = process.env.REACT_APP_EMR_API_URL;
const API_PATH_TEST_GROUP = BASE_URL + "/test-groups";

export const getListPatientXN = (searchObject: any) => {
  let url = API_PATH_TEST_GROUP + `/search`
  return axios.get(urlParamsConfig(url, searchObject));
}

export const getBenhNhanInfo = (id: any) => {
  let url = API_PATH_TEST_GROUP + `/${id}/patient`;
  return axios.get(url);
};

export const getPhieuXetNghemInfo = (id: any) => {
  let url = API_PATH_TEST_GROUP + `/${id}/detail`;
  return axios.get(url);
};

export const saveObsValue = (data: any) => {
  let url = BASE_URL + `/test-obs`
  return axios.post(url, data);
};

export const updateObsFromLis = (testGroupId : string) => {
  let url = `${BASE_URL}/test-lis-obs`
  return axios.post(url, {testGroupId});
};

