import { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../../appContext/AppContext";
import InputSearch from '../../../component/InputSearch';
import { CODE, DEFAULT_PAGE_INDEX, MAX_PAGE_SIZE, RESPONSE_MESSAGE } from "../../../utils/Constant";
import { getListDsNhanVien } from "../../services/KhamDoanHopDongServices";
import { QuanLyHopDongContext, QuanLyHopDongContextProps } from "../tab-quan-ly-hop-dong/QuanLyHopDong";
import { TableDanhSachBenhNhan } from "./TableDanhSachBenhNhan";
type Props = {
  selectedRow: any;
  handlerCloseHD: any,
  setSelectedRow: (row: any) => void;
  setFilter: (data: any) => void;
};

const DanhSachBenhNhan = (props: Props) => {
  let { handlerCloseHD, selectedRow, setSelectedRow } = props;
  const { setIsLoading } = useContext(AppContext);
  const { isSearchNV, TTHopDong } = useContext<QuanLyHopDongContextProps>(QuanLyHopDongContext)
  const [countPaid, setCountPaid] = useState<any>();
  const [dataSummary, setDataSummary] = useState<any[]>([]);
  const [dsBenhNhan, setDsBenhNhan] = useState<any>({
    data: [],
    totalElements: 1,
    totalPages: 1,
  });

  const getDanhSachBenhNhanh = async (filter?: any) => {
    if (TTHopDong?.id) {
      setIsLoading(true);
      let searchObject = {
        pageIndex: DEFAULT_PAGE_INDEX,
        pageSize: MAX_PAGE_SIZE,
        keyword: filter?.keyword,
        contractId: TTHopDong?.id
      };
      try {
        const { data } = await getListDsNhanVien(searchObject);
        if (CODE.SUCCESS === data?.code) {
          setDataSummary(data.data.summary);
          setDsBenhNhan({
            data: data?.data?.content || [],
            totalElements: data?.data?.totalElements,
            totalPages: data?.data?.totalPages,
          });
          setCountPaid({
            notPaidEnough: data?.data?.notPaidEnough,
            paidEnough: data?.data?.paidEnough,
          });
        } else {
          toast.warning(RESPONSE_MESSAGE.ERROR);
        }
      } catch {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      } finally {
        setIsLoading(false);
      }
    } else {
      return setDsBenhNhan({
        data: [],
        totalElements: 1,
        totalPages: 1,
      })
    }
  };

  useEffect(() => {
    isSearchNV && getDanhSachBenhNhanh();
  }, [TTHopDong?.id, isSearchNV]);

  return (
    <div className="position-relative spaces gap-8 bg-white">
      <div className="justify-content-between spaces pl-8 mb-8">
        <div className="d-flex mb-2 bg-white">
          <Col xs="7" className="px-0 mt-1">
            <h4 className="text-title mb-0 fw-bold fs-4 spaces ">Danh sách nhân viên</h4>
          </Col>
          <Col xs="5" className="flex justify-content-end spaces pt-2 px-5 mt-6">
            <Button className="customer-buttom-dk spaces min-w-120 h-30" onClick={handlerCloseHD}>
              <span className="customer-buttom-text">DS Hợp Đồng</span>
            </Button>
          </Col>
        </div>
        <div className="d-flex spaces  gap-10">
          <Row className="mx-0 mb-3">
            <Col xs="6" className="px-0">
              <Form.Check
                className={"customs-form-check-box"}
                type={"checkbox"}
                label={"Đăng ký"}
                name={"dangKy"}
                // checked={true}
                onChange={() => { }}
              />
            </Col>
            <Col xs="6" className="px-0">
              <Form.Check
                className={"customs-form-check-box"}
                type={"checkbox"}
                label={"Đang khám"}
                name={"dangKham"}
                // checked={true}
                onChange={() => { }}
              />
            </Col>
            <Col xs="6" className="px-0">
              <Form.Check
                className={"customs-form-check-box"}
                type={"checkbox"}
                label={"Đã trả kết quả"}
                name={"daTraKetQua"}
                // checked={}
                onChange={() => { }}
              />
            </Col>
            <Col xs="6" className="px-0">
              <Form.Check
                className={"customs-form-check-box"}
                type={"checkbox"}
                label={"Đã khám xong"}
                name={"khamXong"}
                // checked={true}
                onChange={() => { }}
              />
            </Col>
          </Row>
        </div>
        <div className='spaces mb-6 mr-5'>
          <InputSearch placeholder='Tìm kiếm' handleChange={() => { }} className="flex-fill spaces h-32 " />
        </div>
      </div>
      <div>
        <TableDanhSachBenhNhan
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          dsBenhNhan={dsBenhNhan?.data}
          dataSummary={dataSummary}
          countPaid={countPaid}
        />
      </div>
    </div >
  );
};

export default DanhSachBenhNhan;
