import {
  Dispatch,
  FC,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState
} from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { AppContext } from "../../appContext/AppContext";
import InputSearch from "../../component/InputSearch";
import SelectTree from "../../component/SelectTree";
import {
  CODE,
  DEFAULT_PAGE_INDEX,
  DEFAULT_TOTAL_ELEMENTS,
  DEFAULT_TOTAL_PAGES,
  KEY,
  MAX_PAGE_SIZE,
  RESPONSE_MESSAGE,
} from "../../utils/Constant";
import { IMenuVienPhi, SearchObject } from "../models/VienPhiInfoModels";
import { getDsSoThuTien } from "../services/PhanHeVienPhiServices";
import TableDanhSachSoThu from "./TableDanhSachSoThu";
import { TreeSoThu } from "./fakeData";

export const ReceptionContext = createContext(() => {});

type Props = {
  show: boolean;
  onHide: Dispatch<SetStateAction<IMenuVienPhi>>;
  setOpenDanhSachSoThu: Dispatch<SetStateAction<boolean>>;
  setDataSoThu: Dispatch<SetStateAction<any>>;
};

export type benhNhanData = {
  data: any;
  totalPages: number;
  totalElements: number;
};

const ModalDanhSachSoThu: FC<Props> = (props) => {
  const { show, onHide, setOpenDanhSachSoThu, setDataSoThu } = props;
  const [benhNhanData, setBenhNhanData] = useState<benhNhanData>({
    data: [],
    totalPages: DEFAULT_TOTAL_PAGES,
    totalElements: DEFAULT_TOTAL_ELEMENTS,
  });
  const [page, setPage] = useState<number>(DEFAULT_PAGE_INDEX);
  const [rowsPerPage, setRowsPerPage] = useState<number>(MAX_PAGE_SIZE);
  const [keyword, setKeyword] = useState<string>("");
  const [selectedRow, setSelectedRow] = useState<any>({});
  const [codeCollapses, setCodeCollapses] = useState<string[]>([]);
  const [idSelected, setIdSelected] = useState<string>("");
  const [treeData, setTreeData] = useState<any>(TreeSoThu);
  const [fieldSearch, setFieldSearch] = useState<string>("");
  const [valueSearch, setValueSearch] = useState<string>("");

  const { setIsLoading } = useContext(AppContext);

  useEffect(() => {
    setOpenDanhSachSoThu(true);
  }, []);

  useEffect(() => {
    updatePageData();
  }, [page, rowsPerPage, fieldSearch, valueSearch]);

  const handleConvertTreeData = (data: any[]) => {
    let convertedData: any[] = data?.map((item, index) => {
      return {
        ...item,
        code: item?.field,
        name: item?.title,
        filter: item?.items?.map((value: any) => ({
          code: value,
          name: value
        })),
      };
    });
    return convertedData;
  };

  const updatePageData = async () => {
    setIsLoading(true);
    let searchObject: SearchObject = {
      keyword: keyword,
      pageIndex: page,
      pageSize: rowsPerPage,
      field: fieldSearch,
      value: valueSearch,
    };
    try {
      let res = await getDsSoThuTien(searchObject);
      if (res?.data?.code === CODE.SUCCESS) {
        let treeList = res?.data?.data?.treeList;
        let treeDataConverted = {
          code: "all",
          name: treeList?.title || "",
          filter: handleConvertTreeData(treeList?.items)
        };
        setTreeData(treeDataConverted);
        setBenhNhanData({
          data: res?.data?.data?.ledgerTypes || [],
          totalPages: res?.data?.data?.totalPages,
          totalElements: res?.data?.data?.totalElements,
        });
      } else {
        toast.warning(RESPONSE_MESSAGE.ERROR);
      }
      setIsLoading(false);
    } catch (e) {
      toast.error(RESPONSE_MESSAGE.ERROR);
      setIsLoading(false);
    }
  };

  const handleCollapse = (code: string) => {
    if (codeCollapses.includes(code)) {
      setCodeCollapses(codeCollapses.filter((item: string) => item !== code));
    } else {
      setCodeCollapses([...codeCollapses, code]);
    }
  };

  const handleSelected = (code: string) => {
    if (idSelected === code) {
      setIdSelected("");
    } else {
      setIdSelected(code);
    }
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): any => {
    const value = (event.target as HTMLInputElement).value;
    setKeyword(value);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    KEY.ENTER === event.key && updatePageData();
  };
  const handleDoubleClick = (row: any) => {
   if (row?.id) {
     setDataSoThu(row);
     onHide({
       openChonSoThu: false,
       openDanhSachSoThu: false,
       openTaoMoiSoThu: true,
       openDanhSachPhieu: false,
     });
   }
  };

  const handleGetSelectedItem = (data: any) => {
    setFieldSearch(data?.parrentCode);
    setValueSearch(data?.name);
  };

  return (
    <>
      <Modal
        centered
        show={show}
        onHide={() =>
          onHide({
            openChonSoThu: false,
            openDanhSachSoThu: false,
            openTaoMoiSoThu: false,
            openDanhSachPhieu: false,
          })
        }
        size="xl"
      >
        <Modal.Header closeButton className="py-5 header-modal">
          <Modal.Title>Danh sách sổ thu tiền</Modal.Title>
        </Modal.Header>
        <Modal.Body className="spaces px-2 py-0">
          <div className="d-flex w-100">
            <SelectTree
              codeCollapses={codeCollapses}
              handleChangeCollapsesCode={setCodeCollapses}
              idSelected={idSelected}
              handleChangeSelectId={setIdSelected}
              getSelectedItem={handleGetSelectedItem}
              selectTree={treeData || {}}
            />
            <div className="spaces w-85 p-8">
              <Row>
                <Col xs={{ span: 12 }} className="pb-2">
                  <InputSearch
                    handleChange={handleChange}
                    handleSearch={updatePageData}
                    handleKeyDown={handleKeyDown}
                    placeholder="Tìm kiếm"
                    type="text"
                  />
                </Col>
              </Row>
              <div className="h-table border spaces h-82">
                <TableDanhSachSoThu
                  dataDanhSachSoThu={benhNhanData?.data}
                  handleDoubleClick={handleDoubleClick}
                />
              </div>
              <Modal.Footer className="flex flex-end flex-center border-0 pr-0">
                <Button
                  className="btn-fill"
                  onClick={() => {
                    setDataSoThu(null);
                    onHide({
                      openChonSoThu: false,
                      openDanhSachSoThu: false,
                      openTaoMoiSoThu: true,
                      openDanhSachPhieu: false,
                    })
                  }
                  }
                >
                  Thêm mới sổ
                </Button>
              </Modal.Footer>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export { ModalDanhSachSoThu };

