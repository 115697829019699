import "../PhanHeVienPhi.scss";
import { Col, Row } from "react-bootstrap";
import LabelRequired from "../../component/LabelRequired";
import moment from "moment";
import { handleConvertBirthdate } from "../../utils/AppFunction";

type Props = {
  selectedRow: any;
  setSelectedRow: (row: any) => void;
  paymentInfor: any
};

const ThongTinBenhNhan = ({ selectedRow, paymentInfor }: Props) => {
  return (
    <div className="bg-white position-relative spaces gap-6 spaces w-100 mb-6 fl-1">
      <div className="spaces w-100">
        <div className="border-bottom spaces px-10 py-5 gap-10">
          <h4 className="text-title fw-bold fs-4 spaces mb-0">
            Thông tin bệnh nhân
            {selectedRow[0]?.personName
              ? ` - ${selectedRow[0]?.personName} - Mã VP: ${selectedRow[0]?.code}`
              : ``}
          </h4>
        </div>
        <div className="spaces w-100 justify-content-center p-10">
          <Row className="d-flex pl-12 justify-content-center spaces w-100 gap-10">
            <Col className="d-flex align-items-start text-lable-input spaces px-0 pb-4 w-100">
              <LabelRequired label="Ngày sinh:" className="min-w-105px" />
              <span className="w-173">
                {handleConvertBirthdate(paymentInfor) || "..."}
              </span>
            </Col>
            <Col className="d-flex align-items-start text-lable-input spaces px-0 pb-4 w-100">
              <LabelRequired label="Khoa phòng:" className="min-w-105px" />
              <span className="spaces width-186 text-truncate">
                {paymentInfor?.deptName || "..."}
              </span>
            </Col>
            <Col className="d-flex align-items-start text-lable-input spaces px-0 pb-4 w-100">
              <LabelRequired label="Trạng thái:" className="min-w-105px" />
              <span className="w-173">{paymentInfor?.visitStatus || "..."}</span>
            </Col>
          </Row>
          <Row className="d-flex pl-12 justify-content-center spaces w-100 gap-10">
            <Col className="d-flex align-items-start text-lable-input spaces px-0 pb-4 w-100">
              <LabelRequired label="Ngày vào viện:" className="min-w-105px" />
              <span className="w-173">
                {paymentInfor?.visitDateStarted
                  ? moment(paymentInfor?.visitDateStarted).format(
                      "DD/MM/YYYY HH:mm"
                    )
                  : "..."}
              </span>
            </Col>
            <Col className="d-flex align-items-start text-lable-input spaces px-0 pb-4 w-100">
              <LabelRequired label="Ngày ra viện:" className="min-w-105px" />
              <span className="w-173">
                {paymentInfor?.visitDateStopped
                    ? moment(paymentInfor?.visitDateStopped).format(
                        "DD/MM/YYYY HH:mm"
                      )
                    : "..."}
              </span>
            </Col>
            <Col className="d-flex align-items-start text-lable-input spaces px-0 pb-4 w-100">
              <LabelRequired label="Số ngày đ.trị:" className="min-w-105px" />
              <span className="w-173">
                {paymentInfor?.lengthOfStay || "..."}
              </span>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ThongTinBenhNhan;
