import { AddNhanVien } from "../models/ThemNhanVienModel";

export const initialValuesThemHD: AddNhanVien = {
  id: "",
  code: "",
  issuingDate: "",
  issuingPlace: "",
  maritalStatusName: "",
  divisionName: "",
  divisionOther: "",
  addressPermanent: "",
  addressWork: "",
  addressStudy: "",
  occupation: "",
  groupName: "",
  workStartDate: "",
  position: "",
  department: "",
  examExpectedDate: "",
  historyWork: "",
  examPackageId: "",
  examPackageName: "",
  medicalHistory: "",
  medicalHistoryFamily: "",
  preWork: "",
  workAge: "",
  idCard: "",
  contractId: "",
  patientCode: "",
  dob: "",
  name: "",
  address: "",
  phone: "",
  idNumber: "",
  gender: "",
  maritalStatusId: "",
  groupId: "",
  divisionId: "",
  group: null,
  examPackage: null,
  genderObj: null,
  maritalStatus: null,
  division: null,
  obs: null,
  termModelCode: "",
};



