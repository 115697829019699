import { FunctionComponent, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import { PhanHeTiepDonContext } from "../contexts/PhanHeTiepDonContext";
import { convertNumberPrice } from "../../utils/FormatUtils";

interface ThongTinChiPhiProps {}

const ThongTinChiPhi: FunctionComponent<ThongTinChiPhiProps> = () => {
  const { benhNhanInfo, setBenhNhanInfo, thongTinChiPhi } =
    useContext(PhanHeTiepDonContext);
  return (
    <Row className="info-patient px-12 py-16 spaces min-h-163 mx-0">
      <Col xs="8" className="px-0">
        <div className="fs-14px line-height-22 spaces mb-5 w-100">
          <span className="d-inline-block spaces W-135 fw-bold">
            Tổng chi phí
          </span>
          <span className="fw-light">
            {thongTinChiPhi?.ttTong
              ? convertNumberPrice(thongTinChiPhi?.ttTong)
              : 0}
          </span>
        </div>
        <div className="fs-14px line-height-22 spaces mb-5 w-100">
          <span className="d-inline-block spaces W-135 fw-bold">
            Nguồn khác
          </span>
          <span className="fw-light">0</span>
        </div>
        <div className="fs-14px line-height-22 spaces mb-5 w-100 ">
          <span className="d-inline-block spaces W-135 fw-bold">Đã thu</span>
          <span className="fw-light">
            {thongTinChiPhi?.ttDaThu
              ? convertNumberPrice(thongTinChiPhi?.ttDaThu)
              : 0}
          </span>
        </div>
        <div className="fs-14px line-height-22 spaces mb-5 w-100">
          <span className="d-inline-block spaces W-135 fw-bold">Tạm ứng</span>
          <span className="fw-light">
            {thongTinChiPhi?.ttTamUng
              ? convertNumberPrice(thongTinChiPhi?.ttTamUng)
              : 0}
          </span>
        </div>
        <div className="fs-14px line-height-22 spaces mb-5 w-100">
          <span className="d-inline-block spaces W-135 fw-bold">Miễn giảm</span>
          <span className="fw-light">
            {thongTinChiPhi?.ttDiscount
              ? convertNumberPrice(thongTinChiPhi?.ttDiscount)
              : 0}
          </span>
        </div>
      </Col>
      <Col xs="4" className="text-center border-start border-secondary px-0">
        <div className="fs-14px line-height-22 spaces mb-10 text-danger">
          <p className="fw-bold mb-0">Bệnh nhân</p>
          <span className="fw-normal text-danger">
            {thongTinChiPhi?.ttNguoiBenh
              ? convertNumberPrice(thongTinChiPhi?.ttNguoiBenh)
              : 0}
          </span>
        </div>
        <div className="fs-14px line-height-22 spaces text-title">
          <p className="fw-bold mb-0">
            {thongTinChiPhi?.ttTamUng + thongTinChiPhi?.ttDaThu >
            thongTinChiPhi?.ttNguoiBenh
              ? "Còn dư"
              : "Còn nợ"}
          </p>
          <span className="fw-normal">
            {thongTinChiPhi?.ttConNo
              ? convertNumberPrice(thongTinChiPhi?.ttConNo)
              : 0}
          </span>
        </div>
      </Col>
    </Row>
  );
};

export default ThongTinChiPhi;
