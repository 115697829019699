import Fuse from 'fuse.js';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import InputSearch from '../../../../../component/InputSearch';
import SimpleTable from '../../../../../component/table/simple-table/SimpleTable';
import { CODE_DOI_TUONG } from '../../../../../phan-he-tiep-nhan-thanh-toan/constants/PhanHeTiepNhan';
import { formatMoney } from '../../../../../utils/FormatUtils';
import { columnsKeVatTu } from '../../../../columns/modal-ke-vat-tu/ColumnModalKeVatTu';
import { PhanHeTiepDonContext } from '../../../../contexts/PhanHeTiepDonContext';
import { useKeVatTuContext } from '../../context';
import { useTableSelectVatTuContext } from '../../context/TableSelectVatTuContext';
import TableSelectVatTuRowEdit from './TableSelectVatTuRowEdit';

const TableSelectVatTu = () => {
	const { benhNhanInfo } = useContext(PhanHeTiepDonContext);
	const { treeNodeValue, setVatTuChecked, vatTuChecked } = useKeVatTuContext();
	const { selectedRow, setSelectedRow } = useTableSelectVatTuContext();

	const tableDataRef = useRef<object[]>([]);
	const [tableData, setTableData] = useState<object[]>([]);

	const { visit } = benhNhanInfo.thongTinKhamBenh;
	const { BAO_HIEM, VIEN_PHI, YEU_CAU } = CODE_DOI_TUONG;

	useEffect(() => {
		setTableData(treeNodeValue);
		tableDataRef.current = treeNodeValue;
		setSelectedRow(null);
	}, [treeNodeValue]);

	const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
		const keySearch = e.target.value;

		if (!keySearch) {
			setTableData(tableDataRef.current);
			return;
		}

		const fuse = new Fuse(tableData, {
			keys: ['maVatTu', 'tenVatTu', 'giaBhyt', 'giaVienPhi', 'giaDichVu'],
			threshold: 0.8,
		});
		const result = fuse.search(keySearch.trim());
		setTableData(result.map(value => value.item));
	};

	const renderCell = (columnData: any, dataKey: string, rowData: any) => {
		rowData = {
			...rowData,
			tonKho: formatMoney(rowData?.tonKho) || 0,
			giaBhyt: formatMoney(rowData?.giaBhyt) || 0,
			giaVienPhi: formatMoney(rowData?.giaVienPhi) || 0,
			giaDichVu: formatMoney(rowData?.giaDichVu) || 0,
		};
		if (typeof rowData.get === 'function') {
			return rowData.get(dataKey);
		} else {
			return rowData[dataKey];
		}
	};

	const getDonGiaTheoLoaiDoiTuong = (rowData: any) => {
		const MAP_DON_GIA_LOAI_DOI_TUONG = {
			[BAO_HIEM]: 'giaBhyt',
			[VIEN_PHI]: 'giaVienPhi',
			[YEU_CAU]: 'giaDichVu',
		};
		return rowData[MAP_DON_GIA_LOAI_DOI_TUONG[visit.visitObjectTypeCode]];
	};

	const onRowSelect = (rows: any[]) => {
		const rowData = rows[0];
		const isExistVatTu = vatTuChecked.some(row => row.idVatTu === rowData.id);
		if (isExistVatTu) {
			setSelectedRow(null);
			toast.warn('Vật tư đã được kê trong phiếu chỉ định');
			return;
		}
		setSelectedRow(rowData);
	};

	const onSaveSelectedRow = (values: any) => {
		const donGiaVatTu = getDonGiaTheoLoaiDoiTuong(values) || values.donGia;
		let listTemp = [...vatTuChecked];

		const additonalRowData = {
			...values,
			donGia: donGiaVatTu,
			thanhTien: donGiaVatTu * values.soLuong,
			doiTuong: values.doiTuong || visit?.visitObjectTypeName,
		};

		if (!values.idVatTu) {
			listTemp = [
				...listTemp,
				{
					idVatTu: values.id,
					...additonalRowData,
				},
			];
		} else {
			listTemp = vatTuChecked.map((row: any) => {
				if (row.idVatTu === values.idVatTu) {
					return {
						...row,
						...additonalRowData,
					};
				}
				return row;
			});
		}

		setVatTuChecked(listTemp);
		setSelectedRow(null);
	};

	return (
		<>
			<div className="bg-white">
				<div className="px-3 py-1">
					<TableSelectVatTuRowEdit data={selectedRow} onSave={onSaveSelectedRow} />
				</div>
				<div className="px-10 py-5 h-35 spaces">
					<InputSearch
						placeholder="Tìm kiếm"
						handleChange={onSearch}
						className="spaces h-25 pr-4 radius-3"
					/>
				</div>
				<div className="min-h-180 spaces bg-white mb-6">
					<SimpleTable
						data={tableData}
						columns={columnsKeVatTu}
						height={200}
						width={1000}
						scrollable={true}
						setDataChecked={onRowSelect}
						handleRenderCell={renderCell}
						dataChecked={selectedRow ? [selectedRow] : []}
					/>
				</div>
			</div>
		</>
	);
};

export default TableSelectVatTu;
