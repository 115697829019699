import { FC, useEffect, useState } from "react";
import { Accordion, Table } from "react-bootstrap";
import "../table.scss";
import { generateSecureRandomId } from "../../../utils/AppFunction";

interface Props {
  data: any[];
  columns: any[];
  collapseHeader: string;
  collapseBody: string;
  handleClickRow?: (params: any) => void;
  handleContextMenu?: (e: any, row: any) => void;
  phieuHuy?: string;
  className?: string;
  classNameTable?: string;
  height?: any;
  minHeight?: any;
  maxHeight?: any;
}

const TableCollapse: FC<Props> = (props) => {
  let {
    data,
    columns,
    handleClickRow,
    collapseHeader,
    collapseBody,
    handleContextMenu,
    phieuHuy = null,
    className,
    classNameTable,
    height,
    minHeight,
    maxHeight,
  } = props;

  const [rowId, setRowId] = useState<any>("");
  const [styles, setStyles] = useState<object>({});
  const [openKeys, setOpenKeys] = useState<string[]>(["0"]);

  useEffect(() => {
    setStyles({
      ...styles,
      minHeight: minHeight,
      maxHeight: maxHeight,
      height: height,
    });
  }, [minHeight, maxHeight, height]);

  const renderFieldValue = (data: any, field: string) => {
    let result = data;
    field.split(".").forEach((fieldItem) => {
      result = result?.[fieldItem];
    });
    return result;
  };

  const handleCustomContextMenu = (e: any, row: any) => {
    e.preventDefault();
    handleContextMenu && handleContextMenu(e, row);
  };

  const handleClick = (item: any, e: any) => {
    e.stopPropagation();
    handleClickRow?.(item);
    setRowId(item?.id);
  };

  const handleSplitField = (url: string, data: any) => {
    if (url.includes(".")) {
      return url.split(".").reduce((obj, key) => obj?.[key], data);
    } else {
      return data?.[url];
    }
  };

  const handleAccordionToggle = (key: string) => {
    setOpenKeys((prevKeys) =>
      prevKeys.includes(key)
        ? prevKeys.filter((k) => k !== key)
        : [...prevKeys, key]
    );
  };

  return (
    <div
      className={`table-responsive table-scroll-y table-collapse ${className}`}
      style={styles}
    >
      <Table
        className={`table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer ${classNameTable}`}
      >
        <thead className="header-sticky">
          <tr className="text-muted fw-bolder fs-7 text-uppercase gs-0 border bg-pri">
            {columns?.map((column) => (
              <th
                className={`text-uppercase fs-7 fw-bold text-dark cursor-pointer bg-pri text-center p-7 h-35 ${
                  column?.className || ""
                }`}
                key={generateSecureRandomId()}
              >
                {column?.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="text-gray fw-bold bg-white">
          {data?.map((pItem: any, pIndex: number) => {
            let headerValue = handleSplitField(collapseHeader, pItem);
            let bodyValue = handleSplitField(collapseBody, pItem);
            return (
              <tr key={generateSecureRandomId()}>
                <td colSpan={columns.length} className="p-0">
                  <Accordion
                    activeKey={openKeys}
                    onSelect={(key) => handleAccordionToggle(key as string)}
                  >
                    <Accordion.Item eventKey={pIndex.toString()}>
                      {headerValue && (
                        <Accordion.Header>
                          <h6 className="m-0 ps-1">{headerValue}</h6>
                        </Accordion.Header>
                      )}
                      <Accordion.Body className="p-0">
                        <Table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer table-children">
                          <tbody>
                            {bodyValue ? (
                              bodyValue.map((item: any, index: number) => (
                                <tr
                                  key={index}
                                  className={`${
                                    handleClickRow ? "clickable-row" : ""
                                  } ${rowId === item.id ? "bg-secondary" : ""}`}
                                  onClick={(e) => handleClick(item, e)}
                                >
                                  {columns?.map(
                                    (column: any, cIndex: number) => (
                                      <td
                                        key={cIndex}
                                        className={`py-4 p-7 spaces h-29 text-system ${
                                          column?.className || ""
                                        } ${
                                          phieuHuy === item?.id
                                            ? "text-decoration-line-through"
                                            : ""
                                        }`}
                                        onContextMenu={(e) =>
                                          handleCustomContextMenu(e, item)
                                        }
                                      >
                                        {column?.render
                                          ? column.render(
                                              column,
                                              cIndex,
                                              item,
                                              index,
                                              pIndex
                                            )
                                          : renderFieldValue(
                                              item,
                                              column?.field
                                            )}
                                      </td>
                                    )
                                  )}
                                </tr>
                              ))
                            ) : (
                              <tr>
                                {columns?.map((column: any, cIndex: number) => (
                                  <td
                                    key={generateSecureRandomId()}
                                    className={`py-4_5 p-7 spaces text-system ${
                                      column?.className || ""
                                    }`}
                                    onContextMenu={(e) =>
                                      handleCustomContextMenu(e, pItem)
                                    }
                                  >
                                    {column?.render
                                      ? column.render(column, cIndex)
                                      : renderFieldValue(pItem, column?.field)}
                                  </td>
                                ))}
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </td>
              </tr>
            );
          })}
          {data?.length === 0 && (
            <tr>
              <td colSpan={columns.length} className="text-center">
                Không có dữ liệu
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export { TableCollapse };
