/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { KEY_DS_DANH_MUC_TIEP_DON } from "../../../../app/modules/utils/Constant";
import { deleteIndexedDBItem } from "../../../../app/modules/utils/IndexedDB";
import { localStorageItem } from "../../../../app/modules/utils/LocalStorage";

const categories = [
  {
    key: "provinces",
    name: "Tỉnh/Thành phố",
  },
  {
    key: "districts",
    name: "Huyện/Quận",
  },
  {
    key: "subDistricts",
    name: "Xã/Phường",
  },
  {
    key: "listDichVu",
    name: "Dịch vụ",
  },
  {
    key: "listPhongKham",
    name: "Phòng khám",
  },
  {
    key: "listQuocTich",
    name: "Quốc tịch",
  },
  {
    key: "listDanToc",
    name: "Dân tộc",
  },
];

const ClearCache: FC = () => {
  const navigate = useNavigate();

  const handleDeleteLocalStorage = async () => {
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      if (key?.startsWith('form-')) {
        localStorageItem.remove(key);
      }
    };
    await deleteIndexedDBItem(KEY_DS_DANH_MUC_TIEP_DON);
    navigate("/home");
  };

  return (
    <div
      className="menu-item px-5"
      data-kt-menu-trigger="hover"
      data-kt-menu-placement="left-start"
      data-kt-menu-flip="bottom"
    >
      <a href="#" className="menu-link px-5" onClick={() => handleDeleteLocalStorage()}>
        <span className="menu-title position-relative">{"Xóa cache"}</span>
      </a>

      {/* <div className="menu-sub menu-sub-dropdown w-175px py-4">
        {categories.map((item) => (
          <div className="menu-item px-3" onClick={() => handleDeleteLocalStorage()}>
            <a href="#" className={clsx("menu-link d-flex px-5")}>
              {item.name}
            </a>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export { ClearCache };
