import { Form, Formik, FormikErrors } from "formik";
import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Button, Col, Dropdown, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { IconBack } from "../../../component/IconSvg";
import InfoPatientRight from "../../components/InfoPatientRight";
import { PhanHeTiepDonContext } from "../../contexts/PhanHeTiepDonContext";
import SelectTree from "../../../component/SelectTree";
import InputSearch from "../../../component/InputSearch";
import TableDanhSachPhieu from "../../../phan-he-vien-phi/components/TableDanhSachPhieu";
import { TreeDSPhieu } from "../../../phan-he-vien-phi/components/fakeData";
import { DataDanhThanhToan, TreeDSThanhToan } from "../../components/FakeData";
import { TableCustom } from "../../../component/table/table-custom/TableCustom";
import { ThanhToanInfo } from "../../models/DSBenhNhanKhamBenhModels";
import { ThanhToanColumns } from "./ThanhToanColumn";
import PaymentInfor from "../../../phan-he-vien-phi/components/PaymentInfor";

interface Props {
  open: boolean;
  handleClose: Dispatch<SetStateAction<boolean>>;
}

const PageThanhToan: FC<Props> = (props) => {
  let { open, handleClose } = props;
  const { benhNhanInfo } = useContext(PhanHeTiepDonContext);

  const [openLuuMauThuocMoiDialog, setOpenLuuMauThuocMoiDialog] =
    useState<boolean>(false);
  const [openDanhSachMauDialog, setOpenDanhSachMauDialog] =
    useState<boolean>(false);
  const [openModalBenhKemTheo, setOpenModalBenhKemTheo] =
    useState<boolean>(false);
  const [openModalDonThuocCu, setOpenModalDonThuocCu] =
    useState<boolean>(false);
  const [codeCollapses, setCodeCollapses] = useState<string[]>([]);
  const [idSelected, setIdSelected] = useState<string>("");
  const [keyword, setKeyword] = useState<string>("");

  const handleSubmit = async (values: any) => {
    // handleClose(false);
  };
  const [paymentInfor, setPaymentInfor] = useState<any>([]);

  const validationSchema = Yup.object().shape({});

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): any => {
    const value = (event.target as HTMLInputElement).value;
    setKeyword(value);
  };

  useEffect(() => {
    setPaymentInfor([
      {
        name: "Tổng chi phí",
        value: 0,
        type: "price",
        color: 'text-danger'
      },
      {
        name: "BHYT",
        value: 0,
        type: "price",
      },
      {
        name: "Giảm dịch vụ",
        value: 0,
        type: "price",
      },
      {
        name: "Bệnh nhân",
        value: 0,
        type: "price",
      },
      {
        name: "Tạm ứng",
        value: 0,
        type: "price",
      },
      {
        name: "Đã thu",
        value: 0,
        type: "price",
      },
      {
        name: "Giảm phiếu thu",
        value: 0,
        type: "price",
      },
      {
        name: "Còn nợ",
        value: 0,
        type: "price",
      },
    ]);
  }, []);

  return (
    <Modal
      className="modal-thuoc page-full"
      fullscreen
      dialogClassName="h-modal"
      size="xl"
      show={open}
      animation={false}
      centered
      backdropClassName="spaces top-50"
    >
      <Formik<any>
        initialValues={{}}
        validationSchema={validationSchema}
        validateOnChange={true}
        validate={(values) => {
          const errors: FormikErrors<any> = {};
          return errors;
        }}
        onSubmit={handleSubmit}
      >
        {({ touched, errors, setFieldValue, values }) => (
          <Form id="form-thuoc" className="spaces h-100">
            <div className="d-flex flex-column h-100">
              <Modal.Header className="py-3 header-modal">
                <div className="d-flex gap-6">
                  <div
                    className="d-flex align-items-center gap-3"
                    onClick={() => handleClose(false)}
                  >
                    <IconBack />
                    <span className="fw-500">Quay lại</span>
                  </div>
                  <div className="d-flex gap-4">
                    <Button className="btn-fill" onClick={() => {}}>
                      <i className="bi bi-caret-down-fill"></i>
                      In
                    </Button>
                    <Button className="btn-fill" onClick={() => {}}>
                      XML - 4210
                    </Button>
                    <Button
                      className="btn-fill"
                      onClick={() =>
                        toast.warning(
                          "Không tìm thấy cấu hình phác đồ cho các mã bệnh : V16"
                        )
                      }
                    >
                      XML - 130
                    </Button>
                    <Button className="btn-fill spaces min-w-76">
                      Kiểm tra thẻ BH
                    </Button>
                  </div>
                </div>
                {benhNhanInfo && (
                  <InfoPatientRight benhNhanInfo={benhNhanInfo} />
                )}
              </Modal.Header>
              <Modal.Body className="bg-white p-0 spaces overflow-hidden spaces">
                <div className="d-flex w-100 spaces h-100">
                  <SelectTree
                    codeCollapses={codeCollapses}
                    handleChangeCollapsesCode={setCodeCollapses}
                    idSelected={idSelected}
                    handleChangeSelectId={setIdSelected}
                    selectTree={TreeDSThanhToan}
                    className="spaces h-100"
                    // isSearchExpanded={true}
                  />
                  <div className="spaces w-85 p-0">
                    <Row>
                      <Col xs={{ span: 12 }} className="p-4">
                        <InputSearch
                          handleChange={handleChange}
                          // handleSearch={updatePageData}
                          // handleKeyDown={handleKeyDown}
                          placeholder="Tìm kiếm"
                          type="text"
                        />
                      </Col>
                    </Row>
                    <div className="spaces h-calc-vh-211">
                      <TableCustom<ThanhToanInfo>
                        data={DataDanhThanhToan}
                        columns={ThanhToanColumns}
                        // maxHeight={300}
                      />
                    </div>
                    <div className="price-info spaces border-top p-6">
                      <PaymentInfor
                        className="price-info-thanh-toan"
                        paymentData={paymentInfor}
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default PageThanhToan;
