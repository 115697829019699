// @ts-nocheck
import { Column } from 'react-table'
import { useIntl } from 'react-intl'
import { bangLichSuKham } from '../../../models/PhanHeTiepNhanModel'
import { TableCustomHeader } from '../../../../component/table/components/TableCustomHeader'
import { TableCustomCell } from '../../../../component/table/components/TableCustomCell'
import { formatDateToDDMMYYYY, formatTrangThaiBenhNhan, formatTrangThaiBenhNhanDatLich } from '../../../../utils/FormatUtils'

const LichSuKhamColumn: ReadonlyArray<Column<bangLichSuKham>> = [
  {
    Header: (props) => (
      <TableCustomHeader<bangLichSuKham>
        tableProps={props}
        className=' min-w-50px text-center'
      />
    ),
    id: 'member.trangThai',
    Cell: ({ ...props }) => (
      <TableCustomCell className='text-center p-2' 
        data={formatTrangThaiBenhNhan(
          props?.data[props?.row?.index]?.color
        )}
      />
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<bangLichSuKham>
        tableProps={props}
        title='Ngày vào'
        className=' min-w-100px text-center'
      />
    ),
    id: 'member.ngayVao',
    Cell: ({ ...props }) => {
      let data = props.data[props.row.index].visitStartedDate || null;
      return <TableCustomCell className='\text-center p-2' data={data ? formatDateToDDMMYYYY(data) : ""} />
    },
  },
  {
    Header: (props) => (
      <TableCustomHeader<bangLichSuKham>
        tableProps={props}
        title='Khoa'
        className='min-w-250px text-center ps-4'
      />
    ),
    id: 'member.position',
    Cell: ({ ...props }) => (
      <TableCustomCell className='text-start p-2' data={props.data[props.row.index]?.department || ""}/>
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<bangLichSuKham>
        tableProps={props}
        title='Phòng khám'
        className='min-w-250px text-center ps-4'
      />
    ),
    id: 'phong',
    Cell: ({ ...props }) => (
      <TableCustomCell className='text-start p-2' data={props.data[props.row.index]?.clinic || ""}/>
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<bangLichSuKham>
        tableProps={props}
        title='Bác sĩ'
        className='min-w-200px text-center ps-4'
      />
    ),
    id: 'bacSi',
    Cell: ({ ...props }) => (
      <TableCustomCell className='min-w-200px text-start p-2' data={props.data[props.row.index]?.doctor || ""}/>
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<bangLichSuKham>
        tableProps={props}
        title='Thẻ BHYT'
        className='min-w-200px text-center ps-4'
      />
    ),
    id: 'bhyt',
    Cell: ({ ...props }) => (
      <TableCustomCell className='min-w-200px text-start p-2' data={props.data[props.row.index]?.insuranceCode || ""}/>
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<bangLichSuKham>
        tableProps={props}
        title='Ngày sinh'
        className='min-w-100px text-center ps-4'
      />
    ),
    id: 'ngaySinh',
    Cell: ({ ...props }) => (
      <TableCustomCell className='text-start p-2' data={props.data[props.row.index]?.birthDate}/>
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<bangLichSuKham>
        tableProps={props}
        title='Ngày ra'
        className='min-w-200px text-center ps-4'
      />
    ),
    id: 'ngayRa', 
    Cell: ({ ...props }) => {
      let data = props.data[props.row.index].visitStoppedDate || null;
      return <TableCustomCell className='min-w-150px text-center p-2' data={data ? formatDateToDDMMYYYY(data) : ""} />
    },
  },
  {
    Header: (props) => (
      <TableCustomHeader<bangLichSuKham>
        tableProps={props}
        title='Xử trí'
        className='min-w-200px text-center ps-4'
      />
    ),
    id: 'xuTri',
    Cell: ({ ...props }) => (
      <TableCustomCell className='min-w-200px text-start p-2' data={props.data[props.row.index]?.status || ""}/>
    ),
  },
  {
    Header: (props) => (
      <TableCustomHeader<bangLichSuKham>
        tableProps={props}
        title='Thông tin khác'
        className='min-w-200px text-center ps-4'
      />
    ),
    id: 'thongTinKhac',
    Cell: ({ ...props }) => (
      <TableCustomCell className='min-w-200px text-start p-2' data={props.data[props.row.index]?.descriptions || ""}/>
    ),
  },
]

export { LichSuKhamColumn }
