import { IContextMenu } from "../../phan-he-tiep-nhan-thanh-toan/models/ModelTabDSDangKy";
import { CONTEXT_MENU_SUA_DOI_TUONG_KEYS, CONTEXT_MENU_SUA_DOI_TUONG_OPTIONS } from "./Thuoc";

export const CONTEXT_MENU_KE_VAT_TU_KEY = {
    XOA_VAT_TU: 'XOA_VAT_TU',
    ...CONTEXT_MENU_SUA_DOI_TUONG_KEYS
};

export const contextMenuKeVatTuOptions: IContextMenu[] = [
    {
        icon: <i className="bi bi-x-lg text-danger"></i>,
        code: CONTEXT_MENU_KE_VAT_TU_KEY.XOA_VAT_TU,
        name: 'Xóa vật tư',
    },
    CONTEXT_MENU_SUA_DOI_TUONG_OPTIONS
];