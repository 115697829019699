import axios, { AxiosResponse } from "axios";
import { urlParamsConfig } from "../../../../utils/ParamsUtils";
import { WfWorkflowsReq, WfWorkflowsRes } from "../models/WfWorkflows";
import { OctResponse } from "../../../../models/OctResponse";

const API_PATH = process.env.REACT_APP_EMR_API_URL;
const API_SO_KHAM = API_PATH + '/physical_metrics/soKhamSucKhoe'
const API_PATH_DS_HOP_DONG = API_PATH + '/contracts'
const API_PATH_DETAIL_ORDER_KHAM_BENH = API_PATH + '/pe'
const API_PATH_START_KHAM_BENH = API_PATH + '/pe/start'
const API_PATH_KHAM_BENH_KLS = API_PATH + '/pe-obs'
const API_PATH_KLS_WFL = API_PATH + '/wf-workflows/phy_exam';

export const getDetailOrderKhamBenh = (data: any) => {
  let url = API_PATH_DETAIL_ORDER_KHAM_BENH;
  return axios.get(urlParamsConfig(url, data));
};

export const startKhamBenh = (data: any) => {
  return axios.post(API_PATH_START_KHAM_BENH, data);
};

export const updateHopDong = (data: any) => {
  let url = API_PATH_DS_HOP_DONG;
  return axios.put(url, data);
};

export const saveKhamBenh = (data: any, code: string, id: string) => {
  let url = API_PATH_KHAM_BENH_KLS + `/${code}/` + id;
  return axios.put(url, data);
};

export const getDetailKhamBenh = (id: string) => {
  let url = API_PATH_KHAM_BENH_KLS + `/${id}`;
  return axios.get(url);
};

const API_PATH_WORKFLOWS = API_PATH + "/wf-workflows";

export const getWfWorkflows = (trackerCode: string, objectId: string | undefined) => {
  let url = ` ${API_PATH_WORKFLOWS}?tracker=${trackerCode}`;
  if (objectId) url += `&objectId=${objectId}`;

  return axios.get(url);
}
export const updateWfWorkflows = (trackerCode: string, wfWorkflowsReq: WfWorkflowsReq): Promise<AxiosResponse<OctResponse<WfWorkflowsRes>>> => {
  let url = ` ${API_PATH_WORKFLOWS}/${trackerCode}`
  return axios.post(url, wfWorkflowsReq);
};

export const createSoKham = (data: any) => {
  let url = API_SO_KHAM;
  return axios.post(url, data);
};

export const getDetailSoKham = (idNhanVien: string) => {
  let url = `${API_SO_KHAM}/${idNhanVien}`;
  return axios.get(url);
};

export const updateSummaryContract = (data: any) => {
  return axios.post(API_PATH_KLS_WFL, data);
};