import { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import InputSearch from '../../../component/InputSearch';
import SimpleTable from '../../../component/table/simple-table/SimpleTable';
import { IThuoc } from '../../models/ThuocModels';
import { searchThuoc } from '../../services/ThuocService';
import { SelectModalProps } from './type';
import { formatMoney } from '../../../utils/FormatUtils';
import { AppContext } from '../../../appContext/AppContext';
import { toast } from 'react-toastify';
import { RESPONSE_MESSAGE } from '../../../utils/Constant';

const SearchField = ({ onSearch }: { onSearch: (keySeach: string) => void }) => {
	const [value, setValue] = useState<string>('');

	return (
		<InputSearch
			placeholder="Tìm kiếm"
			handleChange={e => {
				setValue(e.target.value);
			}}
			handleKeyDown={key => {
				if (key.key === 'Enter') onSearch(value);
			}}
			handleSearch={() => {
				onSearch(value);
			}}
		/>
	);
};

const SelectModal = ({ onClose, onSave, selectedValue, columns, maKhoThuoc }: SelectModalProps) => {
	const {setIsLoading} = useContext(AppContext);
	const [tableData, setTableData] = useState<IThuoc[]>([]);
	const [selectedRow, setSelectedRow] = useState<any[]>([]);

	useEffect(() => {
		getDanhSachThuoc();
		
		if (selectedValue) {
			setSelectedRow([
				{
					id: selectedValue,
				},
			]);
		}
	}, []);

	const getDanhSachThuoc = async (keySeach?: string) => {
		try {
			setIsLoading(true);
			const response = await searchThuoc({
				wareHouse: maKhoThuoc,
				keyword: keySeach,
			});
			setTableData(response);
		} catch (error) {
			console.error(error);
			toast.error(RESPONSE_MESSAGE.ERROR);
		} finally {
			setIsLoading(false);
		}
	};

	const onRenderCell = (columnData: any, dataKey: string, rowData: any) => {
		rowData = {
			...rowData,
			sellingPrice: formatMoney(rowData?.sellingPrice) || 0,
		};
		if (typeof rowData.get === 'function') {
			return rowData.get(dataKey);
		} else {
			return rowData[dataKey];
		}
	};

	const onSaveForm = () => {
		onSave(selectedRow[0]);
		onClose();
	};

	const onSearch = (keySearch: string) => {
		getDanhSachThuoc(keySearch);
	};

	return (
		<Modal show centered size="xl" className="dialog-background" onHide={onClose}>
			<Modal.Header closeButton className="modal-header">
				<Modal.Title className="text-transform-none">Chỉ định thuốc</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<div className="spaces py-5">
					<SearchField onSearch={onSearch} />
				</div>
				<SimpleTable
					data={tableData}
					columns={columns}
					height={450}
					width={1092}
					scrollable={true}
					setDataChecked={setSelectedRow}
					dataChecked={selectedRow}
					uniquePrefix='code'
					handleRenderCell={onRenderCell}
				/>
			</Modal.Body>

			<Modal.Footer>
				<Button className="btn-fill" onClick={onSaveForm}>
					Xác nhận
				</Button>
			</Modal.Footer>
		</Modal>
	);
};
export default SelectModal;
