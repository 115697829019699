import Fuse from 'fuse.js';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { CODE } from '../../../../../utils/Constant';
import { columnsDanhSachDonThuoc } from '../../../../columns/tab-thuoc/ColumnTabThuoc';
import { PhanHeTiepDonContext } from '../../../../contexts/PhanHeTiepDonContext';
import { getDrugListById } from '../../../../services/ThuocService';
import { useTabThuocContext } from '../../context';
import DanhSachPhieuThuoc from './DanhSachPhieu';
import SearchField from './SearchField';
import TableDonThuoc from './TableDonThuoc';

const DonThuoc = () => {
	const {
		donThuocInfo,
		danhSachDonThuoc,
		setDonThuocInfo,
		donThuocSelectedIndex,
		setDonThuocSelectedIndex,
	} = useTabThuocContext();
	const { benhNhanInfo } = useContext(PhanHeTiepDonContext);

	const initialTableDataRef = useRef<any[]>([]);

	const [tableData, setTableData] = useState<any[]>([]);
	const [danhSachPhieu, setDanhSachPhieu] = useState<any[]>([]);

	useEffect(() => {
		if (donThuocInfo?.id) {
			getTableData(donThuocInfo.id);
		}
	}, [donThuocInfo?.id, benhNhanInfo?.shouldUpdate]);

	useEffect(() => {
		if (!danhSachDonThuoc.length) setTableData([]);
	}, [danhSachDonThuoc.length]);

	useEffect(() => {
		if (!danhSachDonThuoc.length) {
			setDanhSachPhieu([]);
			return;
		}

		setDanhSachPhieu(
			danhSachDonThuoc.map((donThuoc, index) => ({
				...donThuoc,
				date: moment(donThuoc?.doctorOrderDay).format('DD/MM/YYYY'),
				index,
			}))
		);
	}, [danhSachDonThuoc]);

	const getTableData = async (drugGroupId: string) => {
		try {
			const response = await getDrugListById({
				medicalItemGroupId: drugGroupId,
			});

			if (response.code === CODE.SUCCESS) {
				setTableData(response.data.content);
				initialTableDataRef.current = response.data.content;
			}
		} catch (error) {
			console.error(error);
		}
	};

	const onPhieuSelect = (data: any) => {
		setDonThuocInfo(data);
		setDonThuocSelectedIndex(data.index);
	};

	const onSearch = (keySearch: string) => {
		if (!initialTableDataRef.current) {
			return;
		}

		if (!keySearch) {
			setTableData(initialTableDataRef.current);
			return;
		}

		const fuse = new Fuse(initialTableDataRef.current, {
			keys: ['name', 'medicalItemCode', 'route', 'unitPrice', 'intoMoney'],
			threshold: 0.8,
		});
		const result = fuse.search(keySearch).map(record => record.item);
		setTableData(result);
	};

	return (
		<div className="bg-white mb-2">
			<SearchField onSearch={onSearch} />
			<DanhSachPhieuThuoc
				data={danhSachPhieu}
				onSelect={onPhieuSelect}
				selectedIndex={donThuocSelectedIndex}
			/>
			<TableDonThuoc data={tableData} columns={columnsDanhSachDonThuoc} />
		</div>
	);
};

export default DonThuoc;
