import React, { useContext, useEffect } from "react";
import LabelRequired from "../../../../component/LabelRequired";
import { Col, Row } from "react-bootstrap";
import TextField from "../../../../component/TextField";
import { KhamLamSangContext } from "../KhamLamSang";

type Props = { };

const BMIComponent = (props: Props) => {
  const { isView, bmiData, setBMIData } = useContext(KhamLamSangContext);

	useEffect(() => {
    handleBMI();
  }, [bmiData?.height, bmiData?.weight]);

	const handleBMI = () => {
    let chieuCao = Number(bmiData?.height) / 100;
    let canNang = Number(bmiData?.weight);
    let bmi: string | number = "";

    if (chieuCao && canNang) {
      bmi = (canNang / (chieuCao * chieuCao)).toFixed(1);
    }
    setBMIData({
			...bmiData,
			bmi: bmi
		});
  };

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
		setBMIData({
			...bmiData,
			[name]: value
		});
  };

  return (
    <Row className="spaces pt-8">
      <Col xs={3} className="d-flex spaces pr-4">
        <LabelRequired label="Cân nặng" className="min-w-80px spaces ml-5" />
        <TextField
          name="weight"
          className="min-w-130px"
          disabled={isView}
          value={bmiData?.weight || ""}
          onChange={(e: any) => handleChange(e)}
        />
      </Col>
      <Col xs={1}>
        <div className="text-lable-input">kg</div>
      </Col>
      <Col xs={3} className="d-flex spaces pr-4">
        <LabelRequired label="Chiều cao" className="min-w-80px spaces ml-5" />
        <TextField
          name="height"
          disabled={isView}
          value={bmiData?.height || ""}
					onChange={(e: any) => handleChange(e)}
        />
      </Col>
      <Col xs={1}>
        <div className="text-lable-input">cm</div>
      </Col>
      <Col xs={3} className="d-flex spaces pr-4">
        <LabelRequired label="BMI" className="min-w-80px spaces ml-5" />
        <TextField
          name="bmi"
          disabled={isView}
          value={bmiData?.bmi || ""}
					onChange={(e: any) => handleChange(e)}
        />
      </Col>
      <Col xs={1}>
        <div className="text-lable-input">kg/cm2</div>
      </Col>
    </Row>
  );
};

export default BMIComponent;
