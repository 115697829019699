import React, { useContext, useEffect, useState } from "react";
import "./KhamLamSang.scss";
import GenerateFormHTML from "../../../../component/generate-form/GenerateFormHTML"
import { generateForm } from "../../../../utils/AppFunction";
import { AppContext } from "../../../../appContext/AppContext";
import { CODE } from "../../../../utils/Constant";
import { saveKhamBenh } from "../services/KhamLamSangSevice"
import { KhamLamSangContext, KhamLamSangContextProps } from "../KhamLamSang";
import { PhanHeKhamDoanContext, PhanHeKhamDoanContextProps } from "../../../PhanHeKhamDoan";
import { WF_STATUS } from "../../../constants/PhanHeKhamDoan";


type Props = {
  // handlerSaveForm: any;
};

const TabKhamNgoai = (props: Props) => {

  const { } = props;
  const { TTNhanVien,TTDetailKhamBenh} = useContext<KhamLamSangContextProps>(KhamLamSangContext);
  const { setWorkFlowStatus, workFlowStatus } = useContext<PhanHeKhamDoanContextProps>(PhanHeKhamDoanContext);
  const { setIsLoading } = useContext(AppContext);
  const [listJsonGenerate, setListJsonGenerate] = useState<any>([]);
  const [isView, setIsView] = useState<any>(false);

  useEffect(() => {
    getListform()
  }, [])

  const handlerSaveForm = async (value: any) => {
    let values = {
      ...value?.dataSend,
    }
    try {
      setIsLoading(true);
      let { data } = await saveKhamBenh(values, "khamNgoai", TTNhanVien?.encounter?.id);
      if (CODE.SUCCESS === data?.code) {
        setIsLoading(false);
      } else {
        let errorMesage = data?.data?.[0]?.message || "Xảy ra lỗi, vui lòng thử lại!";
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }
 
  useEffect(() => {
    handlerCheckDisable()
  }, [TTNhanVien?.encounter?.id,workFlowStatus?.currStatusCode])

  const handlerCheckDisable = () => {
    if (TTNhanVien?.encounter?.id && workFlowStatus?.currStatusCode !== WF_STATUS.KET_THUC_KHAM.CODE) { setIsView(false) } else {
      setIsView(true)
    }
  }

  const getListform = async () => {
    let data = await generateForm("khamNgoai", setIsLoading)
    setListJsonGenerate(data)
  }
  return (

    <div className="bg-white  modelKhamBenh spaces h-calc-vh-340  mb-6 fl-1">

      {/* <div className="spaces w-100 justify-content-center pt-10 ">
        <TextField
          name="khamBenh"
          className="text-field-label-down-line min-height-90px"
          labelClassName="ps-2"
          as="textarea"
          label="Khám bệnh"
        />
      </div>
      <div className="spaces w-100 justify-content-center pt-10">
        <TextField
          name="phanLoai"
          className="text-field-label-down-line min-height-90px"
          labelClassName="ps-2"
          as="textarea"
          label="Phân loại"
        />
      </div>
      <div className="spaces w-100 justify-content-center pt-10">
        <TextField
          name="ketLuan"
          className="text-field-label-down-line min-height-90px"
          labelClassName="ps-2"
          as="textarea"
          label="Kết luận"
        />
      </div> */}

      <GenerateFormHTML
        isView={isView}
        listFieldAuto={listJsonGenerate}
        handleSave={handlerSaveForm}
        itemEdit={TTDetailKhamBenh}
        isUpdate={true}
      />
    </div>

  );
};

export default TabKhamNgoai;
