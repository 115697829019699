import { FC, useState } from "react";
import { TableCustom } from "../../component/table/table-custom/TableCustom";
import { benhNhan, danhSachLichHen } from "../models/datLichHenModels";
import { benhNhanData } from "../models/datLichHenModels";
import { fakeDataDsHenKham } from "../constants/fakeData";
import { LichHenColumns } from "../columns/LichHenColumns";
import TextField from "../../component/TextField";
import {
  BO_LOC,
  SAP_XEP_THEO,
  trangThaiBenhNhanDatLich,
  benhNhanDatLichStatus
} from "../constants/datLichHenConstants";
import { Col, Row } from "react-bootstrap";
import LabelRequired from "../../component/LabelRequired";
import AutocompleteObjectV2 from "../../component/AutocompleteObjectV2";
import { useFormikContext } from "formik";
import ShowStatus from "../../component/ShowStatus";

type IDSLichHen = {
  benhNhanData?: benhNhanData;
  updatePageData?: () => void;
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleUpdate?: (row: any) => void;
  handlePay?: (row: any) => void;
  handleContextMenu?: (e: any, row: any) => void;
};

export const DanhSachLichHenTable: FC<IDSLichHen> = (props) => {
  let { benhNhanData, updatePageData, handleContextMenu } = props;
  const { values, setFieldValue, handleChange, errors, touched } =
    useFormikContext<benhNhan>();

  return (
    <>
      <div className="d-flex flex-column spaces w-70 ds-hen bg-white pt-10 gap-8">
        <div className="d-flex spaces pr-10 gap-8">
          <div className="text-info fs-4 spaces fw-bold w-50 px-16">
            Danh sách hẹn
          </div>
          <div className="d-flex spaces w-50 gap-6">
            <LabelRequired
              label="Sắp xếp theo"
              className="min-w-85px spaces fw-bold"
            />
            <AutocompleteObjectV2
              options={SAP_XEP_THEO}
              value={values?.gioiTinh}
              name="gioiTinh"
              onChange={(selectedOption) =>
                setFieldValue(selectedOption, "gioiTinh")
              }
              touched={touched?.gioiTinh}
              errors={errors?.gioiTinh}
              className="autocomplete-custom-lich-hen radius spaces width-100 h-25"
            />
          </div>

          <div className="d-flex spaces w-50 gap-6">
            <LabelRequired
              label="Bộ lọc"
              className="min-w-50px spaces pl-4 fw-bold"
            />
            <AutocompleteObjectV2
              options={BO_LOC}
              value={values?.gioiTinh}
              name="gioiTinh"
              onChange={(selectedOption) =>
                setFieldValue(selectedOption, "gioiTinh")
              }
              touched={touched?.gioiTinh}
              errors={errors?.gioiTinh}
              className="autocomplete-custom-lich-hen radius spaces w-100 h-25"
            />
          </div>
          <div className="position-relative spaces w-50 ml-8">
            <TextField
              name="soDienThoai"
              labelClassName="min-w-40px"
              className="ps-29px"
              value={values?.soDienThoai}
              placeholder={"Tìm kiếm"}
            />
            <i className="fa-solid fa-magnifying-glass position-absolute cursor-pointer search-icon"></i>
          </div>
        </div>
        <div className="overflow-auto spaces h-calc-vh-485">
          <TableCustom<danhSachLichHen>
            hasToolbar={false}
            // maxHeight={372}
            data={fakeDataDsHenKham || benhNhanData?.data}
            columns={LichHenColumns}
            handleSearchByPage={updatePageData}
            handleChangeValueInput={handleChange}
            verticalScroll={true}
            handleContextMenu={handleContextMenu}
          />
        </div>
        <ShowStatus status={benhNhanDatLichStatus} />
      </div>
    </>
  );
};

export default DanhSachLichHenTable;
