import { Form, Formik, FormikErrors } from "formik";
import { Dispatch, FC, SetStateAction, useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { AppContext } from "../../../appContext/AppContext";
import { CODE } from "../../../utils/Constant";
import { PhanHeTiepDonContext } from "../../contexts/PhanHeTiepDonContext";
import { createDSChiDinhDV, updateDSChiDinhDV } from "../../services/ChiDinhDVService";
import FormChiDinhDichVu from "./FormChiDinhDichVu";
import { changeStatus, getStatusByTrackerAndObjectIds } from "../../../workflows/services/WfWorkflowsService";
import { WfConstTrackerClinical } from "../../../workflows/Constant";
import { localStorageItem } from "../../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../../auth/core/_consts";
import { useIntl } from "react-intl";

interface Props {
  show?: boolean;
  handleClose: Dispatch<SetStateAction<boolean>>;
  dieuTri?: boolean;
  isSuaPhieu?: boolean;
}

const PageChiDinhDichVu: FC<Props> = (props) => {
  let { show = true, handleClose, isSuaPhieu = false } = props;
  const intl = useIntl();
  const { setIsLoading } = useContext(AppContext);
  const { benhNhanInfo, setBenhNhanInfo, statusChangedEventFunc } = useContext(PhanHeTiepDonContext);
  const currentUser = localStorageItem.get(KEY_LOCALSTORAGE.ACCESS_TOKEN_DECODE);

  const handleSubmit = async (values: any) => {
    let { patient, visit, encounter, orderGroupId } = benhNhanInfo?.thongTinKhamBenh || {};
    let encounterDiagnosisDto = {
      ...values?.encounterDiagnosis,
      patientId: patient?.id,
    };
    let ordersDto = values?.orders?.map((item: any) => {
      return {
        termId: item?.id,
        encounterId: encounter?.id,
        patientId: patient?.id,
        visitId: visit?.id,
        fulfillDeptCode: item?.attributes?.deptLaboratoryCode,
        fulfillDeptName: item?.attributes?.deptLaboratoryName,
      }
    });
    let termIds = values?.orders?.map((item: any) => item?.id);
    let userInfo = {
      indicationPerson: currentUser?.name,
      requestDeptName: currentUser?.departments?.[0]?.name || null
    };
    let createData = {
      ...userInfo,
      encounterDiagnosisDto,
      ordersDto,
    };
    let updateData = {
      // ...userInfo,
      encounterDiagnosis: encounterDiagnosisDto,
      termIds,
      orderGroupId
    };
    try {
      setIsLoading(true);
      let { data } = isSuaPhieu
        ? await updateDSChiDinhDV(updateData)
        : await createDSChiDinhDV(createData);
      if (CODE.SUCCESS === data?.code) {
        let orderGroupIds = data?.data?.orderGroupIds?.join(",");
        let statusRes = await getStatusByTrackerAndObjectIds(WfConstTrackerClinical.ORDER_GROUP, orderGroupIds);
        let statusData = statusRes?.data?.data?.map((item: any) => ({
          objectId: item?.objectId,
          status: item?.newStatusId,
        }));
        await changeStatus(WfConstTrackerClinical.ORDER_GROUP, statusData);
        setBenhNhanInfo({
          ...benhNhanInfo,
          shouldUpdate: !benhNhanInfo?.shouldUpdate,
        });
        statusChangedEventFunc?.();
        toast.success("Cập nhật thành công");
        handleClose(false);
      } else {
        toast.warning("Xảy ra lỗi, vui lòng thử lại!");
      }
    } catch (error) {
      console.error(error);
      toast.warning("Xảy ra lỗi, vui lòng thử lại!");
    } finally {
      setIsLoading(false);
    }
  };

  const validationSchema = Yup.object().shape({
    encounterDiagnosis: Yup.object().shape({
      provisionalDiagnosis: Yup.string().nullable().required(intl.formatMessage({ id: 'VALIDATION.REQUIRE' })),
    })
  });

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  return (
    <Modal
      className="page-full"
      fullscreen
      dialogClassName="h-modal"
      size="xl"
      show={show}
      animation={false}
      centered
      backdropClassName="spaces top-50"
    >
      <Formik<any>
        initialValues={{}}
        validationSchema={validationSchema}
        validateOnChange={true}
        validate={(values) => {
          const errors: FormikErrors<any> = {};
          return errors;
        }}
        onSubmit={handleSubmit}
      >
        <Form id="form-thuoc" className="spaces h-calc-vh-50" onKeyDown={handleKeyPress}>
          <FormChiDinhDichVu
            handleClose={handleClose}
            isSuaPhieu={isSuaPhieu}
          />
        </Form>
      </Formik>
    </Modal>
  );
};

export default PageChiDinhDichVu;
