import React from 'react'

type Props = {}

const TabKetQuaCLS = (props: Props) => {
  return (
    <div>TabKetQuaCLS</div>
  )
}

export default TabKetQuaCLS