import { Column } from "react-table";
import { TableCustomCell } from "../../../component/table/components/TableCustomCell";
import { TableCustomHeader } from "../../../component/table/components/TableCustomHeader";
import { localStorageItem } from "../../../utils/LocalStorage";
import { KEY_LOCALSTORAGE } from "../../../auth/core/_consts";

const currentUser = localStorageItem.get(KEY_LOCALSTORAGE.ACCESS_TOKEN_DECODE);

const DanhSachHDThanhToanColumns: ReadonlyArray<Column<any>> = [
  {
    Header: (props) => (
      <TableCustomHeader<any>
        tableProps={props}
        title={"Tên hợp đồng"}
        className="text-white align-middle bg-pri min-w-50px"
      />
    ),
    id: "age",
    Cell: ({ ...props }) => (
      <>
        <TableCustomCell data={props.data[props.row.index]?.contractName} />
        <TableCustomCell data={props.data[props.row.index]?.companyName} />
      </>
    ),
  },
];

const initialValuesFormThanhToanHD: any = {
  invCode: "",
  invCashier: currentUser?.name,
  invDate: new Date(),
  invTotal: "",
  loaiPhieu: null,
  hinhThuc: null,
  trangThai: "",
  orderSetDataIds: [],
};

export { DanhSachHDThanhToanColumns, initialValuesFormThanhToanHD };
