import { Form, Formik, FormikErrors } from "formik";
import { FC } from "react";
import { Modal } from "react-bootstrap";
import * as Yup from "yup";
import FormChiDinhDichVu from "./FormChiDinhDichVu";

interface Props {
  handleClose: any;
  dieuTri?: boolean;
  setDataDichVu?: any;
  listTerms: any[];
}

const PageChiDinhDichVu: FC<Props> = (props) => {
  let { handleClose, setDataDichVu, listTerms } = props;
  // const { benhNhanInfo, setBenhNhanInfo } = useContext(PhanHeTiepDonContext);

  const handleSubmit = async (values: any) => {
    setDataDichVu(values);
    handleClose();
  };

  const validationSchema = Yup.object().shape({});

  return (
    <Modal
      className="page-full"
      fullscreen
      dialogClassName="h-modal"
      size="xl"
      show={true}
      animation={false}
      centered
      backdropClassName="spaces top-50"
    >
      <Formik<any>
        initialValues={{}}
        validationSchema={validationSchema}
        validateOnChange={true}
        validate={(values) => {
          const errors: FormikErrors<any> = {};
          return errors;
        }}
        onSubmit={handleSubmit}
      >
        <Form id="form-thuoc" className="spaces">
          <FormChiDinhDichVu
            handleClose={handleClose}
            listTermsEdit={listTerms}
          />
        </Form>
      </Formik>
    </Modal>
  );
};

export default PageChiDinhDichVu;
