import { TableCollapseCustom } from "../../component/table/table-collapse/TableCollapseCustom";

const TableDanhSachPhieu = (props: any) => {
  let { dataDanhSachPhieu } = props;

  const danhSachPhieuColumn = [
    {
      title: "Mã sổ", field: "ketQua", className: "spaces width-12",
      render: (rowData: any) => {
        return <span className={`${rowData?.invCanceled ? "text-decoration-line-through text-danger" : ""} ms-n2`}>{rowData?.invLedgerCode || ""}</span>
      }
    },
    {
      title: "Tên sổ", field: "ketQua", className: "spaces width-25",
      render: (rowData: any) => {
        return <span className={`${rowData?.invCanceled ? "text-decoration-line-through text-danger" : ""}`}>{rowData?.invLedgerName || ""}</span>
      }
    },
    {
      title: "Số phiếu", field: "ketQua", className: "spaces width-15",
      render: (rowData: any) => {
        return <span className={`${rowData?.invCanceled ? "text-decoration-line-through text-danger" : ""}`}>{rowData?.invCode || ""}</span>
      }
    },
    {
      title: "Phiếu hoàn", field: "ketQua", className: "spaces width-15",
      render: (rowData: any) => {
        return <span className={`${rowData?.invCanceled ? "text-decoration-line-through text-danger" : ""}`}>{rowData?.invTemporaryRefundCode || ""}</span>
      }
    },
    {
      title: "Mã BN", field: "ketQua", className: "spaces width-15",
      render: (rowData: any) => {
        return <span className={`${rowData?.invCanceled ? "text-decoration-line-through text-danger" : ""}`}>{rowData?.ptPatientCode || ""}</span>
      }
    },
    {
      title: "Tên BN", field: "ketQua", className: "spaces width-18",
      render: (rowData: any) => {
        return <span className={`${rowData?.invCanceled ? "text-decoration-line-through text-danger" : ""}`}>{rowData?.ptPatientName || ""}</span>
      }
    },
  ]

  return (
    <TableCollapseCustom
      columns={danhSachPhieuColumn}
      data={dataDanhSachPhieu || []}
      childrenField="invoices"
      className={`overflow-auto spaces h-calc-vh-340`}
    />
  );
};

export default TableDanhSachPhieu;
